<form [formGroup]="callSheet">
    <div class="row">
        <div class="col-sm-2 form-group radio-button" style="text-align: center;">
            <p-radioButton formControlName="isLastUsed" [value]="true" (click)="disableOtherRadioButton($event)"></p-radioButton>
        </div>
        <div class="col-xs-3 form-group file-name" style=" word-break: break-all;">
            {{callSheet.controls.fileName.value}}
        </div>

        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
            <div class="row">
                <div class="col-sm-6 form-group">
                    <div class="input-wrapper">
                        <span class="sub-header"><strong>Approved By:</strong></span>
                        <input type="text" class="form-control none-border-radius" pInputText formControlName="approver" />
                    </div>
                </div>
                <div class="col-sm-6 form-group approval-date">
                    <div class="input-wrapper">
                        <span class="sub-header"><strong>Approval Date:</strong></span>
                        <datetime-picker format="dd-MMM-yyyy" formControlName="approvalDate" styleClass="float-left-calendar"></datetime-picker>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-sm-2 form-group"></div>
        <div class="col-xs-10 form-group">
            <div>
                {{callSheet.controls.description.value}}
            </div>
            <div *ngIf= "callSheet.controls.uploader.value && callSheet.controls.createDateFormat.value">
                <strong>Uploaded by <span class="uploader-name">{{callSheet.controls.uploader.value}}</span> on {{callSheet.controls.createDateFormat.value}}</strong>
            </div>
        </div>
    </div>
</form>