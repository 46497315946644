import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ValidatorSetting } from '../../../shared/constant';
import { CustomValidators } from '../../../shared/validators';
import { PumpScheduleEventModel } from 'libs/models';

export function createEventWithData(fb: UntypedFormBuilder, eventIndex: number, event: PumpScheduleEventModel): UntypedFormGroup {
  const fg = createEmptyEvent(fb, eventIndex, event.order);
  updateFormGroup(fg, event, false);
  return fg;
}

export function updateFormGroup(fg: UntypedFormGroup, event: PumpScheduleEventModel, emitEvent: boolean){
  const options = {
    emitEvent: false,
    onlySelf: false
  };
  fg.controls.placementMethod.setValue(event.placementMethod, options);
  fg.controls.placementMethodName.setValue(event.placementMethodName, options);
  fg.controls.rate.setValue(event.rate, options);
  fg.controls.actualRate.setValue(event.actualRate, options);
  fg.controls.volume.setValue(event.volume, options);
  fg.controls.topOfFluid.setValue(event.topOfFluid, options);
  fg.controls.length.setValue(event.length, options);
  fg.controls.duration.setValue(event.duration, options);
  fg.controls.actualDuration.setValue(event.actualDuration, options);
  fg.controls.bulkCement.setValue(event.bulkCement, options);
  fg.controls.order.setValue(event.order, options);
}

export function subscribeOn(fg: UntypedFormGroup, event: PumpScheduleEventModel, emitEvent: boolean){
  const options = {
    emitEvent: false,
    onlySelf: false
  };
  fg.controls.placementMethod.setValue(event.placementMethod, options);
  fg.controls.placementMethodName.setValue(event.placementMethodName, options);
  fg.controls.rate.setValue(event.rate, options);
  fg.controls.actualRate.setValue(event.actualRate, options);
  fg.controls.volume.setValue(event.volume, options);
  fg.controls.topOfFluid.setValue(event.topOfFluid, options);
  fg.controls.length.setValue(event.length, options);
  fg.controls.duration.setValue(event.duration, options);
  fg.controls.actualDuration.setValue(event.actualDuration, options);
  fg.controls.bulkCement.setValue(event.bulkCement, options);
  fg.controls.order.setValue(event.order, options);
}

export function createEmptyEvent(fb: UntypedFormBuilder, eventIndex: number, order: number = 0): UntypedFormGroup {
  const maxLength = ValidatorSetting.MAX_DECIMAL_LENGHT;
  const minLenght = ValidatorSetting.MIN_DECIMAL_LENGHT;
  const defaultValue = null;
  return fb.group({
    pumpScheduleEventId: null,
    placementMethod: null,
    placementMethodName: null,
    rate: [{
      value: '',
      disabled: false
    }, Validators.compose([Validators.min(minLenght), Validators.max(maxLength), CustomValidators.validateIsNumber, CustomValidators.validateNegativeNumber])],
    actualRate: [{
      value: '',
      disabled: false
    }],
    volume: [{
      value: eventIndex === 0 ? defaultValue : null,
      disabled: eventIndex === 0
    }, Validators.compose([Validators.min(minLenght), Validators.max(maxLength) , CustomValidators.validateIsNumber])],
    topOfFluid: [{
      value: null,
      disabled: true
    }],
    length: [{
      value: '',
      disabled: true
    }],
    duration: [{
      value: null,
      disabled: true
    }, Validators.compose([Validators.min(minLenght), Validators.max(maxLength), CustomValidators.validateIsNumber])],
    actualDuration: [{
      value: '',
      disabled: false
    }],
    bulkCement: [{
      value: '',
      disabled: false
    }, Validators.compose([Validators.min(minLenght), Validators.max(maxLength), CustomValidators.validateIsNumber])],
    order: order,
  });
}