import { Job } from 'libs/models';

export class IIcemParsedModel {
  jobInfoModel: Job;
  FolderPathContainParsedFiles: string;
  attachments: any[];
  virtualRelativeICemFilePath: string;
  description: string;
  extractedTempFileId: string;

  constructor() {
    this.jobInfoModel = new Job();
    this.FolderPathContainParsedFiles = '';
    this.attachments = [];
    this.virtualRelativeICemFilePath = '';
    this.description = '';
    this.extractedTempFileId = '';
  }
}
