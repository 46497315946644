import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class JobTypeService {

  constructor(private http: HttpService) {
  }

  getJobType(): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/job-type`);
  }
}