import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class JobTechnicalService {
    constructor(private httpService: HttpService) { }

    getBhstTempSource() {
        const bhstTempSource: any = [
            {
                Id: 0,
                Name: 'Customer'
            },
            {
                Id: 1,
                Name: 'Halliburton'
            },
            {
                Id: 2,
                Name: 'Other'
            }
        ];
        return of(bhstTempSource);
    }

    getBhctTempSource() {
        const bhctTempSource: any = [
            {
                Id: 0,
                Name: 'WellCat'
            },
            {
                Id: 1,
                Name: 'API Calculation'
            },
            {
                Id: 2,
                Name: 'iCem'
            }
            ,
            {
                Id: 3,
                Name: 'Other'
            }
        ];
        return of(bhctTempSource);
    }

    getBhpSource() {
        const bhpSource: any = [
            {
                Id: 0,
                Name: 'iCem'
            },
            {
                Id: 1,
                Name: 'Manual calculation'
            },
            {
                Id: 2,
                Name: 'Other'
            }
        ];
        return of(bhpSource);
    }

    getPlugBases() {
        return this.httpService.Get<any>(`${environment.baseUrl}/api/plug-base`);
    }
}