<div class="sidebar-content-wrapper">
    <div class="p-sidebar-header">  
        <button type="button" class="p-ripple p-element p-sidebar-close p-sidebar-icon p-link" (click)="onClose()">
            <span class="p-sidebar-close-icon pi pi-times"></span>
        </button>
    </div>
    <div *ngIf="showLoadingIndicator" class="p-component-overlay" [ngClass]="{'downloading':downloading}">
        <div class="loading-indicator"></div>
    </div>
    <div class="sidebar-header">
        <h1>Download External Documents</h1>
    </div>

    <div class="sidebar-content">
        <div>
            <ifacts-lab-reports (selectionChanged)="labReportsSelectionChanged($event)"></ifacts-lab-reports>
        </div>
        <div>
            <hdf-document-sidebar (selectionChanged)="hdfFilesSelectionChanged($event)"
                [job]="data.job"></hdf-document-sidebar>
        </div>
    </div>

    <div class="sidebar-footer">
        <a href="javascript: void(0)" class="btn lnk-cancel" (click)="onClose()">Cancel</a>
        <button type="button" class="btn primary-btn" [lockableElement]="'job_completed'" [disabled]="downloading || !canDownload"
            pTooltip="Download" tooltipPosition="top" showDelay="100" (click)="download()" hideDelay="200">
            DOWNLOAD
        </button>
    </div>
</div>