import { UntypedFormGroup } from "@angular/forms";
import { CompatibilityConfirmation } from "./compatibility-confirmation";
import { JobCasingEquipment } from './job-casing-equipment';

export class JobCasingEquipmentParameters {
  constructor(
    public id: string = "",
    public jobId: string = "",
    public casingThread: string = null,
    public casingThread2: string = null,
    public casingThread3: string = null,
    public casingWeight: number = null,
    public casingWeight2: number = null,
    public casingWeight3: number = null,
    public minIDBoreHole: number = null,
    public minIDBoreHole2: number = null,
    public minIDBoreHole3: number = null,
    public minIDPipe: number = null,
    public minIDPipe2: number = null,
    public minIDPipe3: number = null,
    public maxODCasing: number = null,
    public maxODCasing2: number = null,
    public maxODCasing3: number = null,
    public maxODPlug: number = null,
    public maxODPlug2: number = null,
    public maxODPlug3: number = null,
    public requestFloatsBeAutofilled: string = "",
    public requestFloatsBeAutofilledComment: string = "",
    public visuallyCheckedMaterialNumbers: string = "",
    public visuallyConfirmedQuantityDelivered: string = "",
    public jobCasingEquipmentEditList: JobCasingEquipment[] = [],
    public maxPressure: number = null,
    public maxTemperature: number = null,
    public compatibilityConfirmation: CompatibilityConfirmation=null,
  ) { }

  public static toModel(fg: UntypedFormGroup): JobCasingEquipmentParameters {
    const res: JobCasingEquipmentParameters = fg.getRawValue();
    return res;
  }
}
