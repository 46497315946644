export enum OptionControlsObjective {
  Type = 0,
  Requirement = 1,
  Justification = 2,
  TargetFitLot = 3,
  FinalPumpPressureRequirement = 4,
  LossInduced = 5,
  Target = 6,
  MarginOfError = 7,
  Density = 8,
  Volume = 9,
  Rate = 10,
  TargetOtherJobObjective = 11,
  UoM = 12,
  ReasonOfOther = 13
}