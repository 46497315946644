import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { TestTableName, ThickeningTimeFields } from '../stage-test-results/stage-test-results.constant';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { StageStateManager } from '../../state/stage-state-manager'; 

@Component({
  selector: 'thickening-time-test-result',
  templateUrl: './thickening-time-test-result.component.html',
  styleUrls: ['./thickening-time-test-result.component.scss']
})
export class ThickeningTimeTestResultComponent extends PumpScheduleTestResultBase implements OnInit {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
    this.updateThickeningTime();
  };
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
    this.updateThickeningTime();
  }
   
  @Input() stageState: StageStateManager;
  @Input() canEdit: boolean;
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();

  ThickeningTimeFields = ThickeningTimeFields;
  get test(): PumpScheduleStageTest | null {
    if (this.tests.length === 1 && this.tests[0]) {
        return this.tests[0];
    }

    const validatedOrFinishedTests = this.tests.filter(this.isTestFinishedOrValidated);

    if (validatedOrFinishedTests.length === 1 && validatedOrFinishedTests[0]) {
      return validatedOrFinishedTests[0];
    }

    return null;
  }

  get temperature(): string {
    const temperatureFieldMapping = {
      [this.IFactTestName.ThickeningTime]: ThickeningTimeFields.Temperature,
      [this.IFactTestName.ThickeningTimeOnOffOn]: ThickeningTimeFields.TestTemperature,
    };

    const test = this.test;
    return this.getField(test, temperatureFieldMapping[test?.testName]);
  }

  constructor() {
    super(TestTableName.ThickeningTime);
  }

  ngOnInit(): void {
    this.stageState.selectedFluid$.subscribe((res) => {
      this.updateThickeningTime();
    });
  }
  updateThickeningTime() {
   
    if (this.stageState &&
      // stage should be only cements
      (this.stageState.isCement && !this.stageState.isShutdownStage) &&
      //untill its manually overridden keep updating the TT value 
      !this.stageState.placementTimeForm.value.isManualyThickeningTime 
    ) {
      if (this.test &&
        this.isTestFinishedOrValidated(this.test)) {
        this.stageState.placementTimeForm.controls['thickeningTime'].setValue(this.getField(this.test, ThickeningTimeFields.BC70));
       
      } else {
        this.stageState.placementTimeForm.controls['thickeningTime'].reset(); 
      }
      this.stageState.placementTimeForm.controls['is70BCOverrideThickeningTime'].setValue(true);
      this.stageState.placementTimeForm.updateValueAndValidity(); 
    }
    if (this.stageState &&
      // stage should be only cements
      (this.stageState.isCement && !this.stageState.isShutdownStage)
    ) {
      if (this.test &&
        this.isTestFinishedOrValidated(this.test)) {
        this.stageState.placementTimeForm.controls['thickeningTime70BC'].setValue(this.getField(this.test, ThickeningTimeFields.BC70));
      }
      else {
        this.stageState.placementTimeForm.controls['thickeningTime70BC'].reset();
      }
      this.stageState.placementTimeForm.updateValueAndValidity();
    }  
  }
  
  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }
}
