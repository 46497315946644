import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { forkJoin, Subscription } from 'rxjs';

import { UserSettingService, ApplicationStateService, EventHubService } from 'libs/shared/services';
import { IUserSettingMetadataListModel, IUserSettingPayloadModel, IUserSettingMetadataModel, UserSettingContract } from '../../models';
import { MessageService } from 'primeng/api';
import { UnitClassModel, UserUnitSystemSettingInfoModel, UnitMeasureModel } from 'libs/models';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { HELP_FILES } from 'libs/constants';
import { environment } from 'libs/environment';

@Component({
  selector: 'gs-dash-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingComponent implements OnInit {

  isShowUserSettingSidebar: boolean = false;

  unitOfMeasureSelections = {
    API: true,
    SI: false,
    Custom: false
  }

  // User Setting - Unit of Measure
  api: Boolean = true;
  si: Boolean = false;
  cust: Boolean = false;
  loaded: Boolean = false;
  defaultApiId = '356e1964-d7ca-4a32-96aa-c59bc7c07d2f';
  defaultSiId = 'aaa63d5f-1576-497a-9d9f-e7d4be724062';
  unitSystemTypes: any[];
  unitClasses: UnitClassModel;
  unitSystemSetting: any[];
  currentUserUnits: UserUnitSystemSettingInfoModel;
  flag: boolean = false;
  // User Setting - Other Settings
  userSettingsDirty: IUserSettingPayloadModel;
  settingMetadata: IUserSettingMetadataListModel = {
    languages: [],
    paperSizes: []
  } as IUserSettingMetadataListModel;

  selectedReportLanguage: any;
  subscription = new Subscription();

  enableUserSettingForLanguage = true; // Hide For VIDA 1.0, this will be re-enabled for VIDA 1.1

  
  constructor(
    private modalManagementService: ApplicationStateService,
    public userSettingService: UserSettingService,
    public unitConversionService: UnitConversionService,
    private messageService: MessageService,
    private eventHubService: EventHubService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.initMasterData();
    this.getUnitSystemSetting(this.defaultApiId);
    this.getUserSetting(this.userSettingService.userSettings);
    this.getUserUnitMeasurement(this.unitConversionService.userUnits);

    this.modalManagementService.openUserSetting$.subscribe(() => {
      this.subscription.add(this.userSettingService.getUserSettingMetadata().subscribe(data => 
        {
          this.settingMetadata = { ...data }
          this.setSelectedReportLanguage();
        }
      ));
      this.subscription.add(this.unitConversionService.getAllUnitSystemTypes().subscribe(res => this.unitSystemTypes = res));
      this.getUserSetting(this.userSettingService.userSettings);
      this.getUserUnitMeasurement(this.unitConversionService.userUnits, this.showUserSetting);
      this.loaded = false;
    });
  }

  updateSelectedUnit(event, i) {
    if (this.currentUserUnits.unitSystemSettings[i] && this.currentUserUnits.unitSystemSettings[i].unitMeasure) {
      this.currentUserUnits.unitSystemSettings[i].unitMeasure.id = event.value.id;
    }
  }

  updateReportLanguage(event): void {
    const value = event.value;
    this.userSettingsDirty.languageId = value.reportsLanguageId;
    this.cd.markForCheck();
  }

  setSelectedReportLanguage(): void {
    if (this.settingMetadata && this.userSettingsDirty) {
      this.selectedReportLanguage = this.settingMetadata.languages.find((x: any) => x.reportsLanguageId === this.userSettingsDirty.languageId);
    }
  }

  showUserSetting() {
    this.isShowUserSettingSidebar = true;
  }

  hideUserSetting() {
    this.isShowUserSettingSidebar = false;
  }

  onHideSidebar() {
    // work around to fix memory leak
    this.subscription.unsubscribe();    
    this.subscription = new Subscription();
  }

  getUserUnitMeasurement(units, cb?: Function) {
    // User Setting - Unit of Measure
    // Update local currentUserUnits variable and Unit of Measurement Service
    this.unitConversionService.userUnits = { ...units };
    this.currentUserUnits = { ...units };

    if (this.currentUserUnits.unitSystemId === this.defaultApiId) {
      this.unitsCheckedApi();
      this.getUnitSystemSetting(this.defaultApiId, cb);
    } else if (this.currentUserUnits.unitSystemId === this.defaultSiId) {
      this.unitsCheckedSi();
      this.getUnitSystemSetting(this.defaultSiId, cb);
    } else {
      this.unitsCheckedCustom(cb);
    }

    // Broadcast event to other components after loading new settings
    if (this.flag) {
      this.unitConversionService.onSaveUnit$.next();
      this.flag = false;
    }

    this.cd.markForCheck();
  }

  getSupportedReportLanguages(): IUserSettingMetadataModel[] {
      return this.settingMetadata.languages;
  }

  getUserSetting(settings) {
    // User Setting - Other Settings
    this.userSettingService.userSettings = { ...settings };

    // Update user setting payload 
    this.userSettingsDirty = {
      languageId: this.userSettingService.userSettings.language.reportsLanguageId,
      paperSizeId: this.userSettingService.userSettings.paperSize.reportsPaperSizeId,
    };
    this.setSelectedReportLanguage();
    this.cd.markForCheck();
  }

  setUserSettings(settings: UserSettingContract){
    this.userSettingService.setUserSettings(settings);
    this.unitConversionService.setUserUnits(settings.UnitSystemSettings);
  }

  setPaperSize(paperSizeId) {
    this.userSettingsDirty = { ...this.userSettingsDirty, paperSizeId };
    this.cd.markForCheck();
  }

  // User Setting - Unit of Measure
  chooseUnits(typeName, typeId) {
    const type = typeId;
    switch (typeName) {
      case 'API': {
        this.unitsCheckedApi();
        this.getUnitSystemSetting(type);
        break;
      }
      case 'SI': {
        this.unitsCheckedSi();
        this.getUnitSystemSetting(type);
        break;
      }
      case 'Custom': {
        this.unitsCheckedCustom();
        this.currentUserUnits = this._deepClone(this.currentUserUnits);
        break;
      }
    }

    this.cd.markForCheck();
  }

  unitsCheckedApi() {
    this.unitOfMeasureSelections.API = true;
    this.unitOfMeasureSelections.SI = false; 
    this.unitOfMeasureSelections.Custom = false;   
  }

  unitsCheckedSi() {
    this.unitOfMeasureSelections.API = false;
    this.unitOfMeasureSelections.SI = true;
    this.unitOfMeasureSelections.Custom = false;
  }

  unitsCheckedCustom(cb?: Function) {
    this.unitOfMeasureSelections.API = false;
    this.unitOfMeasureSelections.SI = false;
    this.unitOfMeasureSelections.Custom = true;

    if (cb) {
      cb.call(this);
    }
  }

  getUnitSystemSetting(type, cb?: Function) {
    const self = this;
    this.unitConversionService.getUnitSystemSetting(type).subscribe((res) => {
      this.unitSystemSetting = res;
      if (cb) {
        cb.call(self);
      }
      this.cd.markForCheck();
    })
  }

  getUnitsMeasureByUnitClassId(id): UnitMeasureModel[] {
    const unitClass = this.unitConversionService.unitMeasures$.value.find(x => x.unitClass.id === id);
    return unitClass ? unitClass.unitMeasures : [];
  }

  updateAllUserSetting() {
    // Prepare user unit of measurement payload 
    const settings: UserSettingContract = this.userSettingsDirty as UserSettingContract;

    if (this.unitOfMeasureSelections.API) {
      this.currentUserUnits.unitSystemId = this.defaultApiId;
      settings.UnitSystemSettings = {
        unitSystemId: this.defaultApiId,
        unitSystemSettings: this.unitSystemSetting
      };
    }

    if (this.unitOfMeasureSelections.SI) {
      this.currentUserUnits.unitSystemId = this.defaultSiId;
      settings.UnitSystemSettings = {
        unitSystemId: this.defaultSiId,
        unitSystemSettings: this.unitSystemSetting
      };
    }

    if (this.unitOfMeasureSelections.Custom) {
      this.currentUserUnits.unitSystemId = '01997acf-def3-41ad-ac6c-63d2330b663d';
      settings.UnitSystemSettings = this.currentUserUnits;
    }
    
    this.userSettingService.updateUserSettings(settings).subscribe(() => {
      this.setUserSettings(settings);
      this.hideUserSetting();
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `User Settings has been saved successfully.` });
      this.cd.markForCheck();
      this.eventHubService.updateUserSetting$.next('update userSetting');
      this.unitConversionService.onSaveUnit$.next();
    }, () => {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `An error occurred. Please try again.` });
    });
  }

  private initMasterData() {    
    this.subscription.add(
      this.userSettingService.getUserSettingMetadata().subscribe((data) => {
        this.settingMetadata = { ...data };
        this.setSelectedReportLanguage();
        this.cd.markForCheck();
      })
    );
    this.subscription.add(
      this.unitConversionService.getAllUnitSystemTypes().subscribe((res) => {
        this.unitSystemTypes = res;
        this.cd.markForCheck();
      })
    );
    this.subscription.add(
      this.unitConversionService.getAllUnitClasses().subscribe((res) => {
        this.unitClasses = res;
        this.cd.markForCheck();
      })
    );
  }

  unitsChanged(controlName: string, evt: any) {
    if(this.loaded) {
      evt.originalEvent.currentTarget.children[0].attributes.class.nodeValue = "p-checkbox-icon pi pi-check";
      const parent = evt.originalEvent.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
      if(controlName == "API") {
        parent.children[1].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[2].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
      } else if(controlName == "SI") {
        parent.children[0].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[2].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
      } else if(controlName == "Custom") {
        parent.children[0].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[1].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
      }
    }
  }

  checkUnitType(typeName: string, elem: any): Boolean {
    if(!this.loaded){
      const parent = elem.cd._lView[0].parentElement.parentElement.parentElement.parentElement.parentElement.children[3];
      if(this.unitOfMeasureSelections.API) {
        parent.children[0].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon pi pi-check";
        parent.children[1].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[2].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
      } else if(this.unitOfMeasureSelections.SI) {
        parent.children[0].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[1].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon pi pi-check";
        parent.children[2].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
      } else if(this.unitOfMeasureSelections.Custom) {
        parent.children[0].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[1].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon";
        parent.children[2].children[0].children[0].children[0].children[0].children[1].children[0].attributes.class.nodeValue = "p-checkbox-icon pi pi-check";
      }
      this.loaded = true;
    }
    return this.unitOfMeasureSelections[typeName];
  }

  openHelp() {
    window.open(HELP_FILES.Settings);
  }

  private _deepClone<T>(obj: T): T {

    if (!obj) {
       return null;
    }

    return (JSON.parse(JSON.stringify(obj)));
  }
    
}
