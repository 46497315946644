import { isEmpty } from 'libs/helpers/lodash-helper';

export function PDropdownDataPumpScheduleHelper(labelKey: string, valueKey: string, disabledKey?: string, obj?: Array<any>) {
  const sanitizedData = (items) => {
    return items.map(item => (
      {
        label: item[labelKey],
        value: item[valueKey],
        disabled: item[disabledKey]
      }
    ));
  };

  if (isEmpty(obj)) {
    return sanitizedData;
  }

  return sanitizedData(obj);
}