export class ReportTemplate {
    id: string;
    fileName: string;
    filePath: string;
    reportTemplateTypeName: string;
    downloadPath: string;
    reportTemplateTypeId: string;
    otherType: string;
    description: string;
    isGlobal: boolean;
    uploadedDate: Date;
    reportTemplateFilePath: string;
    reportTemplateFileName: string;
    reportTemplateId: string;
}

export interface CreateJobReportModel {
    jobId: string;
    templateId: string;
    reportPath: string;
}

export interface ReportPayload {
    wellId: string;
    jobId: string;
    templatePath: string;
    templateTypeName: string;
    username: string;
}