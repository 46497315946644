export enum JobObjectiveOption {
  Toc = 0, //
  TocLead = 1,
  TocTail = 2,
  TopOfSpacer = 3,
  BumpedPlugs = 4, //
  CompetentShoe = 5, //
  FinalBumpPressure = 6, //
  DensityPerformance = 8,
  SetSuccessfulPlug = 9,
  SuccessfullInjectionTest = 10,
  PumpedAsDesign = 11,
  Other = 12,
  HSEObjective = 13, // 365684:Control Point #1: DOS
  PlannedLosses = 14,
  MaintainReturns = 15,
  MinimizeWaitOnCement = 16,
  ZonalIsolation = 17,
}
