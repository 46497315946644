import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environment';
import { Equipment, EquipmentType, JobSurfaceEquipment, JobSurfaceEquipmentState } from '../../models/entities/equipment';
import { HttpParams } from '@angular/common/http';
import { ActualCO2Emissions, CarbonFootPrintCp4Model } from 'apps/vida/src/modules/control-point/models/CO2Emissions';

@Injectable({
  providedIn: 'root'
})
export class JobSurfaceEquipmentService {

  constructor(private http: HttpService) { }

  public getJobSurfaceEquipment(jobId: string): Observable<JobSurfaceEquipment[]> {
    return this.http.Get<JobSurfaceEquipment[]>(`${environment.baseUrl}/api/control-point/${jobId}/job-surface-equipment`).pipe(shareReplay());
  }

  public getJobSurfaceEquipmentState(jobId: string): Observable<JobSurfaceEquipmentState> {
    return this.http.Get<JobSurfaceEquipmentState>(`${environment.baseUrl}/api/control-point/${jobId}/job-surface-equipment-state`).pipe(shareReplay());
  }

  public updateJobSurfaceEquipment(jobId: string, jobSurfaceEquipment: JobSurfaceEquipment[]): Observable<boolean> {
    return this.http.Put<boolean>(`${environment.baseUrl}/api/control-point/${jobId}/job-surface-equipment`, JSON.stringify(jobSurfaceEquipment));
  }

  public updateJobSurfaceEquipmentState(jobId: string, jobSurfaceEquipmentState: JobSurfaceEquipmentState): Observable<boolean> {
    return this.http.Put<boolean>(`${environment.baseUrl}/api/control-point/${jobId}/job-surface-equipment-state`, JSON.stringify(jobSurfaceEquipmentState));
  }

  public updateActualCo2Emissions(jobId: string): Observable<ActualCO2Emissions> {
    return this.http.Post<ActualCO2Emissions>(`${environment.baseUrl}/api/actualCo2Emissions/${jobId}/CP4`, {});
  }

  public getActualCo2Emissions(jobId: string): Observable<ActualCO2Emissions> {
    return this.http.Get<ActualCO2Emissions>(`${environment.baseUrl}/api/actualCo2Emissions/${jobId}`);
  }

  public getEquipmentTypes(groupId: string, isLocal: boolean): Observable<EquipmentType[]> {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/equipment-types?groupId=${groupId}&isLocal=${isLocal}`;
    return this.http.Get<EquipmentType[]>(url).pipe(shareReplay());
  }

  public getEquipmentNames(groupId: string, equipmentTypeId: string, isLocal: boolean): Observable<string[]> {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/equipment-names?groupId=${groupId}&equipmentTypeId=${equipmentTypeId}&isLocal=${isLocal}`;
    return this.http.Get<string[]>(url).pipe(shareReplay());
  }

  public getObjectTypes(groupId: string, equipmentTypeId: string, equipmentName: string, isLocal: boolean): Observable<string[]> {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/object-types?groupId=${groupId}`
                                                                                         + `&equipmentTypeId=${equipmentTypeId}`
                                                                                         + `&equipmentName=${encodeURIComponent(equipmentName)}`
                                                                                         + `&isLocal=${isLocal}`;
    return this.http.Get<string[]>(url).pipe(shareReplay());
  }

  public getEquipment(groupId: string, equipmentTypeId: string, equipmentName: string, objectType: string, isLocal: boolean): Observable<Equipment> {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/equipment?groupId=${groupId}`
                                                                                      + `&equipmentTypeId=${equipmentTypeId}`
                                                                                      + `&equipmentName=${encodeURIComponent(equipmentName)}`
                                                                                      + `&objectType=${encodeURIComponent(objectType)}`
                                                                                      + `&isLocal=${isLocal}`;
    return this.http.Get<Equipment>(url).pipe(shareReplay());
  }

  public willSurfaceEquipmentBeOverwritten(jobId: string, newJobTypeId: string, groupId: string): Observable<boolean> {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/will-be-overwritten?jobId=${jobId}&newJobTypeId=${newJobTypeId}&groupId=${groupId}`;
    return this.http.Get<boolean>(url).pipe(shareReplay());
  }

  public isSurfaceEquipmentSectionVisible(jobId: string) {
    const url = `${environment.baseUrl}/api/control-point/job-surface-equipment/is-section-visible?jobId=${jobId}`;
    return this.http.Get<boolean>(url).pipe(shareReplay());
  }

  public getJobSurfaceEquipmentsFromSap(jobId: string, salesOrder: string, salesOrderLineItem): Observable<any> {
    let params = new HttpParams();
    params = params.append('salesOrder', salesOrder);
    params = params.append('salesOrderLineItem', salesOrderLineItem);
    return this.http.GetWithParams<JobSurfaceEquipment[]>(`${environment.baseUrl}/api/control-point/${jobId}/job-surface-equipment-from-sap`, params);
  }
}
