// Reference & Enhanced
// https://github.com/BioPhoton/custom-control-poc/blob/master/src/app/page/basic-usage/directives/custom-value-accessor-and-form-control-ref/custom-value-accessor-and-form-control-ref.directive.ts

import { Directive, ElementRef, forwardRef, Input, Renderer2, HostListener, AfterViewInit, Injector } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Directive({
  selector: '[dFormValueAccessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormValueAccessorDirective),
      multi: true
    }
  ],
})
export class FormValueAccessorDirective extends AbstractControlDirective implements ControlValueAccessor, AfterViewInit {

  control: AbstractControl; // Reference to the formControl
  @Input() protected formControlName: string; // The formControlName in the parent
  _value: any = ''; // The internal data model
  _focus: any = ''; // The internal focus state
  _disabled: any = ''; // The internal disabled state
  protected composing = false; // The internal state of composing input

  _propagateChange = (_: any) => { };
  _propagateTouched = () => { };

  constructor(
    protected inj: Injector,
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
  ) {
    super();
  }

  ngAfterViewInit() {
    const ngControl = this.inj.get(NgControl);
    this.control = ngControl ? ngControl.control : null;
  }

  writeValue(value: any): void {
    this.renderViewValue(value);
  }

  registerOnChange(fn: (_: any) => void): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.renderViewDisabled(isDisabled);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    this._handleInput(value);
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    this._handleFocus(true);
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    this._handleFocus(false);
  }

  private _handleInput(value: any): void {
    this.writeValueFromViewToModel(value);
  }

  private _handleFocus(isFocus: boolean): void {
    this._focus = isFocus;
    if (!isFocus) {
      this._propagateTouched();
    }
    this.renderViewFocus(isFocus);
  }

  // OPEN FOR OVERRIDE
  writeValueFromViewToModel(value: any) {
    if (value !== this._value) {
      this._value = value;
      this._propagateChange(value);
    }
  }

  renderViewValue(value: any) {
    const normalizedValue = value == null ? '' : value;
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', normalizedValue);
  }

  renderViewDisabled(isDisabled: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  renderViewFocus(isFocus: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'focus', isFocus);
  }
}
