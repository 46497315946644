import { Component, OnInit } from '@angular/core';
import { DynamicSidebarRef } from 'libs/ui';
import { JobLogFileModel } from '../../../control-point/models/job-log-file.model';
import { Subscription } from 'rxjs';
import { HttpCommanderService, ApplicationStateService } from 'libs/shared/services';
import { VidaFileType, CommonMessageText } from '../../../shared/constant';
import { UntypedFormArray } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { environment } from 'libs/environment';

@Component({
  selector: 'dash-job-log-sidebar',
  templateUrl: './job-log-sidebar.component.html',
  styleUrls: ['./job-log-sidebar.component.scss']
})
export class JobLogSidebarComponent implements OnInit {
  data: any;
  loadingSubscription: Subscription;
  lastUsedFile: JobLogFileModel;
  disableSaveButton = true;
  fileList: JobLogFileModel[] = [];
  vidaFileType: VidaFileType = VidaFileType.JobLog;
  filesFg: UntypedFormArray;
  errorMessage: null;

  get jobId(): string {
    return this.data?.jobId;
  };
  get wellId(): string {
    return this.data?.wellId;
  }
  get groupId(): string {
    return this.data?.groupId;
  };
  get destinationFolderPath(): string {
    return this.data?.path;
  };

  constructor(
    private dynamicSidebarRef: DynamicSidebarRef<JobLogSidebarComponent>,
    private hcService: HttpCommanderService,
    private messageService: MessageService,
    private applicationStateService: ApplicationStateService) {
  }

  ngOnInit() {
    this.disableSaveButton = !(this.data?.canEdit);
    this.loadFiles();
  }

  private loadFiles(){
    this.loadingSubscription = this.hcService.getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.JobLog)
    .subscribe(({ statusCode, result }) => {
      if (statusCode === 200) {
        this.handleDataFromHC(result);
      }
    });
  }

  public setFileList(files: JobLogFileModel[]) {
    this.fileList = files;
    const lastUserFile = this.fileList.find(x => x.isLastUsed);
    if (lastUserFile) {
      this.lastUsedFile = lastUserFile;
    } else {
      this.lastUsedFile = null;
    }
  }


  uploadSuccess(event) {
    if (event.isSuccess) {
      this.loadFiles();
      this.disableSaveButton = false;
    }
  }

  uploadInprogress(event) {
    if (event) {
      this.disableSaveButton = true;
    }
  }

  uploadCancel(event) {
    this.close();
  }

  public save() {
    this.loadingSubscription = this.hcService.setPrimaryFile(this.lastUsedFile ? this.lastUsedFile.virtualRelativeFilePath : ''
        , this.jobId
        , this.wellId
        , VidaFileType.JobLog).subscribe((data: any) => {
      if (data.statusCode === 200) {
        this.refreshLastUsedList();
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.JOB_LOG_FILE.SUCCESS });
        this.applicationStateService.$fileSelected.next({type: 'Job Log', filesList: this.fileList});
      } else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: CommonMessageText.JOB_LOG_FILE.FAILED });
      }
    });
    this.close(false);
  }

  public cancel() {
    this.close();
  }

  private close(isCanceled: boolean = true) {
    this.dynamicSidebarRef.close(isCanceled);
  }

  private handleDataFromHC(result: any) {
    var files = result.map(file => Object.assign(new JobLogFileModel, file));
    this.setFileList(files);
  }

  private refreshLastUsedList(){
    this.fileList.forEach(f => {
      f.isLastUsed = this.lastUsedFile === f;
    });
  }
}
