<div class="panel panel-custom no-padding custom-panel" [formGroup]="pumpScheduleForm">
    <div class="panel-heading">Liner Job Parameters</div>
    <div class="panel-body">
        <div class="ui-table">
            <table>
                <thead>
                    <tr>
                        <th>Circulation Method above Liner{{ isLinerParamsRequired ? '*' : '' }}</th>
                        <th>Length of Cement above Liner (Gauge Hole){{ isLinerParamsRequired ? '*' : '' }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p-dropdown formControlName="linerCirculationMethod"
                                [options]="LinerCirculationMethods"
                                placeholder="Choose Circulation Method"
                                [lockableElement]="'Liner_CirculationMethod'">
                            </p-dropdown>
                        </td>
                        <td class="vertical-align-middle">
                            <div class="input-unit" [lockableElement]="'Liner_CementLength'">
                                <input-unit [maxlength]="'6'" [pKeyFilter]="'pint'" formControlName="linerCementLength" unit={{UnitType.Distance}}></input-unit>
                            </div>
                            <div class="custom-unit" dUnitName={{UnitType.Distance}}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div  class="linear-doc">
                                <span>
                                    General Liner Engineering Guidelines: 
                                    <a (click)="openLinerDocment($event, documentKeyword.GeneralLinear)" class="link-warning">
                                       <u>GD-GL-HAL-CMT-100J</u> 
                                    </a>
                                </span>
                            </div>
                            <div *ngIf="pumpScheduleForm.controls.isVersaFlexLiner.value" class="linear-doc">
                                <span>
                                    Halliburton Expandable Liner Hanger Cementing Guidelines: 
                                    <a (click)="openLinerDocment($event, documentKeyword.ExpandableLinear)" class="link-warning">
                                        <u>GD-GL-HAL-CMT-100K</u>
                                    </a>
                                </span>
                            </div>                            
                        </td>
                    </tr>                    
                </tbody>
            </table>
        </div>
        <div class="ui-table">
            <table>
                <thead>
                    <tr>
                        <th>Is This a VersaFlex Liner?{{ isLinerParamsRequired ? '*' : '' }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <p-dropdown formControlName="isVersaFlexLiner"
                                class="versaFlexOptions"
                                [options]="VersaFlexOptions"
                                [lockableElement]="'Liner_IsVersaFlex'"
                                placeholder=" ">
                            </p-dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
