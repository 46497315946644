import { Injectable } from '@angular/core';
import { PumpSchedule } from 'libs/models/entities/pump-schedule';
import { PumpScheduleService } from './pump-schedule.service';
import { PumpScheduleCOGSModel, PumpScheduleStageCOGSModel, PumpScheduleStageMaterialCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { PumpScheduleStageModel} from 'libs/models';
import { MaterialCOGS } from 'libs/models/entities/material-cogs-calculation-result';
import { MaterialService } from 'libs/shared/services/material.service';
import { NoCogsMaterialModel } from '../../shared/models/no-cogs-material.model';
import { NoMaterialDataComponent } from '../../shared/components/no-material-data-modal/no-material-data-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MODAL_ID } from 'libs/constants/modal-id.constants';

@Injectable()
export class PumpScheduleLogic {
    constructor(
        private pumpScheduleService: PumpScheduleService,
        private materialService: MaterialService,
        private modalService: BsModalService) { }
    saved: boolean = false;
    allJobMaterialCOGS: PumpScheduleStageMaterialCOGSModel[] = [];    

    public getTotalCOGS(pumpSchedule: PumpSchedule): PumpScheduleCOGSModel {
        const stageCOGS: PumpScheduleStageCOGSModel[] = [];
        pumpSchedule.stages.forEach((stage: PumpScheduleStageModel) => {
            const cogsModel: PumpScheduleStageCOGSModel = {
                id: stage.id,
                totalCOGS: stage.totalCOGS,
                fluidMaterials: []
            };
            stage.fluidMaterials.forEach((materialModel: PumpScheduleStageMaterialCOGSModel) => cogsModel.fluidMaterials.push({
                id: materialModel.id,
                totalCOGS: materialModel.totalCOGS
            }));
            stageCOGS.push(cogsModel);
        });
        return { totalCOGS: pumpSchedule.totalCOGS, stages: stageCOGS} as PumpScheduleCOGSModel;
    }

    public updateTotalCOGS(materialCOGS: MaterialCOGS[], pumpSchedule: PumpSchedule): boolean {        
        let valueChanged = false; 
        if (pumpSchedule && materialCOGS.every(x => x.materialId)) {
            const stageCOGS: PumpScheduleStageCOGSModel[] = [];               
            const previousJobTotalCOGSValue = pumpSchedule.totalCOGS;             
            
            materialCOGS.forEach(res => {
              const material =  this.allJobMaterialCOGS.find(x => x.id === res.materialId);
              if (!material) {
                const materialModel = new PumpScheduleStageMaterialCOGSModel();
                materialModel.id = res.materialId;
                materialModel.totalCOGS = res.materialCost;
                this.allJobMaterialCOGS.push(materialModel);
              } else {
                  material.totalCOGS = res.materialCost;
              }
            });  
            
            pumpSchedule.stages.forEach((stage, index) => {
                        
                const stageCogsModel: PumpScheduleStageCOGSModel = {
                    id: stage.id,
                    totalCOGS: stage.totalCOGS,
                    fluidMaterials: []
                };

                if (stage.fluidMaterials) {
                    stage.fluidMaterials.filter(x => x.id).forEach(fluidMaterial => {                                     
                        let material = this.allJobMaterialCOGS.find(x => x.id === fluidMaterial.id);                    
                        if (!material) {
                            material = {
                                id: fluidMaterial.id,
                                totalCOGS: null
                            } as PumpScheduleStageMaterialCOGSModel;
                            
                            const iFactsFluidMaterial = stage.slurry ? stage.slurry.fluidMaterial.find(x => x.id === fluidMaterial.fluidMaterialId) : null;
                            material.totalCOGS = iFactsFluidMaterial && iFactsFluidMaterial.materialType && this.materialService.isWater(iFactsFluidMaterial.materialType) ? 0 : fluidMaterial.totalCOGS;                                                   
                        }
                        if (!stageCogsModel.fluidMaterials.find(x => x.id === material.id)) {
                            stageCogsModel.fluidMaterials.push(material);  
                        }                         
                    });
                }                   
                stageCogsModel.totalCOGS = stageCogsModel.fluidMaterials && stageCogsModel.fluidMaterials.length === 0 ? 0 
                                : (stageCogsModel.fluidMaterials && stageCogsModel.fluidMaterials.every(x => x && (x.totalCOGS || x.totalCOGS === 0)) ? +stageCogsModel.fluidMaterials.sum(x => x.totalCOGS).toFixed(2)
                                    : null);
                stageCOGS.push(stageCogsModel);
            });           
            const totalJobCOGS = stageCOGS.every(x => x.totalCOGS || x.totalCOGS === 0) ? +stageCOGS.sum(x => x.totalCOGS).toFixed(2) : null;
            valueChanged = previousJobTotalCOGSValue !== totalJobCOGS; 
            const jobCOGSModel = { totalCOGS: totalJobCOGS, stages: stageCOGS } as PumpScheduleCOGSModel;                    
            if (valueChanged && !this.saved && pumpSchedule.pumpScheduleId) {
                // US 391816 - Save once after pump schedule load in case of price changed
                this.saveTotalCOGS(pumpSchedule.pumpScheduleId, jobCOGSModel);
                this.saved = true;      
                this.allJobMaterialCOGS = [];
            }                  
        }
        return valueChanged; 
    }

    private saveTotalCOGS(pumpScheduleId: string, pumpScheduleCOGS: PumpScheduleCOGSModel): void {
        this.pumpScheduleService.setTotalCogs(pumpScheduleId, pumpScheduleCOGS).subscribe();
    }

    public allowSave() {
        this.saved = false;
    }

    public openCogsHelp(fluids: any[], groupName: string, group: string) {
        const noCogsMaterials: NoCogsMaterialModel[] = [];
        let allMaterials: any[] = [];


        if (fluids && fluids.length > 0) {
            fluids.filter(x => x.fluidMaterials && x.fluidMaterials.length > 0)
                .map(x => {
                    x.fluidMaterials.map(x => allMaterials.push(x));
                });
        }

        allMaterials = allMaterials.sort(function (a, b) {
            return a.fluidMaterialName.localeCompare(b.fluidMaterialName);
        });

        let count = 1;
        for (let i = 0; i < allMaterials.length; i++) {
            const item = allMaterials[i];

            if (item.totalCOGS !== 0 && !item.totalCOGS && !this.isWaterMaterial(item)) {
                if (allMaterials[i + 1] && +item.ifactMaterialId === +allMaterials[i + 1].ifactMaterialId) {
                    count++;
                } else {
                    const noCogsItem = new NoCogsMaterialModel(
                        item.id,
                        item.fluidMaterialName,
                        item.sapMaterialName,
                        item.sapMaterialNumber,
                        count,
                        null
                    );
                    noCogsMaterials.push(noCogsItem);
                    count = 1;
                }
            }
        }

        const ref = this.modalService.show(NoMaterialDataComponent, {
            initialState: {
                missingDataMaterials: noCogsMaterials,
                currentGroup: groupName,
                jobGroupId: group
            },
            id: MODAL_ID.NO_COGS,
            class: 'w-50',
            ignoreBackdropClick: true,
            keyboard: false
        });
    }

    private isWaterMaterial(material: any) {
        return material && material.fluidMaterialName && material.fluidMaterialName.includes('water');
    }
}