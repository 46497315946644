import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cp2-dialog-confirm-complete',
  templateUrl: './cp2-dialog-confirm-complete.component.html',
  styleUrls: ['./cp2-dialog-confirm-complete.component.scss']
})

export class Cp2DialogConfirmCompleteComponent {
  @Input() isVisible: boolean;
  @Output() isCP2CompletionConfirmed: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  closeDialog(isConfirmed: boolean) {
    this.isCP2CompletionConfirmed.emit(isConfirmed);
    this.isVisible = false;
  }
}
