export enum VidaFileType {
    iCem = 1,
    JobProgramDocument = 2,
    ApprovalDocument = 3,
    LocalWellReview = 4,
    CallSheet = 5,
    ReportTemplate = 6,
    RiskAssessment = 7,
    iCemRealtime = 8,
    JobSiteQualityAssurance = 9,
    EquipmentFunctionTest = 10,
    PostJobReport = 11,
    JobLog = 12,
    Handover = 13,
    MixingProcedure = 14,
    LabReport = 15,
    iCemImages = 16,
    JobSafetyAnalysis = 17,
    WellCustomerInfo = 18,
    CEChecksheets = 19,
    MaterialLoadSheet = 20
}

export const VidaFileTypeFilenameLimit = {
    [VidaFileType.JobSafetyAnalysis]: 70,    
    [VidaFileType.JobSiteQualityAssurance]: 70,
    [VidaFileType.EquipmentFunctionTest]: 70
}