import { Injectable } from '@angular/core';

import { environment } from 'libs/environment';
import { Observable, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { CollectionModel } from 'apps/vida/src/modules/collection/components/models';
import { IAdditionalParams } from 'apps/vida/src/modules/shared/models';

@Injectable()
export class CollectionService {
  collectionIdOld: string;
  saveCollectionSuccess$ = new Subject<boolean>();
  refreshCollection$ = new Subject<any>();
  
  constructor(private httpService: HttpService) {
  }

  getAllCollections(): Observable<CollectionModel[]> {
    return this.httpService.Get<CollectionModel[]>(`${environment.baseUrl}/api/personal-collections`);
  }

  getJobsByCollection(collectionId: string, additionalParams: IAdditionalParams) {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.httpService.Get<any>(`${environment.baseUrl}/api/personal-collections/collection/${collectionId}/info${params}`);
  }

  getAllCollectionName(jobId: string): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/personal-collections/job-collection/${jobId}/not-assign`);
  }
  
  addToCollectionName(collection: CollectionModel): Observable<boolean> {
    return this.httpService.Post(`${environment.baseUrl}/api/personal-collections/assign-job`, JSON.stringify(collection));
  }

  getAllCollectionNameRemove(jobId: string): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/personal-collections/job-collection/${jobId}/info`);
  }

  removeFromCollectionName(collection: CollectionModel): Observable<boolean> {
    return this.httpService.Post(`${environment.baseUrl}/api/personal-collections/job-collection/un-assign-job`, JSON.stringify(collection));
  }
}
