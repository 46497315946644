import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { environment } from 'libs/environment';
import { MixingProcedure, PumpSchedule } from 'libs/models';
import { PumpScheduleCOGSModel } from 'libs/models/entities/pump-schedule-stage-cogs.model';
import { PumpScheduleEventStageLogModel } from 'libs/models/entities/pump-schedule-event-stage-log';
import { PlantPush, StageForPlantPush } from 'libs/models/plant-push.model';
import { PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { PushedJobModel } from '../../control-point/models/pushed-job.model';
import { PushedJobReportModel } from '../../control-point/models/pushed-job-report.model';
import { PushedBulkPlantInfo } from '../../pump/models/pushed-bulk-plant-info.model';
import { PushedJobInfoModel } from '../../control-point/models/pushed-job-info.model';

@Injectable()
export class PumpScheduleService {

  updateType$ = new Subject();
  checkSelectedTop: boolean = false;
  isFormChange = false;

  public constructor(
    private httpClient: HttpClient
  ) {
  }

  public clearActualQty(fluidid: string) {
    return this.httpClient.get<Boolean>(`${environment.baseUrl}/api/pumpSchedule/clearActualQty/${fluidid}`, {});
  }

  public getPumpScheduleByJobIdForDashboard(jobId: string): Observable<PumpSchedule[]> {
    return this.httpClient.get<PumpSchedule[]>(`${environment.baseUrl}/api/pumpSchedule/dashboard/${jobId}`);
  }

  public getPumpScheduleByJobId(jobId: string): Observable<PumpSchedule[]> {
    return this.httpClient.get<PumpSchedule[]>(`${environment.baseUrl}/api/pumpSchedule/${jobId}`);
  }

  public getEventStageLogByPumpSchedule(pumpScheduleId: string): Observable<PumpScheduleEventStageLogModel[]> {
    return this.httpClient.get<PumpScheduleEventStageLogModel[]>(`${environment.baseUrl}/api/pumpSchedule/event-stage-log/${pumpScheduleId}`);
  }

  public setTotalCogs(pumpingScheduleId: string, pumpScheduleCOGS: PumpScheduleCOGSModel): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.baseUrl}/api/pumpSchedule/update-total-cost/${pumpingScheduleId}`, pumpScheduleCOGS);
  }

  public getLinerCirculation(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}/api/pumpSchedule/liner-circulation-method`);
  }

  getMixingProcedures(): Observable<MixingProcedure[]> {
    return this.httpClient.get<MixingProcedure[]>(`${environment.baseUrl}/api/pumpSchedule/mixing-procedures`);
  }

  public checkPumpScheduleDuplicate(data): Observable<boolean> {
    return this.httpClient.post<boolean>(`${environment.baseUrl}/api/pumpSchedule/check-duplicate-name`, data);
  }

  public getPumpForPlantPush(jobId: string): Observable<StageForPlantPush[]> {
    return this.httpClient.get<StageForPlantPush[]>(`${environment.baseUrl}/api/pumpSchedule/plant-push/${jobId}`);
  }

  public savePlantPush(plantPush: PlantPush): Observable<PushedJobModel> {
    return this.httpClient.post<PushedJobModel>(`${environment.baseUrl}/api/pumpSchedule/plant-push`, plantPush);
  }
  
  public updateStageTests(jobId: string, tests: PumpScheduleStageTestTable[]) {
    if (jobId && tests) {
      this.updateStageTests$(jobId, tests).subscribe();
    }
  }

  public updateStageTests$(jobId: string, tests: PumpScheduleStageTestTable[]): Observable<any> {
    return this.httpClient.post<any>(`${environment.baseUrl}/api/pumpSchedule/stage-tests/${jobId}`, tests)
  } 

  public setFileNameBulkPlantPushedJob(pushedJobModel: PushedJobInfoModel): Observable<any>{
    return this.httpClient.post<any>(`${environment.baseUrl}/api/pumpSchedule/pushed-job-update-filename`, pushedJobModel);
  }
  
  public getBBPushedJobs(jobId: string): Observable<PushedJobReportModel[]>{
    return this.httpClient.get<PushedJobReportModel[]>(`${environment.baseUrl}/api/pumpSchedule/pushed-jobs/${jobId}`);
  }

  public getStagesPushedBulkPlants(jobId: string): Observable<PushedBulkPlantInfo[]> {
    return this.httpClient.get<any[]>(`${environment.baseUrl}/api/pumpSchedule/stages-pushed-plants/${jobId}`);
  }

  public sendJobBulkPlantFileToAppian(pushedJobModel: PushedJobInfoModel): Observable<any>{
    return this.httpClient.post<any>(`${environment.baseUrl}/api/pumpSchedule/send-bulk-plant-file`, pushedJobModel);
  }
}
