const helpFilesUrl = '../../../../HelpFiles/HelpFiles';

export const HELP_FILES = {
  Main: `${helpFilesUrl}/First_Topic.htm`,
  Administrators: `${helpFilesUrl}/Administrators/Maintenance_Overview.htm`,
  Settings: `${helpFilesUrl}/Settings/User_Settings_Overview.htm`,
  Navigation_Overview: `${helpFilesUrl}/Navigation_Overview_in_VIDA/Main_Screen_Layout_Overview.htm`,
  Wells_Overview: `${helpFilesUrl}/Wells/Wells_Overview.htm`,
  Collections_Overview: `${helpFilesUrl}/Collections/Collections_Overview.htm`,
  Control_Point_1_Overview: `${helpFilesUrl}/Control_Points/CP1/Control_Point_1_Overview.htm`,
  Control_Point_2_Overview: `${helpFilesUrl}/Control_Points/CP2/Control_Point_2_Overview.htm`,
  Control_Point_3_Overview: `${helpFilesUrl}/Control_Points/CP3/Control_Point_3_Overview.htm`,
  Control_Point_4_Overview: `${helpFilesUrl}/Control_Points/CP4/Control_Point_4_Overview.htm`,
  Job_Header: `${helpFilesUrl}/Job_Maintenance/Job_Header/Job_Header_Overview.htm`,
  Job_Objectives: `${helpFilesUrl}/Job_Maintenance/Job_Objectives/Job_Objectives_Overview.htm`,
  Pump_Schedule: `${helpFilesUrl}/Job_Maintenance/Pump_Schedule/Pump_Schedule_Overview.htm`,
  Fluids: `${helpFilesUrl}/Job_Maintenance/Fluids/Fluids_Overview.htm`,
  Job_Dashboard_Navigation: `${helpFilesUrl}/Job_Maintenance/Job_Dashboard_Navigation_Overview.htm`,
  Communications_Log: `${helpFilesUrl}/Communications_Log/How_to_Add_A_Comment_to_A_Job.htm`,
  File_Explorer: `${helpFilesUrl}/File_Explorer/File_Explorer_Overview.htm`,
  Management_Of_Change: `${helpFilesUrl}/Management_of_Change/How_to_Add_An_MOC_to_a_Job.htm`,
  Reports: `${helpFilesUrl}/Reports/VIDA_Reports_Overview.htm`, 
  Job_History: `${helpFilesUrl}/Job_History/How_to_View_the_Job_History.htm`, 
  Create_Job: `${helpFilesUrl}/Job_Maintenance/How_to_Start_a_New_Job.htm`,
  Edit_Job: `${helpFilesUrl}/Job_Maintenance/Tasks_on_Job_Dashboard/How_to_Edit_Job_Data.htm`,
  Create_Group: `${helpFilesUrl}/Administrators/Group_Management/How_to_Create_a_New_Group.htm`,
  Edit_Group: `${helpFilesUrl}/Administrators/Group_Management/How_to_Edit_a_Group.htm`,
  Add_Well: `${helpFilesUrl}/Administrators/Well_Management/How_to_Add_a_New_Well.htm`,
  Edit_Well: `${helpFilesUrl}/Administrators/Well_Management/How_to_Edit_a_Well.htm`,
  Add_Rig: `${helpFilesUrl}/Administrators/Rig_Management/How_to_Add_a_New_Rig.htm`,
  Edit_Rig: `${helpFilesUrl}/Administrators/Rig_Management/How_to_Edit_a_Rig.htm`,
  Change_Log:`${helpFilesUrl}/Administrators/Material_Management/How_to_View_the_Change_Log_in_the_Material_Management_Table.htm`,
  Create_Fluids: `${helpFilesUrl}/Job_Maintenance/Fluids/How_to_Create_New_iFacts_Request_Based_on_Fluid.htm`, 
  Edit_Fluids: `${helpFilesUrl}/Job_Maintenance/Fluids/How_to_Edit_Fluid_Parameters.htm`,
  Link_Fluid_Request: `${helpFilesUrl}/Job_Maintenance/Fluids/How_to_Link_Fluid_Request_ID.htm`,
  Search_For_iFacts: `${helpFilesUrl}/Job_Maintenance/Fluids/How_to_Search_for_iFacts_Request.htm`,
  Manage_ICem_Files: `${helpFilesUrl}/Job_Maintenance/Tasks_on_Job_Dashboard/How_to_Manage_iCem_Files.htm`,
  Create_Collection: `${helpFilesUrl}/Collections/How_to_Create_a_Collection.htm`,
  Add_JobProgram:  `${helpFilesUrl}/Control_Points/CP1/How_to_Add_the_Job_Program.htm`,
  Complete_CDI: `${helpFilesUrl}/Control_Points/CP1/How_to_Complete_the_CDI_Data.htm`,
  Create_Icem_File: `${helpFilesUrl}/Job_Maintenance/Tasks_on_Job_Dashboard/How_to_Create_an_iCem_Design_from_the_Job_Dashboard.htm`,
  Duplicate_Job: `${helpFilesUrl}/Job_Maintenance/Tasks_on_Job_Dashboard/How_to_Duplicate_a_Job.htm`,
  iFacts_Visualizer_Test_Filters: `${helpFilesUrl}/iFacts_Visualizer/How_to_Use_the_iFacts_Visualizer_Test_Filters.htm`,
  iFacts_Visualizer_Financial_Filters: `${helpFilesUrl}/iFacts_Visualizer/How_to_Use_the_iFacts_Visualizer_Financial_Filters.htm`,
  iFacts_Visualizer_Results: `${helpFilesUrl}/iFacts_Visualizer/How_to_Use_the_iFacts_Visualizer_Results.htm`,
  iFacts_Visualizer_Selected_Result: `${helpFilesUrl}/iFacts_Visualizer/How_to_Use_the_iFacts_Visualizer_Selected_Result.htm`,
};

