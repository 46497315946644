<div [loadingIndicator]="loadingSubscription" class="sidebar-content-wrapper">
  <div class="sidebar-header">
    <h1>{{ title }}</h1>
  </div>

  <div class="sidebar-content">
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="form-group">
            <label>Lab</label>
            <p-dropdown [options]="labs" [(ngModel)]="labId" name="labId" placeholder="&nbsp;" showClear="true"
              (onChange)="onChangeLab()" ></p-dropdown>
          </div>
          <div class="form-group">
            <label>Customer</label>
            <p-dropdown [options]="customers" [(ngModel)]="customerId" name="customerId" placeholder="&nbsp;" showClear="true"
              ></p-dropdown>
          </div>
          <div class="form-group">
            <label>Used for</label>
            <p-dropdown [options]="usedFors" [(ngModel)]="usedFor" name="usedFor" ></p-dropdown>
          </div>
          <!-- <div *ngIf="data.requestId === null" class="sidebar-content-row"> -->
          <div class="sidebar-content-row">
            <div>
              <div><label>BHST</label></div>
              <div><b><span [(ngModel)]="data.job.bhst" [ngModelOptions]="{standalone: true}" dUnitValue={{UnitType.Temperature}} dUnitWithName="true"></span></b></div>
            </div>
            <div>
                <div><label>BHCT</label></div>
                <div><b><span [(ngModel)]="data.job.bhct" [ngModelOptions]="{standalone: true}" dUnitValue={{UnitType.Temperature}} dUnitWithName="true"></span></b></div>
            </div>
            <div>
              <p-checkbox [(ngModel)]="isTransferChecked" name="transfer" binary="true" label="Transfer these values to iFacts">
              </p-checkbox>
            </div>
          </div>
          <div class="m-top-1">*Mixing procedure data is coming from HDF and is not equivalent to iFacts in all cases. Please verify before submitting lab request.</div>
        </form>
      </div>
    </div>
  </div>

  <div class="sidebar-footer">
    <div class="sidebar-footer__container">
      <div class="buttons_container">
        <a href="javascript: void(0)" class="btn lnk-cancel" (click)="close()">Cancel</a>
        <button *ngIf="data.action === ActionState.Copy" type="button" class="btn primary-btn" (click)="submitCopyRequest()" pTooltip="Clicking ‘Create’ will open a new window in iFacts. Test must be submitted in iFacts for creation to be complete."
          [disabled]="!labId || !usedFor || !customerId" tooltipZIndex="99990000" tooltipPosition="top" showDelay="100"> Create
        </button>

        <button *ngIf="data.action === ActionState.Created" type="button" class="btn primary-btn"  appDebounceClick (debounceClick)="submitCreateRequest()"
        [debounceTime]="1200" [pTooltip]="'Clicking \'Create\' will open a new window in iFacts. Test must be submitted in iFacts for creation to be complete.'"
          tooltipStyleClass="file-description-tooltip" [disabled]="!data.job.editMode" tooltipZIndex="99990000" tooltipPosition="top" showDelay="100"> Create
        </button>
      </div>
      <div *ngIf="!data.job.editMode && data.action === ActionState.Created" class="alert-red">Job Creation must be complete before iFacts Requests can be created</div>
    </div>
  </div>
  <p-toast [baseZIndex]="99999999"></p-toast>
</div>

