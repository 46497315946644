import { Contingencies, JobObjective, DesignCriteria } from '.';

export class ObjectiveModel {
    jobId?: string;
    objectiveList: JobObjective[];
    designCriteriaList: DesignCriteria[];
    contingencyList: Contingencies[];

    constructor() {
        this.objectiveList = [];
        this.designCriteriaList = [];
        this.contingencyList = [];
    }
}