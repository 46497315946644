import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { WellModel, SaleOrderNumberModel, IBomType, UpdateFromSalesOrderParameters } from 'libs/models';
import { UserSettingService, WellService, MasterDataService, SaleOrderNumberService } from 'libs/shared/services';
import { Subscription } from 'rxjs';


@Component({
    selector: 'modal-update-so',
    templateUrl: './modal-update-so.component.html',
    styleUrls: ['./modal-update-so.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalUpdateSOComponent implements OnInit, OnDestroy {
    data: any;
    existingData: SaleOrderNumberModel;
    newData: SaleOrderNumberModel;
    currentGroup: any;
    noWellNameRegistration: boolean = false;
    bomTypes: IBomType[];
    isExistedWellName: boolean = false;
    private subscription: Subscription = new Subscription();

    constructor(public ref: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private userSettingService: UserSettingService,
        private wellService: WellService,
        private saleOrderNumberService: SaleOrderNumberService,
        private masterDataService: MasterDataService,
        private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.data = this.dynamicDialogConfig.data;


        this.existingData = this.data.existingData;
        this.newData = this.data.newData;

        if (this.newData.shipToLocation&&this.newData.shipToLocation.startsWith(',')) {
            this.newData.shipToLocation = this.newData.shipToLocation.substring(1);
        }

        if (this.newData.shipTo&&this.newData.shipTo.startsWith('0')) {
            this.newData.shipTo = Number.parseFloat(this.newData.shipTo).toString();
        }

        if (this.existingData.shipToLocation&&this.existingData.shipToLocation.startsWith(',')) {
            this.existingData.shipToLocation = this.existingData.shipToLocation.substring(1);
        }

        this.getWell();
    }

    getWell() {
        const selectedGroup = this.userSettingService.selectedLocation;
        let well: WellModel = null;
        this.subscription.add(this.wellService.getWellByGroup(selectedGroup.groupId, this.newData.wellId).subscribe(
            (res: WellModel[]) => {
                well = res.find(x => x.wellName === this.newData.wellName);
                if (well) {
                    this.isExistedWellName = true;
                    this.newData.wellId = well.id;
                }
                this.cd.markForCheck();
            }
        ));
    }

    onClose() {
        const updateFromSaleOrderParameters: UpdateFromSalesOrderParameters = {
            model: this.existingData,
            isSaleOrderUpdateConfirmed: false
        };
        this.saleOrderNumberService.updateFromSalesOrder$.next(updateFromSaleOrderParameters);
        this.ref.close(false);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSubmit(): void {
        const updateFromSaleOrderParameters: UpdateFromSalesOrderParameters = {
            model: this.newData,
            isSaleOrderUpdateConfirmed: true
        };
        this.saleOrderNumberService.updateFromSalesOrder$.next(updateFromSaleOrderParameters);
        this.ref.close(true);
    }
}
