<div class="table-event" [formGroup]="form">
  <p-table [value]="form.controls.events['controls']" class="pump-event-table">
    <ng-template pTemplate="header">
      <tr class="pump-event-header">
        <th>Placement Method</th>
        <th>Rate</th>
        <th>Volume</th>
        <th>Top of Fluid</th>
        <th>Length</th>
        <th>Duration</th>
        <th>Bulk Cement</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-eventFormGroup let-i="rowIndex">
      <tr *ngIf="!readonly; else readonlyEventTemplate" class="pump-event-body" [formGroup]="eventFormGroup">
        <td *ngIf="outerIndex===0" [lockableElement]="'PumpSchedule_placementMethod'">
          <p-dropdown class="custom-dropdown" 
              [options]="pumpScheduleAdapter.placementMethodTypeFirstStage$ | async"
             formControlName="placementMethod" 
             
             placeholder="&nbsp;" showClear="true" filter="true">
          </p-dropdown>
        </td>
        <td *ngIf="outerIndex!=0" [lockableElement]="'PumpSchedule_placementMethod'">
          <p-dropdown class="custom-dropdown" 
            [options]="pumpScheduleAdapter.placementMethodTypeOtherStage$ | async"
            
            formControlName="placementMethod"  placeholder="&nbsp;" showClear="true"
            filter="true"></p-dropdown>
        </td>
        <td>
          <div class="display-measure-event" [lockableElement]="'PumpSchedule_rate'">
            <input-unit formControlName="rate" unit={{UnitType.FlowRate}} tooltip={{ValidatorSetting.ERROR_UNIT_DECIMAL_POS}}
              tooltipClass="tooltip-events"></input-unit>
            <span class="unit-of-measure" dUnitName={{UnitType.FlowRate}}></span>
          </div>
          <error-display formControlName="rate" [errorMsg]="errorMessages.rate"></error-display>
        </td>
        <td>
          <div class="display-measure-event" [lockableElement]="'PumpSchedule_volume'">
            <input-unit formControlName="volume" unit={{UnitType.LargeVolume}} tooltip={{ERROR_UNIT}}
              tooltipClass="tooltip-events" ></input-unit>
            <span class="unit-of-measure" dUnitName={{UnitType.LargeVolume}}></span>
          </div>
        </td>
        <td>
          <div class="display-measure-event" [lockableElement]="'PumpSchedule_topOfFluid'">
            <input-unit formControlName="topOfFluid" unit={{UnitType.Distance}}></input-unit>
            <span class="unit-of-measure" dUnitName={{UnitType.Distance}}></span>
          </div>
        </td>
        <td>
          <div class="display-measure-event" [lockableElement]="'PumpSchedule_length'">
            <input-unit formControlName="length" unit={{UnitType.Distance}}></input-unit>
            <span class="unit-of-measure" dUnitName={{UnitType.Distance}}></span>
          </div>
        </td>
        <td>
          <div class="display-measure-event" [lockableElement]="'PumpSchedule_duration'">
            <input type="text" class="form-control" formControlName="duration" pInputText pKeyFilter="num"
              pTooltip={{ERROR_UNIT}} tooltipStyleClass="tooltip-events" dUnitFormat>
            <span class="unit-of-measure">min</span>
          </div>
        </td>
        <td class="relative" [lockableElement]="'pumpSchedule_addNewEvent'">
          <div class="flx-ctn-row">
            <div class="flx-itm-grow">
              <input-unit class="flx-itm-grow m-r-10" formControlName="bulkCement" unit={{UnitType.BaseMaterialWeight}}
                tooltip={{ValidatorSetting.ERROR_UNIT_DECIMAL_POS}} tooltipClass="tooltip-events" [sackWeight]="form.controls.slurry?.value?.sackWeight">
              </input-unit>
            </div>
            <div>
              <span class="flx-itm-center" [unitType]="UnitType.BaseMaterialWeight"></span>
            </div>
          </div>
          <button class="btn-remove-pump-event" (click)="removeItem(eventFormGroup, i)">
            <div class="btn-remove-pump-event-icon">
              <i class="fa fa-times"></i>
            </div>
          </button>
        </td>
      </tr>
      <ng-template #readonlyEventTemplate>
        <tr [formGroup]="eventFormGroup" class="pump-event-body">
          <td>
            <span>{{eventFormGroup.controls.placementMethodName.value}}</span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="eventFormGroup.controls.rate.value"
              [unitType]="UnitType.FlowRate"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="eventFormGroup.controls.volume.value"
              [unitType]="UnitType.LargeVolume"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="eventFormGroup.controls.topOfFluid.value"
              [unitType]="UnitType.Distance"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="eventFormGroup.controls.length.value"
              [unitType]="UnitType.Distance"></span>
          </td>
          <td>
            <span class="unit-value">{{ eventFormGroup.controls.duration.value | number:'1.2-2' }}</span>
            <span class="unit-measure">min</span>
          </td>
          <td class="relative">
            <div >
              <div class="div-left">
                <span formControlName="bulkCement" dUnitValue={{UnitType.BaseMaterialWeight}} dUnitWithName="true" [sackWeight]="form.controls.slurry?.value?.sackWeight"></span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </ng-template>
  </p-table>
</div>
<div [lockableElement]="'pumpSchedule_addNewEvent'">
  <button *ngIf="!readonly && outerIndex!==0" class="action-add" (click)="addItem()">
    <i class="fa fa-plus-circle"></i><span>Add New Event</span>
  </button>
</div>