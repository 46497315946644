import { FileStatus } from './fileStatus';
import { UploadedFile } from './uploadedFile';

export class UploadProgress {

    public readonly percentage: number;

    public constructor (

        public fileId: string,

        total: number,

        public bytes: number,

        public status: FileStatus,

        public readonly cancel: () => void = null,

        public readonly uploadedFile: UploadedFile = null,

        public httpStatus: number = 200,

        public message: string = null

    ) {
        this.percentage = this.bytes * 100 / (total || 1);
    }
}
