<div class="form-subtitle-section">
    {{header}}
    <a class="view-less" (click)="isTableExpanded = !isTableExpanded">{{ isTableExpanded ? 'View All': 'View Less'}}</a>
</div>
<p-table [hidden]="isTableExpanded" [value]="materialFormGroups" styleClass="no-fixed-table">
    <ng-template pTemplate="header">
        <tr class="pump-fluid-material-header">
            <th>iFacts Material Name</th>
            <th *ngIf="!isCP4">Concentration</th>
            <th *ngIf="tabName !== 'Fluids'">Planned Qty</th>
            <th *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || controlPointNumber == 2 || isCP4)">
                {{isOffshore ? 'Required on Rig' : 'Load Out Qty'}}
            </th>
            <th width="150" *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || controlPointNumber == 2 || isCP4)">Override Qty</th>
            <th width="150" *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || isCP4)">Actual Qty</th>
            <th>SAP Material</th>
            <th style="width: 110px;" *ngIf="(!controlPointNumber || isCP1) && (isJobReviewer$ | async) && (showCogs$ | async)">Material COGS</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-form let-index="rowIndex">
        <tr [formGroup]="form">
            <td class="vertical-align-middle">{{form.controls.ifactMaterialName.value}}
            </td>
            <td *ngIf="!isCP4" class="vertical-align-middle">
              <div *ngIf="form?.controls?.materialType?.value !== 'Mix Water'; else mixWater" [ngClass]="checkDisableItem()" class="flx-row no-gutters concen-input-group">
                  <span>{{form.controls.concentration?.value | number:'.3'}} {{form?.controls?.concentrationUnit?.value}}</span>
              </div>
              <ng-template #mixWater>
                  <span formControlName="concentration" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true"></span>
              </ng-template>
            </td>
            <td class="vertical-align-middle" *ngIf="tabName !== 'Fluids'">
                <span formControlName="plannedVolume" [dUnitValue]="form?.controls?.plannedVolumeUnit.value" dUnitWithName="true"></span>
            </td>
            <td class="vertical-align-middle" *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || controlPointNumber == 2 || isCP4)">
                <span formControlName="loadoutVolume" [dUnitValue]="form?.controls?.loadoutVolumeUnit.value" dUnitWithName="true"></span>
            </td>
            <td class="vertical-align-middle" *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || controlPointNumber == 2 || isCP4)">
              <div *ngIf="!isCP4">
                  <div class="flx-ctn-row" [ngClass]="checkDisableByState()">
                    <input-unit class="flx-itm-grow m-r-10" formControlName="overrideVolume" unit={{form?.controls?.loadoutVolumeUnit.value}} [lockableElement]="'job_completed'"
                                [disabled]="checkDisableByState()">
                      </input-unit>
                      <span class="flx-itm-center" dUnitName={{form?.controls?.loadoutVolumeUnit.value}}></span>
                  </div>
              </div>
              <div *ngIf="isCP4">
                  <span class="unit-value" [apiValue]="form?.controls?.overrideVolume.value" [unitType]="form?.controls?.loadoutVolumeUnit.value"></span>
              </div>
            </td>
            <td class="vertical-align-middle" *ngIf="tabName !== 'Fluids' && (controlPointNumber == null || isCP4)">
                <div class="flx-ctn-row" [ngClass]="checkDisableByState()" [lockableElement]="'FluidDetails_actualQuantityUnit'">
                    <input-unit class="flx-itm-grow m-r-10" formControlName="actualQty" unit={{form?.controls?.loadoutVolumeUnit.value}} [lockableElement]="'FluidDetails_actualQty'">
                    </input-unit>
                    <span class="flx-itm-center" dUnitName={{form?.controls?.loadoutVolumeUnit.value}}></span>
                </div>
                <error-display formControlName="actualQty" [errorMsg]="errorMessages.actualQty"></error-display>
            </td>
            <td class="vertical-align-middle">
                <div *ngIf="!isCP4" [lockableElement]="'FluidDetails_actualQty'">
                    <sap-material [disabled]="isBlockedByJobState()" [ngClass]="checkDisableItem()" [generalMaterialId]="form?.controls?.ifactMaterialId" [sapMaterialNumber]="form?.controls?.sapMaterialNumber" [sapMaterialName]="form?.controls?.sapMaterialName" useTextArea="true" (onChangeSAP)="onChangeSAP(form, $event, index)" (onClearSAP)="onClearSAP(form, $event, index)">
                    </sap-material>
                    <error-display formControlName="sapMaterialNumber" [errorMsg]="errorMessages.sapMaterialNumber"></error-display>
                </div>
                <div *ngIf="isCP4">
                    <span class="unit-value">{{form?.controls?.sapMaterialName?.value}}</span>
                </div>
            </td>
            
            <td *ngIf="(!controlPointNumber || isCP1) && (isJobReviewer$ | async) && (showCogs$ | async)" class="vertical-align-middle">
                <div *ngIf="job && job.id">
                    <div *ngIf="form?.controls?.uomIncompatible?.value" class="warning">Units conversion failure</div>
                    <div *ngIf="!form?.controls?.totalCOGS?.value && form?.controls?.totalCOGS?.value !== 0  && !form?.controls?.uomIncompatible?.value" class="warning">No COGS available</div>
                </div>                
                <span class="unit-value" *ngIf="!form?.controls?.uomIncompatible?.value && (form?.controls?.totalCOGS?.value || form?.controls?.totalCOGS?.value === 0)">${{form?.controls?.totalCOGS?.value | number: '.2'}}</span>                
            </td>
        </tr>
    </ng-template>
</p-table>