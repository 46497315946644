<div class="modal-header">
  <span class="modal-title"> Disconnect Originating Job </span>
  <a role="button" tabindex="0" class="close" (click)="onClose()"><span class="pi pi-times"></span></a>

</div>
<div class="modal-body">
  <p>Are you sure you want to disconnect the originating job ?</p>
</div>

<div class="modal-footer text-right">
  <button class="btn btn-link" (click)="onClose()">Cancel</button>
  <button class="btn btn-primary pull-right" (click)="btnConfirm_click()">Confirm</button>
</div>