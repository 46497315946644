import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from 'libs/environment';
import { mergeMap, finalize } from 'rxjs/operators';
import { isIE } from 'libs/ui/helpers/browser.helper';

const NUMBER_CONCURRENT_REQUESTS = 2

/**
 * IE got bottleneck issues for both sending and receiving requests. Sending many requests simultaneously will cause
 * IE hung and also slow down the request body data transferring (Bug 383456)
 * 
 * This interceptor is made as a self-control request queue for IE. We will hold the request in queue and only
 * dequeue when the concurrent request number below the threshold (default is 2)
 */

@Injectable()
export class IERequestQueueInterceptor implements HttpInterceptor {
  currentRunningRequestNumber = 0;
  queue: any[];

  constructor() {
    this.queue = [];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isIE() || !request.url.includes(environment.baseUrl)) {
      return next.handle(request);
    }

    let isRequestSent = false;

    // Using from(Promise) to make Observable pausable.
    // Approaches tried: takeUntil, delayWhen, pausable
    return from(new Promise((resolve) => {
      this.queue.push(resolve);

      if (this.currentRunningRequestNumber === 0) {
        this.triggerNextRequest();
      }
    })).pipe(
      mergeMap(() => {
        isRequestSent = true;
        return next.handle(request).pipe(
          finalize(() => {
            this.onRequestComplete();
          })
        );
      }),
      finalize(() => {
        // Angular might cancel the observable before the stream can reach to mergeMap()
        // This is a fallback for that scenario when the request hasn't been sent yet
        if (!isRequestSent) {
          this.onRequestComplete();
        }
      })
    );
  }
  
  private onRequestComplete() {
    this.currentRunningRequestNumber--;
    this.triggerNextRequest();
  }

  private triggerNextRequest() {
    if (this.currentRunningRequestNumber >= NUMBER_CONCURRENT_REQUESTS) {
      return;
    }

    const resolve = this.queue.shift();
    
    if (resolve) {
      this.currentRunningRequestNumber++;
      resolve();
    }
  }
}

