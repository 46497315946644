import { Injectable, ComponentRef, ViewContainerRef, Type } from '@angular/core';

@Injectable()
export class DynamicComponentService {

  container: ViewContainerRef;
  componentRef: ComponentRef<any>;

  constructor() {}

  setRootView(viewContainerRef: ViewContainerRef) {
    this.container = viewContainerRef;
  }

  createComponent(type: Type<unknown>, data?) {
    this.componentRef = this.container.createComponent(type);
    this.componentRef.instance.data = data;

    this.componentRef.instance.onClose.subscribe(_ => {
      this.destroyComponent();
    });
  }

  destroyComponent() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
