import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, ChangeDetectionStrategy, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, FormsModule, Form, FormControl } from '@angular/forms';
import { PumpScheduleService } from '../../services';
import { ValidatorSetting } from '../../../shared/constant';
import { ConfirmDialogService, PDropDownModel, FormControlContainer, ErrorMessageModel } from 'libs/ui';
import { UnitType, ERROR_TYPE, FLUID_TYPE_SCHEDULE } from 'libs/constants';
import { PumpScheduleAdapter } from '../../../pump-schedule/adapters';
import { createEventWithData } from './pump-event-form.factory';
import { distinctUntilChanged } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { PumpEventLogic, LocalEventModel, EventPropertyChanged, EventListChanged } from './pump-event.logic';
import { PumpScheduleEventModel } from 'libs/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pump-event',
  templateUrl: './pump-event.component.html',
  styleUrls: ['./pump-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpEventComponent extends FormControlContainer implements OnInit, OnChanges, OnDestroy {

  @Input() outerIndex: number;
  @Input() form: UntypedFormGroup;
  @Input() readonly: boolean;
  @Input() permissionEvent: boolean;
  @Output() onChangePlacementMethod = new EventEmitter<string>();

  _logic: PumpEventLogic;
  _subscribers: Subscription = new Subscription();

  hidePermission: boolean;
  placementMethods: PDropDownModel[] = [];
  public get isFirstStage(): boolean {
    return this.form?.controls.order.value == 0;
  };
  
  //true means disabled
  eventsElementAtts: any = [
    [['rate', false], ['volume', false], ['topOfFluid', true], ['length', true], ['duration', true], ['bulkCement', false]],
    [['rate', true], ['volume', true], ['topOfFluid', true], ['length', true], ['duration', false], ['bulkCement', true]]
  ];
  disableControlOptions = {
    emitEvent: false,
    onlySelf: true
  };
  enableControlOptions = {
    emitEvent: false,
    onlySelf: false
  };
  defaultValue: number = 0;
  ERROR_UNIT = ValidatorSetting.ERROR_UNIT_DECIMAL;
  ValidatorSetting = ValidatorSetting;
  UnitType = UnitType;

  errorMessages = {
    rate: [
      new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
      new ErrorMessageModel('max', ValidatorSetting.ERROR_UNIT_DECIMAL_MAX)
    ],
    actualRate: [
      new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
      new ErrorMessageModel('required', ValidatorSetting.ERROR_REQUIRED)
    ],
    actualDuration: [
      new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
      new ErrorMessageModel('required', ValidatorSetting.ERROR_REQUIRED)
    ]
  };

  constructor(
    protected inj: Injector,
    protected fb: UntypedFormBuilder,
    public pumpScheduleService: PumpScheduleService,
    private confirmDialogService: ConfirmDialogService,
    public pumpScheduleAdapter: PumpScheduleAdapter,
    protected cdr: ChangeDetectorRef
  ) {
    super(inj);
  }

  ngOnInit() {
    this.initLogic();
    if (this.form.controls.pumpScheduleFluidTypeName)
      this.initializeFluidEventList(this.form.controls.pumpScheduleFluidTypeName.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { form } = changes;

    this.hidePermission = this.permissionEvent;

    if (this.formControl){
      this.formControl = null;
      this._subscribers.unsubscribe();
      this._subscribers = new Subscription();
    }

    if (form) {
      this.formControl = this.form;
      const eventFormArray = this.form.controls.events as UntypedFormArray;

      this.enableBulkCement(eventFormArray);
      const subscription: Subscription = this.form.controls.pumpScheduleFluidTypeId.valueChanges
        .pipe(distinctUntilChanged()).subscribe(newFluidTypeId => {
          this.initializeFluidEventList(this.getFluidTypeName(newFluidTypeId));
          this.enableBulkCement(eventFormArray);
        });
      this._subscribers.add(subscription);
      if (this.form.controls.completeMode){
        (this.form.controls.completeMode as UntypedFormGroup).valueChanges.subscribe(_ => {
          this.updateEventControlsValidation();
          this.cdr.markForCheck();
        });
      }
    }
  }

  initializeFluidEventList(fluidTypeName: string){
    if (fluidTypeName !== null){
      if (this._logic.isPlugFluid(fluidTypeName)){
        this._logic.removeAll();
      }
      else {
        if (this._logic.NoEvents){
          this._logic.addEmptyEvent(null);
        }
      }
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(){
    this._subscribers.unsubscribe();
  }

  addItem(){
    this._logic?.addEmptyEvent(null);
    this.setFormChangeFlag();
  }

  removeItem(fg: UntypedFormGroup) {
    const order: number = fg.controls.order?.value;
    this._logic?.removeEvent(order);
    this.setFormChangeFlag();
  }

  public get isCompleteMode(): boolean {
    return this.form && this.form.controls.completeMode ? this.form.controls.completeMode.value : false;
  }

  private initLogic() {
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    const formEvents = eventFormArray.getRawValue();
    const events: PumpScheduleEventModel[] = formEvents.map((e) => {
      return {
        pumpScheduleEventId: e.pumpScheduleEventId,
        placementMethod: e.placementMethod,
        placementMethodName: e.placementMethodName,
        rate: e.rate,
        actualRate: e.actualRate,
        volume: e.volume,
        topOfFluid: e.topOfFluid,
        length: e.length,
        duration: e.duration,
        actualDuration: e.actualDuration,
        bulkCement: e.bulkCement,
        order: e.order
      } as PumpScheduleEventModel;
    });
    this._logic = new PumpEventLogic(events, this.isFirstStage, this.pumpScheduleAdapter);
    this.updateFormsOrder();
    this.subscribeEventFormArrayChanges();
    const propertyChangedSbr: Subscription = this._logic.onProperyChanged.subscribe(this.onLogicProperyChanged.bind(this));
    this._subscribers.add(propertyChangedSbr);
    const listChangedSbr: Subscription = this._logic.onListChanged.subscribe(this.onLogicListChanged.bind(this));
    this._subscribers.add(listChangedSbr);
    this.cdr.markForCheck();
  }

  private get PumpScheduleFluidTypeId(): string {
    return this.form.controls.pumpScheduleFluidTypeId ? this.form.controls.pumpScheduleFluidTypeId.value : null;
  }

  protected get PumpScheduleFluidTypeName(): string {
    let result = this.form.controls.pumpScheduleFluidTypeName ? this.form.controls.pumpScheduleFluidTypeName.value : null;
    if (result == null || result == ''){
      const id = this.form.controls.pumpScheduleFluidTypeId ?this.form.controls.pumpScheduleFluidTypeId.value : null;
      if (id != null && id != '') {
        result = this.getFluidTypeName(id);
      }
    }
    return result === '' ? null : result;
  }

  protected updateEventControlsValidation(){
    (this.form.controls.events as UntypedFormArray).controls.forEach(e => {
      const eventControls = (e as UntypedFormGroup).controls;
      Object.keys(eventControls).forEach((propertyName: string) => {
        eventControls[propertyName].updateValueAndValidity({emitEvent: false});
      });
    });
    this.form.controls.events.markAllAsTouched();
  }

  private onLogicProperyChanged(changedEvent: EventPropertyChanged){
    const property: string = changedEvent.propertyName;
    if (property == 'order') return; //the function logic is based on order so don't apply it
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    const fg: UntypedFormGroup = eventFormArray.controls[changedEvent.event.order] as UntypedFormGroup;
    fg.controls[property].setValue(changedEvent.event[property], {emitEvent: false, onlySelf: true});
  }

  private onLogicListChanged(e: EventListChanged){
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    const eventOrder = e.event.order;
    if (e.action === 'add'){
      const newEvent: LocalEventModel = e.event;
      const fg: UntypedFormGroup = createEventWithData(this.fb, this.outerIndex, newEvent);
      this.subscribeFormChanges(fg, newEvent);
      if (eventOrder >= eventFormArray.length){
        eventFormArray.push(fg);
      }
      else {
        this.updateFormsOrder(eventOrder, 1);
        eventFormArray.insert(eventOrder, fg);
      }
    }
    else if (e.action === 'remove'){
      const fg: UntypedFormGroup = eventFormArray.controls[eventOrder] as UntypedFormGroup;
      this.unsubscribeFormChanges(fg);
      this.updateFormsOrder(eventOrder, -1);
      eventFormArray.removeAt(eventOrder);
    }
  }

  private subscribeEventFormArrayChanges(){
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    const formArrayControls = eventFormArray.controls;
    
    this._logic.Events.forEach((e, index) => {
      const fg: UntypedFormGroup = formArrayControls[index] as UntypedFormGroup;
      this.subscribeFormChanges(fg, e);
    });
  }

  protected subscribeFormChanges(fg: UntypedFormGroup, event: LocalEventModel){
      const fgControls = fg.controls;
      const formSubscription = new Subscription();
      formSubscription.add(fgControls.placementMethod.valueChanges.subscribe(value => {
        if (event.placementMethod !== value){
          this.changePlacementMethod(event, value);
        }
      }));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'rate'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'actualRate'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'volume'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'topOfFluid'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'length'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'duration'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'actualDuration'));
      formSubscription.add(this.subscribeEventProperty(fg, event, 'bulkCement'));

      fg['eventSubscription'] = formSubscription;
      this._subscribers.add(formSubscription);
  }

  protected unsubscribeFormChanges(fg: UntypedFormGroup){
    const subscription = fg['eventSubscription'] as Subscription;
    if (subscription !== null){
      subscription.unsubscribe();
      this._subscribers.remove(subscription);
    }
  }

  private subscribeEventProperty(fg: UntypedFormGroup, event: LocalEventModel, propertyName: string): Subscription{
    return fg.controls[propertyName].valueChanges.subscribe(value => {
      this._logic.updateEventProperty(event.localId, propertyName, value);
    });
  }

  private updateFormsOrder(position: number = null, step: number = null){
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    const formArrayControls = eventFormArray.controls;
    this._logic.Events.forEach((e, index) => {
      const formIndex = position === null || index < position ? index : index - step;
      if (position === null || formIndex >= position){
        const fg: UntypedFormGroup = formArrayControls[formIndex] as UntypedFormGroup;
        if (fg.controls.order.value !== e.order) 
          fg.controls.order.setValue(e.order, this.disableControlOptions);
      }
    });
  }  

  private getEventForm(order: number): UntypedFormGroup {
    const eventFormArray = this.form.controls.events as UntypedFormArray;
    return eventFormArray.controls[order] as UntypedFormGroup;
  }
  
  private changePlacementMethod(event: LocalEventModel, newValue: string): void {
    const prevValue = event.placementMethod;
    if (event.placementMethodName !== null && event.placementMethodName !== 'Shutdown' && event.placementMethodName !== 'Volume') {
      this.showConfirmMessageWhenChangeDataFromiCem(() => { 
        this._logic.updateEventProperty(event.localId, 'placementMethod', newValue);
        this.updateFormGroupAttributes(event, event.placementMethodName == 'Shutdown' ? 1 : 0);
        this.clearOtherStagesEventData();
        if (event.order === 0){
          this.form.value.topOfFluid = '';
        }
        this.cdr.markForCheck();
      },() => this.resetPlacementMethodToPreviousValue(prevValue, this.getEventForm(event.order)));
    }
    else {
      this._logic.updateEventProperty(event.localId, 'placementMethod', newValue);
      this.updateFormGroupAttributes(event, event.placementMethodName == 'Shutdown' ? 1 : 0);
      this.cdr.markForCheck();
    }
  }

  private resetPlacementMethodToPreviousValue(placementMethodId: string, eventFromGroup: UntypedFormGroup) {
    eventFromGroup.controls.placementMethod.setValue(placementMethodId);
  }

  private clearOtherStagesEventData() {
    this.onChangePlacementMethod.next(this.form.value.id);
  }

  private showConfirmMessageWhenChangeDataFromiCem(accept: Function, reject: Function) {
    this.confirmDialogService.show({
      message: `Are you sure you want to change to the other placement methods?`,
      header: 'Confirmation',
      accept: accept,
      reject: reject,
      acceptLabel: 'Yes',
      rejectLabel: 'No'
    });
  }

  private updateFormGroupAttributes(event: LocalEventModel, typeNo: number){
    const fg:UntypedFormGroup = this.getEventForm(event.order);
    const elementAtts = this.eventsElementAtts[typeNo];
    this.disableElement(fg, elementAtts.filter(value => value[1]));
    this.enableElement(fg, elementAtts.filter(value => !value[1]));
    this.enableBulkCementForEvent(fg, event);
  }

  disableElement(eventFormGroup, elementArray) {
    elementArray.forEach(element => {
      eventFormGroup.get(element[0]).disable(this.disableControlOptions);
    });
  }

  enableElement(eventFormGroup, elementArray) {
    elementArray.forEach(element => {
      if (!this.isFirstStage) {
        eventFormGroup.get(element[0]).enable(this.enableControlOptions);
      }
    });
  }

  setFormChangeFlag() {
    this.form?.markAsDirty();
    this.pumpScheduleService.isFormChange = true;
  }

  private enableBulkCement(eventFormArray: UntypedFormArray) {
    if (this._logic === undefined) return;
    const events = this._logic.Events;
    eventFormArray.controls.forEach((eventFormGroup: UntypedFormGroup, index: number) => {
      this.enableBulkCementForEvent(eventFormGroup, events[index]);
    });
    this.cdr.markForCheck();
  }

  private enableBulkCementForEvent(eventFormGroup: UntypedFormGroup, event: LocalEventModel) {
    if (eventFormGroup.controls.order.value != event.order.toString())
      console.error('enableBulkCementForEvent argrument exception: from group and event are not match');
    eventFormGroup.controls.bulkCement.enable();
    const placementMethodName = event.placementMethodName;
    if (placementMethodName == null) return null;
    const fluidTypeOfStageName = this.PumpScheduleFluidTypeName;
    if (fluidTypeOfStageName == null) return null;

    if (fluidTypeOfStageName !== 'Cement' && placementMethodName === 'Shutdown') {
      eventFormGroup.controls.bulkCement.disable();
    }

    this.cdr.markForCheck();
  }

  protected getFluidTypeName(fluidTypeId: string){
    const fluidTypeOfStage = this.pumpScheduleAdapter.fluidTypes.find(item => item.id === fluidTypeId);
    return fluidTypeOfStage === undefined ? null : fluidTypeOfStage.name;
  }
}
