export const IFACT_CONSTANTS = {
  WRAPPER_OBJ: {
    Thickening: {
      data: {
        name: 'Thickening Time (TT)'
      },
      children: [
        {
          data: {
            name: 'Thickening Time'
          },
          children: []
        },
        {
          data: {
            name: 'TT-Aged Mix Fluid'
          },
          children: []
        },
        {
          data: {
            name: 'TT On-Off-On'
          },
          children: []
        },
        {
          data: {
            name: 'TT-contaminated'
          },
          children: []
        },
        {
          data: {
            name: 'TT-Thixotropic Cup'
          },
          children: []
        },
        {
          data: {
            name: 'TT-Hesitation'
          },
          children: []
        }
      ]
    },
    'Free Fluid': {
      data: {
        name: 'Free Fluid'
      },
      children: [
        {
          data: {
            name: 'API 10A'
          },
          children: []
        },
        {
          data: {
            name: 'API 10B-2'
          },
          children: []
        }
      ]
    },
    'Fluid Loss': {
      data: {
        name: 'Fluid Loss'
      },
      children: [
        {
          data: {
            name: 'API Fluid Loss'
          },
          children: []
        },
        {
          data: {
            name: 'Stirring Fluid Loss'
          },
          children: []
        }
      ]
    },
    'UCA': {
      data: {
        name: 'UCA'
      },
      children: [{
        data: {
          name: 'UCA Comp. Strength'
        },
        children: []
      }]
    },
    'Rheology': {
      data: {
        name: 'Rheology'
      },
      children: [
        {
          data: {
            name: 'API Rheology'
          },
          children: []
        },
        {
          data: {
            name: 'Non-API Rheology'
          },
          children: []
        }
      ]
    }
  },

  MISSING_TEST_WRAPPER: {
    'Thickening Time': [],
    'Thickening Time - Aged Mix Fluid': [],
    'Thickening Time - contaminated': [],
    'Thickening Time - Hesitation': [],
    'Thickening Time - ON-OFF-ON': [],
    'Thickening Time - Thixotropic Cup': [],
    'API Fluid Loss': [],
    'Stirring Fluid Loss': [],
    'API Rheology': [],
    'Non-API Rheology': [],
    'Free Fluid API 10A': [],
    'Free Fluid API 10B-2': [],
    'UCA Comp. Strength': []
  },

  HOLESIZE_LIST: [
    { id: 197, value: 3.78 },
    { id: 198, value: 4.125 },
    { id: 1, value: 4.5 },
    { id: 2, value: 4.625 },
    { id: 3, value: 4.75 },
    { id: 4, value: 4.875 },
    { id: 5, value: 5 },
    { id: 6, value: 5.125 },
    { id: 7, value: 5.25 },
    { id: 9, value: 5.5 },
    { id: 10, value: 5.625 },
    { id: 8, value: 5.735 },
    { id: 11, value: 5.75 },
    { id: 12, value: 5.875 },
    { id: 13, value: 6 },
    { id: 14, value: 6.125 },
    { id: 15, value: 6.25 },
    { id: 17, value: 6.5 },
    { id: 18, value: 6.625 },
    { id: 16, value: 6.735 },
    { id: 19, value: 6.75 },
    { id: 20, value: 6.875 },
    { id: 21, value: 7 },
    { id: 22, value: 7.125 },
    { id: 23, value: 7.25 },
    { id: 25, value: 7.5 },
    { id: 26, value: 7.625 },
    { id: 24, value: 7.735 },
    { id: 27, value: 7.75 },
    { id: 28, value: 7.875 },
    { id: 29, value: 8 },
    { id: 30, value: 8.125 },
    { id: 31, value: 8.25 },
    { id: 196, value: 8.375 },
    { id: 33, value: 8.5 },
    { id: 34, value: 8.625 },
    { id: 32, value: 8.735 },
    { id: 35, value: 8.75 },
    { id: 36, value: 8.875 },
    { id: 37, value: 9 },
    { id: 38, value: 9.125 },
    { id: 39, value: 9.25 },
    { id: 41, value: 9.5 },
    { id: 42, value: 9.625 },
    { id: 40, value: 9.735 },
    { id: 43, value: 9.75 },
    { id: 44, value: 9.875 },
    { id: 45, value: 10 },
    { id: 46, value: 10.125 },
    { id: 47, value: 10.25 },
    { id: 49, value: 10.5 },
    { id: 50, value: 10.625 },
    { id: 48, value: 10.735 },
    { id: 51, value: 10.75 },
    { id: 52, value: 10.875 },
    { id: 53, value: 11 },
    { id: 54, value: 11.125 },
    { id: 55, value: 11.25 },
    { id: 57, value: 11.5 },
    { id: 58, value: 11.625 },
    { id: 56, value: 11.735 },
    { id: 59, value: 11.75 },
    { id: 60, value: 11.875 },
    { id: 61, value: 12 },
    { id: 62, value: 12.125 },
    { id: 63, value: 12.25 },
    { id: 199, value: 12.375 },
    { id: 65, value: 12.5 },
    { id: 66, value: 12.625 },
    { id: 64, value: 12.735 },
    { id: 67, value: 12.75 },
    { id: 68, value: 12.875 },
    { id: 69, value: 13 },
    { id: 70, value: 13.125 },
    { id: 71, value: 13.25 },
    { id: 73, value: 13.5 },
    { id: 74, value: 13.625 },
    { id: 72, value: 13.735 },
    { id: 75, value: 13.75 },
    { id: 76, value: 13.875 },
    { id: 77, value: 14 },
    { id: 78, value: 14.125 },
    { id: 79, value: 14.25 },
    { id: 81, value: 14.5 },
    { id: 82, value: 14.625 },
    { id: 80, value: 14.735 },
    { id: 83, value: 14.75 },
    { id: 84, value: 14.875 },
    { id: 85, value: 15 },
    { id: 86, value: 15.125 },
    { id: 87, value: 15.25 },
    { id: 88, value: 15.375 },
    { id: 89, value: 15.5 },
    { id: 90, value: 15.625 },
    { id: 91, value: 15.75 },
    { id: 92, value: 15.875 },
    { id: 93, value: 16 },
    { id: 94, value: 16.25 },
    { id: 95, value: 16.5 },
    { id: 96, value: 16.75 },
    { id: 97, value: 17 },
    { id: 98, value: 17.25 },
    { id: 99, value: 17.5 },
    { id: 100, value: 17.75 },
    { id: 101, value: 18 },
    { id: 102, value: 18.25 },
    { id: 103, value: 18.5 },
    { id: 104, value: 18.75 },
    { id: 105, value: 19 },
    { id: 106, value: 19.25 },
    { id: 107, value: 19.5 },
    { id: 108, value: 19.75 },
    { id: 109, value: 20 },
    { id: 110, value: 20.25 },
    { id: 111, value: 20.5 },
    { id: 112, value: 20.75 },
    { id: 113, value: 21 },
    { id: 114, value: 21.25 },
    { id: 115, value: 21.5 },
    { id: 116, value: 21.75 },
    { id: 117, value: 22 },
    { id: 118, value: 22.25 },
    { id: 119, value: 22.5 },
    { id: 120, value: 22.75 },
    { id: 121, value: 23 },
    { id: 122, value: 23.25 },
    { id: 123, value: 23.5 },
    { id: 124, value: 23.75 },
    { id: 125, value: 24 },
    { id: 126, value: 24.25 },
    { id: 127, value: 24.5 },
    { id: 128, value: 24.75 },
    { id: 129, value: 25 },
    { id: 130, value: 25.25 },
    { id: 131, value: 25.5 },
    { id: 132, value: 25.75 },
    { id: 133, value: 26 },
    { id: 134, value: 26.25 },
    { id: 135, value: 26.5 },
    { id: 136, value: 26.75 },
    { id: 137, value: 27 },
    { id: 138, value: 27.25 },
    { id: 139, value: 27.5 },
    { id: 140, value: 27.75 },
    { id: 141, value: 28 },
    { id: 142, value: 28.25 },
    { id: 143, value: 28.5 },
    { id: 144, value: 28.75 },
    { id: 145, value: 29 },
    { id: 146, value: 29.25 },
    { id: 147, value: 29.5 },
    { id: 148, value: 29.75 },
    { id: 149, value: 30 },
    { id: 150, value: 30.25 },
    { id: 151, value: 30.5 },
    { id: 152, value: 30.75 },
    { id: 153, value: 31 },
    { id: 154, value: 31.25 },
    { id: 155, value: 31.5 },
    { id: 156, value: 31.75 },
    { id: 157, value: 32 },
    { id: 158, value: 32.25 },
    { id: 159, value: 32.5 },
    { id: 160, value: 32.75 },
    { id: 161, value: 33 },
    { id: 162, value: 33.25 },
    { id: 163, value: 33.5 },
    { id: 164, value: 33.75 },
    { id: 165, value: 34 },
    { id: 166, value: 34.25 },
    { id: 167, value: 34.5 },
    { id: 168, value: 34.75 },
    { id: 169, value: 35 },
    { id: 170, value: 35.25 },
    { id: 171, value: 35.5 },
    { id: 172, value: 35.75 },
    { id: 173, value: 36 },
    { id: 174, value: 36.5 },
    { id: 175, value: 37 },
    { id: 176, value: 37.5 },
    { id: 177, value: 38 },
    { id: 178, value: 38.5 },
    { id: 179, value: 39 },
    { id: 180, value: 39.5 },
    { id: 181, value: 40 },
    { id: 182, value: 40.5 },
    { id: 183, value: 41 },
    { id: 184, value: 41.5 },
    { id: 185, value: 42 },
    { id: 186, value: 42.5 },
    { id: 187, value: 43 },
    { id: 188, value: 43.5 },
    { id: 189, value: 44 },
    { id: 190, value: 44.5 },
    { id: 191, value: 45 },
    { id: 192, value: 45.5 },
    { id: 193, value: 46 },
    { id: 194, value: 46.5 },
    { id: 195, value: 52 },
  ],

  CASINGSIZE_LIST: [
    { id: 44, value: 1 },
    { id: 43, value: 1.5 },
    { id: 54, value: 1.66 },
    { id: 42, value: 1.75 },
    { id: 41, value: 2.375 },
    { id: 40, value: 2.875 },
    { id: 45, value: 3 },
    { id: 46, value: 3.5 },
    { id: 37, value: 4 },
    { id: 1, value: 4.5 },
    { id: 2, value: 5 },
    { id: 3, value: 5.5 },
    { id: 4, value: 5.75 },
    { id: 5, value: 6 },
    { id: 6, value: 6.625 },
    { id: 7, value: 7 },
    { id: 8, value: 7.625 },
    { id: 9, value: 7.75 },
    { id: 10, value: 8 },
    { id: 11, value: 8.125 },
    { id: 12, value: 8.625 },
    { id: 13, value: 8.75 },
    { id: 14, value: 9 },
    { id: 49, value: 9.375 },
    { id: 39, value: 9.46 },
    { id: 15, value: 9.625 },
    { id: 16, value: 9.75 },
    { id: 17, value: 9.875 },
    { id: 18, value: 10 },
    { id: 55, value: 10.125 },
    { id: 19, value: 10.75 },
    { id: 20, value: 11.75 },
    { id: 21, value: 11.875 },
    { id: 22, value: 12 },
    { id: 57, value: 12.75 },
    { id: 23, value: 13 },
    { id: 24, value: 13.375 },
    { id: 25, value: 13.5 },
    { id: 26, value: 13.625 },
    { id: 27, value: 14 },
    { id: 28, value: 16 },
    { id: 59, value: 16.772 },
    { id: 58, value: 17 },
    { id: 52, value: 17.875 },
    { id: 50, value: 18 },
    { id: 29, value: 18.625 },
    { id: 56, value: 18.701 },
    { id: 30, value: 20 },
    { id: 31, value: 21.5 },
    { id: 48, value: 22 },
    { id: 32, value: 24 },
    { id: 33, value: 24.5 },
    { id: 35, value: 26 },
    { id: 53, value: 27 },
    { id: 51, value: 28 },
    { id: 34, value: 30 },
    { id: 47, value: 32 },
    { id: 36, value: 36 },
    { id: 38, value: 46 },
  ],
  REQUEST_TYPES: [{
    value: '53',
    label: 'Application Development',
  }, {
    value: '6',
    label: 'Investigation',
  }, {
    value: '51',
    label: 'Historical',
  }, {
    value: '52',
    label: 'New Product Development',
  }, {
    value: '4',
    label: 'Operation',
  }, {
    value: '5',
    label: 'Pilot',
  }, {
    value: '3',
    label: 'Project',
  }, {
    value: '1',
    label: 'QA/QC',
  }, {
    value: '2',
    label: 'Tender',
  }],

  UNITS_OF_MEASURE: {
    "%bwoc": {iFactsCode: "% BWOC"},
    "%bvow": {iFactsCode: "% BVOW"},
    "%bwow": {iFactsCode: "% BWOW"},
    "kg/m3": {iFactsCode: "kg/m3"},
    "l/m3": {iFactsCode: "l/m3"},
    "l/100kg": {iFactsCode: "L/100kg"},
    "gal/bbl": {iFactsCode: "gal/bbl"},
    "gal/mgal": {iFactsCode: "gal/Mgal"}, //TODO is this needed??
    "lb/bbl": {iFactsCode: "lb/bbl"},
    "lb/sk": {iFactsCode: "lb/sk"},
    "gps": {iFactsCode: "gps"},
  },

  CEMENT_SLURRY_TYPE: ['%bwoc', '%bwow', 'gps', 'lb/sk', '%bvow', 'l/100kg'],
  SPACER_SLURRY_TYPE: ['lb/bbl', 'kg/m3', 'l/m3', 'gal/bbl'],

  CONCENTRATION_TO_OUTPUT_UNIT_MAP: {
    "%bwoc": {unit: "g"},
    "%bvow": {unit: "ml"},
    "%bwow": {unit: "g"},
    "kg/m3": {unit: "g"},
    "l/m3": {unit: "ml"},
    "l/100kg": {unit: "ml"},
    "gal/bbl": {unit: "ml"},
    "gal/mgal": {unit: "ml"},
    "lb/bbl": {unit: "g"},
    "lb/sk": {unit: "g"},
    "gps": {unit: "ml"}
  },

  FLUID_CONCENTRATIONS: {
    "%bvow": {},
    "l/m3": {},
    "l/100kg": {},
    "gal/bbl": {},
    "gal/mgal": {},
    "gps": {}
  }
};
