import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SlurrySource } from 'libs/constants';
import { FluidMaterialModel, FluidModel, ITestType } from 'libs/models';
import { MaterialService, MasterDataService } from 'libs/shared/services';
import { indexOf, maxBy } from 'libs/helpers/lodash-helper';

export class MaterialGroupsSummary {
    waterMaterial: UntypedFormGroup;
    cementMaterials: UntypedFormGroup[];
    baseCementMaterial: UntypedFormGroup;
    blendMaterials: UntypedFormGroup[];
    notBlendMaterials: UntypedFormGroup[];
    supplementalMaterial: UntypedFormGroup[];
}

@Injectable()
export class FluidFormFactory {
    private _testTypes: ITestType[] = [];

    public constructor(
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _materialService: MaterialService,
        private readonly _masterDataService: MasterDataService
    ) {
        this._masterDataService.listTestTypes()?.subscribe(testTypes => this._testTypes = testTypes);
    }

    public createEmptyFluidForm(): UntypedFormGroup {

        return this._formBuilder.group({
            id: null,
            name: null,
            requestId: null,
            requestIdHDF: null,
            slurrySource: SlurrySource.ManualFluid,
            slurryId: { value: null, disabled: true },
            slurryNo: null,
            slurryIdHDF: null,
            primaryStatus: null,
            primaryStatusId: null,
            displayName: null,
            requestDisplay: { value: null, disabled: true },
            testTypeId: null,
            testType: null,
            slurryTypeId: { value: null, disabled: false },
            testStatus: { value: null, disabled: true },
            density: { value: null, disabled: true },
            water: { value: null, disabled: true },
            waterSAPNumber: { value: null, disabled: true },
            mixWaterId: { value: null, disabled: true },
            mixFluid: { value: null, disabled: true },
            waterRequirements: null,
            yield: { value: null, disabled: true },
            mixWater: { value: null, disabled: true },
            sapMaterialName: null,
            sapMaterialNumber: null,
            isFoam: { value: null, disabled: true },
            foamDensity: { value: null, disabled: true },
            foamQuality: { value: null, disabled: true },
            order: null,
            isCement: null,
            isCementBlend: { value: null, disabled: true },
            labName: { value: null, disabled: true },
            blendName: { value: null, disabled: true },
            cementName: { value: null, disabled: true },
            sackWeight: { value: null, disabled: true },
            requestInfo: null,
            thickeningTime: null,
            fluidMaterial: this._formBuilder.array([]),
            fluidAdditiveMaterial: this._formBuilder.array([]),
            fluidBlendMaterial: this._formBuilder.array([]),
            supplementalMaterial: this._formBuilder.array([]),
            pumpScheduleNumber :{ value: null, disabled: true }
        });
    }

    public createFluidForm(fluid: FluidModel) {
        const cementMaterial = this._materialService.getCementMaterial(fluid.fluidMaterial);

        const fg = this._formBuilder.group({
            createdDate: fluid.createdDate,
            primaryStatus: fluid.primaryStatus,
            primaryStatusId: fluid.primaryStatusId,
            id: fluid.id,
            name: (fluid.name !== '') ? fluid.name : fluid.sapMaterialName,
            requestId: fluid.requestId,
            requestIdHDF: fluid.requestIdHDF,
            slurrySource: fluid.slurrySource,
            slurryId: { value: fluid.slurryId, disabled: true },
            slurryNo: fluid.slurryNo,
            slurryIdHDF: fluid.slurryIdHDF,
            displayName: fluid.displayName,
            iCemName: fluid.iCemName,
            tempId: fluid.tempId,
            isFromVisualizer: fluid.isFromVisualizer,
            isFromIFactsBasicSearch: fluid.isFromIFactsBasicSearch,
            requestDisplay: { value: fluid.requestId ? `${fluid.requestId}/${fluid.slurryNo}` : '', disabled: true },
            testTypeId: [{
                value: this._getVidaTestTypeId(fluid.testType,fluid.testTypeId),
                disabled: fluid.slurrySource === SlurrySource.ManualFluid || fluid.slurrySource === SlurrySource.HDFFluid
            },
            [Validators.required]
            ],
            testType: [{ value: fluid.testType, disabled: false }],
            slurryTypeId: [{ value: fluid.slurryTypeId, disabled: false }, [Validators.required]],
            testStatus: { value: fluid.testStatus, disabled: true },
            density:
                fluid.slurrySource && fluid.slurrySource === SlurrySource.ManualFluid
                    ? [{ value: fluid.density, disabled: true }, [Validators.required]]
                    : { value: fluid.density, disabled: true },
            water: { value: fluid.water, disabled: true },
            waterDensity: fluid.waterDensity,
            waterSAPNumber: fluid.waterSAPNumber,
            mixWaterId: {
                value: fluid.mixWaterId,
                disabled: fluid.slurrySource === SlurrySource.LinkedFluid || fluid.slurrySource === SlurrySource.HDFFluid
            },
            mixFluid: { value: fluid.mixFluid, disabled: true },
            waterRequirements: fluid.waterRequirements,
            yield: { value: fluid.yield, disabled: true },
            mixWater: { value: fluid.mixWater, disabled: true },
            mixVolume: fluid.mixVolume,
            sapMaterialName: { value: null, disabled: fluid.slurrySource === SlurrySource.HDFFluid },
            sapMaterialNumber: {
                value: this._getFluidSAPNumber(fluid, cementMaterial),
                disabled: fluid.slurrySource === SlurrySource.HDFFluid
            },
            isFoam: { value: fluid.isFoam, disabled: true },
            foamDensity: { value: fluid.foamDensity, disabled: true },
            foamQuality: { value: fluid.foamQuality, disabled: true },
            order: fluid.order,
            isCement: fluid.isCement,
            isCementBlend: { value: fluid.isCementBlend, disabled: true },
            labName: { value: fluid.labName, disabled: true },
            blendName: { value: fluid.blendName, disabled: true },
            cementName: { value: cementMaterial ? cementMaterial.materialName : '', disabled: true },
            sackWeight: { value: fluid.sackWeight, disabled: true },
            requestInfo: fluid.requestInfo,
            hdfSlurryIds: { value: fluid.hdfSlurryIds, disabled: true },
            slurryType: fluid.slurryType,
            thickeningTime: fluid.thickeningTime,
            pumpScheduleNumber: {value: fluid.pumpScheduleNumber,  disabled: true}
        });
        const materialGroups = this._createMaterialFormList(fluid.fluidMaterial, fluid.slurrySource);
        const materialGroupsSummary = this.createMaterialGroupsSummary(materialGroups);
        fg.controls.fluidMaterial = this._formBuilder.array(materialGroups);
        fg.controls.fluidAdditiveMaterial = this._formBuilder.array(materialGroupsSummary.notBlendMaterials);
        fg.controls.fluidBlendMaterial = this._formBuilder.array(materialGroupsSummary.blendMaterials);
        fg.controls.supplementalMaterial = this._formBuilder.array(materialGroupsSummary.supplementalMaterial);

        return fg;
    }

    public createMaterialForm(material: FluidMaterialModel, slurrySource: SlurrySource): UntypedFormGroup {

        const isExternalFluid = slurrySource === SlurrySource.LinkedFluid || slurrySource === SlurrySource.HDFFluid;
        const isNotSupplementalInExternalFluid = isExternalFluid && !this._materialService.isSupplemental(material);
        const concentrationUnit = material.concentrationUnit ? material.concentrationUnit : material.fluidUnitMeasureName;

        return this._formBuilder.group(
            {
                id: { value: material.id, disabled: isExternalFluid },
                materialId: [
                    {
                        value: material.materialId,
                        disabled: slurrySource === SlurrySource.LinkedFluid,
                    },
                    [Validators.required]
                ],
                additiveId: material.additiveId,
                cemBlendCompId: material.cemBlendCompId,
                materialName: [
                    {
                        value: material.materialName,
                        disabled: slurrySource === SlurrySource.LinkedFluid && !this._materialService.isSupplemental(material)
                    },
                    [Validators.required]
                ],
                materialType: material.materialType ? material.materialType : material.materialTypeName,
                materialTypeId: { value: material.materialTypeId, disabled: isExternalFluid },
                isBaseMaterial: { value: material.isBaseMaterial, disabled: isExternalFluid },
                concentration: material.concentration,
                concentrationUnit: concentrationUnit,
                fluidUnitMeasureId: { value: material.fluidUnitMeasureId, disabled: isNotSupplementalInExternalFluid },
                isBlendComponent: material.isBlendComponent,
                notExistedSAPMaterial: material.notExistedSAPMaterial,
                sapMaterialName: { value: material.sapMaterialName ? material.sapMaterialName : null, disabled: false },
                sapMaterialNumber: { value: material.sapMaterialNumber ? material.sapMaterialNumber : null, disabled: false },
                order: material.order,
                mixingProcedureId: material.mixingProcedureId,
                mixingProcedureValue: material.mixingProcedureValue,
                hdfComponentId: material.hdfComponentId,
                hdfMaterialId: material.hdfMaterialId,
                sg: material.sg,
                testAmount: material.testAmount,
                outputUnit: material.outputUnit,
                materialNumberAssignedFlag: material.materialNumberAssignedFlag,
                ifactMaterialAssignedFlag: material.ifactMaterialAssignedFlag,
                sapMaterialNumberAssignedValue: '',
                bulkDensity: material.bulkDensity,
                totalCOGS: material.totalCOGS,
                sampleId: material.sampleId,
                batchNo: material.batchNo,
                lotNo: material.lotNo,
                fluidTempId: material.fluidTempId,
            });
    }

    public createMaterialGroupsSummary(materialGroups: UntypedFormGroup[]): MaterialGroupsSummary {

        const waterMaterialForm = materialGroups.find(
            (fg: UntypedFormGroup) => this._materialService.isWater(fg.controls.materialType.value)
        );

        const cementMaterialsForms = materialGroups.filter(
            (fg: UntypedFormGroup) => fg.controls.materialType.value === this._materialService.CementType
        );

        const baseCementMaterialForm = maxBy(
            cementMaterialsForms, (fg: UntypedFormGroup) => fg.controls.concentration.value
        );

        const blendMaterialsForms = materialGroups.filter(
            (fg: UntypedFormGroup) => this._materialService.isCement(fg.value) && fg !== waterMaterialForm
        );

        const supplementalMaterialForms = materialGroups.filter(
            (fg: UntypedFormGroup) => this._materialService.isSupplemental(fg.value)
        );

        const exceptBlendMaterialsForms = materialGroups.filter(
            (fg: UntypedFormGroup) => indexOf(blendMaterialsForms, fg) === -1 && indexOf(supplementalMaterialForms, fg) === -1
        );

        const filterExceptBlendMaterials = this._filterExceptBlendMaterialsFn(baseCementMaterialForm, waterMaterialForm);

        const notBlendMaterials = exceptBlendMaterialsForms.filter(filterExceptBlendMaterials);

        return {
            waterMaterial: waterMaterialForm,
            cementMaterials: cementMaterialsForms,
            baseCementMaterial: baseCementMaterialForm,
            blendMaterials: blendMaterialsForms,
            notBlendMaterials,
            supplementalMaterial: supplementalMaterialForms
        };
    }

    private _createMaterialFormList(materials: FluidMaterialModel[], slurrySource: SlurrySource): UntypedFormGroup[] {

        return materials.map(material => this.createMaterialForm(material, slurrySource));
    }

    private _getFluidSAPNumber(fluid: FluidModel, cementMaterial: FluidMaterialModel) {

        let number = null;

        if (fluid.slurrySource === SlurrySource.HDFFluid && cementMaterial) {

            number = cementMaterial.sapMaterialNumber || null;
        } else {

            number = fluid.sapMaterialNumber || null;
        }

        return number;
    }

    private _filterExceptBlendMaterialsFn(baseCementMaterial, waterMaterial)
        : (materialForm: UntypedFormGroup) => boolean {

        return (materialForm: UntypedFormGroup) => (
            materialForm.controls.materialType.value !== this._materialService.CementType

            // When importing from HDF Material Type can be Cement for non-blend component (additive)
            || materialForm.controls.materialType.value === this._materialService.CementType
            && !materialForm.controls.isBlendComponent.value
            && materialForm.controls.hdfComponentId && materialForm.controls.hdfComponentId.value
        )
            && materialForm !== baseCementMaterial
            && materialForm !== waterMaterial;
    }

    private _getVidaTestTypeId(fluidTestType: string, fluidTestTypeId: string) {
        let testType: ITestType = null;

        testType = this._testTypes.find(x => x.id === fluidTestTypeId); //case: fluid has already had VIDA testType

        if (testType === null) {
            testType = this._testTypes.find(x => x.name === fluidTestType); //case: fluid has just added from iFacts and has ifacts testTypeId which should be changed
        }

        if (testType){
            return testType.id;
        }
    }
}
