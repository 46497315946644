import * as moment from 'moment-mini';
import { DATE_TIME_CALL_MOMENT_JS } from 'libs/constants';
export class JobProgram {
    virtualRelativeFilePath: string;
    filePathDownload: string;
    isLastUsed: boolean;
    uploader: string;
    createdDate: string;
    description: string;
    approver: string;
    approvalDate: string;
    clientApproveType: string;
    comment: string;
    separateApprovalFile: SeparateApprovalFile;
    getFileName() {
        return this.virtualRelativeFilePath ? this.virtualRelativeFilePath.split('/').pop() : '';
    }
    getSeparateApprovalFile() {
        return this.separateApprovalFile && this.separateApprovalFile.approvalVirtualRelativeFilePath ? this.separateApprovalFile.approvalVirtualRelativeFilePath.split('/').pop() : '';
    }
    getSeparateApprovalFileUploaded() {
        return this.separateApprovalFile && this.separateApprovalFile.approvalFileCreatedDate ? this.separateApprovalFile.approvalFileCreatedDate : '';
    }
    getApproveMessage() {
        if (this.approver && !this.approvalDate) {
            return `Approved by ${this.approver}`;
        }
        if (!this.approver && this.approvalDate) {
            return `Approved on ${moment(this.approvalDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        if (this.approver && this.approvalDate) {
            return `Approved by ${this.approver} on ${moment(this.approvalDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }
    getClientApprovalDocumentUploader() {
        if (this.separateApprovalFile && this.separateApprovalFile.approvalFileUploader) {
            return `Uploaded by ${this.separateApprovalFile.approvalFileUploader} on ${moment(this.createdDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }
    getMessage() {
        if (this.uploader && this.createdDate) {
            return `Uploaded by ${this.uploader} on ${moment(this.createdDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }

}

class SeparateApprovalFile {
    approvalVirtualRelativeFilePath: string;
    approvalFilePathDownload: string;
    approvalFileCreatedDate: string;
    approvalFileUploader: string;
    approvalFileComment: string;
}
