import { Component, ElementRef} from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'loading-indicator-container',
  templateUrl: './loading-indicator.container.html',
  styleUrls: ['./loading-indicator.container.scss']
})
export class LoadingIndicatorContainer implements BlockableUI {
  blocked: boolean = true;
  target: any;
  message: string;
  
  constructor(private el: ElementRef) {
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
