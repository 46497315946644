<div *ngIf="displayMode === 'Vertical'">
  <div class="upload m-bottom-2">
    <label *ngIf="header != ''"><span *ngIf="badgeNumber == true" class="badge-number">1</span> {{header}}</label>
    <div class="uploader-subtitle" *ngIf="title != ''">{{title}}</div>
    <div class="job-program">
      <div class="upload-handler">
        <p-fileUpload #fileInput chooseLabel="Browse" customUpload="true" [disabled]="isLoading || isDisabled" [showCancelButton]="false"
          [showUploadButton]="false" (onSelect)="onFileSelect($event)">
          <ng-template pTemplate="content">
            <div *ngIf="!fileInput.hasFiles()" style="line-height:34px">{{drapDropDescription}}</div>
          </ng-template>
          <ng-template let-file pTemplate="file">
            <span [ngClass]="{'error-custom': isMaxLengthError}" *ngIf="isMaxLengthError">
              <p class="status-message">{{uploadStatus}}</p>
            </span>
            <a class="btn btn-default" (click)="cancelUpload()">Cancel Upload</a>
          </ng-template>
          <ng-template pTemplate="toolbar">
            <div class="pi-filename" [attr.title]="fileInput.files[0]?.name ? fileInput.files[0]?.name : null">
              <input class="pi-filename-input" [formControl]="originalFileName" disabled/>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
  </div>

  <div class="file-rename" >
    <div><label>File Name</label></div>
    <div><input class="pi-filename-input" [formControl]="fileNameInput" /></div>
    <div><i>This is the name the file will be called in VIDA file explorer</i></div>
  </div>

  <div class="file-metadata" *ngIf="isEnableDescriptionInput">
    <label>Description</label>
    <textarea pInputTextarea class="full-width-input" rows="6" [disabled]="isLoading" [(ngModel)]="fileDescription" (ngModelChange)="changeDescription($event)"
      maxlength="256"></textarea>
  </div>
  <div *ngIf="showProgressBar" class="file-metadata" style="margin-top:5px;">
    <p-progressBar *ngIf="uploadedPercent != null" [value]="uploadedPercent" [styleClass]="uploadState" [showValue]="true"></p-progressBar>
    <span [ngClass]="{'error-custom': isError}" *ngIf="isError">
      <p class="status-message" *ngIf="uploadStatus">{{uploadStatus}}</p>
    </span>
    <span  *ngIf="!isError">
      <p class="status-message" *ngIf="uploadStatus">{{uploadStatus}}</p>
    </span>
  </div>
  <div class="row" style="margin-top:5px;">
    <div class="col-xs-12">
      <button type="button" class="btn btn-default" (click)="uploadFile(fileInput)"
              [disabled]="!fileInput.hasFiles() || isLoading" style="float: right;">
        <span class="ui-button-icon-left ui-clickable fa fa-arrow-circle-o-up"></span>
        <span class="ui-button-text ui-clickable"><strong>Upload</strong></span>
      </button>
    </div>
  </div>
</div>

<div *ngIf="displayMode === 'Horizontal'" class="sidebar-horizontal">
  <div class="row">
    <div class="col-xs-6">
      <div class="upload m-bottom-2">
        <label class="col-left-title" *ngIf="header != ''">{{header}}</label>
        <div class="job-program">
          <div class="upload-handler">
            <p-fileUpload #fileInput chooseLabel="Choose a file" customUpload="true" [disabled]="isDisabled ? true : isLoading"
              [showCancelButton]="false" [showUploadButton]="false" (onSelect)="onFileSelect($event)">
              <ng-template pTemplate="content">
                <div *ngIf="!fileInput.hasFiles()" class="dnd-description">{{drapDropDescription}}</div>
              </ng-template>
              <ng-template let-file pTemplate="file">

                <span [ngClass]="{'error-custom': isMaxLengthError}" *ngIf="isMaxLengthError">
                  <p class="status-message">{{uploadStatus}}</p>
                </span>
                <a class="btn btn-default" (click)="cancelUpload(fileInput)">Cancel Upload</a>
              </ng-template>

              <ng-template pTemplate="toolbar">
                <div class="pi-filename" [attr.title]="fileInput.files[0]?.name ? fileInput.files[0]?.name : null">
                  <input class="pi-filename-input" [formControl]="originalFileName" disabled/>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
      </div>

      <div class="file-metadata" style="margin-top:5px;">
        <p-progressBar *ngIf="uploadedPercent != null && showProgressBar" [value]="uploadedPercent" [styleClass]="uploadState" [showValue]="true"></p-progressBar>
        <span [ngClass]="{'error-custom': isError}" *ngIf="isError">
          <p class="status-message" *ngIf="uploadStatus">{{uploadStatus}}</p>
        </span>
      </div>
    </div>


    <div class="col-xs-6">
      <div class="file-rename-horizontal" >
        <div><label>File Name</label></div>
        <div><input class="pi-filename-input" [disabled]="isDisabled" [formControl]="fileNameInput" /></div>
        <div><i>This is the name the file will be called in VIDA file explorer</i></div>
      </div>

      <div class="file-metadata" *ngIf="isEnableDescriptionInput" class="description-call-sheet file-description">
        <span class="col-right-title">{{descriptionTitle}}</span>
        <div>
          <textarea pInputTextarea class="full-width-input" rows="5" [disabled]="isDisabled ? true : isLoading" placeholder="{{descriptionPlaceHolder}}"
            [(ngModel)]="fileDescription" (ngModelChange)="changeDescription($event)"></textarea>
        </div>
      </div>
      <div class="row" style="margin-top:5px;">
        <div class="col-xs-12">
          <button type="button" [disabled]="isDisabled" class="btn btn-default" (click)="uploadFile(fileInput)"
            [ngClass]="{'p-disabled': !fileInput.hasFiles() || uploadState===''}" [attr.disabled]="(!fileInput.hasFiles() || isLoading) ? '' : null"
            style="float: right;">
            <span class="ui-button-icon-left ui-clickable fa fa-arrow-circle-o-up"></span>
            <span class="ui-button-text ui-clickable"><strong>Upload</strong></span>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>

<div *ngIf="displayMode === 'Simple'" class="simple-uploader">
  <div class="upload">
    <label class="col-left-title" *ngIf="header != ''">{{header}}</label>
    <div class="job-program">
      <div class="upload-handler">
        <p-fileUpload #fileInput
            chooseLabel="Choose a file"
            customUpload="true"
            [disabled]="isDisabled ? true : isLoading"
            [showCancelButton]="false"
            [showUploadButton]="false"
            (onSelect)="onFileSelect($event)">
            <ng-template pTemplate="content">
              <div *ngIf="!fileInput.hasFiles()" class="dnd-description">{{drapDropDescription}}</div>
            </ng-template>
            <ng-template let-file pTemplate="file">
              <div class="pi-filename" [attr.title]="fileInput.files[0]?.name ? fileInput.files[0]?.name : null" *ngIf="!isMaxLengthError">
                <input class="pi-filename-input" [formControl]="fileNameInput" />
              </div>
              <span [ngClass]="{'error-custom': isMaxLengthError}" *ngIf="isMaxLengthError">
                <p class="status-message">{{uploadStatus}}</p>
              </span>
              <a class="btn btn-default" (click)="cancelUpload(fileInput)">Cancel Upload</a>
            </ng-template>

            <ng-template pTemplate="toolbar">
              <button type="button" *ngIf="!fileInput.hasFiles()"
                class="ui-button p-component ui-state-default ui-corner-all ui-button-text-icon-left"
                (click)="uploadFile(fileInput)"
                [disabled]="!fileInput.hasFiles() || isLoading">
                <i class="fa fa-folder-open move-right" aria-hidden="true"></i>
              </button>
            </ng-template>
          </p-fileUpload>

        <div class="file-metadata">
          <p-progressBar *ngIf="uploadedPercent != null" [value]="uploadedPercent" [styleClass]="uploadState" [showValue]="true"></p-progressBar>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="displayMode === 'Minimal'">
  <div>
    <p-fileUpload class="p-fileupload-minimal" #fileInput customUpload="true" 
      chooseLabel="Choose a file"
      [disabled]="isDisabled ? true : isLoading" 
      [showCancelButton]="false"
      [showUploadButton]="false" 
      multiple="multiple"
      (onSelect)="uploadFile(fileInput)"
    >
      <ng-template pTemplate="content">
        <div style="display: inline;">
          <span (click)="onBrowse()" style="cursor: pointer;">
            <p style="text-decoration: underline; display: inline;">Choose a file</p> 
          </span>   
          <p style="text-decoration: none; display: inline;">{{drapDropDescription}}</p>
        </div>
      </ng-template>
      <ng-template let-file pTemplate="file">
      </ng-template>

      <ng-template pTemplate="toolbar">
      </ng-template>
    </p-fileUpload>

    <div class="file-metadata">
      <p-progressBar *ngIf="uploadedPercent != null" [value]="uploadedPercent" [styleClass]="uploadState"
        [showValue]="true"></p-progressBar>
    </div>
  </div>
</div>
