import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { CPESharedModule } from '../shared/shared.module';
import { UIModule } from 'libs/ui';
import { ActiveJobsComponent } from './components';
import { ToastModule } from 'primeng/toast';;
import { InputSwitchModule } from 'primeng/inputswitch';
import { SharedModule } from 'primeng/api';
import { PaginatorModule } from 'primeng/paginator';
import { DWXService } from '../well/services';

const myJobRoute: Routes = [
  { path: '', component: ActiveJobsComponent }
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ToastModule,
    InputSwitchModule,
    RouterModule.forChild(myJobRoute),
    Ng2SearchPipeModule,
    CPESharedModule,
    SharedModule,
    UIModule,
    PaginatorModule
  ],
  providers: [
    DWXService
  ],
  declarations: [ActiveJobsComponent]
})
export class ActiveJobsModule { }
