export class MudParameterModel {
  id: string;
  mudTypeId: string;
  typeName: string;
  mudName: string;
  mudDensity: number;

  constructor() {
    this.id = null;
    this.mudTypeId = null;
    this.typeName = null;
    this.mudName = null;
    this.mudDensity = null;
  }
}
