import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HDF_API_URL } from 'libs/constants';
import { ProposalVersionHDF, Attachment, HDFAttachmentType, GetJobInfoBySORespone, JobHDFInfo } from 'libs/models';
import { map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { UserSettingService } from './user-setting.service';
import { CacheService } from './cache.service';

@Injectable()
export class HdfService extends BaseApiService {

  constructor(
    protected httpClient: HttpClient
    , protected userSettingService: UserSettingService
    , protected cacheService: CacheService) {
    super(httpClient, cacheService);
  }

  // US 359802
  getJobInfoBySaleOrder(saleOrder: string): Observable<GetJobInfoBySORespone> {
    return this.httpClient.get<GetJobInfoBySORespone>(HDF_API_URL.SALE_ORDER_JOB_INFO(saleOrder));
  }

  getProposalVersions(proposal: string): Observable<ProposalVersionHDF[]> {
    return this.httpClient.get<ProposalVersionHDF[]>(HDF_API_URL.PROPOSAL_VERSIONS(proposal));
  }

  getExcutionByProposalNumberAndHDFJobId(proposalNumber, hdfJobId) {
    return this.getProposalVersions(proposalNumber)
      .pipe(
        map(data => [].concat.apply([], data.map(x => x.jobs))),
        map((hdfJobs: JobHDFInfo[]) => {
          const hdfJob = hdfJobs.find((x: JobHDFInfo) => x.jobId.toLocaleLowerCase() === hdfJobId.toLocaleLowerCase());
          if (hdfJob && hdfJob.jobType.toLowerCase() === 'execution') {
            return hdfJob;
          }
          return hdfJobs.find((_hdfJob: JobHDFInfo) => _hdfJob.proposalJobId.toLocaleLowerCase() === hdfJobId.toLocaleLowerCase());
        })
      );
  }

  mapJobInfoData(jobId: string): any {
    const { groupId } = this.userSettingService.selectedLocation;
    return this.httpClient.get<any[]>(HDF_API_URL.MAP_JOB_INFO_DATA(jobId, groupId));
  }

  getAttachments(jobId: string): Observable<Attachment[]> {
    return this.httpClient.get<Attachment[]>(HDF_API_URL.ATTACHMENTS(jobId));
  }

  getAttachmentTypes(): Observable<HDFAttachmentType[]> {
    return this._get<HDFAttachmentType[]>(HDF_API_URL.ATTACHMENT_TYPES);
  }
}
