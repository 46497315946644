import { Injectable,  ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ConfirmDialogContainer } from '../components/confirm-dialog/confirm-dialog.container';
import { Confirmation } from 'primeng/api';
// SINGLETON CONTAIN ONLY 1 DIALOG MESSAGE AT A SAME TIME
@Injectable()
export class ConfirmDialogService {

  // factoryResolver: ComponentFactoryResolver
  rootViewContainer: ViewContainerRef;
  componentRef: ComponentRef<ConfirmDialogContainer>;

  constructor(private factoryResolver: ComponentFactoryResolver) {
    // this.factoryResolver = factoryResolver
  }

  setRootView(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
    const factory = this.factoryResolver.resolveComponentFactory(ConfirmDialogContainer);
    this.componentRef = this.rootViewContainer.createComponent(factory);
    
  }

  show(confirmation: Confirmation) {
    if (confirmation.rejectVisible === undefined)
      confirmation.rejectVisible = true;
    confirmation.acceptLabel = confirmation.acceptLabel ? confirmation.acceptLabel : 'Yes';
    confirmation.rejectLabel = confirmation.rejectLabel ? confirmation.rejectLabel : 'No';
    this.componentRef?.instance.show(confirmation);
  }

  hide() {
    this.componentRef?.instance.hide();
  }
}