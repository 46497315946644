import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { APIFluidLossField, FluidLossField, StirringFluidLossField, StirringFluidLossTimeFields, TestTableName } from '../stage-test-results/stage-test-results.constant';
import { IFactsAvailableSlurry } from 'libs/models/ifact/ifacts-available-slurry';

@Component({
  selector: 'fluid-loss-test-result',
  templateUrl: './fluid-loss-test-result.component.html',
  styleUrls: ['./fluid-loss-test-result.component.scss']
})
export class FluidLossTestResultComponent extends PumpScheduleTestResultBase implements OnInit {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  };
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }
  @Input() canEdit: boolean;
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();

  FluidLossField = FluidLossField;

  constructor() {
    super(TestTableName.APIFluidLoss);
  }

  ngOnInit(): void {
  }

  get test(): PumpScheduleStageTest | null {

    if (this.tests.length === 1) {
        return this.tests[0];
    }

    const validatedOrFinishedTests = this.tests.filter(this.isTestFinishedOrValidated);

    if (validatedOrFinishedTests.length === 1) {
      return validatedOrFinishedTests[0];
    }

    const otherTests = this.tests.filter(t => !this.isTestFinishedOrValidated(t));

    if (otherTests.length === 1 && validatedOrFinishedTests.length === 0) {
      return otherTests[0];
    }

    return null;
  }

  getFluidLoss(test: PumpScheduleStageTest) {
    
    let fluidLoss = null;

    if (test.testName === this.IFactTestName.APIFluidLoss) {

      fluidLoss = Number(this.getField(test, APIFluidLossField.TestTime)) >= 30
                    ? this.getField(test, APIFluidLossField.APIFluidLoss)
                    : this.getField(test, APIFluidLossField.CalculatedAPIFluidLoss);

    } else if (test.testName === this.IFactTestName.FluidLoss) {

      fluidLoss = Number(this.getField(test, FluidLossField.TestTime)) >= 30
                    ? this.getField(test, FluidLossField.APIFluidLoss)
                    : this.getField(test, FluidLossField.CalculatedAPIFluidLoss);
                    
    } else if (test.testName === this.IFactTestName.StirringFluidLoss) {

      fluidLoss = this.getField(test, StirringFluidLossField.mlPer30Min)
                    ? this.getField(test, StirringFluidLossField.APIFluidLoss)
                    : this.getField(test, StirringFluidLossField.CalculatedAPIFluidLoss);
    }

    return fluidLoss;
  }

  getFluidTemperature(test: PumpScheduleStageTest) {
    
    let fluidTemp = null;

    if (test.testName === this.IFactTestName.APIFluidLoss) {

      fluidTemp = Number(this.getField(test, APIFluidLossField.TestTemperature));

    } else if (test.testName === this.IFactTestName.FluidLoss) {

      fluidTemp = Number(this.getField(test, FluidLossField.TestTemperature));
                    
    } else if (test.testName === this.IFactTestName.StirringFluidLoss) {

      fluidTemp = this.getField(test, StirringFluidLossField.TestTemperature);
    }

    return fluidTemp;
  }

  getTestTime(test: PumpScheduleStageTest) {
    
    let testTime = null;

    if (test.testName === this.IFactTestName.StirringFluidLoss) {

      const notEmptyField = StirringFluidLossTimeFields.find(f => test.pumpScheduleStageTestFields.find(x => x.testFieldDesc === f && x.result));

      if (notEmptyField) {
        testTime = notEmptyField.replace(/mL\s+\/\s/, '');
      }
      
    } else {
      
      testTime = this.getNumericField(test, FluidLossField.TestTime)?.toFixed(2) + ' min';

    }

    return testTime;
  }

  getTestDisplayName(test: PumpScheduleStageTest) {
    return this.getField(test, test.testName === this.IFactTestName.StirringFluidLoss ?
      StirringFluidLossField.APIFluidLoss : APIFluidLossField.APIFluidLoss) ? 'API Fluid Loss' : 'Calc Fluid Loss';
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }

}
