import { ChangeDetectionStrategy, Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'job-errors-modal',
    templateUrl: './job-errors-modal.component.html', 
    styleUrls: ['./job-errors-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobErrorsModalComponent implements OnInit{
    data: any;
    completeValidate:string[]=[];

    constructor(public ref: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.data = this.dynamicDialogConfig.data;
        this.completeValidate=this.data.completeValidate;
    }

    onClose() {
        this.ref.close();
    }
}