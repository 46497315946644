export const CommonMessageText = {
  ITEM_NOT_FOUND: 'No items found.',
  TOOLTIPS: {
    OPS_MESSAGE: 'Ops message alert.',
    JOB_DUE_SOON: 'Due soon',
    JOB_COMPLIANCE: 'Out of compliance'
  },
  ICEM_IMPORT_EXTRACTION: {
    SUCCESS: 'iCem file has been extracted successfully.',
    JOB_LOG: 'Job Log data has been updated'
  },
  ICEM_IMPORT: {
    SUCCESS: 'iCem file has been updated successfully.',
    JOB_LOG: 'Job Log data has been updated'
  },
  LIBRARY_REMOVED: {
    SUCCESS: 'has been removed from your Saved Jobs successfully.',
    FAILED: 'was failed to remove from your Saved Jobs.'
  },
  LIBRARY_SAVED: {
    SUCCESS: 'has been saved to your Saved Jobs successfully.',
    FAILED: 'was failed to save to your Saved Jobs.'
  },
  WELL_REMOVED: {
    SUCCESS: 'is removed from My Wells successfully.',
    FAILED: 'is failed to remove from My Wells successfully.'
  },
  WELL_SAVED: {
    SUCCESS: 'is added to My Wells successfully.',
    FAILED: 'is failed to add to My Wells'
  },
  ICEM_PARSE: {
    SUCCESS: 'iCem file has been parsed successfully.',
  },
  FLUID_ADD: {
    SUCCESS: 'Selected Slurry ID(s) has been added to the fluids list successfully.',
  },
  IFACTS_VIS: {
    SAVE_SUCCESS: 'Selected Slurry has been added to the fluids list successfully.',
    ALREADY_SAVED: 'Selected Slurry is already added to the fluids list'
  },
  FLUID_EDIT_LINK: {
    SUCCESS: 'Linked Slurry has been edited as Manual Slurry',
  },
  JOB_PROGRAM: {
    SUCCESS: 'Job Program has been added to the Job Programs list successfully.',
    FAILED: 'Job Program was failed to save to the Job Programs.',
  },
  LOCAL_WELL_REVIEW: {
    SUCCESS: 'Local Well Review has been updated successfully.',
    FAILED: 'Local Well Review File was failed to save to the Local Well Review.',
  },
  CALL_SHEET: {
    SUCCESS: 'Call Sheet has been added successfully.',
    FAILED: 'Call Sheet File was failed to save to the Call Sheet.',
  },
  POST_JOB_REPORT: {
    SUCCESS: 'Post-Job Report has been added successfully.',
    FAILED: 'Post-Job Report File was failed to save to the Post-Job Report.',
  },
  JOB_LOG_FILE: {
    SUCCESS: 'Job Log File has been added successfully.',
    FAILED: 'Job Log File was failed to save to the Job Logs.',
  },
  RISK_ASSESSMENT: {
    SUCCESS: 'Risk Assessment has been added successfully.',
    FAILED: 'Risk Assessment File was failed to save to the Risk Assessment.',
  },
  MATERIAL_LOAD_SHEET: {
    SUCCESS: 'Material Load Sheet has been added successfully.',
    FAILED: 'Material Load Sheet File was failed to save to the Material Load Sheet.',
  },
  STATE_ACTIVATION: 'Confirm Activation',
  STATE_CONTENT_ACTIVATION: `Are you sure you want to set this Job State to 'Active'?`,
  STATE_ERROR_ACTIVATION: `Only Reviewer role of job's location is allowed to update Job Status: Completed/Cancelled to Active.`,
  STATE_CLOSED: 'Confirm Job Close',
  STATE_CONTENT_CLOSED: `Are you sure you want to set this Job State to 'Closed'? No edits will be permitted on a closed job, except for work in the File explorer.`,
  STATE_ERROR_CLOSED: `Reviewer permissions are required to close a job. Please ask your reviewer to close this job.`,
  STATE_DRAFT: 'Confirm Draft',
  STATE_CONTENT_DRAFT: `Are you sure you want to set this project status to 'Draft'?`,
  STATE_CANCELLED: 'Confirm Cancellation',
  STATE_CONTENT_CANCELLED: `Are you sure you want to set this Job State to 'Cancelled'? No edits will be permitted on a cancelled job, except for work in the File Explorer.`,
  STATE_COMPLETE: 'Confirm Complete',
  STATE_CONTENT_COMPLETE: `Are you sure you want to mark this Job as Complete? `,
};

