import {
  Component,
  forwardRef,
  Input,
  Injector,
  ViewChild,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import { FormValueAccessor } from 'libs/ui/form-value-accessor';
import { ColumnDefinition } from 'libs/ui/models/column-definition';
import { isIE } from 'libs/ui/helpers/browser.helper';
import { addErrorToControl } from 'libs/helpers';
import { LifeHookComponentItemBehaviour } from '../../helpers/life-hook-component-item-behaviour';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'typeahead-datatable',
  templateUrl: './typeahead-datatable.component.html',
  styleUrls: ['typeahead-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeaheadDatatableComponent),
      multi: true
    }
  ]
})
export class TypeaheadDatatableComponent extends FormValueAccessor implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() touched: boolean = false;
  @Input() columns: ColumnDefinition[];
  @Input() options: any[];
  @Input() showEmptyList: boolean = true;
  @Input() placeholder: string;
  @Input() useTextArea: boolean;
  @Input() styleClass: string;
  @Input() debounceTime: number = 300;
  @Input() disabled: boolean;
  @Input() lifeHookItemBehaviour: LifeHookComponentItemBehaviour;
  @Input() appendTo = null;
  @Input() shouldValidate: boolean = true;
  @Input() shouldMarkAsTouchedOnFocus: boolean = true;
  @Input() shouldHidePanelOnEmptyString: boolean = false;
  @Input() inputLengthForHidePanel: number;
  @Input() showOptionsOnFocus = false;

  @Output() onLazyLoad = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<any>();
  @Output() onKeyup = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();
  @Output() onChangeValidState = new EventEmitter<boolean>();
  @ViewChild('overlayPanel') _overlayPanel: OverlayPanel;
  @ViewChild('input') _input: ElementRef;
  _visibleColumns: ColumnDefinition[];
  _selectedItem: any;
  _keyup$ = new Subject<string>();
  _isLoading: boolean = false;
  _isFirstFocusOut: boolean = true;
  _subscriptions: Subscription[] = [];
  currentInputString: string;

  constructor(
    protected inj: Injector,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  public ngOnInit() {

    if (!this.placeholder) {
      this.placeholder = '';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { columns, options, touched, disabled } = changes;
    if (columns && columns.currentValue) {
      this._visibleColumns = this.columns.filter(col => col.isVisible);
    }
    if (options && options.currentValue 
        && (!this.showOptionsOnFocus || this.showOptionsOnFocus && this._isLoading)) {
      this._isLoading = false;
      this._selectedItem = null;
      this.showOverlayTable();
    }
    if (touched) {
      if (this.touched)
        this._formControl.markAsTouched();
      else
        this._formControl.markAsUntouched();
    }

    if (disabled) {
      if (this.disabled) {
        this._formControl.disable();
      } else {
        this._formControl.enable();
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this._subcribeEvents();
    this.triggerLifeHookItemBehaviour('ngAfterViewInit');
  }

  ngOnDestroy() {
    this._unsubscribeEvents();
  }

  showLoading() {
    this._isLoading = true;
    this.cd.markForCheck();
  }

  hideLoading() {
    this._isLoading = false;
    this.cd.markForCheck();
  }

  showOverlayTable() {

    const shouldHide =
        ((this.inputLengthForHidePanel ?? false) && this.currentInputString && this.currentInputString.length > this.inputLengthForHidePanel)
        || ((this.shouldHidePanelOnEmptyString ?? false) && this.shouldHidePanelOnEmptyString && !this.currentInputString);

    if (this._overlayPanel && shouldHide) {
      this._overlayPanel.hide();
      return;
    }

    if (this._input
      && this.options
      && (this.options.length > 0
        || this.options.length === 0 && this.showEmptyList)) {
      this._overlayPanel.show({ currentTarget: this._input.nativeElement });
    }
  }

  hideOverlayTable() {
    this._overlayPanel.hide();
  }

  _unsubscribeEvents() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  _subcribeEvents() {
    let keyupChange$ = this._keyup$.pipe(
      tap(value => {
        this.currentInputString = value;
        this._isLoading = true;
        this._selectedItem = null;
        this._propagateChange(value);
        this.showOverlayTable();
        if (value && this.control && this.shouldValidate) {
          addErrorToControl(this.control, 'notExisted');
          this.onChangeValidState.emit(false);
        }
        this.cd.markForCheck();
      })
    );

    if (this.onLazyLoad && this.onLazyLoad.observers && this.onLazyLoad.observers.length) {
      keyupChange$ = keyupChange$.pipe(
        debounceTime(this.debounceTime),
        tap(value => {
          if (value) {
            this.onLazyLoad.emit(value);
          } else {
            this._isLoading = false;
            this.cd.markForCheck();
            this.onClear.emit();
          }
          this.cd.markForCheck();
        })
      );
    } else {
      keyupChange$ = keyupChange$.pipe(
        tap(_ => this._isLoading = false)
      );
    }

    this._subscriptions.push(keyupChange$.subscribe());
  }

  triggerLifeHookItemBehaviour(lifeHook: string){

    if (!this.lifeHookItemBehaviour
      || lifeHook !== this.lifeHookItemBehaviour.componentLifeHook) return;

      const componentField = this[this.lifeHookItemBehaviour.item];

      if (componentField)
        this.lifeHookItemBehaviour.behaviour(componentField);
  }

  _onRowSelect(item: any) {
    this._overlayPanel.hide();
    this.control.setErrors(null);
    this.onChange.emit(item);
    this.cd.markForCheck();
  }

  _onKeyup(e) {
    this.control.markAsDirty();
    this._keyup$.next(this._formControl.value);
    this.onKeyup.emit(e);
  }

  _onFocus(e) {
    if (this.shouldMarkAsTouchedOnFocus) {
      this.control.markAsTouched();
    }
    this._isLoading = false;

    if (this.showOptionsOnFocus 
        && this._input != null
        && this._input.nativeElement != null
        && this.options != null
        && this.options.length > 0 
        && (this._formControl.value == null 
            || this._formControl.value.length == 0)) {
      this._overlayPanel.show({ currentTarget: this._input.nativeElement });
    }

    this.onFocus.emit(e);
  }

  _onFocusOut(e) {
    // Bug 382193 - on IE the textbox lost focus, when we click on the scrollbar
    // Bug 425335 - after initialization, the p-overlayPanel doesn't hide first time upon loss of focus https://github.com/primefaces/primeng/issues/2380
    // Bug 426174 - sometimes an option is not select from the list, reason: this._overlayPanel.hide();, so added setTimeout(), so that the click has time to emit before the panel hides

    /*if (this._isFirstFocusOut && !isIE()) {
      this._isFirstFocusOut = false;
      setTimeout(() => { this._overlayPanel.hide(); }, 200);
    }*/

    this.control.markAsTouched();
    this.onFocusOut.emit(e);
  }
}
