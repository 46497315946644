export enum SpacerMixMethod {
    MixOnTheFly,
    BatchMix
  }

export const SpacerMixMethods = [{
    value: 0,
    label: 'Mix on the Fly',
    disabled: false,
  }, {
    value: 1,
    label: 'Batch Mix',
    disabled: false,
  }];

  export enum LinerCirculationMethod {
    Reverse = 1,
    Conventional = 2
  }

  export const LinerCirculationMethods = [{
    value: null,
    label: 'Not Set',
    disabled: false,
  },
    {
    value: 1,
    label: 'Reverse',
    disabled: false,
  }, {
    value: 2,
    label: 'Conventional',
    disabled: false,
  }];

  export const VersaFlexOptions = [{
    value: null,
    label: 'Not Set',
    disabled: false,
  },
    {
    value: true,
    label: 'Yes',
    disabled: false,
  }, {
    value: false,
    label: 'No',
    disabled: false,
  }];
