import { MaterialManagementMappingModel } from "libs/models";
import { IfactService } from "libs/shared";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

export class JobMaterialSAPMapping {
  constructor(private ifactService: IfactService, private getJobMappings: fnJobMapping) {
    ifactService.forceUpdateMapping$.subscribe(_ => {
      this._groupId = null; this._materialMappings = null;
    });
  }

  private _materialMappings: MaterialManagementMappingModel[];
  private _jobMappings: JobMappingModel[];
  private _groupId: string;
  private _loading$: Observable<MaterialManagementMappingModel[]>;
  
  public loadSAPMapping(groupId: string): Observable<MaterialManagementMappingModel[]> {
    if (this._groupId == groupId) {
      return this._materialMappings ? of(this._materialMappings) : this._loading$;
    }
    this._groupId = groupId;
    this._loading$ = this.ifactService.getMaterialMappings(groupId, null, null).pipe(
      tap(materialMappings => {
        if (!materialMappings) return;
        this._materialMappings = materialMappings;
      }
    ));
    return this._loading$;
  }

  public findIFacts(sapNumber: string, mappingFilter: fnFilterJobMapping, useCache: boolean = true): JobMappingModel {
    const materialTableSAP = this.findInMaterialTable(x => x.sapMaterialNumber === sapNumber);
    if (materialTableSAP !== null) {
      return materialTableSAP;
    }

    const jobMappings = this.getCachedJobMappings(useCache);
    let filteredItems = jobMappings.filter(x => x.sapMaterialNumber == sapNumber 
                                                && x.materialId != null);
    if (filteredItems.length > 0 && mappingFilter) {
      filteredItems = filteredItems.filter(x => mappingFilter(x.details));
    }
    
    if (filteredItems.length > 1) {
      const values = filteredItems.map(x => x.materialId).distinct();
      if (values.length > 1) { //items have different materialId
        filteredItems = []; //than no one item can be applied
      }
    }
    return filteredItems.length > 0 && filteredItems[0].materialId ? filteredItems[0] : null;
  }

  public findSAP(materialNumber: string, mappingFilter: fnFilterJobMapping, useCache: boolean = true): JobMappingModel {
    const materialTableIFacts = this.findInMaterialTable(x => 
                        x.materialId === materialNumber 
                      && x.sapMaterialNumber != null 
                      && x.sapMaterialNumber != '');
    if (materialTableIFacts !== null) {
      return materialTableIFacts;
    }

    //find in job
    const jobMappings = this.getCachedJobMappings(useCache);
    let filteredItems = jobMappings.filter(x => x.materialId == materialNumber 
                  && x.sapMaterialNumber != null && x.sapMaterialNumber != '');
    if (filteredItems.length > 0 && mappingFilter) {
      filteredItems = filteredItems.filter(x => mappingFilter(x.details));
    }

    if (filteredItems.length > 1) {
      const values = filteredItems.map(x => x.sapMaterialNumber).distinct();
      if (values.length > 1) { //items have different materialId
        filteredItems = []; //than no one item can be applied
      }
    }

    return filteredItems.length > 0 && filteredItems[0].sapMaterialNumber ? filteredItems[0] : null;
  }

  public findInMaterialTable(filterFunction : (item: MaterialManagementMappingModel)=> boolean): JobMappingModel {
    if (this._materialMappings === undefined) {
      throw '_materialMappings is not initialized. Can\'t define sap suggestion.';
    }
    const mappedMaterials = this._materialMappings.filter((x, _) => filterFunction(x));
    if (mappedMaterials.length === 1) {
      return this.convertToJobMappingModel(mappedMaterials[0]);
    }
    return null;
  }

  private getCachedJobMappings(useCache: boolean = true): JobMappingModel[] {
    if (useCache && this._jobMappings){
      return this._jobMappings;
    }
    if (!this.getJobMappings) //do not throw error if jobMappings is not defined - it is not critical
      this._jobMappings = [];
    else {
      this._jobMappings = this.getJobMappings();
      if (!this._jobMappings) {
        this._jobMappings = [];
      }
      this._jobMappings.forEach(x => x.isMaterialMappingTable = false);
    }
    return this._jobMappings;
  }

  private convertToJobMappingModel(mappedMaterial: MaterialManagementMappingModel): JobMappingModel {
    const result: JobMappingModel = {
      materialId: mappedMaterial.materialId,
      iFactMaterialName: mappedMaterial.iFactMaterialName,
      sapMaterialNumber: mappedMaterial.sapMaterialNumber,
      isMaterialMappingTable: true,
      details: null
    };
    return result;
  }
}

export type fnJobMapping = () => JobMappingModel[];
export type fnFilterJobMapping = (data: any) => boolean;
export class JobMappingModel {
  materialId: string;
  iFactMaterialName: string;
  sapMaterialNumber: string;
  isMaterialMappingTable: boolean;
  details: any;
}