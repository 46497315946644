import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

export class EventCalculator {

    public readonly isShutdown$: Observable<boolean> = this._isShutdown$
        .pipe(shareReplay());

    public readonly rate$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {
                return isShutdown ? of(0) : this._manualRate$;
            }),
            shareReplay()
        );

    public readonly volume$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {
                return isShutdown ? of(0) : this._manualVolume$;
            }),
            shareReplay()
        );

    public readonly topOfFluid$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {
                return isShutdown ? of(null) : this._topOfFluid$;
            }),
            shareReplay()
        );

    public readonly nonShutdownDuration$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {

                return isShutdown
                    ? of(null)
                    : combineLatest([
                        this.rate$,
                        this.volume$
                    ])
                    .pipe(
                        map(([rate, volume]) => {
        
                            return this._calcDuration(rate, volume);
                        })
                    );
            }),
            shareReplay()
        );

    private readonly _shutdownDuration$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {
                return isShutdown ? this._manualDuration$ : of(null);
            }),
            shareReplay()
        );

    public readonly duration$: Observable<number> = this._isShutdown$
        .pipe(
            switchMap(isShutdown => {

                return isShutdown ? this._shutdownDuration$ : this.nonShutdownDuration$;
            }),
            shareReplay()
        );

    public constructor (

        private readonly _manualRate$: Observable<number>,

        private readonly _manualVolume$: Observable<number>,

        private readonly _manualDuration$: Observable<number>,

        private readonly _topOfFluid$: Observable<number>,

        private readonly _isShutdown$: Observable<boolean>
    ) {
    }

    private _calcDuration(rate: number, volume: number): number {

        if (!rate || !volume || isNaN(rate) || isNaN(volume)) {
            return 0;
        }

        return volume / rate;
    }
}
