<div class="note">
  <span>*Note: To build a Pump Schedule with advanced placement methods, please create the schedule in
    iCem and then import into VIDA.</span>
</div>
<div class="flx-ctn-row-rev"
  [ngClass]="{'disable-form': this.controlpointState === ControlPointState.Submitted || controlpointState === ControlPointState.Approved, 'disable-state': jobStateCancel}"
  [formGroup]="pumpScheduleForm" [loadingIndicator]="loadingSubscription">

  <div class="flx-itm-3-12 flx-itm-xs-12-12 flx-ctn-col p-t-20">
    <div class="flx-itm p-b-25 editing">
      <i class="glyphicon glyphicon-envelope custom-icon" aria-hidden="true"></i>
      <span (click)="onReportMail()" class="custom-span">Report SAP Mismapping</span>
    </div>
    <pump-schedule-shoetrack [jobPlacementTime]="pumpScheduleAdapter.jptList$ | async"
      [pumpScheduleForm]="pumpScheduleForm" 
      [cpState]="controlpointState" 
      [job]="job" 
      [jobState]="jobState"
      [isManuallySpacerMixMethod]="model?.isManuallySpacerMixMethod"
      (openJobCogsHelp)="openJobCogsHelp()">
    </pump-schedule-shoetrack>
  </div>

  <div class="flx-itm-9-12 flx-itm-xs-12-12 flx-ctn-col p-t-20 p-r-20 pump-stages">
    <pump-stage *ngFor="let stageForm of stageFormArray.controls; let i=index; let odd=odd; let even=even;" 
      [stageModel]="model?.stages ? model.stages[i] : null" 
      [index]="i"
      [pumpScheduleModel]="model"
      [stageForm]="stageForm"
      [pumpScheduleForm]="pumpScheduleForm"
      [fluids]="editJobAdapter.fluids$ | async"
      [permission]="job.canEdit"
      [isImportDataFromHDF]="model?.isImportDataFromHDF"
      [even]="even" 
      [odd]="odd" 
      [cpState]="controlpointState" 
      [jobState]="jobState" 
      [job]="job"
      [sapMaterialMappings]="editJobAdapter.availableSapMappings$ | async"
      (insertBefore)="onInsertStageBefore(i)"
      (insertAfter)="onInsertStageAfter(i)"
      (delete)="onDeleteStage(i)"
      (onSelectedFluid)="onSelectedFluid($event)"
      (openStageCogsHelp)="openStageCogsHelp(stageForm.value)">
    </pump-stage>
  </div>
</div>
