import * as moment from 'moment-mini';
import { DATE_TIME_CALL_MOMENT_JS } from 'libs/constants';
import { AdUser } from 'libs/models';
export class LocalWellReviewModel {
  virtualRelativeFilePathLocal: string;
  description: string;
  createdDate: string;
  uploader: string;
  filePathDownload: string;
  comment: string;
  participantNames: AdUser[];
  reviewDate: Date;
  isWellReviewCompleted: boolean;
  participants: string;
  getFileName() {
    return this.virtualRelativeFilePathLocal ? this.virtualRelativeFilePathLocal.split('/').pop() : '';
  }
  getMessage() {
    if (this.uploader && this.createdDate) {
      return `Uploaded by ${this.uploader} on ${moment(this.createdDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
    }
    return '';
  }
  getReviewDate() {
    if (this.reviewDate) {
      return `${moment(this.reviewDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
    }
    return '';
  }
  getParticipant() {
    if (this.participantNames && this.participantNames.length) {
      return `${this.participantNames.map(parti => parti.displayName).join(', ')}`;
    }
    return '';
  }
}
