<div [class.job-edit-test-result]="isJobEditView" [class.cp-test-result]="!isJobEditView">
  <div class="d-flex flex-wrap row-1">
    <div class="col-rheology">
      <rheology-test-result 
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)"
        (onDeleteTest)="deleteTest($event)">
      </rheology-test-result>
    </div>
    <div class="col-tt">
      <thickening-time-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        [stageState]="stageState"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </thickening-time-test-result>
    </div>
    <div class="col-uca">
      <uca-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </uca-test-result>
    </div>
    <div class="col-free-fluid">
      <free-fluid-test-result 
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </free-fluid-test-result>
    </div>
    <div class="col-sgs">
      <static-gel-strength-result [canEdit]="canEdit"
                              [testTables]="testTables$ | async"
                              [availableSlurries]="availableSlurries"
                              (onChangeSlurryNo)="changeSlurryNo($event)">

      </static-gel-strength-result> 
    </div>
    <div class="col-fluid-loss">
      <fluid-loss-test-result
        [canEdit]="canEdit"
        [testTables]="testTables$ | async"
        [availableSlurries]="availableSlurries"
        (onChangeSlurryNo)="changeSlurryNo($event)">
      </fluid-loss-test-result>
    </div>
    <div class="col-document-links">
      <div class="tr-doc-section">
        <span class="guideline-text">Thickening Time Guideline for TPs and BD: 
          <a class="link-red" (click)="openTestResultDocument($event,designCriteriaOption.ThickeningTime)">
            <u>GD-GL-HAL-CMT-LAB-202</u>
          </a>
        </span>
        <span class="guideline-text">Compressive Strength Guideline for TPs and BD: 
          <a class="link-red" (click)="openTestResultDocument($event,designCriteriaOption.CompressiveStrength)">
            <u>GD-GL-HAL-CMT-LAB-206</u>
          </a>
        </span>
        <span class="guideline-text">Static Gel Strength Guideline for TPs and BD: 
          <a class="link-red" (click)="openTestResultDocument($event,designCriteriaOption.StaticGelStrength)">
            <u>GD-GL-HAL-CMT-LAB-217</u>
          </a>
        </span>
        <span class="guideline-text">Free Fluid & Fluid Density Guideline for TPs and BD: 
          <a class="link-red" (click)="openTestResultDocument($event,designCriteriaOption.FreeFluid)">
            <u>ST-GL-HAL-CMT-LAB-210</u>
          </a>
        </span>
        <span class="guideline-text">Fluid Loss Guideline for TPs and BD: 
          <a class="link-red" (click)="openTestResultDocument($event,designCriteriaOption.FluidLoss)">
            <u>GD-GL-HAL-CMT-LAB-207</u>
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
