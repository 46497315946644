import { FileChunk } from './fileChunk';
import { IdGenerator } from 'libs/helpers';

export class FileMetadata {

    public readonly name: string;

    public readonly size: number;

    public readonly chunks: FileChunk[] = [];

    public constructor(

        public readonly id: string,

        public readonly file: File,

        public readonly path: string,

        public readonly destination: string,

        public readonly description: string,

        public readonly overwrite: boolean,

        public readonly chunksCount: number,

        public chunkSize: number,

        chunkIdsToUpload: number[] = null,

        fileName: string = file.name
    ) {
        this.id = id ? id : IdGenerator.getUnique();
        this.name = fileName;
        this.size = this.file.size;

        if (!isNaN(this.chunksCount)) {
        this.chunks = Array.from(new Array(this.chunksCount), () => new FileChunk());
        }
        
        if (chunkIdsToUpload) {

            this.chunks
                .filter((_, i) => chunkIdsToUpload.every(ui => ui !== i))
                .forEach(ch => {
                    ch.size = chunkSize;
                    ch.bytesUploaded = chunkSize;
                });
        }
    }
}
