export enum SlurrySource {
  ManualFluid = 1,
  LinkedFluid = 2,
  PumpScheduleFluid = 3,
  HDFFluid = 4,
}

export enum PrimaryStatus {
  None = 'BE740A33-2DD3-41FD-BAF1-59B80018D58C',
  Planned = '9DE995FA-3F71-44CA-A745-B93C7C57A9DB',
  Used = 'E75CDA98-6A85-4F43-8D64-D0EECFE3A5D3'
}