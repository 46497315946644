import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class AdminService {
  data = [];
  addmember: any = false;
  msg = 'test from HttpService';
  create: any = false;
  selectedGroup: any = {};
  groupDetails: any = {};
  tempGroupDetails: any = {};
  groupDetailsObject: any;
  selectedRegion: any;
  selectedRegionId: any;
  adminDetails: any = [];
  createGroupMembers: any = [];
  regionIdForCreate: any;
  createGroupObject: any;
  groupName: any;
  modalOpenSelectedRegion: any;
  baseURL = environment.baseUrl;
  isEditJob: boolean = false;
  
  constructor(private http: HttpService) {
    this.groupDetailsObject = {
      groupName: '',
      regionId: '',
      groupRoles: [{
        networkDomain: '',
        networkId: '',
        isReviewer: false
      }]
    };

    this.createGroupObject = {
      groupName: '',
      regionId: '',
      groupRoles: [{
        networkDomain: '',
        networkId: '',
        isReviewer: false
      }]
    };       
  }

  getGroups() {
    return this.http.Get(`${environment.baseUrl}/api/admin/groups`);
  }

  getIsAdmin() {
    return this.http.Get(`${environment.baseUrl}/api/admin/IsAdmin`);
  }

  getGroupsByRegionId(regionId) {
    this.selectedRegionId = regionId;
    return this.http.Get(`${environment.baseUrl}/api/Admin/Groups/ByRegionId?regionId=${regionId}`);
  }

  getForbidenFileExtensions(): Observable<string[]> {
    return this.http.Get<string[]>(`${environment.baseUrl}/api/admin/file-restrictions`);    
  }

  isAdmin() {
    return this.http.Get(`${environment.baseUrl}/api/admin/IsAdmin`);
  }

  mapgroupId(id: any, regions: any) {
    for (let x = 0; x <= regions.length; x++) {
      if (id === regions[x].regionId) {
        this.selectedRegion = regions[x].regionCode;
        this.modalOpenSelectedRegion = regions[x].regionCode;
        break;
      }
    }
  }

  saveGroupselected(selectedGroup: any) {
    this.selectedGroup = selectedGroup;
  }

  getGroupDetailsByGroupId(groupId: string) {
    this.http.Get(`${environment.baseUrl}/api/admin/groups/${groupId}`).subscribe(data => {
      this.tempGroupDetails = JSON.parse(JSON.stringify(this.groupDetails));
    });
  }

  assignTempGroupDetail() {
    this.tempGroupDetails = JSON.parse(JSON.stringify(this.groupDetails));
  }

  removeUser(index: any) {
    this.groupDetails.members.splice(index, 1);
  }

  cancel() {
    this.groupDetails = JSON.parse(JSON.stringify(this.tempGroupDetails));
  }

  save() {
    this.tempGroupDetails = JSON.parse(JSON.stringify(this.groupDetails));
  }

  back() {
    this.groupDetails = JSON.parse(JSON.stringify(this.tempGroupDetails));
  }

  saveGroupDetails() {
    this.groupDetailsObject.groupName = this.groupDetails.groupName;
    this.groupDetailsObject.regionId = this.selectedRegionId;
    this.groupDetailsObject.groupRoles = this.groupDetails.members;

    const requestURL = `${environment.baseUrl}/api/Admin/Groups/Group/${this.groupDetails.groupId}`;
    this.http.Put(requestURL, JSON.stringify(this.groupDetailsObject)).subscribe(data => {
      console.log(data);
    });
  }

  fetchAdmins(regionId: any) {
    this.http.Get(`${environment.baseUrl}/api/Admin/Admins/ByRegionId/${regionId}`).subscribe(data => {
      this.adminDetails = data;
      if (this.create === false) {
        this.groupDetails.regionAdmins = this.adminDetails;
      } else {
        this.regionIdForCreate = regionId;
      }
    });
  }  

  createGroup() {
    this.groupDetailsObject = {
      groupName: '',
      regionId: '',
      groupRoles: '',
    };

    this.createGroupObject = {
      groupName: '',
      regionId: '',
      groupRoles: [{
        networkDomain: '',
        networkId: '',
        isReviewer: false
      }]
    };

    this.groupDetails = [];
    this.selectedRegion = this.modalOpenSelectedRegion;
    this.createGroupMembers = [];
    this.fetchAdmins(this.selectedRegionId);
  }

  addGroup(name) {
    this.createGroupObject = {
      groupName: name,
      regionId: this.regionIdForCreate,
      groupRoles: [],
    };

    for (let x = 0; x <= this.createGroupMembers.length - 1; x++) {
      const group = this.createGroupMembers[x];

      this.createGroupObject.groupRoles.push({
        networkDomain: group.networkDomain,
        networkId: group.networkId,
        isReviewer: group.isReviewer
      });
    }

    this.http.Post(`${environment.baseUrl}/api/admin/groups/group`, JSON.stringify(this.createGroupObject)).subscribe();
  }

  getReviewerByGroupId(groupId) {
    if (groupId) {
      return this.http.Get(`${environment.baseUrl}/api/admin/group/reviewers/${groupId}`);
    }
    return of([]);
  }

  getReviewerInGroupId(groupId) {
    if (groupId) {
      return this.http.Get(`${environment.baseUrl}/api/admin/group/is-reviewers-in-group/${groupId}`);
    }
    return of([]);
  }
  
  getUserRole(groupId) {
    return this.http.Get(`${environment.baseUrl}/api/admin/roles/${groupId}`);
  }

}
