import { UnitMeasureModel } from "..";
import { RigEquipment } from '../entities/equipment';

export class IRigModel {
  rigId: string;
  rigName: string;
  rigOwner: string;
  activityStatus: string;
  rigTypeId: string;
  rigTypeName: string;
  comments: string;
  activityType: string;
  groupId: string;
  createdBy: string;
  updatedBy: string;
  updateDate: string;
  directionsToLocation: string;
  distanceToLocation: number | null;
  distanceToLocationUnit: UnitMeasureModel;
  isLand: boolean;
  rigEquipment?: RigEquipment[];

  constructor() {
  }
}

export function createEmptyRig(): IRigModel {
  return {
    rigId: '',
    rigName: '',
    rigOwner: '',
    activityStatus: '',
    rigTypeId: '',
    rigTypeName: '',
    comments: '',
    activityType : '',
    groupId : '',
    createdBy: '',
    updatedBy: '',
    updateDate: '',
    directionsToLocation: '',
    distanceToLocation: null,
    distanceToLocationUnit: null,
    isLand: null,
    rigEquipment: []
  };
}

export function createRig(
  rigName: string, rigOwner: string, activityStatus: string, rigTypeId: string, rigTypeName: string, comments: string , activityType: string,
  groupId: string, createdBy: string, directionsToLocation: string, distanceToLocation: number | null, distanceToLocationUnit: UnitMeasureModel, isLand
  ): IRigModel {
  return {
    rigId: '',
    rigName,
    rigOwner,
    activityStatus,
    rigTypeId,
    rigTypeName,
    comments,
    activityType,
    groupId,
    createdBy,
    updatedBy: '',
    updateDate: '',
    directionsToLocation,
    distanceToLocation,
    distanceToLocationUnit,
    isLand
  };
}
