import * as moment from 'moment-mini';
import { DATE_TIME_CALL_MOMENT_JS } from 'libs/constants';

export class PostJobReport {
  virtualRelativeFilePath: string;
  filePathDownload: string;
  isLastUsed: boolean;
  uploader: string;
  createdDate: string;
  description: string;
  sentToCustomerDate: string;
  comment: string;
  
  constructor() {
    this.virtualRelativeFilePath = null;
    this.filePathDownload = null;
    this.isLastUsed = false;
    this.uploader = null;
    this.createdDate = null;
    this.description = null;
    this.sentToCustomerDate = null;
    this.comment = null;
  }

  getFileName() {
    return this.virtualRelativeFilePath ? this.virtualRelativeFilePath.split('/').pop() : '';
  }

  getCreatedDate() {
    if (this.uploader && this.createdDate) {
      return `${moment(this.createdDate.toString()).format(DATE_TIME_CALL_MOMENT_JS)}`;
    }
    return '';
  }
}