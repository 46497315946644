import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IJobModel } from '../../../shared/models';
import { JobModalManagementService } from 'libs/ui/services';
import { WellModel } from 'libs/models';
import { HELP_FILES } from 'libs/constants';
import { JobService, EventHubService, ApplicationStateService } from 'libs/shared/services';

@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent implements OnInit, OnDestroy {
  jobId: string;
  listJob: any;
  selectedItem: IJobModel;
  wellName: string;
  jobListData: IJobModel[] = [];
  destroy$ = new Subject();

  constructor(
    private router: Router,
    private jobService: JobService,
    private jobModalManagementService: JobModalManagementService,
    private eventHubService: EventHubService,
    private applicationStateService: ApplicationStateService,
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.indexOf('job-simple') > -1) {
        const urlArr = event.url.split('/');
        const temp = urlArr[urlArr.length - 1];
        const jobTemp = temp.split('?');
        this.jobId = jobTemp[0];
        this.getJobsByWell(this.jobId);
      }
    });

    this.jobModalManagementService.$saveSuccess.pipe(takeUntil(this.destroy$)).subscribe(_ => {
        this.getJobsByWell(this.jobId);
    });

    this.eventHubService.updateWell$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: WellModel) => this.wellName = res.wellName);

    // Reload job droplist data on top navigation after successfully importing iCem file
    this.applicationStateService.iCemFileImported$.subscribe(() => this.getJobsByWell(this.jobId));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getWellName(): string {
    return this.wellName?.length > 60 ? this.wellName.substring(0, 58) + ".." : this.wellName;
  }

  getJobsByWell(jobId) {
    this.jobService.getJobsInSameWell(jobId).subscribe((data: IJobModel[]) => {
      this.jobListData = data;
      this.selectedItem = this.jobListData.find(res => res.value.toLowerCase() === jobId.toLowerCase());
      if (this.jobListData.length > 0) {
        this.wellName = this.jobListData[0].wellName;
      }
    });
  }

  onJobChange(job: IJobModel) {
    this.eventHubService.refreshFluids$.next(job.value);
    this.router.navigate(['/job-simple/' + job.value]);
  }

  openHelp() {
    window.open(HELP_FILES.Job_Dashboard_Navigation);
  }
}
