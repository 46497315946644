import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CacheService } from 'libs/shared/services';
import { environment } from 'libs/environment';
import { IMudType } from 'libs/models';

@Injectable()
export class MudTypeService {

  readonly EXPIRY = 3600000;

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) { }

  private _get<T>(url: string): Observable<T> {
    return this.cacheService.get(url, this.httpClient.get<T>(url), this.EXPIRY);
  }

  getMudTypes(): Observable<IMudType[]> {
    return this._get<IMudType[]>(`${environment.baseUrl}/api/pumpSchedule/mud-types`);
  }
}
