<div class="typeahead-datatable" [ngClass]="{'dropdown-show':dropdownShow}" (keypress)="navigate($event)" (keydown)="updown($event)">
  <p-overlayPanel (onShow)="toggleClass()" (onHide)="toggleClass()">
    <p-table [value]="rowData" [columns]="colDefs" scrollable="true" scrollHeight="200px" selectionMode="single" [metaKeySelection]="true" [(selection)]="selectedRow"
             (onRowSelect)="onRowSelect($event.data)">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [style.width]="col.width">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr [pSelectableRow]="row" class="clickable">
          <td *ngFor="let col of columns">
            <span *ngIf="!isDisableRow">{{ row[col.field] }}</span>
            <span *ngIf="isDisableRow" [ngClass]="disableRow(row[colCheckDisable])">{{ row[col.field] }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-overlayPanel>

  <div #target tabindex="0" class="typeahead-dropdown-panel form-control clickable" [ngClass]="{'invalid-class':isInvalid}" (click)="!disabled && toggleTable($event)"
    [class.disabled]="disabled" pTooltip="{{selectedRow && selectedRow[rowLabel] ? selectedRow[rowLabel] : ''}}">
    <span class="typeahead-placeholder">{{ selectedRow && selectedRow[rowLabel] ? selectedRow[rowLabel] : "" }}</span>
    <i *ngIf="showClear && selectedRow" class="ui-dropdown-clear-icon pi pi-times" (click)="clearSelectedRow(); $event.stopPropagation();"></i>
    <div class="typeahead-dropdown">
      <span class="pi pi-chevron-down" [ngClass]="{'pi-chevron-up':op.overlayVisible}"></span>
    </div>
  </div>
</div>