import * as moment from 'moment-mini';
import { DATE_TIME_CALL_MOMENT_JS } from 'libs/constants';
import { SelectedFileModel } from '.';

export class JobLogFileModel extends SelectedFileModel {
    getCreatedDate() {
        if (this.uploader && this.createdDate) {
            return `${moment(this.createdDate.toString()).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }
}