import { Injectable,  ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ConfirmationDialogContainer } from '../components/confirmation-dialog/confirmation-dialog.container';
import { ConfirmationDialogModel } from '../models';

// SINGLETON CONTAIN ONLY 1 DIALOG MESSAGE AT A SAME TIME
@Injectable()
export class ConfirmationDialogService {

  // factoryResolver: ComponentFactoryResolver
  rootViewContainer: ViewContainerRef;
  componentRef: ComponentRef<ConfirmationDialogContainer>;

  constructor(private factoryResolver: ComponentFactoryResolver) {
    // this.factoryResolver = factoryResolver
  }

  setRootView(viewContainerRef: ViewContainerRef) {
    this.rootViewContainer = viewContainerRef;
    const factory = this.factoryResolver.resolveComponentFactory(ConfirmationDialogContainer);
    this.componentRef = this.rootViewContainer.createComponent(factory);
    
  }

  confirm(options: ConfirmationDialogModel, autoHide = true) {
    this.componentRef?.instance.confirm(options, autoHide);
  }

  hide() {
    this.componentRef?.instance.hide();
  }
}
