import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, JOB_LOG, PUMP_SCHEDULE_API_URL, SAP_API_URL } from 'libs/constants';
import {
    IBomType,
    IFluidUnitMeasuareModel,
    IMaterialTypeModel,
    IPrimaryStatus,
    IPumpScheduleFluidType,
    IRegionModel,
    ISAPMaterialModel,
    ISlurryType,
    ITestType,
    IPlacementMethod, ICasingSizeModel
} from 'libs/models';
import { JobLogActivityType } from 'libs/models/entities/job-log';
import { Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { CacheService } from './cache.service';

@Injectable()
export class MasterDataService extends BaseApiService {

    public constructor(

        httpClient: HttpClient,

        cacheService: CacheService
    ) {

        super(httpClient, cacheService);
    }

    public listTestTypes(): Observable<ITestType[]> {

        return this._get<ITestType[]>(API_URL.TEST_TYPES_URL);
    }

    public listSlurryTypes(): Observable<ISlurryType[]> {

        return this._get<ISlurryType[]>(API_URL.SLURRY_TYPES_URL);
    }

    public listPrimaryStatuses(): Observable<IPrimaryStatus[]> {

        return this._get<IPrimaryStatus[]>(API_URL.PRIMARY_STATUSES_URL);
    }

    public listBomTypes(): Observable<IBomType[]> {

        return this._get<IBomType[]>(API_URL.BOM_TYPE_URL);
    }

    public listMaterialTypes(): Observable<IMaterialTypeModel[]> {

        return this._get<IMaterialTypeModel[]>(API_URL.MATERIAL_TYPES_URL);
    }

    public listFluidUnitMeasures(): Observable<IFluidUnitMeasuareModel[]> {

        return this._get<IFluidUnitMeasuareModel[]>(API_URL.CONCENTRATION_UNIT_URL);
    }

    public listAdminRegions(): Observable<IRegionModel[]> {

        return this._get<IRegionModel[]>(API_URL.ADMIN_REGIONS_URL);
    }

    public getSAPMaterialByNumber(number: string): Observable<ISAPMaterialModel> {

        return number
            ? this._get<ISAPMaterialModel>(SAP_API_URL.SAP_MATERIAL_BY_NUMBER_URL(number))
            : of(new ISAPMaterialModel);
    }

    public listPumpScheduleFluidTypes(): Observable<IPumpScheduleFluidType[]> {

        return this._get<IPumpScheduleFluidType[]>(PUMP_SCHEDULE_API_URL.FLUID_TYPE_URL);
    }

    public listPumpSchedulePlacementMethods(stageSequence: number): Observable<IPlacementMethod[]> {

        return this._get<IPlacementMethod[]>(PUMP_SCHEDULE_API_URL.PLACEMENT_METHOD_URL(stageSequence));
    }

    public getJobLogActivities(): Observable<JobLogActivityType[]> {

        return this._get<JobLogActivityType[]>(JOB_LOG.GET_ACTIVITY_TYPES);
    }

    public refreshJobLogActivities(): void {

        this.cacheService.delete(JOB_LOG.GET_ACTIVITY_TYPES);
    }

    public listCasingSizes(): Observable<ICasingSizeModel[]> {
        return this._get<ICasingSizeModel[]>(API_URL.CASING_SIZES_URL);
    }

    public listHoleSizes(): Observable<ICasingSizeModel[]> {
        return this._get<ICasingSizeModel[]>(API_URL.HOLE_SIZES_URL);
    }


    public getBuildNumber() {
      this.httpClient.get(API_URL.BUILD_NUMBER, { responseType: 'text' }).subscribe(buildNumber => console.log('Build: ', buildNumber));
    }
}
