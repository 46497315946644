import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from 'libs/shared/services';
import { tap } from 'rxjs/operators';
import { WellImportedModel } from '../models';

@Injectable()
export class DWXService {

  constructor(private httpService: HttpService) {
  }

  getWellsImported(groupName: string): Observable<any> {
    let isDisableDwxFeatures = true;
    let items: WellImportedModel[] = [];

    if(!isDisableDwxFeatures){
      return this.httpService.Get(`${environment.baseUrl}/api/dwx/active-jobs/${groupName}/wells-imported`).pipe(
        tap(data => {
          items = <WellImportedModel[]> data;
        })
      );
    }

    return of(items);
  }

  deleteWell(wellIds: any): Observable<any> {
    return this.httpService.Post(`${environment.baseUrl}/api/dwx/deleteWell`, wellIds);
  }
}

class DateConversion {
  private allMonthes = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  private regexDate = /\b\d{2}-[a-zA-z]{3}-\d{4} \d{2}:\d{2} UTC\b/gm;

  public replaceDates(s: string): string{
    var result: string = s.replace(this.regexDate, (substring) => {
      var cmp: DateParts = this.splitDate(substring);
      var result: string = this.getFormattedDate(cmp);
      return result ? result : substring;
     });
    return result;
  }

  private splitDate(dateString: string): DateParts {
    var day = dateString.substr(0, 2);
     var month = dateString.substr(3, 3);
     var year = dateString.substr(7, 4);
     var hours = dateString.substr(12, 2);
     var minutes = dateString.substr(15, 2);
     return {
      day: day,
      month: month,
      year: year,
      hours: hours,
      minutes: minutes
     } as DateParts;
  }

  private validateDate(cmp: DateParts) {
    var result: DateParts = {
     day: parseInt(cmp.day),
     month: this.allMonthes.indexOf(cmp.month),
     year: parseInt(cmp.year),
     hours: parseInt(cmp.hours),
     minutes: parseInt(cmp.minutes)
    };
    return result.day <= 31
     && result.month >=0
     && result.year > 1000 && result.year < 5000
     && result.hours <= 24
     && result.minutes <= 59 ? result : null;
  }

  private getDate(cmp: DateParts): Date{
    return new Date(Date.UTC(cmp.year, cmp.month, cmp.day, cmp.hours, cmp.minutes));
  }

  private getFormattedDate(cmp: DateParts): string {
    cmp = this.validateDate(cmp);
    if (cmp){
     var d = this.getDate(cmp);
     return d.getDate().toString().padStart(2, '0') + '-' + this.allMonthes[d.getMonth()] + '-' + d.getFullYear() + ' ' 
        + d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0');
    }
    return null;
  }
};

class DateParts {
  day: any;
  month: any;
  year: any;
  hours: any;
  minutes: any;
}
