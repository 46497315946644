export class IwellConditionVerificationModel {
  id: string;
  jobId: string;
  customerMD: number;
  customerTVD: number;
  customerCasingLinerMD:number
  customerMudWeight: number;
  isStaticWell: boolean;
  comments: string;
  controlPointId: string;
  isCasingStretch:boolean;
  casingStretchComments:string;

  constructor() {
    this.id = null;
    this.jobId = null;
    this.customerMD = null;
    this.customerTVD = null;
    this.customerCasingLinerMD = null;
    this.customerMudWeight = null;
    this.isStaticWell = null;
    this.comments = null;
    this.controlPointId = null;
    this.isCasingStretch=null;
    this.casingStretchComments=null;
  }
}
