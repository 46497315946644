
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';
import { JobState } from 'libs/models/entities/job-state';

@Injectable({ providedIn: 'root' })
export class JobStateService {
    jobStateData: JobState[] = [];
    jobStatusIsCancelled: string;

    constructor(private http: HttpService) {
    }

    getJobState(): Observable<any> {
        return this.http.Get(`${environment.baseUrl}/api/job-status`);
    }

    findJobState(name: string) {
        // Check job state Canceled disable all field
        const jobState = this.jobStateData.find(state => state.statusDescription === name);
        return this.jobStatusIsCancelled = jobState ? jobState.id : null;
    }

    getActiveStatusId(): string {
        const defaultJobState = this.jobStateData.find(x => x.statusDescription === 'Active');
        return defaultJobState.id;
    }
}