import { IWellGroupModel } from 'libs/models';
import { Plant } from 'libs/models/ifact/plant.model';

export interface DropListLocation {
  groupId: string;
  displayName: string;
  lastSelected: boolean;
  plant: Plant;
  labId: number;
  updatedDateiFacts: Date;
  updatedDateSAP: Date;
  defaultFirstContractId: string;
  defaultSecondContractId: string;
  groupName: string;
}

export function createEmptyDropListLocation() {
  return {
    groupId: '',
    displayName: '',
    lastSelected: false,
    plant: null,
    labId: null,
    updatedDateSAP: null,
    updatedDateiFacts: null,
    defaultFirstContractId: null,
    defaultSecondContractId: null,
    groupName: ''
  };
}

export function mapGroupRegionByUserModelToDropListLocation(data): DropListLocation[] {
  const locations: DropListLocation[] = [];
  data.forEach(location => {
    location.groups.forEach(group => {
      locations.push({
        groupId: group.groupId,
        displayName: `${location.regionCode} - ${group.groupName} (${!group.role || group.role === 'Admin' ? '' : group.role})`,
        lastSelected: group.lastSelected,
        plant: group.plant,
        labId: group.labId,
        updatedDateSAP: group.updatedDateSAP,
        updatedDateiFacts: group.updatedDateiFacts,
        defaultFirstContractId: group.defaultFirstContractId,
        defaultSecondContractId: group.defaultSecondContractId,
        groupName: group.groupName
      });
    });
  });
  return locations;
}

export function mapDropListLocationToIWellGroupModel(data): IWellGroupModel[] {
  const locations = mapGroupRegionByUserModelToDropListLocation(data);
  return locations.map(location => {
    return {
      groupId: location.groupId,
      groupName: '',
      groupNameByRegion: location.displayName,
      membersCount: 0,
      networkId: '',
      regionId: '',
      reviewersCount: 0
    };
  });
}
