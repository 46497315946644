import { Directive, ElementRef, Input, Injector, Renderer2, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { UnitType, CustomUnit, CustomUnitMeasure } from 'libs/constants';
import { UnitConversionService } from './unit-conversion.service';
import { FormValueAccessorDirective } from '../form-value-accessor.directive';
import { formatNumber, getDisplayValue } from './unit-helper';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UnitMeasureModel } from 'libs/models';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';

@AutoUnsubscribe()
@Directive({
  selector: '[dUnitValue]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitValueDirective),
      multi: true
    }
  ],
})
export class UnitValueDirective extends FormValueAccessorDirective implements OnInit, OnChanges {

  // @Input() unit: UnitType;
  @Input('dUnitValue') dUnitValue: UnitType;
  @Input() dUnitWithName: boolean;
  @Input() dCustomUnit: string;
  @Input() sackWeight: number;
  @Input() yieldValue: number;
  @Input() dUnitDecimalPlaces: number = 2;
  private subscription = new Subscription();

  constructor(
    protected inj: Injector,
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    private unitConversionService: UnitConversionService
  ) {
    super(inj, renderer, elementRef);
  }

  ngOnInit() {
    this.subscription.add(this.unitConversionService.onSaveUnit$.subscribe(_ => {
      this.renderViewValue(this.control.value);
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { sackWeight, dCustomUnit, dUnitValue } = changes;
    if (sackWeight || dCustomUnit) {
      this.control && this.renderViewValue(this.control.value);
    }

    this.renderViewValue(this._value);
  }

  renderViewValue(value: any) {
    let customUnit;
    this._value = value;

    // TODO: Move to unit constants
    if (this.dCustomUnit) {
      if (this.dCustomUnit === CustomUnit.galbbl) {
        customUnit = CustomUnitMeasure.galbbl
      }

      if (this.dCustomUnit === CustomUnit.lm3) {
        customUnit = CustomUnitMeasure.lm3
      }
    }
    
    if (this.dUnitDecimalPlaces < 0) {
      this.dUnitDecimalPlaces = 0;
    }
    if (this.dUnitValue == null) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', '');
      return;
    }
    const unit = this.dUnitValue;
    const unitClass = this.unitConversionService.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum.toString() === unit.toString());
    const displayValue = getDisplayValue(value, this.unitConversionService.getApiUnitMeasure(unit), customUnit || this.unitConversionService.getCurrentUnitMeasure(unit), this.sackWeight, this.yieldValue);
    const formattedValue = formatNumber(displayValue, this.dUnitDecimalPlaces);

    
    let text = formattedValue ? formattedValue : '';
    text = this.dUnitWithName && unitClass && unitClass.unitMeasure && unitClass.unitMeasure.name ?
      `${text} ${this.dCustomUnit || unitClass.unitMeasure.name}` : text;
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', text);
  }
}
