/**
 * Returns the first occurance of the value in a given collection with a possible shift
 * @param {Array<T>} collection given collection
 * @param {T} value intended value
 * @param {number} index optional starting index
 * @returns {number} position of the value if exists otherwise -1
 */
export function indexOf<T>(
    collection: Array<T>,
    value: T,
    index?: number
): number {
    if ((!collection || collection.length === 0) || (index && index > collection.length - 1)) {
        return -1;
    }
    let startIndex = index ? index - 1 : 0;
    while (startIndex < collection.length) {
        if (collection[startIndex] === value) {
            return startIndex;
        }
        startIndex++;
    }
    return -1;
}

/**
 * Returns an element of the collection with the biggest value in a property defined by the iteratee
 * @param {Array<T>} collection given collection
 * @param {(element: any) => any} iteratee function which defines the property, which has to be either Number or String
 * @returns {T} returns the element of the acollection or undefined
 */
export function maxBy<T>(
    collection: Array<T>,
    iteratee: (element: any) => any
): T {
    if (!collection || collection.length === 0 || !iteratee) {
        return undefined;
    }
    let maxElement = collection[0];
    for (let i = 1; i < collection.length; i++) {
        const val = iteratee(collection[i]);
        if (typeof(val) === 'number') {
            if (iteratee(maxElement) >= iteratee(collection[i])) {
                continue;
            }
            else {
                maxElement = collection[i];
            }
        }
        else if (typeof(val) === 'string') {
            if (iteratee(maxElement).length >= iteratee(collection[i]).length) {
                continue;
            }
            else {
                maxElement = collection[i];
            }
        }
        else {
            return undefined;
        }
    }
    return maxElement;
}

/**
 * Defines whether an object or a collection is empty
 * @param {Object | Array<T> | Map<TKey, TValue>} value collection, map or object
 * @returns {boolean} returns true if at least one element exists otherwise false
 */
export function isEmpty<T, TKey, TValue>(
    value: Object | Array<T> | Map<TKey, TValue>
): boolean {
    if (value === null || value === undefined) {
        return true;
    }
    return Object.keys(value).length > 0 ? false : true;
}

/**
 * Tries to cast a value to a number
 * @param {Object | Array<T> | Map<TKey, TValue>} value any value
 * @returns {boolean} returns a number if conversion is possible, 0 if value is null and NaN in all other cases
 */
export function toNumber(
    value: any
): number {
    if (typeof (value) === 'number') {
        return value;
    }
    else if (value === null) {
        return 0;
    }
    else if (typeof (value) === 'string') {
        const val = (value as string).trim();
        return parseInt(val);
    }
    else {
        return NaN;
    }
}

/**
 * Finds simple unique elements of the collection
 * @param {Object | Array<T> | Map<TKey, TValue>} collection of simple values
 * @returns {Array<T>} returns a new collection of the unique elements
 */
export function uniq<T>(
    collection: Array<T>
): Array<T> {
    if (collection === null || isEmpty(collection)) {
        return collection;
    }
    return [...new Set(collection)];
}

/**
 * Finds unique complex elements by certain properties
 * @param {Array<T>} collection
 * @param {(item: T) => TResult} iteratee function which defines the property, by which the uniqueness is defined
 * @returns {Array<T>} returns a new collection of the unique elements
 */
export function uniqBy<T, TResult>(
    collection: Array<T>,
    iteratee: (item: T) => TResult
): Array<T> {
    if (!collection || collection.length < 2 || !iteratee) {
        return collection;
    }
    let result = [];
    result.push(collection[0]);
    for (let i = 1; i < collection.length; i++) {
        let exists = false;
        let num;
        for (let n = 0; n < result.length; n++) {
            const comparator = iteratee(collection[i]);
            if (iteratee(result[n]) === comparator) {
                exists = true;
                num = n;
                break;
            }
        }
        if (exists) {
            continue;
        }
        else {
            result.push(collection[i]);
        }
    }
    return result;
}

/**
 * Finds unique complex elements of the collection under certain conditions
 * @param {Array<T>} collection
 * @param {(element1: T, element2: T) => boolean} iteratee function which compares two elements of the collection
 * @returns {Array<T>} returns a new collection of the uniq elements
 */
export function uniqWith<T>(
    collection: Array<T>,
    iteratee: (element1: T, element2: T) => boolean
): Array<T> {
    if (!collection || collection.length < 2 || !iteratee) {
        return collection;
    }

    let result = [];
    result.push(collection[0]);
    for (let i = 1; i < collection.length; i++) {
        let exists = false;
        let num;
        for (let n = 0; n < result.length; n++) {
            if (iteratee(collection[i], collection[n])) {
                exists = true;
                num = n;
                break;
            }
        }
        if (exists) {
            continue;
        }
        else {
            result.push(collection[i]);
        }
    }
    return result;
}

/**
 * Creates a dictionary of key-value pairs
 * @param {Array<T>} collection
 * @param {string} iteratee defines the way to find keys which consist of properties separated by dots
 * @returns {Object} returns the dictionary of keys and the given collection. If some properties do not exist, such elements are not added
 */
export function keyBy<T>(
    collection: Array<T>,
    iteratee: string
): Object {
    const accesssors = iteratee.split('.');
    let dict = {};

    for (const item of collection) {
        let key = item;
        for (const ac of accesssors) {
            if (key) {
                key = key[ac]
            }        
        }
        if (key) {
            dict[key.toString()] = item;
        }
    }
    return dict;
}

/**
 * Merges two objects
 * @param {Object} object1 first object
 * @param {Object} object2 second object
 * @returns {Object} returns a new object which includes all properties from the both objects
 */
export function merge(
    object1: Object,
    object2: Object
): Object {
    if (!object1 || isEmpty(object1)) {
        return object2;
    }
    if (!object2 || isEmpty(object2)) {
        return object1;
    }
    let final = {};
    return create(object1, object2, final);
}

function create(
    obj1: Object,
    obj2: Object,
    fin: Object
): Object {
    fin = obj1;
    const firstKeys = Object.keys(obj1);
    const secondKeys = Object.keys(obj2);

    for (let i = 0; i < firstKeys.length; i++) {
        if (!(firstKeys[i] in obj2)) {
            continue;
        }
        else {
            if (typeof (obj1[firstKeys[i]]) === 'object' && typeof (obj2[firstKeys[i]]) === 'object') {
                fin[firstKeys[i]] = create(obj1[firstKeys[i]], obj2[firstKeys[i]], fin[firstKeys[i]]);
            }
            else if (typeof (obj1[firstKeys[i]]) === 'object') {
                fin[firstKeys[i]] = obj1[firstKeys[i]];
            }
            else if (typeof (obj2[firstKeys[i]]) === 'object') {
                fin[firstKeys[i]] = obj2[firstKeys[i]];
            }
            else {
                const union = Object.assign(obj1, obj2);
                return union;
            }
        }
    }
    for (let i = 0; i < secondKeys.length; i++) {
        if (!(secondKeys[i] in obj1)) {
            fin[secondKeys[i]] = obj2[secondKeys[i]];
        }
    }
    return fin;
}

/**
 * Creates an intersection of two arrays
 * @param {Array<T>} collection1 first array
 * @param {Array<T>} collection2 second array
 * @returns {Array<T>} returns a new collection
 */
export function intersection<T>(
    collection1: Array<T>,
    collection2: Array<T>,
): Array<T> {
    var set2 = new Set(collection2);
    return [...new Set(collection1)].filter(x => set2.has(x));
}

/**
 * Recursively clones an object
 * @param {Object} obj object to be cloned
 * @returns {Object} returns a deeply cloned object
 */
export function cloneDeep(
    obj: Object
): Object {
    return deepCloning(obj);
}

function deepCloning(
    obj: any,
    hash = new WeakMap()
) {
    if (Object(obj) !== obj) {
        return obj;
    }
    if (hash.has(obj)) {
        return hash.get(obj);
    }
    const result = obj.constructor
        ? new obj.constructor()
        : Object.create(null);
    hash.set(obj, result);
    return Object.assign(result, ...Object.keys(obj).map(
        key => ({ [key]: deepCloning(obj[key], hash) })
    ));
}

/**
 * Creates a flatten array of all elements of the given array which are previously passed through the iteratee.
 * If an element has the Array type it will get flatten.
 * @param {Array<T>} collection
 * @param {(element: T) => TResult} iteratee function
 * @returns {Array<T>} returns a new array
 */
export function flatMap<T, TResult>(
    collection: Array<T>,
    iteratee: (element: T) => TResult
): Array<T> | Array<TResult> {
    if (!collection || collection.length === 0 || !iteratee) {
        return collection;
    }
    let result = [];
    for (let i = 0; i < collection.length; i++) {
        const a = iteratee(collection[i]);
        flatten(a, result);
    }
    return result;
}

function flatten<T>(
    element: T, 
    finalArray: Array<T>
): void {
    if (Array.isArray(element)) {
        for (let i = 0; i < element.length; i++) {
            flatten(element[i], finalArray);
        }
    }
    else {
        finalArray.push(element);
    }
}

/**
 * Deletes an indicated node from the object. Nested properties are included as well.
 * @param {Object} obj object
 * @param {string} node property name
*/
export function deleteNode(obj: Object, node: string): void {
    if (isEmpty(obj) || !node) {
        return;
    }

    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
        if (keys[i] === node) {
            delete obj[node];
            break;
        }
        if (typeof obj[keys[i]] === 'object') {
            deleteNode(obj[keys[i]], node);
        }
    }
}