export class CommunicationType {
  order: number;
  id: string;
  name: string;

  constructor() { 
    this.order = null;
    this.id = null;
    this.name = null;
  }

}
