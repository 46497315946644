import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { CustomTooltipComponent } from 'apps/vida/src/modules/shared/components/custom-tooltip/custom-tooltip.component';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolTipService {

  get ref(): ComponentRef<CustomTooltipComponent> {
    return this.componentRef;
  }

  get isShown(): boolean {
    return !!this.componentRef;
  }

  private componentRef: ComponentRef<CustomTooltipComponent>;
  public viewContainerRef: ViewContainerRef

  constructor(
    private resolver: ComponentFactoryResolver   
  ) { }

  public show(data: IToolTip): void {
    const factory = this.resolver.resolveComponentFactory(CustomTooltipComponent);
    this.componentRef = this.viewContainerRef.createComponent(factory);
    this.componentRef.instance.htmlText = data.htmlText;
    this.componentRef.instance.left = data.left;
    this.componentRef.instance.top = data.top;
  }

  public close() {
    this.componentRef.destroy();
    this.componentRef = null;
  }
}

export interface IToolTip {
  htmlText: string;
  left: number;
  top: number;
}