import { SurveyData } from "../entities/surveyData";
import { TemperatureProfile } from "../entities/temperatureProfile";

export class WellModel {
  id: string;
  wellName: string;
  wellNumber: string;
  groupId: string;
  description: string;
  inLibrary: boolean;
  groupName: string;
  isActive?: boolean;
  activeDate?: string;
  inactiveDate?: string;
  jobs: any[];

  createdBy?: string;
  mostRecentJobUsingDate?: Date;
  source?: string;
  activityStatus?: string; // status of well to show display
  groupNameByRegion?: string;

  surveyData: SurveyData[];
  temperatureProfile : TemperatureProfile[];
}
