import { JobObjectiveOption, ObjectState } from './../../../apps/vida/src/modules/shared/constant/';
import { JobObjectiveUOM } from './job-objective-uom';
import { UnitType } from 'libs/constants';

export class JobObjective {
  id: string;
  option: JobObjectiveOption;
  type: UnitType;
  requirement: number;
  target: number;
  targetOtherJobObjective: number;
  targetFitLot: number;
  finalPumpPressureRequirement: number;
  uoM: UnitType;
  justification: string;
  lossInduced: boolean;
  marginOfError: number;
  density: boolean;
  rate: boolean;
  volume: boolean;
  reasonOfOther: string;
  state: ObjectState;
  jobObjectiveUOM: JobObjectiveUOM;
  optionObjectiveMet: number;
  resultComment: string;
  actual: string;

  constructor() {
    this.id = '';
    this.option = null;
    this.type = null;
    this.requirement = null;
    this.target = null;
    this.targetOtherJobObjective = null;
    this.targetFitLot = null;
    this.finalPumpPressureRequirement = null;
    this.uoM = null;
    this.justification = '';
    this.lossInduced = null;
    this.marginOfError = null;
    this.density = null;
    this.rate = null;
    this.volume = null;
    this.reasonOfOther = '';
    this.state = ObjectState.Created;
    this.jobObjectiveUOM = new JobObjectiveUOM();
    this.optionObjectiveMet = null;
    this.resultComment = '';
    this.actual = '';
  }
}