import { MaterialCOGS } from 'libs/models/entities/material-cogs-calculation-result';
import { Component, Input, Output, OnChanges, SimpleChanges, QueryList, ViewChildren, ChangeDetectionStrategy, Injector, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { FluidModel, Job, PumpScheduleStageModel, MaterialManagementMappingModel } from 'libs/models';
import { UnitType, DeadVolumeFluidType, MATERIAL_TYPE } from 'libs/constants';
import { createPumpFluidDetail, mapPumpFluidDetail } from './pump-fluid-detail.form.factory';
import { FormControlContainer } from 'libs/ui';
import { PumpFluidMaterialDetailComponent } from '../pump-fluid-material-detail/pump-fluid-material-detail.component';
import { calculateStageWater, calculateBulkCementByLoadOutVolume, calculateStageWaterAndOverrideQtyByDeadQty } from 'libs/shared/calculations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { FluidDetailInfoComponent, FluidFormFactory } from '../../../fluids/components';
import { MaterialService } from 'libs/shared/services';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';

@AutoUnsubscribe()
@Component({
  selector: 'pump-fluid-detail',
  templateUrl: './pump-fluid-detail.component.html',
  styleUrls: ['./pump-fluid-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PumpFluidDetailComponent extends FormControlContainer implements OnChanges, OnDestroy {
  @Input() stage: UntypedFormGroup;
  @Input() stageModel: PumpScheduleStageModel;
  @Input() fluid: FluidModel;
  @Input() plannedVolume: number;
  @Input() controlPointNumber: string;
  @Input() tabName: string;
  @Input() cpState: number;
  @Input() jobState: string;
  @Input() job: Job;
  @Input() canEdit: boolean;
  @Input() sapMaterialMappings: MaterialManagementMappingModel[];
  @Output() onLoadoutVolumeChanged = new EventEmitter<number>();
  @ViewChildren(PumpFluidMaterialDetailComponent) pumpFluidMaterialDetailComponent: QueryList<PumpFluidMaterialDetailComponent>;
  @ViewChild('fluiddetailinfo', { static: true }) fluidDetailInfoComponent: FluidDetailInfoComponent;
  formGroup: UntypedFormGroup;
  UnitType = UnitType;
  MATERIAL_TYPE = MATERIAL_TYPE;
  internalSubscription = new Subscription();

  constructor(
    protected inj: Injector,
    private _fb: UntypedFormBuilder,
    private _unitConversionService: UnitConversionService,
    private _materialService: MaterialService,
    private _fluidFormFactory: FluidFormFactory
  ) {
    super(inj);
    this.formControl = this.formGroup = createPumpFluidDetail(this._fb, new FluidModel, null, null, this._materialService);
    const fluidFormGroup = this.formControl as UntypedFormGroup;
    const disableOverrideVolume = !!fluidFormGroup.controls.deadVolumeFluidType.value;
    if (this.fluidDetailInfoComponent && this.stageModel && !this.stageModel.isManuallyDeadVolume) {
      this.fluidDetailInfoComponent.setValueLoadOutAndBulkCement(!disableOverrideVolume ? fluidFormGroup.controls.deadVolume.value : 0);
    }

    this.onLoadoutVolumeChanged.emit(fluidFormGroup.controls.loadoutVolume.value);

    fluidFormGroup.controls.deadVolumeFluidType.valueChanges.pipe(
      filter(_ => {
        return this.formGroup.controls.isMixFluid.value;
      })
    ).subscribe(_ => {
      const disableOverrideVolume = !!fluidFormGroup.controls.deadVolumeFluidType.value;
      if (this.fluidDetailInfoComponent && (!this.stageModel || (this.stageModel && !this.stageModel.isManuallyDeadVolume))) {
        this.fluidDetailInfoComponent.setValueLoadOutAndBulkCement(!disableOverrideVolume ? fluidFormGroup.controls.deadVolume.value : 0);
      }
      this.calculateStageWaterAndOverrideQtyByDeadQty(disableOverrideVolume);
    });

    fluidFormGroup.controls.deadVolume.valueChanges.pipe(
      filter(_ => {
        return this.formGroup.controls.isMixFluid.value && !!fluidFormGroup.controls.deadVolumeFluidType.value;
      })
    ).subscribe(_ => {
      if (!_) {
        this.calculateStageWaterAndOverrideQtyByDeadQty(false);
      } else {
        const isMix = this.formGroup.controls.isMixFluid.value;
        if (isMix && fluidFormGroup.controls.deadVolumeFluidType.value) {
          this.calculateStageWaterAndOverrideQtyByDeadQty();
        }
      }
    });
  }

  private _updateFluid() {
    mapPumpFluidDetail(
      this._fb,
      this.formGroup,
      this._fluidFormFactory,
      this.fluid,
      this.plannedVolume,
      this.stageModel,
      this.stage,
      this._materialService);

    if (this.stageModel) {
      const isCementFluid = this.fluid && this.fluid.slurryType && this.fluid.requestId ? this.fluid.slurryType.indexOf('Cement') >= 0 : false;
      const deadVolumeFluidType = this.stage.controls.deadVolumeFluidType.value !== null && this.stage.controls.deadVolumeFluidType.value !== undefined ?
        this.stage.controls.deadVolumeFluidType.value :
        this.stageModel.deadVolumeFluidType;

      if (!isCementFluid && deadVolumeFluidType === DeadVolumeFluidType.MixFluid) {
        this.calculateStageWaterAndOverrideQtyByDeadQty(false, true);
      }
    }

    if (this.formGroup.controls.deadVolumeFluidType.value === DeadVolumeFluidType.MixFluid) {
      this.calculateStageWaterAndOverrideQtyByDeadQty();
    }

    if (!this.canEdit) {

      this.disableFluidFormFields();
    }
  }

  private disableFluidFormFields(): void {

    this.formGroup.disable({ emitEvent: false });
  }

  ngOnChanges(changes: SimpleChanges) {

    const { canEdit } = changes;

    if (canEdit && !canEdit.currentValue) {

      this.disableFluidFormFields();
    }

    if (this.internalSubscription && !this.internalSubscription.closed) {
      this.internalSubscription.unsubscribe();
    }

    this.internalSubscription = new Subscription();

    const { fluid, plannedVolume, stage } = changes;
    if (stage && this.stage) {
      this.internalSubscription.add(this.stage.controls.actualVolumePumped.valueChanges.subscribe(_ => {
        this._cdRef.markForCheck();
      }));
    }

    if (fluid && (fluid.currentValue || this.stageModel.pumpScheduleFluidTypeName === 'Cement')) {
      this._updateFluid();
    }

    // fix Bug 395033:[VIDA_1.1_Regression Bug] Updated Dead Qty is not displayed on CP1/CP2/CP4 -> Pump Schedule (Job Specific/HDF)
    if (this.stageModel && this.stageModel.deadVolume && this.formGroup.controls.deadVolume.value === 0) {
      this.formGroup.controls.deadVolume.setValue(this.stageModel.deadVolume);
    }

    if (plannedVolume && !plannedVolume.firstChange && this.formGroup && this.formGroup.controls.loadoutVolume) {
      this.formGroup.controls.plannedVolume.setValue(this.plannedVolume, { emitEvent: false });
      if (!plannedVolume.firstChange && this.isLoadoutVolumeNotManual()) {
        this.formGroup.controls.loadoutVolume.setValue(this.plannedVolume, { emitEvent: false });
        if (!this.stageModel || !this.stageModel.isManuallyDeadVolume) {
          this.formGroup.controls.loadoutVolume.setValue(this.plannedVolume + this.formGroup.controls.deadVolume.value, { emitEvent: false });
        }
        const loadoutQty = this.formGroup.controls.loadoutVolume.value;
        // Update Stage water
        const yieldData = this.formGroup.controls.yield ? this.formGroup.controls.yield.value : 0;
        const mixWater = this.formGroup.controls.mixWater ? this.formGroup.controls.mixWater.value : 0;
        const waterRequirements = calculateStageWater(loadoutQty, this.plannedVolume, yieldData, mixWater);
        this.formGroup.controls.stageWaterTotal.setValue(waterRequirements);
        this.formGroup.controls.waterRequirements.setValue(waterRequirements);

        // Update bulk Cement

        // Set data Bulk Cemet display form
        const bulkCement = calculateBulkCementByLoadOutVolume(loadoutQty, this.plannedVolume, yieldData);
        this.formGroup.controls.bulkCement.setValue(bulkCement, { emitEvent: false });
        this.formGroup.controls.isBulkCement.setValue(true, { emitEvent: false });
        // Set data Bulk Cemet save data
        this.stage.controls.bulkCement.setValue(bulkCement, { emitEvent: false });
        this.stage.controls.isBulkCement.setValue(true, { emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    if (this.internalSubscription) {
      this.internalSubscription.unsubscribe();
    }
  }

  _onLoadoutVolumeChanged() {
    const isMix = this.formGroup.controls.isMixFluid.value;
    const fluidFormGroup = this.formControl as UntypedFormGroup;
    if (isMix && fluidFormGroup.controls.deadVolumeFluidType.value) {
      this.calculateStageWaterAndOverrideQtyByDeadQty();
    }
    this.onLoadoutVolumeChanged.emit(this.formGroup.controls.loadoutVolume.value);
  }

  isLoadoutVolumeNotManual() {
    return (!this.stageModel || !this.stageModel.isChangeLoadOutVolume);
  }

  calculateStageWaterAndOverrideQtyByDeadQty(disableOverrideVolume = true, forceClean = false) {
    const fluidFormGroup = this.formControl as UntypedFormGroup;
    const deadVolume = fluidFormGroup.controls.deadVolume.value;

    if (!deadVolume && disableOverrideVolume) {
      return;
    }
    const additiviveMaterialsForm = (fluidFormGroup.controls.fluidMaterial as UntypedFormArray);
    const supplementalMaterialsForm = (fluidFormGroup.controls.supplementalMaterial as UntypedFormArray);

    if (!disableOverrideVolume) {
      fluidFormGroup.controls.stageWaterTotal.setValue(fluidFormGroup.controls.waterRequirements.value);
      additiviveMaterialsForm.controls.forEach((additiviveMaterial: UntypedFormGroup, index) => {
        if (additiviveMaterial.controls.overrideVolume.disabled || forceClean) {
          additiviveMaterial.controls.overrideVolume.setValue('');
          additiviveMaterial.controls.overrideVolume.enable();
        }
      });
      supplementalMaterialsForm.controls.forEach((additiviveMaterial: UntypedFormGroup, index) => {
        if (additiviveMaterial.controls.overrideVolume.disabled || forceClean) {
          additiviveMaterial.controls.overrideVolume.setValue('');
          additiviveMaterial.controls.overrideVolume.enable();
        }
      });
      return;
    }
    const waterRequirements = fluidFormGroup.controls.waterRequirements.value;

    const result = calculateStageWaterAndOverrideQtyByDeadQty(this._unitConversionService, deadVolume, waterRequirements, additiviveMaterialsForm.getRawValue());
    const resultSup = calculateStageWaterAndOverrideQtyByDeadQty(this._unitConversionService, deadVolume, waterRequirements, supplementalMaterialsForm.getRawValue());
    fluidFormGroup.controls.stageWaterTotal.setValue(result.stageWaterByDeadQty);
    additiviveMaterialsForm.controls.forEach((additiviveMaterial: UntypedFormGroup, index) => {
      const overrideVolume = result.overrideQtyByDeadQtyList[index];
      if (overrideVolume !== null && overrideVolume !== undefined) {
        additiviveMaterial.controls.overrideVolume.setValue(overrideVolume);
        additiviveMaterial.controls.overrideVolume.disable();
      }
    });
    supplementalMaterialsForm.controls.forEach((additiviveMaterial: UntypedFormGroup, index) => {
      const overrideVolume = resultSup.overrideQtyByDeadQtyList[index];
      if (overrideVolume !== null && overrideVolume !== undefined) {
        additiviveMaterial.controls.overrideVolume.setValue(overrideVolume);
        additiviveMaterial.controls.overrideVolume.disable();
      }
    });
  }

  getSanitizedData() {
    if (this.formGroup.controls.bulkCement.dirty && !this.formGroup.controls.isBulkCement.value) {
      this.formGroup.controls.isBulkCement.setValue(true);
    }
    const data = this.formGroup.getRawValue();
    return {
      loadoutVolume: data.loadoutVolume,
      bulkCement: data.bulkCement,
      isBulkCement: data.isBulkCement,
      isLoadOutVolume: this.stage.controls.isChangeLoadOutVolume ? this.stage.controls.isChangeLoadOutVolume.value : null,
      sapMaterialNumber: data.sapMaterialNumber,
      sapMaterialName: data.sapMaterialName,
      deadVolume: data.deadVolume ? data.deadVolume : 0,
      isManuallyDeadVolume: data.isManuallyDeadVolume,
      deadVolumeFluidType: data.deadVolumeFluidType,
      stageWaterTotal: data.stageWaterTotal,
      waterRequirements: data.waterRequirements,
      fluidMaterials: data.materials.map(material => {
        return {
          plannedQty: material.plannedVolume,
          actualQty: material.actualQty,
          id: material.id,
          fluidMaterialId: material.fluidMaterialId,
          fluidMaterialName: material.ifactMaterialName,
          ifactMaterialId: material.ifactMaterialId,
          plannedVolumeUnit: material.plannedVolumeUnit,
          overrideVolume: material.overrideVolume,
          sapMaterialName: material.sapMaterialName ? material.sapMaterialName : null,
          sapMaterialNumber: material.sapMaterialNumber ? material.sapMaterialNumber : null,
          order: material.order,
          totalCOGS: material.totalCOGS
        };
      }),
      totalCOGS: this.getStageTotalCOGS()
    };
  }

  getStageTotalCOGS(): number {
    const formData = this.formGroup.getRawValue();
    const nonWaterMaterials = formData.materials ? formData.materials.filter(x => !this._materialService.isWater(x.materialType)) : [];
    return nonWaterMaterials.length === 0 ? 0 :
      (nonWaterMaterials.every(x => x.totalCOGS || x.totalCOGS === 0) ?
        +nonWaterMaterials.sum(x => x.totalCOGS).toFixed(2) :
        null);
  }

  getMaterialCOGSCalculationResult(): MaterialCOGS[] {
    const formData = this.formGroup.getRawValue();
    return formData.materials.map(x => {
      const materialCOGS = new MaterialCOGS();
      materialCOGS.materialId = x.id;
      materialCOGS.materialCost = x.totalCOGS;
      return materialCOGS;
    });
  }

  getHeaderMaterialName(controls, type) {
    const length = controls ? controls.length : 0;
    const map = {
      Blend: `Blend Materials (${length})`,
      Additives: `Additives (${length}) (* = Exclude from Total Mix Fluid)`,
      Supplemental: `Supplemental Materials (${length})`
    };
    return map[type];
  }

  markAsTouched() {
    super.markAsTouched();
    if (this.pumpFluidMaterialDetailComponent)
      this.pumpFluidMaterialDetailComponent.forEach(f => f.markAsTouched());
  }

  isInvalid(): boolean {
    if (this.pumpFluidMaterialDetailComponent) {
      return this.formGroup.invalid || this.pumpFluidMaterialDetailComponent.some(item => item.isInvalid());
    }
  }

  isCogsCalculationComplete(): boolean {
    const formData = this.formGroup.getRawValue();
    const nonWaterMaterials = formData.materials ? formData.materials.filter(x => !this._materialService.isWater(x.materialType)) : [];
    return !nonWaterMaterials.length ? true : nonWaterMaterials.every(x => x.cogsCalculated);
  }

}
