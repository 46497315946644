import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { SAP_API_URL, FLUID_API_URL, MATERIAL_TYPE, UnitType } from 'libs/constants';
import {
    FluidMaterialModel,
    IMaterialModel,
    IMaterialTypeModel,
    ISAPMaterialModel,
    IFactsRequest,
    IFactsMaterial
} from 'libs/models';

@Injectable()
export class MaterialService {

    public readonly AdditiveType: string = 'Additive';

    public readonly CementType: string = 'Cement';

    public readonly SpacerType: string = 'Spacer';

    private readonly WaterType: string = 'Water';

    private readonly SupplementalType: string = MATERIAL_TYPE.SUPPLEMENTAL;

    private readonly MixWaterType: string = 'Mix Water';

    public constructor(

        private readonly _httpClient: HttpClient
    ) {
    }

    public isBlend(material: FluidMaterialModel | IFactsMaterial): boolean {

        return material && this.isCement(material) && !this.isWater(material.materialType);
    }

    public isAdditive(material: FluidMaterialModel, baseCementMaterial: FluidMaterialModel): boolean {

        return material
                && !this.isBlend(material)
                && !this.isSupplemental(material)
                && (!this._isCementType(material.materialType)
                    || this._isCementType(material.materialType) && !!material.hdfComponentId && !material.isBlendComponent
                )
                && !this.isWater(material.materialType)
                && material !== baseCementMaterial;
    }

    private _isCementType(materialType: string): boolean {

        return materialType === this.CementType;
    }

    public isCement(material: FluidMaterialModel | IFactsMaterial): boolean {

        const result =
            material.isBlendComponent === true || material.isBlendComponent === 'Y'
            // When importing from HDF Material Type can be not Cement for blend component
            || material.materialType === this.CementType && (material instanceof FluidMaterialModel && !material.hdfComponentId)
            || material.order != null && +material.order === 0;

        return result;
    }

    public isCementIFacts(material: FluidMaterialModel | IFactsMaterial): boolean {
        const result =
            material.isBlendComponent === true || material.isBlendComponent === 'Y'
            || material.materialType === this.CementType
            || material.order != null && +material.order === 0;

        return result;
    }

    public isWater(materialType: string): boolean {
        return materialType && materialType.includes(this.WaterType);
    }

    public isSupplemental(material: FluidMaterialModel | IFactsMaterial): boolean {
        return material && material.materialType === this.SupplementalType;
    }

    public isMixWater(material: FluidMaterialModel | IFactsMaterial): boolean {
        return material && material.materialType === this.MixWaterType;
    }

    public isLiquidUnit = (unitType: UnitType): boolean => unitType === UnitType.SmallVolume;

    public getCementMaterial(materials: FluidMaterialModel[]): FluidMaterialModel {

        if (!materials) {
            return null;
        }

        const materialsCopy = [...materials];

        const byConcentrationDesc = materialsCopy
            .filter(fluid => fluid.materialType === this.CementType)
            .sort((a, b) => b.concentration - a.concentration);

        if (byConcentrationDesc.length === 0) {
            return null;
        }

        return byConcentrationDesc[0];
    }

    public searchSAPMaterialbyId(keyword: string): Observable<ISAPMaterialModel[]> {
        return keyword ? this._httpClient.post<ISAPMaterialModel[]>(SAP_API_URL.SAP_MATERIAL_SEARCH_URL, { keyword }) : of([]);
    }

    public searchMaterialByName(keyword: string): Observable<IMaterialModel[]> {
        return this._httpClient.post<IMaterialModel[]>(FLUID_API_URL.MATERIAL_SEARCH_URL, { keyword });
    }

    public getMissingFluidNames(sapNumbers: string[]) : Observable<IMaterialModel[]> {
        var json = JSON.stringify(sapNumbers);
        return this._httpClient.post<IMaterialModel[]>(SAP_API_URL.SAP_MATERIAL_MISSING, sapNumbers);
    }

    public updateFromRequest(
        existingMaterials: FluidMaterialModel[],
        fluidId: string,
        request: IFactsRequest,
        materialTypes: IMaterialTypeModel[]): FluidMaterialModel[] {

        const updatedMaterialList: FluidMaterialModel[] = [];
        const iFactsMaterialNames: string[] = [];
        const generateKey = (m: FluidMaterialModel) => `${m.cemBlendCompId}-${m.additiveId}`;

        if (request) {
            if(request.selectedSlurry != null)
            {
                request.selectedSlurry.materials.forEach(material => {

                    let foundMaterial: FluidMaterialModel = null;
                    if (existingMaterials) {
    
                        foundMaterial = existingMaterials.find(m => this._isInRequest(m, material));
    
                        if (foundMaterial) {
                            iFactsMaterialNames.push(generateKey(foundMaterial));
                        }
                    }
    
                    const updatedMaterial: FluidMaterialModel = this._updateFromRequest(
                        foundMaterial,
                        request,
                        material,
                        materialTypes,
                        fluidId);
    
                    updatedMaterialList.push(updatedMaterial);
                });
            }
           
        }

        const existingSupplementals = existingMaterials
            .filter(m => this.isSupplemental(m))
            .map(m => Object.assign(new FluidMaterialModel, m));

        const supplementalsNotFromIFacts = existingSupplementals.filter(m =>
            !iFactsMaterialNames.includes(generateKey(m))
        );

        return [...updatedMaterialList, ...supplementalsNotFromIFacts];
    }

    public _isInRequest(material: FluidMaterialModel, iFactsMaterial: IFactsMaterial): boolean {
        return +material.materialId === iFactsMaterial.generalMaterialId
            && material.additiveId === iFactsMaterial.additiveId
            && material.cemBlendCompId === iFactsMaterial.cemBlendCompId;
    }

    private _updateFromRequest(
        material: FluidMaterialModel,
        request: IFactsRequest,
        iFactsMaterial: IFactsMaterial,
        materialTypes: IMaterialTypeModel[],
        slurryId: string): FluidMaterialModel {

        if (!material || !material.id) {
            material = new FluidMaterialModel();
            const existing = materialTypes.find(x => x.name === iFactsMaterial.materialType);
            material.materialTypeId = existing ? existing.id : null;
            material.slurryId = slurryId;
        }

        material.materialId = null;
        material.materialName = null;

        if (!iFactsMaterial) {

            return material;
        }

        if (iFactsMaterial.generalMaterialId) {

            material.materialId = iFactsMaterial.generalMaterialId.toString();
            material.materialName = iFactsMaterial.name;
        }

        material.additiveId = iFactsMaterial.additiveId;
        material.cemBlendCompId = iFactsMaterial.cemBlendCompId;
        material.materialType = iFactsMaterial.materialType;
        material.concentration = iFactsMaterial.concentration;
        if (this.isMixWater(iFactsMaterial)) {

            material.concentration = null;
            if (request) {

                material.concentration =
                    request.selectedSlurry.mixWaterReqGPS;
            }
        }
        material.concentrationUnit = iFactsMaterial.concentrationUnit;
        material.isBlendComponent = iFactsMaterial.isBlendComponent;
        material.testAmount = iFactsMaterial.testAmount;
        material.sg = iFactsMaterial.sg;
        material.outputUnit = iFactsMaterial.outputUnit;
        material.order = iFactsMaterial.order;
        material.bulkDensity = iFactsMaterial.bulkDensity;
        material.mixingProcedureValue = iFactsMaterial.mixingProcedure;
        material.sampleId = iFactsMaterial.sampleId;
        material.batchNo = iFactsMaterial.batchNo;
        material.lotNo = iFactsMaterial.lotNo;

        return material;
    }
}
