<div [formGroup]="fluidFormGroup" class="ui-table">
  <div *ngIf="tabName === 'Fluids'" class="flx-ctn-row p-10">
    <p-checkbox class="m-r-20 custom-text" binary="true" formControlName="isCementBlend" label="Blend"
      [lockableElement]="'job_completed'" [ngClass]="{'disabled-checkbox':OriginalJobId!=null||OriginalJobId!=undefined}"></p-checkbox>
    <p-checkbox class="m-r-20 custom-text" *ngIf="!(tabName === 'Fluids' && !isFoam)" binary="true"
      formControlName="isFoam" label="Foamed Fluid" (onChange)="onFoamChange($event)"
      [lockableElement]="'job_completed'"></p-checkbox>
    <span *ngIf="(tabName === 'Fluids' && !isFoam)" class="flx-itm-center">Not a foamed fluid</span>
  </div>

  <table>
    <thead>
      <tr class="pump-fluid-detail-header">
        <th *ngIf="tabName === 'Fluids'">Laboratory Name</th>
        <th>Blend Name</th>
        <th>Cement Name</th>
        <th>Water</th>
        <th>Sack Weight</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="vertical-align-middle" *ngIf="tabName === 'Fluids'">
          <span>{{fluidFormGroup.controls.labName.value}}</span>
        </td>
        <td class="vertical-align-middle">
          <span>{{fluidFormGroup.controls.blendName.value}}</span>
        </td>
        <td class="vertical-align-middle">
          <span>{{fluidFormGroup.controls.cementName.value}}</span>
        </td>
        <td class="vertical-align-middle">
          <span>{{fluidFormGroup.controls.water.value}}</span>
        </td>
        <td class="vertical-align-middle">
          <span>{{fluidFormGroup.controls.sackWeight.value | pUnitFormat}} lb/sack</span>
        </td>
      </tr>
    </tbody>
    </table>

  <table>
    <thead>
      <tr class="pump-fluid-detail-header">
        <th>Slurry Density</th>
        <th>Mix Water</th>
        <th>Total Mix Fluid</th>
        <th>Yield</th>
        <th *ngIf="!(tabName === 'Fluids' && !isFoam)">Foam Density</th>
        <th *ngIf="!(tabName === 'Fluids' && !isFoam)">Foam Quality</th>
        <th *ngIf="isCP4">Thickening Time</th>
        <th *ngIf="isCP4">Job Placement Time</th>
        <!-- <th *ngIf="tabName === 'Fluids'" width="450px">SAP Material</th> -->
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="vertical-align-middle">
          <span formControlName="density" dUnitValue={{UnitType.Density}} dUnitWithName="true"></span>
        </td>
        <td class="vertical-align-middle">
          <span formControlName="mixWater" [yieldValue]="fluidFormGroup.controls.yield.value" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true" [dCustomUnit]="mixWaterUnit"
            [sackWeight]="fluidFormGroup.controls.sackWeight.value"></span>
        </td>
        <td class="vertical-align-middle">
          <span formControlName="mixFluid" dUnitValue={{UnitType.LiquidsSolidsRatio}} dUnitWithName="true"
            [sackWeight]="fluidFormGroup.controls.sackWeight.value"></span>
        </td>
        <td class="vertical-align-middle">
          <span formControlName="yield" dUnitValue={{UnitType.Yield}} dUnitWithName="true"
            [sackWeight]="fluidFormGroup.controls.sackWeight.value"></span>
        </td>
        <td class="vertical-align-middle" *ngIf="!(tabName === 'Fluids' && !isFoam)">
          <span formControlName="foamDensity" dUnitValue={{UnitType.Density}} dUnitWithName="true"></span>
        </td>
        <td class="vertical-align-middle" *ngIf="!(tabName === 'Fluids' && !isFoam)">
          <span>{{fluidFormGroup.controls.foamQuality.value | pUnitFormat}} %</span>
        </td>
        <td *ngIf="isCP4"><span>{{stage?.controls?.jptThickening?.value}}</span></td>
        <td *ngIf="isCP4"><span>{{stage?.controls?.jptTime?.value}}</span></td>
        <!-- <td class="vertical-align-middle" *ngIf="tabName === 'Fluids'">
          <ng-container
            *ngTemplateOutlet="sapTemplate; context: {fluidFormGroup: fluidFormGroup, errorMessages: errorMessages}">
          </ng-container>
        </td> -->
      </tr>
    </tbody>
    </table>

  <table *ngIf="tabName !== 'Fluids'">
    <thead>
      <tr class="pump-fluid-detail-header">
        <th>Planned Qty</th>
        <th>
          <span class="float-left">Dead Qty</span>
          <div class="more-info-icon float-left">
            <div class="more-info-icon-target"></div>
            <i class="fa fa-info-circle icon-info" (click)="deadVolume.toggle($event)"></i>
            <p-overlayPanel #deadVolume [dismissable]="true" [showCloseIcon]="true" styleClass="cpoint-overlay-panel">
              This is Dead Volume/Pit Loss and represents the amount of fluid that is left behind when the fluid is
              pumped.
            </p-overlayPanel>
          </div>
        </th>
        <th width="160px">Dead Volume Fluid Type</th>
        <th *ngIf="(controlPointNumber == null || controlPointNumber == 2) && !isCP4">
          {{isOffshore ? 'Required on Rig' : 'Load Out Qty'}}</th>
        <th *ngIf="isCP4">{{isOffshore ? 'Required on Rig' : 'Load Out Qty'}}</th>
        <th>Bulk Cement</th>
        <th>Stage Water Total</th>
        <!-- <th>SAP Material</th> -->
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="vertical-align-middle">
          <span formControlName="plannedVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
        </td>
        <td class="vertical-align-middle" *ngIf="controlPointNumber == null || controlPointNumber == 2">
          <div class="flx-ctn-row" [ngClass]="checkDisableItem()" [lockableElement]="'fluidDetailInfo_deadVolume'">
            <input-unit class="flx-itm-grow m-r-10" [lockableElement]="'fluidDetailInfo_deadVolume'"
              [class.exception-disable-item]="job && !job.canEdit"
              formControlName="deadVolume" unit={{UnitType.LargeVolume}}>
            </input-unit>
            <span class="flx-itm-center" [unitType]="UnitType.LargeVolume"></span>
          </div>
        </td>
        <td class="vertical-align-middle" *ngIf="controlPointNumber != null && controlPointNumber != 2">
          <span formControlName="deadVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
        </td>
        <td class="vertical-align-middle" *ngIf="controlPointNumber == null || controlPointNumber == 2">
          <div class="flx-ctn-row">
            <p-dropdown [options]="deadVolumeFluidTypes" [ngClass]="checkDisableItem()" formControlName="deadVolumeFluidType"
              [disabled]="job.canEdit ? !isCementFluid : true" class="flx-itm-grow m-r-10" [lockableElement]="'fluidDetailInfo_deadVolume'">
              <ng-template let-type pTemplate="selectedItem">
                <div>{{type.label}}</div>
              </ng-template>
              <ng-template let-type pTemplate="item">
                <div *ngIf="type.disabled" pTooltip="No mixing procedure information is available"
                  tooltipPosition="right">{{type.label}}</div>
                <div *ngIf="!type.disabled">{{type.label}}</div>
              </ng-template>
            </p-dropdown>
          </div>
        </td>
        <td class="vertical-align-middle" *ngIf="controlPointNumber != null && controlPointNumber != 2">
          <span>{{getDeadVolumeFluidTypeName(fluidFormGroup?.controls?.deadVolumeFluidType?.value)}}</span>
        </td>
        <td class="vertical-align-middle" *ngIf="(controlPointNumber == null || controlPointNumber == 2) && !isCP4">
          <div class="flx-ctn-row" [ngClass]="checkDisableItem()" [lockableElement]="'fluidDetailInfo_loadoutVolume'">
            <input-unit [lockableElement]="'fluidDetailInfo_loadoutVolume'" class="flx-itm-grow m-r-10"
              formControlName="loadoutVolume" unit={{UnitType.LargeVolume}}>
            </input-unit>
            <span class="flx-itm-center" [unitType]="UnitType.LargeVolume"></span>
          </div>
        </td>
        <td *ngIf="isCP4" class="vertical-align-middle">
          <span formControlName="loadoutVolume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="controlPointNumber == 1 || isCP4">
            <span formControlName="bulkCement" dUnitValue={{UnitType.BaseMaterialWeight}} dUnitWithName="true"
            [sackWeight]="fluidFormGroup.controls.sackWeight.value"></span>
          </div>
          <div *ngIf="controlPointNumber != 1 && !isCP4" class="flx-ctn-row" [ngClass]="checkDisableItem()">
            <input-unit class="flx-itm-grow m-r-10" formControlName="bulkCement" unit={{UnitType.BaseMaterialWeight}}
              [sackWeight]="fluidFormGroup.controls.sackWeight.value" [lockableElement]="'fluidDetailInfo_bulkCement'"
              [lockableElement]="'job_completed'"></input-unit>
            <span class="flx-itm-center" [unitType]="UnitType.BaseMaterialWeight"></span>
          </div>
        </td>
        <td class="vertical-align-middle">
          <span formControlName="stageWaterTotal" dUnitValue={{UnitType.LargeVolume}}
            dUnitWithName="true"></span>
        </td>
        <!-- <td class="vertical-align-middle">
      <ng-container
        *ngTemplateOutlet="sapTemplate; context: {fluidFormGroup: fluidFormGroup, errorMessages: errorMessages}">
      </ng-container>
    </td> -->
      </tr>
    </tbody>
    </table>
</div>

<ng-template #sapTemplate let-fluidFormGroup="fluidFormGroup" let-errorMessages="errorMessages">
  <div [formGroup]="fluidFormGroup">
    <sap-material class="exception-disable-item" [sapMaterialNumber]="fluidFormGroup?.controls?.sapMaterialNumber"
      [sapMaterialName]="fluidFormGroup?.controls?.sapMaterialName" (onChangeSAP)="onChangeSAP($event)">
    </sap-material>
    <error-display formControlName="sapMaterialNumber" [errorMsg]="errorMessages.sapMaterialNumber"></error-display>
  </div>
</ng-template>