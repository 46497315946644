<div class="heading-elements">
  <ul *ngIf="canEdit" class="list-inline" [ngClass]="{'disabled':disabled}">
    <li  *ngIf="!isDashboard" class="custom-list-item">
      <a (click)="!disabled &&  onReportMail()" title="Report SAP Mismapping">
        <i class="glyphicon glyphicon-envelope custom-icon" aria-hidden="true"></i>
        <span class="link-text">Report SAP Mismapping</span>
      </a>
    </li>
    <!-- <li class="list-inline-item">
      <a *ngIf="!isDashboard" href="under-construction" title="Create New Request">
        <i class="fa fa-plus"></i> <span class="link-text">Create Request</span>
      </a>
      <a *ngIf="isDashboard" (click)="addNewFluid()" title="Create New Request">
        <i class="fa fa-plus"></i> <span class="link-text">Create Request</span>
      </a>
    </li> -->
    <li class="list-inline-item">
      <a (click)="!disabled && showIfactSearchPage()" title="Search for iFacts Request">
        <i class="fa fa-search"></i> <span class="link-text">Search iFacts</span>
      </a>
    </li>
    <li class="list-inline-item" [lockableElement]="'job_completed'">
      <a (click)="!disabled && showFluidSettings()" title="Link Fluid Request ID">
        <i class="fa fa-link"></i> <span class="link-text">Link Fluid Request ID</span>
      </a>
    </li>
    <li *ngIf="isDashboard" class="list-inline-item" [lockableElement]="'job_completed'">
      <a (click)="!disabled && showFluidEditParameter()" title="Edit Fluid Parameters">
        <i class="fa fa-cog"></i> <span class="link-text">Edit Fluid Parameters</span>
      </a>
    </li>
  </ul>
</div>