<div [formGroup]="formGroup">
  <fluid-detail-info #fluiddetailinfo [fluidFormGroup]="formGroup" [controlPointNumber]="controlPointNumber"
    [tabName]="tabName" [job]="job" [cpState]="cpState" [jobState]="jobState" [stageModel]="stageModel" [stage]="stage"
    [isMix]="formGroup?.controls?.isMixFluid?.value"
    [haveSupplemental]="formGroup?.controls?.supplementalMaterial['controls'].length"
    [isCementFluid]="formGroup?.controls?.isCementFluid?.value"></fluid-detail-info>

  <pump-fluid-material-detail *ngIf="formGroup.controls.fluidBlendMaterial['controls'].length"
    class="m-l-30 m-t-30 pump-fluid-material-blends" [fluid]="fluid" [isBlend]="true"
    [header]="getHeaderMaterialName(formGroup?.controls?.fluidBlendMaterial['controls'], MATERIAL_TYPE.BLEND)"
    [materialFormGroups]="formGroup?.controls?.fluidBlendMaterial.controls" [plannedVolume]="plannedVolume"
    [loadoutVolume]="formGroup.controls.loadoutVolume.value" [mixVolume]="formGroup.controls.mixVolume.value"
    [yield]="formGroup.controls.yield.value" [bulkCementBaseOnFluid]="formGroup.controls.totalBulkCement.value"
    [controlPointNumber]="controlPointNumber" [tabName]="tabName" [cpState]="cpState" [jobState]="jobState" [job]="job" 
    [actualVolume]="stage?.controls?.actualVolumePumped?.value" [fluidType]="MATERIAL_TYPE.BLEND" [sapMaterialMappings]="sapMaterialMappings" [stage]="stage">
  </pump-fluid-material-detail>

  <pump-fluid-material-detail class="m-t-30 pump-fluid-material-additives" [fluid]="fluid"
    [header]="getHeaderMaterialName(formGroup?.controls?.fluidMaterial['controls'], MATERIAL_TYPE.ADDITIVE)"
    [isBlend]="false" [materialFormGroups]="formGroup?.controls?.fluidMaterial['controls']"
    [plannedVolume]="plannedVolume" [loadoutVolume]="formGroup.controls.loadoutVolume.value"
    [mixVolume]="formGroup.controls.mixVolume.value" [yield]="formGroup.controls.yield.value"
    [bulkCementBaseOnFluid]="formGroup.controls.totalBulkCement.value" [job]="job"
    [controlPointNumber]="controlPointNumber" [tabName]="tabName" [cpState]="cpState" [jobState]="jobState"
    [actualVolume]="stage?.controls?.actualVolumePumped?.value" [fluidType]="MATERIAL_TYPE.ADDITIVE"
    (onLoadoutVolumeChanged)="_onLoadoutVolumeChanged()" [sapMaterialMappings]="sapMaterialMappings" [stage]="stage"></pump-fluid-material-detail>

  <pump-fluid-material-detail class="m-l-30 m-t-30 pump-fluid-material-blends" [fluid]="fluid"
    [header]="getHeaderMaterialName(formGroup?.controls?.supplementalMaterial['controls'], MATERIAL_TYPE.SUPPLEMENTAL)"
    [isBlend]="false" [materialFormGroups]="formGroup?.controls?.supplementalMaterial['controls']"
    [plannedVolume]="plannedVolume" [loadoutVolume]="formGroup.controls.loadoutVolume.value"
    [mixVolume]="formGroup.controls.mixVolume.value" [yield]="formGroup.controls.yield.value"
    [bulkCementBaseOnFluid]="formGroup.controls.totalBulkCement.value" [job]="job"
    [controlPointNumber]="controlPointNumber" [tabName]="tabName" [cpState]="cpState" [jobState]="jobState"
    [actualVolume]="stage?.controls?.actualVolumePumped?.value" [fluidType]="MATERIAL_TYPE.SUPPLEMENTAL"
    (onLoadoutVolumeChanged)="_onLoadoutVolumeChanged()" [sapMaterialMappings]="sapMaterialMappings" [stage]="stage"></pump-fluid-material-detail>
</div>
