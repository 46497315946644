import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class ComunicationLogService {

  constructor(private http: HttpService) { }

  getComments(jobId: string, startIndex: number, endIndex: number): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/communicationLog/comment/job/${jobId}/${startIndex}/${endIndex}`);
  }

  addComment(groupId: string, jobId: string, model: any) {
    return this.http.Post(`${environment.baseUrl}/api/communicationLog/comment/group/${groupId}/job/${jobId}`, JSON.stringify(model));
  }
  // updateComment(groupId: string, jobId: string, model: any) {
  //   return this.http.Put(`${environment.baseUrl}/api/communicationLog/comment/group/${groupId}/job/${jobId}`, JSON.stringify(model));
  // }
  // deleteComment(groupId: string, jobId: string) {
  //   return this.http.Delete(`${environment.baseUrl}/api/communicationLog/comment/group/${groupId}/job/${jobId}`);
  // }
}
