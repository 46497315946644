<div class="table-event" [formGroup]="form">
    <p-table [value]="form.controls.events['controls']" class="pump-event-table" [contextMenu]="cm">
        <ng-template pTemplate="header">
            <tr class="pump-event-header">
                <th>Placement Method</th>
                <th>Volume</th>
                <th>Bulk Cement</th>
                <th>Planned Duration</th>
                <th>Actual Duration*</th>
                <th>Planned Rate</th>
                <th>Actual Rate{{isFirstStage ? '' : '*'}}</th>
                <th class="context-menu-width"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eventFormGroup let-i="rowIndex">
            <tr [formGroup]="eventFormGroup" class="pump-event-body">
                <td>
                    <span>{{eventFormGroup.controls.placementMethodName.value}}</span>
                </td>
                <td>
                    <span *ngIf="!isShutdown(eventFormGroup)" class="unit-value" [apiValue]="eventFormGroup.controls.volume.value"
                        [unitType]="UnitType.LargeVolume"></span>
                </td>
                <td>
                    <span *ngIf="!isShutdown(eventFormGroup)" formControlName="bulkCement" dUnitValue={{UnitType.BaseMaterialWeight}}
                        dUnitWithName="true" [sackWeight]="form.controls.slurry?.value?.sackWeight"></span>
                </td>
                <td>
                    <span class="unit-value">{{ eventFormGroup.controls.duration.value | number:'1.2-2' }}</span>
                    <span class="unit-measure">min</span>
                </td>
                <td>
                    <div *ngIf="isShutdown(eventFormGroup)" class="display-measure-event" [lockableElement]="'PumpSchedule_actualDuration'">
                        <input type="text" class="form-control" formControlName="actualDuration" pInputText pKeyFilter="pnum"
                            pTooltip={{ValidatorSetting.ERROR_UNIT_DECIMAL_POS}} tooltipStyleClass="tooltip-events" dUnitFormat>
                        <div class="flx-itm-center uom-ctrl">
                            <span class="unit-of-measure">min</span>
                        </div>
                    </div>
                </td>
                <td>
                    <span *ngIf="!isShutdown(eventFormGroup)" class="unit-value" [apiValue]="eventFormGroup.controls.rate.value"
                        [unitType]="UnitType.FlowRate"></span>
                </td>
                <td>
                    <div>
                        <div *ngIf="!isShutdown(eventFormGroup) && !isFirstStage" class="div-left">
                            <div class="display-measure-event flx-ctn-row" [lockableElement]="'PumpSchedule_actualRate'">
                                <div class="input-ctrl">
                                    <input-unit formControlName="actualRate" unit={{UnitType.FlowRate}} tooltip={{ValidatorSetting.ERROR_UNIT_DECIMAL_ONE_POS}}
                                        tooltipClass="tooltip-events" decimalPlaces="1" pKeyFilter="pnum"></input-unit>
                                </div>
                                <div class="flx-itm-center uom-ctrl">
                                    <span class="unit-of-measure" dUnitName={{UnitType.FlowRate}}></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </td>
                <td class="relative">
                    <div *ngIf="showContextMenu" class="context-menu-button_cp4">
                            <span [lockableElement]="'job_completed'">
                                <i class="fa fa-ellipsis-h" (click)="onEventContextMenu(cm,$event,eventFormGroup);"></i>
                            </span>
                    </div>
                    <button *ngIf="showRemoveButton(eventFormGroup)" class="btn-remove-pump-event-readonly"
                            (click)="removeItem(eventFormGroup)">
                      <div>
                        <i class="fa fa-times"></i>
                      </div>
                    </button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <p-contextMenu #cm [model]="editShutdownMenuItems" appendTo="body" styleClass="events-summary-contextmenu"></p-contextMenu>
</div>
<!-- <div [lockableElement]="'pumpSchedule_addNewEvent'">
    <button *ngIf="!readonly && outerIndex!==0" class="action-add" (click)="addItem()">
        <i class="fa fa-plus-circle"></i><span>Add New Event</span>
    </button>
</div> -->