import { Component, OnInit, ViewChild } from '@angular/core';
import { VidaFileType, CommonMessageText } from '../../../shared/constant';
import { DynamicSidebarContent, DynamicSidebarRef } from 'libs/ui';
import { ControlPointService2 } from 'libs/shared/services';
import { HttpCommanderService } from 'libs/shared/services';
import { Subscription } from 'rxjs';
import { RiskAssessment } from '../../../control-point/models';
import { MessageService } from 'primeng/api';
import { JobUploaderComponent } from 'apps/vida/src/modules/shared/components';
import { environment } from 'libs/environment';

@Component({
  selector: 'risk-assessment-sidebar',
  templateUrl: './risk-assessment-sidebar.component.html',
  styleUrls: ['./risk-assessment-sidebar.component.scss']
})
export class RiskAssessmentSidebarComponent implements DynamicSidebarContent,  OnInit {

  data: any;
  jobId: string;
  vidaFileType: VidaFileType = VidaFileType.RiskAssessment;
  errorMessage: string;
  isCallSheetVisible = false;
  resetUploerUI = false;
  disableSaveButton: boolean = true;
  destinationFolderPath: string;
  loadingSubscription: Subscription;
  riskAssessmentFiles: RiskAssessment[];
  lastUsedFile: RiskAssessment;
  isChanged: boolean = false;
  @ViewChild('uploader', { static: true }) jobUploader: JobUploaderComponent;

  constructor(
    private HCService: HttpCommanderService,
    private dynamicSidebarRef: DynamicSidebarRef<RiskAssessmentSidebarComponent>,
    private controlPointService: ControlPointService2,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.destinationFolderPath = this.data.path;
    this.jobId = this.data.jobId;
    this.lastUsedFile = new RiskAssessment();
    this.getFiles();
  }

  // Upload in progress callback
  uploadInprogress(event) {
    if (event) {
      this.disableSaveButton = true;
    }
  }

  // Upload success callback
  uploadSuccess(event) {
    if (event.isSuccess) {
      this.isChanged = true;
      this.getFiles();
    }
  }

  // Upload cancel callback
  uploadCancel(event) {
    if (!event.isSuccess) {
    }
  }

  cancelRiskAssessment() {
    this.jobUploader.cancelUpload();
    this.hideFileSidebar();
  }

  hideFileSidebar() {
    this.errorMessage = '';
    this.dynamicSidebarRef.close(this.isChanged ? this.riskAssessmentFiles : null);
  }

  riskAssessmentSelectedHandler() {
    this.disableSaveButton = false;
    this.riskAssessmentFiles.map(riskAss => riskAss.isLastUsed = false);
  }

  getFiles() {
    this.loadingSubscription = this.HCService
      .getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.RiskAssessment)
      .subscribe((data: any) => {
        this.handleDataFromVIDA(data);
      });
  }

  handleDataFromVIDA(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        this.riskAssessmentFiles = result.map(riskAss => {
          const temp = Object.assign(new RiskAssessment, riskAss);
          return temp;
        });
        this.lastUsedFile = this.riskAssessmentFiles.find((riskAss: any) => riskAss.isLastUsed);
        if (this.lastUsedFile) {
          this.disableSaveButton = false;
        }
      }
    }
  }

  saveRiskAssessment() {
    if (this.lastUsedFile) {
      this.lastUsedFile.isLastUsed = true;
    }
    this.HCService.saveRiskAssessment(this.riskAssessmentFiles, this.jobId).subscribe((res: any) => {
      this.handleDateFromHC(res);
      if (res.statusCode === 200) {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.RISK_ASSESSMENT.SUCCESS });
        this.dynamicSidebarRef.close(this.riskAssessmentFiles);
      } else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: CommonMessageText.RISK_ASSESSMENT.FAILED });
        this.hideFileSidebar();
      }
    });
  }

  
  handleDateFromHC(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        this.riskAssessmentFiles = result.map(riskAss => {
          const temp = Object.assign(new RiskAssessment, riskAss);
          return temp;
        });
        this.lastUsedFile = this.riskAssessmentFiles.find((job: any) => job.isLastUsed);
      }
    }
  }
}


