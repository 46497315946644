import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UnitConversionService } from './unit-conversion.service';

@Directive({
  selector: '[dUnitName]'
})
export class UnitNameDirective implements OnChanges {

  @Input('dUnitName') dUnitName: number;

  constructor(
    private el: ElementRef,
    private unitService: UnitConversionService,
  ) {
    this.unitService.onSaveUnit$.subscribe(x => {
      this.updateUnit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { dUnitName } = changes;
    if (dUnitName && dUnitName.currentValue) {
      this.updateUnit();
    }
  }

  updateUnit(): void {
    const htmlElement = this.el.nativeElement as HTMLElement;
    const unitClass = this.unitService.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum.toString() === this.dUnitName.toString());
    if (unitClass && unitClass.unitMeasure && unitClass.unitMeasure.name) {
      htmlElement.innerText = unitClass.unitMeasure.name;
    }
  }
}
