export class ProposalVersionHDF {
  proposalNumber: number;
  version: string;
  proposalStatus: string;
  displayName: string;
  wellData: WellHDFdata;
  jobs: JobHDFInfo[];

  constructor() {
    this.proposalNumber = null;
    this.version = '';
    this.proposalStatus = '';
    this.displayName = '';
    this.wellData = null;
    this.jobs = [];
  }
}

export class WellHDFdata {
  countryUniqueWellIdentifier: string;
  sapShipToCode: string;
  wellLocation: string;
  wellName: string;
  wellNumber: string;
}

export class JobHDFInfo {
  jobId: string;
  jobName: string;
  jobType: string;
  displayName: string;
  salesOrderSection: SalesOrderSectionHDF[];
  proposalJobId: string;
  
  constructor() {
    this.salesOrderSection = [];
  }
}

export class SalesOrderSectionHDF {
  salesOrder: string;
}

export class HDFAttachmentType {
  name: string;
  value: string;
}

// US 359802
export class GetJobInfoBySORespone {
  listProposalInfo: ProposalVersionHDF[];
}