import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription, Subject, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { MasterDataService } from 'libs/shared/services';
import { FLUID_TYPE_SCHEDULE } from 'libs/constants';
import { Job, PumpScheduleStageModel, PumpScheduleEventModel, IPumpScheduleFluidType, IPlacementMethod } from 'libs/models';
import { PDropdownDataSanitizerHelper, PDropdownDataPumpScheduleHelper } from '../../shared/helpers';

@Injectable()
export class PumpScheduleAdapter {

  constructor(
    private masterDataService: MasterDataService
  ) {
  }

  public readonly ThickeningTimeJobType = ['conductor casing', 'intermediate casing', 'production casing', 'surface casing'];

  placementMethodFirstStage$ = new BehaviorSubject<IPlacementMethod[]>([]);
  placementMethodOtherStage$ = new BehaviorSubject<IPlacementMethod[]>([]);
  placementMethodTypeFirstStage$ = new BehaviorSubject<any[]>([]);
  placementMethodTypeOtherStage$ = new BehaviorSubject<any[]>([]);
  fluidTypePumpScheduleData$ = new BehaviorSubject<any[]>([]);
  jptList$ = new BehaviorSubject<any[]>([]);
  stageChanges$ = new Subject();
  disableSelectedTopArray: IPumpScheduleFluidType[] = [];
  enableSelectedTopArray: IPumpScheduleFluidType[] = [];
  fluidTypes: IPumpScheduleFluidType[] = [];
  fluidTypeDataStageOne: any = [];
  fluidTypes$ = new BehaviorSubject<any[]>([]);

  public hashColors = {
    [-1]: '#d1d1d1',
    0: '#57c5e8',
    1: '#5d3116',
    2: '#efc027',
    3: '#ec2d24',
    4: '#80a095',
    5: '#ea6925',
    6: '#06ad4d',
    7: '#b01f23',
    8: '#412f8e',
    9: '#7bc045',
    10: '#047b80',
    11: '#71421b',
    12: '#de5e47',
    13: '#a8a138',
    14: '#4a190d'
  };
  private stages: UntypedFormArray;
  private job: Job;
  private subscriptions: Subscription[] = [];

  initFluidType(): IPumpScheduleFluidType[] {
    this.disableSelectedTopArray = [];
    return this.disableSelectedTopArray = this.getFluidTypeStage(false);
  }

  public initFluidTypePumpScheduleData(): Observable<any> {
    return this.masterDataService.listPumpScheduleFluidTypes().pipe(tap(res => {
      this.fluidTypes = res;
      this.fluidTypePumpScheduleData$.next(res);
      this.fluidTypeDataStageOne = PDropdownDataSanitizerHelper('description', 'id', this.getFluidTypeStage(true));
      this.fluidTypes$.next(PDropdownDataSanitizerHelper('description', 'id', this.getFluidTypeStage(false)));
    }));
  }

  public initPlacementMethodFirstStage(): Observable<any> {
    return this.masterDataService.listPumpSchedulePlacementMethods(0).pipe(tap(res => {
      this.placementMethodFirstStage$.next(res);
      this.placementMethodTypeFirstStage$.next(PDropdownDataPumpScheduleHelper('description', 'id', 'isDisable', res));
    }));
  }

  public initPlacementMethodOtherStage(): Observable<any> {
    return this.masterDataService.listPumpSchedulePlacementMethods(1).pipe(tap(res => {
      this.placementMethodOtherStage$.next(res);
      this.placementMethodTypeOtherStage$.next(PDropdownDataPumpScheduleHelper('description', 'id', 'isDisable', res));
    }));
  }

  getFluidTypeStage(isEnable: boolean): IPumpScheduleFluidType[] {
    return this.fluidTypes.filter(data => data.isEnableFirstStage === isEnable);
  }

  watchStageChanges(stages: UntypedFormArray, job: Job) {
    this.stages = stages;
    this.job = job;
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    this.setStageIndex(stages);
    this.calculateJobPlacemenTime(stages, job);
    this.stageChanges$.next();

    stages.controls.forEach((stage: UntypedFormGroup) => {
      this.subscriptions.push(
        stage.controls.pumpScheduleFluidTypeId.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
          this.setStageIndex(stages);
          this.calculateJobPlacemenTime(stages, job);
          this.stageChanges$.next();
        })
      );

      this.subscriptions.push(
        stage.controls.slurry.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
          this.setStageIndex(stages);
          this.calculateJobPlacemenTime(stages, job);
        })
      );

      this.subscriptions.push(
        stage.controls.order.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
          this.calculationTotalVolumeStage(stage);
          this.calculationAvgRateStage(stage);
        })
      );

      this.subscriptions.push(
        stage.controls.events.valueChanges.pipe(
          map(events => events.length),
          distinctUntilChanged()
        ).subscribe(_ => {
          this.calculateJobPlacemenTime(stages, job);
          this.calculateTotalBulkCement(stage);
          this.calculationTotalVolumeStage(stage);
          this.calculationAvgRateStage(stage);
          this.calculationTopOfFluid(stage);

          const events = stage.controls.events as UntypedFormArray;
          events.controls.forEach((event: UntypedFormGroup) => {
            this.subscriptions.push(
              event.controls.duration.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculateJobPlacemenTime(stages, job);
              })
            );
            this.subscriptions.push(
              event.controls.bulkCement.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculateTotalBulkCement(stage);
              })
            );
            this.subscriptions.push(
              event.controls.volume.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculationTotalVolumeStage(stage);
                this.calculationAvgRateStage(stage);
              })
            );
            this.subscriptions.push(
              event.controls.rate.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculationAvgRateStage(stage);
              })
            );
            this.subscriptions.push(
              event.controls.placementMethod.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculateJobPlacemenTime(stages, job);
                this.calculationTopOfFluid(stage);
              })
            );
            this.subscriptions.push(
              event.controls.topOfFluid.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                this.calculationTopOfFluid(stage);
              })
            );
          });
        })
      );
    });
  }

  private setStageIndex(stages: UntypedFormArray) {
    let number = 0;
    const sepecialTypes = this.fluidTypes.filter(e => e.name === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || e.name === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT);

    for (let i = 0; i < stages.length; i++) {
      const stage = stages.controls[i] as UntypedFormGroup;
      if (stage == null) continue;
      const stageType = stage.controls.pumpScheduleFluidTypeId.value;
      const stageNumber = sepecialTypes.find(type => type.id === stageType) != null ? -1 : number++;

      stage.controls.order.setValue(i);
      stage.controls.number.setValue(stageNumber);
    }
  }

  public calculateJPT(stages: UntypedFormArray, job: Job) {
    const placementMethodShutdown = this.placementMethodFirstStage$.value.find(e => e.name === 'Shutdown');
    let shutdownId = '';
    if (placementMethodShutdown) {
      shutdownId = placementMethodShutdown.id;
    }

    const cementType = this.fluidTypes.find(f => f.name === FLUID_TYPE_SCHEDULE.CEMENT);
    const stageList = stages.getRawValue();
    stageList.reverse().forEach((stage, index) => {
      const firstIndex = stage.events.findIndex(ev => ev.placementMethod !== shutdownId);
      const totalDuration = stage.events.sum(event => +event.duration);
      const shutdownDuration = stage.events.slice(0, firstIndex).sum(event => +event.duration);
      const previusStageDuration = stageList[index - 1] ? +stageList[index - 1].totalDuration : 0;
      stage.totalDuration = previusStageDuration + totalDuration;
      stage.placementTime = previusStageDuration + totalDuration - shutdownDuration;
    });

    const jobPlacementTimes = stageList.reverse().filter((stage) => {
      return stage.number !== -1 && cementType && stage.pumpScheduleFluidTypeId === cementType.id;
    }).filter((stage, index) => {
      const isShutdownStage = stage.events.every(ev => ev.placementMethod === shutdownId);
      if (isShutdownStage && index === 0) return false;
      return true;
    }).map((stage: PumpScheduleStageModel) => {
      let thickeningTimeJpt;
      const isPull = stage && !stage.isManualyThickeningTime && stage.slurry
        && job.jobTypeName && this.ThickeningTimeJobType.includes(job.jobTypeName.toLowerCase().trim());

      if (isPull) {
        if (stage.slurry.status === 'Draft') {
          thickeningTimeJpt = null;
        } else {
          thickeningTimeJpt = stage.slurry.thickeningTime;
        }
      } else {
        if (stage.isManualyThickeningTime) {
          thickeningTimeJpt = stage.thickeningTime;
        }
      }

      return {
        id: stage.id,
        stageNumber: stage.number ? stage.number + 1 : -1,
        placementTime: stage.placementTime,
        thickeningTimeJpt: thickeningTimeJpt,
        slurryType: stage.slurry ? stage.slurry.slurryType : '',
      };
    });
    return jobPlacementTimes;
  }

  public calculationTopOfFluidStage(events: any, placementMethodShutdownId: string) {
    const nonShutdownEvents = events.filter((x: PumpScheduleEventModel) => x.placementMethod !== placementMethodShutdownId && x.topOfFluid != null && !isNaN(x.topOfFluid));
    return (nonShutdownEvents && nonShutdownEvents.length > 0) ? nonShutdownEvents.min(x => x.topOfFluid) : null;
  }

  public isTopBottom(stage: PumpScheduleStageModel): boolean {
    if (stage == null) {
      return false;
    }
    return stage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG || stage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT;
  }

  public calculateDuration(volumn: number, rate: number): number {
    if (rate === 0 || rate == null || volumn == null) return null;
    return volumn / rate;
  }

  public convertToUserUnit(schedule: any, placementMethodShutdownId: string) {
    if (schedule) {
      schedule.stages.forEach((stage, stageIndex) => {
        stage.events.forEach(event => {
          if (stageIndex > 0 && event.volume && event.rate) {
            event.duration = event.volume / event.rate;
          }
        });
        stage.topOfFluid = this.calculationTopOfFluidStage(stage.events, placementMethodShutdownId);
      });
    }
  }

  private calculateJobPlacemenTime(stages: UntypedFormArray, job: Job) {
    const jobPlacementTimes = this.calculateJPT(stages, job);
    this.jptList$.next(jobPlacementTimes);
  }

  private calculateTotalBulkCement(stage: UntypedFormGroup) {
    const stageValue = stage.getRawValue();
    const events = stageValue.events as any[];
    const totalBulkCement = events.every(e => e.bulkCement === null || e.bulkCement === undefined || e.bulkCement === '') ?
      null : events.sum(e => e.bulkCement !== null && e.bulkCement !== undefined ? +e.bulkCement : 0);
    stage.controls.totalBulkCement.setValue(totalBulkCement);
  }

  private calculationTotalVolumeStage(stage: UntypedFormGroup) {
    const stageValue = stage.getRawValue();
    const events = stageValue.events;

    if (!stage.controls.isChangeActualVolumePumped.value) {
      stage.controls.actualVolumePumped.setValue(0, { emitEvent: false });
    }

    let totalVolumeStage = 0;
    let count = 0;
    events.forEach(element => {
      if (element.volume && element.volume !== '') {
        count++;
        totalVolumeStage += +element.volume;
      }
    });
    if (isNaN(totalVolumeStage)) {
      stage.controls.volume.setValue(0);
    } else {
      stage.controls.volume.setValue(count === 0 ? '' : +totalVolumeStage);
      if (!stage.controls.isChangeActualVolumePumped.value) {
        stage.controls.actualVolumePumped.setValue(count === 0 ? '' : +totalVolumeStage);
      }
    }

  }

  private calculationAvgRateStage(stage: UntypedFormGroup) {
    const stageValue = stage.getRawValue();
    const order = stageValue.order;
    const events = stageValue.events;

    let totalVolumeStage = 0;
    let totalDuration = 0;
    let count = 0;
    for (let i = 0; i < events.length; i++) {
      const duration = +this._calculationDuration(events, i, order);
      if (!isNaN(duration)) {
        totalDuration += duration;
      }
      if (events[i].volume && events[i].volume !== null) {
        count++;
        totalVolumeStage += +events[i].volume;
      }
    }
    if (totalDuration === 0 || count === 0) {
      stage.controls.avgRate.setValue('');
    } else {
      stage.controls.avgRate.setValue(+(totalVolumeStage / totalDuration));
    }

  }

  public updateFormsWithCOGSSum(pumpScheduleForm: UntypedFormGroup, stageCOGS: number[]): boolean {
    const stageCOGSValues = [];
    let valueChanged = false;
    if (stageCOGS && stageCOGS.length) {
      const stagesFormArr = pumpScheduleForm.controls.stages as UntypedFormArray;

      stagesFormArr.controls.forEach((stage: UntypedFormGroup, index) => {
          const stageTotalCOGS = stageCOGS[index] !== undefined ? stageCOGS[index] : 0;
          stage.controls.totalCOGS.setValue(stageTotalCOGS, { emitEvent: false });
          stageCOGSValues.push(stageTotalCOGS);
      });
  
      if (stageCOGSValues.length) {
          const calculatedJobCOGS = stageCOGSValues.every(x => x || x === 0) ? 
                                                  +stageCOGSValues.sum(x => x).toFixed(2) : null;
          valueChanged = calculatedJobCOGS !== pumpScheduleForm.controls.totalCOGS.value;                     
          pumpScheduleForm.controls.totalCOGS.setValue(calculatedJobCOGS, { emitEvent: false }); 
      }
    }
    
    return valueChanged;
}

  private calculationTopOfFluid(stage: UntypedFormGroup) {
    const stageValue = stage.getRawValue();
    const events = stageValue.events;
    const placementMethodShutdown = this.placementMethodFirstStage$.value.find(e => e.name === 'Shutdown');
    const placementMethodShutdownId = placementMethodShutdown === undefined ? null : placementMethodShutdown.id;
    const topOfFluid = this.calculationTopOfFluidStage(events, placementMethodShutdownId);
    stage.controls.topOfFluid.setValue(topOfFluid);
  }

  private _calculationDuration(events, index, order) {
    const defaultValue = 0;
    const rate = +events[index].rate;
    const volume = +events[index].volume;
    return (rate !== 0) ? volume / rate : defaultValue;
  }
}
