import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BhctRiskLevelService } from '../../../../modules/control-point/services/bhctRiskLevelService.service';

@Component({
  selector: 'dash-modal-bhct-validation',
  templateUrl: './modal-bhct-validation.component.html',
  styleUrls: ['./modal-bhct-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBhctValidationComponent implements OnInit {

  data = [];

  cols = [
    { field: 'bhct', header: 'BHCT', width: '50%' },
    { field: 'bhst', header: 'BHST', width: '50%' }
  ];

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private bhctRiskLevelService: BhctRiskLevelService
  ) { }

  ngOnInit() {
    this.data.push(this.dynamicDialogConfig.data);
  }

  confirm() {
    this.bhctRiskLevelService.bhctConfirmUpdate(true);
    this.dynamicDialogRef.close();
  }

  onClose() {
    this.dynamicDialogRef.close();
  }

}
