<div [formGroup]="stageForm" [loadingIndicator]="loadingSubscription" class="ui-table w-100">
  <table>
    <thead>
      <tr class="stage-info-header">
        <th width="175px">Type <span *ngIf="!readonly">*</span>
        </th>
        <th *ngIf="!isMudType">
          <span *ngIf="!isTopBottom">Fluid
            <a *ngIf="stageForm && stageForm.controls.slurry.value && stageForm.controls.slurry.value.slurryId"
               href="{{stageForm.controls.slurry.value.slurryId | iFactsLink:'slurry'}}" target="_blank">
              (View in iFacts)
            </a>
            <a (click)="showFluidSettings()" title="Link Fluid Request ID" [lockableElement]="'job_completed'"
                [class.disabled]="(applicationStateService.notifyIFactDown$ | async) || !job.canEdit || !isJobInProgess()">
              <span class="link-text">Link Fluid Request ID</span>
            </a>
          </span>
        </th>
        <th *ngIf="isMudType">
          <span [lockableElement]="'pumpSchedule_btnMudParamEdit'">Mud parameters
            <a *ngIf="!controlPointNumber || controlPointNumber===1" (click)="openMudParameters()"
               [class.disabled]="(applicationStateService.notifyIFactDown$ | async) || !job.canEdit">(Edit)</a>
          </span>
        </th>
        <th width="120px"><span *ngIf="!isTopBottom">Planned Rate</span></th>
        <th width="130px"><span *ngIf="!isTopBottom">Planned Quantity</span></th>
        <th width="120px"><span *ngIf="!isTopBottom">Top of Fluid</span></th>
        <th *ngIf="controlPointNumber == null" width="140px">
          <span *ngIf="!isTopBottom">Actual Quantity*</span>
        </th>
        <th width="100px" *ngIf="!isTopBottom && (!controlPointNumber || isCP1) && (isJobReviewer$ | async) && (showCogs$ | async) "><span>Fluid COGS</span></th>
        <th width="170px" *ngIf="!readonly"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!readonly; else readonlyStageTemplate" class="stage-info-body" [formGroup]="stageForm">
        <td [lockableElement]="'PumpSchedule_pumpScheduleFluidTypeId'">
          <p-dropdown *ngIf="stageForm.controls.order.value === 0" [options]="fluidTypeDataStageOne" formControlName="pumpScheduleFluidTypeId"
                      placeholder="Enter a Fluid Value" showClear="true" (onChange)="_onFluidChange($event)" filter="true"></p-dropdown>
          <p-dropdown *ngIf="stageForm.controls.order.value !== 0" [options]="pumpScheduleAdapter.fluidTypes$ | async"
                      formControlName="pumpScheduleFluidTypeId" placeholder="Enter a Fluid Value" showClear="true" (onChange)="_onFluidChange($event)" filter="true">
          </p-dropdown>
          <error-display formControlName="pumpScheduleFluidTypeId" [errorMsg]="errorMessages.pumpScheduleFluidType">
          </error-display>
        </td>
        <td *ngIf="!isMudType" class="vertical-align-middle">
          <div *ngIf="!isTopBottom" [lockableElement]="'PumpSchedule_slurry'">
            <p-autoComplete #autoComplete (onFocus)="onFocusShowAllData()" (onClear)="onFocusShowAllData();onClearFluid.next()" formControlName="slurry"
                            (onBlur)="onBlurFluid($event)" [suggestions]="filteredFluid" (completeMethod)="onComplete($event.query)" field="displayName"
                            (onSelect)="onSelectedFluid.next($event)" [minLength]="0" (onKeyUp)="onFluidKeyUp.emit()" class="auto-complete-icon">
            </p-autoComplete>
          </div>
        </td>
        <td *ngIf="isMudType" class="vertical-align-middle">
          <span>{{mudParameter}}</span>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="avgRate" dUnitValue={{UnitType.FlowRate}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="volume" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="!isTopBottom">
            <div class="display-measure">
              <span formControlName="topOfFluid" dUnitValue={{UnitType.Distance}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle">
          <div *ngIf="controlPointNumber == null && !isTopBottom">
            <div class="display-measure">
              <span formControlName="actualVolumePumped" dUnitValue={{UnitType.LargeVolume}} dUnitWithName="true"></span>
            </div>
          </div>
        </td>
        <td class="vertical-align-middle" *ngIf="!isTopBottom && (!controlPointNumber || isCP1) && (isJobReviewer$ | async) && (showCogs$ | async)">
          <div *ngIf="stageForm.controls.totalCOGS.value === null && job.id">
            <span class="warning pr-1">Not all COGS available</span>
            <span class="fa fa-question-circle icon-help pl-1" (click)="openStageCogsHelp.emit()"></span>
          </div>
          <span *ngIf="stageForm.controls.totalCOGS.value !== null || !job.id" class="unit-value">${{getStageTotalCogs()}}</span>
        </td>
        <td>
          <ul class="list-inline">
            <li class="list-inline-item" [lockableElement]="'PumpSchedule_addStage'">
              <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-hidden="true"
                 *ngIf="stageForm.controls.number.value === 0">
                Add Stage
              </a>
              <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-hidden="true"
                 *ngIf="stageForm.controls.number.value !== 0">
                Add/ Remove Stage
              </a>
              <ul *ngIf="!readonly" class="dropdown-menu">
                <li [ngClass]="{disabled: stageForm.controls.order.value === 0}">
                  <a *ngIf="stageForm.controls.order.value !== 0" (click)="insertStageBefore.emit()">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                  <a *ngIf="stageForm.controls.order.value === 0">
                    <i class="fa fa-plus"></i> Insert Stage Before
                  </a>
                </li>
                <li>
                  <a (click)="insertStageAfter.emit()">
                    <i class="fa fa-plus"></i> Insert Stage After
                  </a>
                </li>
                <li [ngClass]="{disabled: stageForm.controls.order.value === 0}">
                  <a *ngIf="stageForm.controls.order.value !== 0" (click)="deleteStage.emit()">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                  <a *ngIf="stageForm.controls.order.value === 0">
                    <i class="fa fa-times"></i> Delete Stage
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </td>
      </tr>

      <ng-template #readonlyStageTemplate>
        <tr>
          <td class="vertical-align-middle">
            <span>{{stageForm.controls.pumpScheduleFluidTypeName.value}}</span>
          </td>
          <td *ngIf="!isMudType">
            <div *ngIf="!isTopBottom" [lockableElement]="'PumpSchedule_selectedFluidId'">
              <p-dropdown *ngIf="controlPointNumber; else readonlyFluidTemplate" placeholder="&nbsp;" (onChange)="_onSelectedFluid($event)"
                          [options]="fluidDropDownModels" formControlName="selectedFluidId"
                          [class.exception-disable-item]="cpState === ControlPointState.Approved || cpState === ControlPointState.Submitted">

              </p-dropdown>
              <ng-template #readonlyFluidTemplate>
                <p-autoComplete formControlName="slurry" [suggestions]="filteredFluid" (completeMethod)="onComplete($event.query)" field="displayName"
                                [minLength]="0" [lockableElement]="'job_completed'" class="auto-complete-icon exception-disable-item">
                </p-autoComplete>
              </ng-template>
            </div>
            <div *ngIf="isCP4">
              <span class="unit-value">{{stageForm?.controls?.slurry?.value?.displayName}}</span>
            </div>
          </td>
          <td *ngIf="isMudType">
            <span>{{mudParameter}}</span>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="stageForm.controls.avgRate.value" [unitType]="UnitType.FlowRate"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="stageForm.controls.volume.value" [unitType]="UnitType.LargeVolume"></span>
            </div>
          </td>
          <td class="vertical-align-middle">
            <div *ngIf="!isTopBottom">
              <span class="unit-value" [apiValue]="stageForm.controls.topOfFluid.value" [unitType]="UnitType.Distance"></span>
            </div>
          </td>
          <td *ngIf="controlPointNumber == null" class="vertical-align-middle">
            <span *ngIf="!isTopBottom" class="unit-value" [apiValue]="stageForm.controls.actualVolumePumped.value" [unitType]="UnitType.LargeVolume"></span>
          </td>
          <td class="vertical-align-middle" *ngIf="!isTopBottom && (!controlPointNumber || isCP1) && (isJobReviewer$| async) && (showCogs$ | async)">
            <div *ngIf="stageForm.controls.totalCOGS.value === null && job.id">
              <span class="warning pr-1">Not all COGS available</span>
              <span class="fa fa-question-circle icon-help pl-1" (click)="openStageCogsHelp.emit()"></span>
            </div>
            <span *ngIf="stageForm.controls.totalCOGS.value !== null || !job.id" class="unit-value">${{getStageTotalCogs()}}</span>
          </td>
        </tr>
      </ng-template>

    </tbody>
  </table>
</div>