<div class="d-flex pt-4">
  <div class="form-subtitle-section">{{testTableName}}</div>
  <div class="pt-2 pl-2 text-nowrap" style="font-weight: bold;">Slurry #</div>
  <div>
    <p-dropdown
      [formControl]="selectedSlurry"
      (onChange)="changeSlurryNo($event)"
      [options]="availableTestSlurries"
      [disabled]="!canEdit"
      appendTo="body"
      [placeholder]="'\u00A0'"
      baseZIndex="9999">
    </p-dropdown>
  </div>
</div>

<div class="ui-table">
    <table style="font-weight: bold;">
        <thead>
            <tr>
                <th style="width: 10%">Test Name</th>
                <th>Temp</th>
                <th>600<br>(rpm)</th>
                <th>300<br>(rpm)</th>
                <th>200<br>(rpm)</th>
                <th>100<br>(rpm)</th>
                <th>60<br>(rpm)</th>
                <th>30<br>(rpm)</th>
                <th>20<br>(rpm)</th>
                <th>10<br>(rpm)</th>
                <th>6<br>(rpm)</th>
                <th>3<br>(rpm)</th>
                <th>BR<br>Conf</th>
                <th>
                    <div class="d-flex flex-row">
                        <div>PV</div>
                        <div class="ml-3">
                            <more-info-tooltip>
                                <i icon class="fa fa-info-circle" style="color: black;"></i>
                                <div message>Calculation Method: Traditional (300 & 100 RPM based)</div>
                            </more-info-tooltip>
                        </div>
                    </div>
                    <div class="d-flex flex-row">(cP)</div>
                </th>
                <th style="width: 75px;">
                    <div class="d-flex flex-row">
                        <div>Yp</div>
                        <div class="ml-3">
                            <more-info-tooltip>
                                <i icon class="fa fa-info-circle" style="color: black;"></i>
                                <div message>Calculation Method: Traditional (300 & 100 RPM based)</div>
                            </more-info-tooltip>
                        </div>
                    </div>
                    <div class="d-flex flex-row">(lbs/100ft2)</div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let test of tests" class="test-row">

                <td class="p-0">{{test.testName}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">
                    <span [apiValue]="getTemperatureField(test)" [unitType]="UnitType.Temperature" [shouldShowUoM]="getTemperatureField(test)"></span>
                </td>

                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F600)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F300)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F200)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F100)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F60)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F30)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F20)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F10)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F6)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.F3)}}</td>
                <td *ngIf="!isTestFinishedOrValidated(test)" colspan="15">{{test.testStatusDescription}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.BRConf)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, RheologyField.Pv)}}</td>
                <td *ngIf="isTestFinishedOrValidated(test)" class="relative">{{getField(test, RheologyField.Yp)}}
                    <button *ngIf="canEdit" class="btn-delete-test" (click)="deleteTest(test)">
                        <div class="btn-delete-test-icon">
                            <i class="fa fa-times"></i>
                        </div>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>