import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';

import { environment } from 'libs/environment';
import {
  DropListLocation, createEmptyDropListLocation, IUserSettingModel,
  IUserSettingMetadataListModel, IUserSettingPayloadModel, mapGroupRegionByUserModelToDropListLocation, UserSettingContract
} from 'apps/vida/src/modules/application/models';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  selectedLocation: DropListLocation = createEmptyDropListLocation();
  locations: DropListLocation[] = [];
  locationsAll = [];
  userSettings: IUserSettingModel;
  formatDateString: string = 'dd/MM/yyyy';
  formatTimeString: string = 'HH:mm';
  private _showCogs$ = new BehaviorSubject<boolean>(false);
  public readonly showCogs$ = this._showCogs$.asObservable();

  _settingsMetDataCache: Observable<IUserSettingMetadataListModel> = null;

  constructor(private httpClient: HttpClient) { }

  set showCogs(newValue: boolean) {
    if (this._showCogs$.value === newValue) return;

    this.httpClient.put(`${environment.baseUrl}/api/UserSettings/showCogs/${newValue}`, null).subscribe(() => {
      this._showCogs$.next(newValue);
    });
  }

  get showCogs() {
    return this._showCogs$.value;
  }

  getHcToken() {
    return this.httpClient.get<string>(`${environment.baseUrl}/api/tokens/hc-token`).pipe(
      catchError(response => of(response.error.text))
    );
  }

  getLocations(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/API/UserSettings/GroupsByUser`);
  }

  updateLastSelectedGroup(groupId: string) {
    return this.httpClient.put(`${environment.baseUrl}/API/UserSettings/current-group/${groupId}`, JSON.stringify({}));
  }

  updateLastSelectedLab(labIdList: any) {
    return this.httpClient.put(`${environment.baseUrl}/API/UserSettings/current-lab/${labIdList}`, JSON.stringify({}));
  }

  /**
   * Handle User Setting
   */
  getUserSettingMetadata(): Observable<IUserSettingMetadataListModel> {
    if (this._settingsMetDataCache === null) {
      this._settingsMetDataCache = this.httpClient.get<IUserSettingMetadataListModel>(`${environment.baseUrl}/api/UserSettings/MetaData`)
        .pipe(
          publishReplay(1),
          refCount()
        );
    }
    return this._settingsMetDataCache;
  }

  getUserSetting(): Observable<IUserSettingModel> {
    return this.httpClient.get<IUserSettingModel>(`${environment.baseUrl}/api/UserSettings`);
  }

  setUserSettings(settings: IUserSettingPayloadModel): void {
    this.userSettings.language.reportsLanguageId = settings.languageId;
    this.userSettings.paperSize.reportsPaperSizeId = settings.paperSizeId;
  }

  updateUserSettings(settings: UserSettingContract): Observable<any> {
    return this.httpClient.put(`${environment.baseUrl}/api/UserSettings`, settings);
  }

  updateLastSelectedCountry(lastSelectedCountryCode: string) {
    return this.httpClient.put(`${environment.baseUrl}/api/UserSettings/current-group/${lastSelectedCountryCode}`, null).subscribe(
      () => {
          this.userSettings.lastSelectedCountry.lastSelectedCountryId = lastSelectedCountryCode;
      });
  }

  getAllRegionGroups(): Observable<any> {
    return this.httpClient.get(`${environment.baseUrl}/api/admin/all-region-groups`);
  }

  getNetworkId(keyword: string) {
    return this.httpClient.get(`${environment.baseUrl}/api/user/networkId/${keyword}`).pipe(
      map((result: any) => result.networkId)
    );
  }

  refreshLocationList(newLocation) {
    this.locations = mapGroupRegionByUserModelToDropListLocation(newLocation);
    if (this.locations.length > 0) {
      this.selectedLocation = this.locations.find(location => location.lastSelected === true);
    }

    if (!this.selectedLocation) {
      this.selectedLocation = this.locations[0];
    }
  }

  getAllSettings() {
    return this.httpClient.get(`${environment.baseUrl}/api/UserSettings/allsettings`).pipe(
      tap((res: any) => {
        this._showCogs$.next(res.settings.showCogs) ;
      }),
      map((result: any) => result)
    );
  }

  updateAppianBulkBoardSettings(groupId: string) {
    return this.httpClient.put(`${environment.baseUrl}/api/UserSettings/bulk-board/updateAppianSettings/${groupId}`, null)
  }
}
