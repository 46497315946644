import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFACT_CONSTANTS, UnitType } from 'libs/constants';
import { environment } from 'libs/environment';
import { preciseRound } from 'libs/helpers/math';
import { Customer, FluidMaterialModel, FluidModel, IFactsRequest, IFactsRequestInfo, Job, RequestSearch, MaterialSearch, ILab, MaterialLabValueModel } from 'libs/models';
import { MaterialManagementMappingModel } from 'libs/models/material-management/material-management-mapping.model';
import { RequestStatusModel, IFactsCreateResponse } from 'libs/models/entities/request';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { keyBy, merge, deleteNode } from 'libs/helpers/lodash-helper';
import { TreeNode } from 'primeng/api';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { flatMap, map, mergeAll, tap, toArray } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { ApplicationStateService } from './modal-management.service';
import { SAPMaterialMappingService } from './sap-material-mapping.service';

import { ActiveResultsDto } from 'apps/vida/src/modules/ifacts-visualizer/models/dto/active-results-dto.model';
import { IfactsArea } from 'libs/models/ifact/ifacts-area';
import { IFactsUser } from 'libs/models/ifact/ifacts-user';
import { IFactsSearchParams } from 'libs/models/ifact/ifacts-dto/ifacts-search-params'
import { IfactsSearchSummary } from 'libs/models/ifact/ifacts-dto/ifacts-search-summary'
import { IFactsSlurryType } from 'libs/models/ifact/ifacts-slurry-type';
import { IfactsResponseDto } from 'libs/models/ifact/ifacts-dto/ifacts-response-dto.model';
import { IFactsLabReportAttachment } from 'libs/models/entities/ifacts-lab-report-attachment';

@Injectable()
export class IfactService {

    public readonly forceUpdateMapping$ = new Subject<string[]>();

    private readonly EXPIRY_IFACT_REQUEST: number = 5000;

    private readonly EXPIRY_AGE_SAP_MAPPING: number = 28800000;

    private readonly _tapHandleIFact: any;

    private opsMessageUseCache: boolean = false;

    constructor(

        private readonly _httpClient: HttpClient,

        private readonly _appStateService: ApplicationStateService,

        private readonly _cacheService: CacheService,

        private readonly _unitConversionService: UnitConversionService,

        private readonly _sapMaterialMappingService: SAPMaterialMappingService
    ) {
        this._tapHandleIFact = tap(null,
            () => {
                this._appStateService.notifyIFactDown$.next(true);
            }
        );
    }

    public getLabReports(jobId: string, requestIds: string[]): Observable<IFactsLabReportAttachment[]> {
        const url = `${environment.baseUrl}/api/ifacts/labReports/${jobId}`;
        const params = new HttpParams().append('requestids', requestIds.join(','));
        return this._httpClient.get<IFactsLabReportAttachment[]>(url, { params });
    }

    public updateMaterialMapping(materialMappings: any[]) {
        const url = `${environment.baseUrl}/api/ifacts/sap-material-mapping`;
        return this._httpClient.put<any[]>(url, materialMappings);
    }

    public getMaterialMappings(groupId, firstContract, secondContract): Observable<MaterialManagementMappingModel[]> {
        let queryParam = 'group?groupId=' + groupId;
        queryParam += firstContract ? `&firstContractId=${firstContract}` : '';
        queryParam += secondContract ? `&secondContractId=${secondContract}` : '';
        return this._httpClient.get<MaterialManagementMappingModel[]>(`${environment.baseUrl}/api/ifacts/sap-material-mapping/${queryParam}`);
    }
    
    public getMaterialSapCogs(plantCode:string, sapMaterialNumbers: string[]): Observable<any>{
        const sapCodes: string = sapMaterialNumbers.join(',');
        return this._httpClient.get(`${environment.baseUrl}/api/ifacts/sap-cogs-plant-values/${plantCode}?sapCodes=${sapCodes}`);
    }

    public getLabMaterialValues(labId:number, materialIds: string[]): Observable<MaterialLabValueModel[]>{
        const materialIdsParam: string = materialIds.join(',');
        return this._httpClient.get<MaterialLabValueModel[]>(`${environment.baseUrl}/api/ifacts/lab-material-values/${labId}?materialIds=${materialIdsParam}`);
    }
    public getRequestInfo(requestId, shouldCache: boolean): Observable<IFactsRequestInfo> {
        return requestId && shouldCache ? this._cacheService.get<IFactsRequestInfo>(
            `REQUEST_INFO_${requestId}`,
            this._httpClient.get<IFactsRequestInfo>(`${environment.baseUrl}/api/ifacts/request-info/${requestId}`), this.EXPIRY_IFACT_REQUEST
        ).pipe(this._tapHandleIFact) : of(null);
    }

    public getRequestDetail(slurryId): Observable<IFactsRequest> {
        return slurryId ? this._cacheService.get<IFactsRequest>(
            `REQUEST_DETAIL_${slurryId}`,
            this._httpClient.get<IFactsRequest>(`${environment.baseUrl}/api/ifacts/request/${slurryId}`), this.EXPIRY_IFACT_REQUEST
        ).pipe(this._tapHandleIFact) : of(null);
    }

    public checkOpsMessage(requestIds: number[], useCache: boolean = true): Observable<number[]> {
        const payLoad: any = {
            requestIds: requestIds,
            useCache: useCache && this.opsMessageUseCache
        };
        this.opsMessageUseCache = true;
        return this._httpClient.post<number[]>(`${environment.baseUrl}/api/ifacts/check-pending-message`, payLoad);
    }

    public updateFluidMaterials(jobId: string, requestIds: number[]): Observable<any> {
        return this._httpClient.post<any>(`${environment.baseUrl}/api/ifacts/update-fluid-materials/${jobId}`, requestIds);
    }

    public updateUsedOnJob(slurryId: number) {
        return this._httpClient.post<any>(`${environment.baseUrl}/api/ifacts/slurry/${slurryId}/UsedOnJob`, null);
    }

    public updateRequestStatus(slurryId: number) {
        return this._httpClient.post<any>(`${environment.baseUrl}/api/ifacts/slurry/${slurryId}/updateStatusToCompleted`, null);
    }

    public getRequests(fluids: FluidModel[]): Observable<IFactsRequest[]> {

        const fluidsFromIFacts = fluids.filter(f => f.requestId);
        const requestIdSlurryNoList: ReadonlyArray<string> = fluidsFromIFacts.map(f => `${f.requestId}/${f.slurryNo}`).distinct();

        if (requestIdSlurryNoList.length === 0) {
            return of([]);
        }

        // split one http request to several passing up to <packetSize> params per call to not break browser url max size
        const packetSize = 100;
        let i: number = 0;
        let requestIDsPacket: string[];
        let requests: Observable<IFactsRequest[]>[] = [];
        
        while (requestIdSlurryNoList.length - i * packetSize > 0) {
        
            requestIDsPacket = requestIdSlurryNoList.slice(i * packetSize, (i + 1) * packetSize);
            ++i;

            const params = new HttpParams({
                fromObject: {
                    requestIdSlurryNoList: requestIDsPacket
                }
            });

            requests.push(this._httpClient.get<IFactsRequest[]>(`${environment.baseUrl}/api/ifacts/requests`, { params: params})
            .pipe(
                this._tapHandleIFact
            ));
        }

        return forkJoin(requests).pipe(
            map(data => [].concat(...data))
        );
    }

    public getSapMaterialDetails(generalMaterialId: number, groupId: string = null): Observable<any[]> {
        let url = `${environment.baseUrl}/api/ifacts/sap-material/${generalMaterialId}`;
        if (groupId !== null) {
            url += `?groupId=${groupId}`;
        }
        return this._cacheService.get<any[]>(url, this._httpClient.get<any[]>(url), this.EXPIRY_AGE_SAP_MAPPING);
    }

    public updateSapMaterialDetails(generalMaterialId: number, groupId: string): Observable<any[]> {
        let url = `${environment.baseUrl}/api/ifacts/sap-material/${generalMaterialId}`;
        if (groupId !== null) {
            url += `?groupId=${groupId}`;
        }
        return this._httpClient.get<any[]>(url).pipe(
            map(res => {
                this._cacheService.set(url, res, this.EXPIRY_AGE_SAP_MAPPING);
                return res;
            })
        );
    }

    public getCustomerList(): Observable<Customer[]> {
        return this._httpClient.get<Customer[]>(`${environment.baseUrl}/api/ifacts/general-customers`);
    }

    public getRequestTypes(): Observable<any> {
        return of(IFACT_CONSTANTS.REQUEST_TYPES);
    }

    public getCasingSizes(): Observable<any> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/casingsize`);
    }

    public getCasingSizesValue(value): Observable<any> {
        return this.getCasingSizes()
            .pipe(
                map(result => {
                    return result.filter((x: any) =>
                        preciseRound(x.CasingSize, 3) === preciseRound(value, 3)).
                        map(x => x.CasingSizeID)
                        .shift();
                })
            );
    }

    public getHoleSizes(): Observable<any> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/holesize`);
    }

    public getHoleSizeValue(value: number): Observable<any> {
        return this.getHoleSizes().pipe(
            map(result => {
                return result.filter((x: any) => 
                    preciseRound(x.HoleSize, 3) === preciseRound(value, 3)).
                    map(x => x.HoleSizeID).shift();
            })
        );
    }

    public getJobTypeValue(labId, jobType) {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/jobtypes?labId=${labId}`).pipe(
            map(result => {
                return result.filter((x: any) => x.JobType.toLowerCase() === jobType.toLowerCase()).
                    map(x => x.JobTypeId).shift();
            })
        );
    }

    public createRequestWithGenMat(data): Observable<IFactsCreateResponse> {
        const isFixed = this._validateAndFixIFactsRequest(data);
        return isFixed ? this._httpClient.post<IFactsCreateResponse>(`${environment.baseUrl}/api/ifacts/CreateWithGenMat`, data): of(null);
    }

    public getCustomers(areaId: number): Observable<Customer[]> {
        return this._httpClient.get<Customer[]>(`${environment.baseUrl}/api/ifacts/customers?areaId=${areaId}`);
    }

    public getSlurryTypes(): Observable<IFactsSlurryType[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/slurrytypes`);
    }

    public getAreas(): Observable<IfactsArea[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/areas`);
    }

    public getLabs(): Observable<ILab[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/labs`);
    }

    public getRigs(areaId): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/rigs?areaId=${areaId}`);
    }

    public getRigsValue(areaId, rigValue) {
        return this.getRigs(areaId).pipe(
            tap(x => console.log(rigValue)),
            map(rigs => {
                return rigs.filter((x: any) => {
                    return x.RigName.toLowerCase() === rigValue.toLowerCase();
                }).map(x => x.RigId)
                    .shift();
            })
        );
    }

    public getWells(areaId): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/wells?areaId=${areaId}`);
    }

    public getBlends(areaId): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/blends?areaId=${areaId}`);
    }

    public getUsers(networkId: string): Observable<IFactsUser[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/users/${networkId}`);
    }

    public getIfactsVisualizerSummary(model: IFactsSearchParams): Observable<IfactsSearchSummary> {
        return this._httpClient.post<IfactsSearchSummary>(`${environment.baseUrl}/api/ifacts/VisualizerSearch`, model);
    }

    public getIfactsVisualizerData(key: string, startIndex: number, chunkSize: number): Observable<IfactsResponseDto[]> {
        let params = new HttpParams();
        params = params
            .append('StartIndex', startIndex.toString())
            .append('ChunkSize', chunkSize.toString());
        return this._httpClient.get<IfactsResponseDto[]>(`${environment.baseUrl}/api/ifacts/VisualizerSearchData/${key}`, { params });
    }

    public getVisualizerSpreadsheetUrl(activeResults: ActiveResultsDto): Observable<{ url: string, fileName: string }> {

        return this._httpClient.post<{ url: string, fileName: string }>(
            `${environment.baseUrl}/api/ifacts/visualizer-spreadsheet`,
            activeResults
        );
    }

    public getIfactSearchResult(data, testField) {

        testField.map(testData => {
            if (!IFACT_CONSTANTS.MISSING_TEST_WRAPPER[testData.testName].hasOwnProperty(testData.testField)) {
                IFACT_CONSTANTS.MISSING_TEST_WRAPPER[testData.testName].push({ data: { name: testData.testField } });
            }
        });
        let params = new HttpParams();
        params = params.append('Header[CustomerId]', data.Header.CustomerId ? this._mapIdFieldToSearch(data.Header.CustomerId.map((x: any) => x.value)) : '')
            .append('Header[UsedFor]', data.Header.UsedFor)
            .append('Header[JobTypeID]', data.Header.JobTypeID ? data.Header.JobTypeID : '')
            .append('Header[LabID]', data.Header.LabID ? this._mapIdFieldToSearch(data.Header.LabID.map((x: any) => x.value)) : '')
            .append('Header[RequestedDateFrom]', data.Header.RequestedDateFrom)
            .append('Header[RequestedDateTo]', data.Header.RequestedDateTo)
            .append('Header[RequestTypeId]', data.Header.RequestTypeId)
            .append('Header[RequestStatus]', data.Header.RequestStatusId)
            // Round density values regarding Partha's update
            .append('Slurry[DensitySGFrom]', (Math.floor(data.Slurry.DensitySGFrom * 100000) / 100000).toString())
            .append('Slurry[DensitySGTo]', (Math.ceil(data.Slurry.DensitySGTo * 100000) / 100000).toString())
            .append('Slurry[UsedOnJob]', data.Slurry.UsedOnJob)
            .append('TestCondition[BhstFrom]', data.TestCondition.BhstFrom)
            .append('TestCondition[BhstTo]', data.TestCondition.BhstTo)
            .append('TestCondition[BhctFrom]', data.TestCondition.BhctFrom)
            .append('TestCondition[BhctTo]', data.TestCondition.BhctTo)
            .append('TestCondition[MdFrom]', data.TestCondition.MdFrom ? data.TestCondition.MdFrom : '')
            .append('TestCondition[MdTo]', data.TestCondition.MdTo ? data.TestCondition.MdTo : '')
            .append('TestCondition[TvdFrom]', data.TestCondition.TvdFrom ? data.TestCondition.TvdFrom : '')
            .append('TestCondition[TvdTo]', data.TestCondition.TvdTo ? data.TestCondition.TvdTo : '');

        return this._httpClient.get(`${environment.baseUrl}/api/ifacts/BasicSearch`, { params }).pipe(
            flatMap((item: any) => item),
            map((item: any) => {
                return item.SlurryHeader.map(slurry => {
                    return { ...slurry, Header: item.Header };
                });
            }),
            mergeAll(),
            toArray(),
            map((request: any) => {
                return request.sort((a, b) => {
                    if (a.Header.RequestID < b.Header.RequestID) return 1;
                    if (a.Header.RequestID > b.Header.RequestID) return -1;
                    return 0;
                });
            }),
            map(dataFromIfact => {
                if (dataFromIfact && dataFromIfact.length) {
                    const result = this._iFactsDataSearch(dataFromIfact, testField);
                    const col = result.row && result.row.length ? result.col : [];
                    const row = <TreeNode[]>result.row;
                    return { col, row };
                } else {
                    return { col: [], row: [] };
                }
            })
        );
    }

    public getRequestStatuses(): Observable<RequestStatusModel[]> {
        return this._httpClient.get<any[]>(`${environment.baseUrl}/api/ifacts/requeststatus`);
    }

    public getIFactDataBaseOnVIDA = (job: Job, metaData, fluidId: string) => {
        const jobMapping = { ...job };
        return forkJoin(
            this.getHoleSizeValue(jobMapping.holeSizeValue),
            this.getCasingSizesValue(jobMapping.casingSizeValue),
            this.getRigsValue(metaData.areaId, jobMapping.rigName),
            this._getWellsValue(metaData.areaId, jobMapping.wellName),
            this.getJobTypeValue(metaData.labId, jobMapping.jobTypeName),
            this._sapMaterialMappingService.getWaterMaterialId(fluidId),
        ).pipe(
            map(data => this._mappingDataIfact(data, jobMapping)),
            map(mappingDataIfact => this._fillMetaData(metaData, mappingDataIfact))
        );
    }

    public moveSuplementalToAdditives(fluid: any) {
        if (fluid.supplementalMaterial) {
            fluid.supplementalMaterial.forEach(sm => {
                sm.materialTypeId = null;
                sm.materialType = null;
                fluid.fluidAdditiveMaterial.push(sm);
            });
            fluid.supplementalMaterial = []; // clear suplemental materials - they are transfered to additives
        }

        fluid.fluidMaterial.forEach((fm: FluidMaterialModel) => {
            if (fm.materialType === 'Supplemental') {
                fm.materialTypeId = null;
                fm.materialType = null;
            }
        });
    }

    reorderFluidMaterialsCollection(fluidMaterial: FluidMaterialModel[]) {
        let order = 1;
        let mixWaterOrder = 0;
        fluidMaterial.forEach((fm: FluidMaterialModel) => {
            if (fm.materialType === 'Cement') fm.order = 0;
            else if (fm.materialType === 'Mix Water') fm.order = 100 + mixWaterOrder++;
            else {
                fm.order = order++;
            }
        });

        fluidMaterial.sort((fm1: FluidMaterialModel, fm2: FluidMaterialModel) => fm1.order - fm2.order);
    }

    private _validateAndFixIFactsRequest(data): boolean {
        const additives = data.Slurry.Additives;
        if (additives) {
            for (const additive of additives) {
                const cu = (additive.ConcentrationUnit || '').toLowerCase().replace(/\s+/g, '');
                if (!(cu in IFACT_CONSTANTS.UNITS_OF_MEASURE)) {
                    return false;
                }
                additive.ConcentrationUnit = IFACT_CONSTANTS.UNITS_OF_MEASURE[cu].iFactsCode;
            }
        }
        return true;
    }

    private _getWellsValue(areaId, wellValue) {
        return this.getWells(areaId)
            .pipe(
                map(wells => {
                    return wells.filter((well: any) => well.WellName.toLowerCase() === wellValue.toLowerCase()).
                        map((well: any) => well.WellId).shift();
                })
            );
    }

    private _mapIdFieldToSearch(data: number[]): string {
        return (data || '').toString();
    }

    private _filterByTest(testName: string): boolean {

        const testCase = [
            'Thickening Time',
            'Thickening Time - Aged Mix Fluid',
            'Thickening Time - contaminated',
            'Thickening Time - Hesitation',
            'Thickening Time - ON-OFF-ON',
            'Thickening Time - Thixotropic Cup',
            'API Fluid Loss',
            'Stirring Fluid Loss',
            'API Rheology',
            'Non-API Rheology',
            'Free Fluid API 10A',
            'Free Fluid API 10B-2',
            'UCA Comp. Strength'
        ];

        return testCase.includes(testName.trim());
    }

    private _iFactsDataSearch(dataArray, testField): { col: string[], row: any[] } {
        if (!dataArray.length) {
            return { col: [], row: [] };
        }
        const filteredData = this._filterData(dataArray, testField);
        const santinezerData = this._removeDuplicate(filteredData.row);
        return { col: filteredData.col, row: santinezerData };
    }

    private _filterData(dataSearch, testField): { col: string[], row: any[] } {
        const globalStore = new Map();
        const col = [];
        dataSearch.map(testData => {

            const material = new Set();

            if (testData.Fluid && testData.Fluid.length) {
                testData.Fluid.map(fluid => {
                    const concentration = `${fluid.Concentration ? fluid.Concentration : ''} ${fluid.ConcentrationUnit}`;
                    material.add(`${fluid.MaterialName} ${concentration}`);
                });
            }

            const materialList = [Array.from(material).join('<br/>')]
                .map(data => {
                    return { Concentration: data };
                });

            const nameMapping = [
                { UsedFor: 'Used for' },
                { PressurePsi: 'Pressure' },
                { BHCTF: 'BHCT' },
                { BHSTF: 'BHST' },
                { SG: 'Density' },
                ...materialList
            ];

            const headerData = testData.Header;
            const colName = `${headerData.RequestID}-${testData.SlurryID}-${testData.SlurryNumber}-${testData.UsedOnJob}-${this._slurryTypeDecision(headerData.UsedFor, testData.SlurryType)}-${headerData.RequestStatus}-${headerData.RequestType}`;
            const rowHeader = this._customizeHeaderData(testData.Header, colName);
            // Add SG to Header due to change mapping request
            testData.Header.SG = testData.SG;
            const rowUsedFor = this._customizeUsedFor(testData.Header, colName, nameMapping);
            // Remove duplicate test group and take first one
            const takeFirstTestGroup = new Map();
            let rowTest = testData.Tests.filter(test => test.TestName)
                .map(test => {
                    if (!takeFirstTestGroup.has(test.TestName)) {
                        takeFirstTestGroup.set(test.TestName, test);
                    }
                });
            if (takeFirstTestGroup.size > 0) {
                rowTest = Array.from(takeFirstTestGroup.values())
                    .map(test => {
                        const data = { name: test.TestName };
                        const children = test.TestData.map(testUnit => this._buildChildrenData(testUnit, data.name, colName, testField));
                        return { data, children };
                    });
            } else {
                rowTest = [
                    {
                        children: [{ data: { name: 'Temp', [colName]: '' } }],
                        data: { name: 'Thickening Time' }
                    }
                ];
            }

            if (rowTest && rowTest.length) {
                col.push(colName);
                if (globalStore.size === 0) {
                    globalStore.set('customer', rowHeader);
                    globalStore.set('recipe', rowUsedFor);
                    rowTest.filter(test => this._filterByTest(test.data && test.data.name)).map(rowTestData => {
                        globalStore.set(rowTestData.data.name, rowTestData.children);
                    });
                } else {
                    const headerStored = globalStore.get('customer');
                    headerStored.data[colName] = rowHeader.data[colName];
                    const mergedHeaderChildren = this._mergerArrayOfObjectByKey(rowHeader.children, headerStored.children);
                    headerStored.children = mergedHeaderChildren;
                    globalStore.set('customer', headerStored);


                    const recipeStored = globalStore.get('recipe');
                    const mergedRecipe = this._mergerArrayOfObjectByKey(rowUsedFor.children, recipeStored.children);
                    recipeStored.children = mergedRecipe;
                    globalStore.set('recipe', recipeStored);

                    let dataStored = null;
                    rowTest.filter(test => this._filterByTest(test.data && test.data.name)).map(rowTestData => {
                        if (globalStore.has(rowTestData.data.name)) {
                            dataStored = globalStore.get(rowTestData.data.name);
                            const mergedArray = this._mergerArrayOfObjectByKey(rowTestData.children, dataStored);
                            globalStore.set(rowTestData.data.name, mergedArray);
                        } else {
                            globalStore.set(rowTestData.data.name, rowTestData.children);
                        }
                    });
                }
            }

        });

        Object.keys(IFACT_CONSTANTS.MISSING_TEST_WRAPPER).map(key => {
            if (!globalStore.has(key))
                globalStore.set(key, IFACT_CONSTANTS.MISSING_TEST_WRAPPER[key]);
        });

        return { col: col, row: Array.from(globalStore) };
    }

    private _removeDuplicate(filteredResult) {
        const treeStruct = {};

        const result = filteredResult.map(row => {
            if (this._filterByTest(row[0])) {
                const wrapper = this._wrapParentForTest(row[0]);
                const treeRow = { data: { name: row[0] }, children: row[1] };

                if (treeStruct.hasOwnProperty(wrapper.data.name)) {
                    treeStruct[wrapper.data.name].children.push(treeRow);
                } else {
                    treeStruct[wrapper.data.name] = { data: { name: wrapper.data.name }, children: [treeRow] };
                }
            } else {
                row = row[1];
                return row;
            }
        }).filter(e => e);
        const wrapperObj = IFACT_CONSTANTS.WRAPPER_OBJ;
        const addTestNotExists = Object.entries(wrapperObj).map(([key, value]) => {
            const keyWrapperObj = key;
            if (key.includes('Thickening')) {
                key = 'Thickening Time (TT)';
                // Rename Thickening Time  => TT
                if (treeStruct[key] && treeStruct[key].children) {
                    const childrenTmp = treeStruct[key].children.map(thickeningTime => {
                        if (thickeningTime.data.name === 'Thickening Time - ON-OFF-ON') {
                            thickeningTime.data.name = thickeningTime.data.name.replace('Thickening Time - ON-OFF-ON', 'TT On-Off-On');
                        } else {
                            thickeningTime.data.name = thickeningTime.data.name.replace('Thickening Time - ', 'TT-');
                        }
                        return thickeningTime;
                    });
                    treeStruct[key].children = childrenTmp;
                }
            }
            if (!treeStruct.hasOwnProperty(key)) {
                value = wrapperObj[keyWrapperObj];
            } else {
                if (key.includes('Free Fluid')) {
                    const childrenTmp = treeStruct[key].children.map(thickeningTime => {
                        if (thickeningTime.data.name.trim() === 'Free Fluid API 10B-2') {
                            thickeningTime.data.name = thickeningTime.data.name.replace('Free Fluid API 10B-2', 'API 10B-2');
                        } else if (thickeningTime.data.name.trim() === 'Free Fluid API 10A') {
                            thickeningTime.data.name = thickeningTime.data.name.replace('Free Fluid API 10A', 'API 10A');
                        }
                        return thickeningTime;
                    });
                    treeStruct[key].children = childrenTmp;
                }
                const merge = this._mergerArrayOfObjectByKey(wrapperObj[keyWrapperObj].children, treeStruct[key].children);
                treeStruct[key].children = merge;
                value = treeStruct[key];
            }
            return [key, value];
        })
            .filter(data => data && data.length)
            .reduce((obj, [dKey, dValue]) => Object.assign(obj, { [dKey.toString()]: dValue }), {});
        return [...result, ...Object.values(addTestNotExists)];
    }

    private _wrapParentForTest(testName) {
        const key = Object.keys(IFACT_CONSTANTS.WRAPPER_OBJ).find(keyWrapper => testName.includes(keyWrapper));
        const wrapper = IFACT_CONSTANTS.WRAPPER_OBJ[key];

        // remove the parent property as it causes the stack overflow exception
        deleteNode(wrapper, 'parent');
        
        return wrapper;
    }

    private _customizeHeaderData(header, identifyName) {
        const customize = { data: {}, children: [] };
        const parentData = { name: 'Customer' };
        parentData[identifyName] = header.CustomerName || '';
        const nameMapping = [{ JobTypeName: 'Job Type' }, { WellName: 'Well Name' }, { RigName: 'Rig Name' }, { PlantName: 'Plant Name' }];
        const children = nameMapping.map(name => {
            const childrenEl = { data: { name: Object.values(name).shift() } };
            childrenEl.data[identifyName] = header[Object.keys(name).shift()];
            return childrenEl;
        });
        customize.data = parentData;
        customize.children = children;
        return customize;
    }

    private _customizeUsedFor(header, identifyName, nameMapping) {
        const usedFor = { data: {}, children: [] };
        const parentData = { name: 'Recipe' };
        const children = nameMapping.map(name => {
            const keyValue = Object.entries(name)[0];
            const keyTemp = keyValue[1].toString();
            const valueTemp = keyValue[0].toString();
            let childrenEl = { data: { name: keyTemp } };
            if (valueTemp === 'Concentration') {
                childrenEl = { data: { name: 'Materials and Quantities' } };
                childrenEl.data[identifyName] = keyValue[1];
            } else {
                let headerValue = header[valueTemp];
                if (keyTemp === 'Density') {
                    headerValue = this._convertToUserUnit(headerValue, UnitType.Density);
                } else if (keyTemp === 'Pressure') {
                    headerValue = this._convertToUserUnit(headerValue, UnitType.Pressure);
                } else if (keyTemp === 'BHCT' || keyTemp === 'BHST') {
                    headerValue = this._convertToUserUnit(headerValue, UnitType.Temperature);
                }
                childrenEl.data[identifyName] = headerValue;
            }
            return childrenEl;
        });
        usedFor.data = parentData;
        usedFor.children = children;
        return usedFor;
    }

    private _buildChildrenData(data, testName, identifyName, testField) {
        const el = { name: '' };
        const splitName = data.TestFieldName.split('(').shift();
        el.name = splitName;
        let unit = null;
        const convertDecision = testField.filter(testFieldFind => testFieldFind.dynamicUOM)
            .find(testFieldFind => testFieldFind.testField.trim() === data.TestFieldName.trim() && testFieldFind.testName.trim() === testName.trim());
        if (convertDecision && convertDecision.dynamicUOM.trim() === 'Temperature') {
            unit = UnitType.Temperature;
        } else if (convertDecision && convertDecision.dynamicUOM.trim() === 'Pressure') {
            unit = UnitType.Pressure;
        }
        el[identifyName] = this._convertToUserUnit(data.Result, unit);
        return { data: el };
    }

    private _mergerArrayOfObjectByKey(arr1, arr2) {
        let mergedArray = merge(keyBy(arr1, 'data.name'), keyBy(arr2, 'data.name'));
        mergedArray = Object.values(mergedArray);
        return mergedArray;
    }

    private _convertToUserUnit(value: number, unitType: UnitType) {
        if (!value || !unitType) {
            return value || '';
        }
        const userUnits = this._unitConversionService.userUnits;
        const destinationUnit = userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === unitType).unitMeasure.name;
        const unitTemp = userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.Temperature).unitMeasure.name;
        const unitDensity = userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.Density).unitMeasure.name;
        const unitPsi = userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === UnitType.Pressure).unitMeasure.name;

        if (unitType && ((unitTemp.trim() === '°C' && unitType === UnitType.Temperature)
            || (unitPsi.trim() != 'psi' && unitType === UnitType.Pressure)
        )) {
            value = this._unitConversionService.convertFromApiToCurrentUnit(value, unitType);
        } else if (unitType && (unitDensity.trim() === 'kg/m3' || unitDensity.trim() === 'ppg') && unitType === UnitType.Density) {
            // sg => ppg = x8.33 / sg => kg/m3 = x1000
            if (unitDensity.trim() === 'kg/m3')
                value = value * 1000;
            else
                value = this._unitConversionService.convertFromSiToCurrentUnit(value, unitType);
        }
        return `${value} ${destinationUnit}`;
    }

    private _slurryTypeDecision(usedFor, slurryType) {
        if (usedFor === 'Cement') {
            return `${slurryType}-1`;
        }
        return 'Spacer-2';
    }

    private _mappingDataIfact = (data, jobMapping) => {
        const job = { ...jobMapping };
        const [holeSizeValue, casingSizeValue, rig, wellId, jobType, water] = data;
        return Object.assign(job, { holeSizeValue, casingSizeValue, rig, wellId, jobType }, { water });
    }

    private _fillMetaData = (metaData, jobMapping) => {
        const { lab, usedFor, jobType, user } = metaData;
        const job = { ...jobMapping };
        job.lab = lab;
        job.usedFor = usedFor;
        job.user = user;
        return job;
    }
}
