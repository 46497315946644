import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CPESharedModule } from '../shared/shared.module';
import { UIModule } from 'libs/ui';
import {
  FluidContainerComponent,
  FluidDetailComponent,
  FluidDetailInfoComponent,
  FluidMaterialsComponent,
  FluidRequestInfoComponent,
  FluidFormFactory
} from './components';
import { VIDASharedModule } from 'libs/shared/vida-shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TabViewModule } from 'primeng/tabview';

const thirdPartyModules = [
  CommonModule,
  FormsModule,
  TabViewModule,
  AutoCompleteModule,
  InputMaskModule,
  OverlayPanelModule,
  CheckboxModule,
  TooltipModule
];

const services = [
  FluidFormFactory
];

const adapters = [
];

const components = [
  FluidContainerComponent,
  FluidDetailComponent,
  FluidDetailInfoComponent,
  FluidMaterialsComponent,
  FluidRequestInfoComponent
];

@NgModule({
  imports: [
    ...thirdPartyModules,
    UIModule,
    CPESharedModule,
    VIDASharedModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ],
  providers: [
  ]
})
export class FluidsModule {
  static forRoot(): ModuleWithProviders<FluidsModule> {
    return {
      ngModule: FluidsModule,
      providers: [
        ...services,
        ...adapters
      ]
    };
  }
}
