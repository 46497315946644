import {Directive, ElementRef, HostListener, OnDestroy, OnInit} from '@angular/core';
import {RoleService, UserSettingService} from 'libs/shared';
import {environment} from 'libs/environment';
import {map, switchMap, take} from 'rxjs/operators';
import {RoleLevels} from 'apps/vida/src/modules/application/models';
import {Subscription} from "rxjs";

@Directive({
  selector: '[openBulkBoard]'
})
export class OpenBulkBoardDirective implements OnInit, OnDestroy {
  appianBulkBoardPageLink = environment.appianBulkBoardPageLink;
  isAllowed: boolean;
  subscription: Subscription;

  constructor( private userSettingService: UserSettingService,
               private elem: ElementRef,
               private roleService: RoleService
               ) {
    this.isAllowed = !this.roleService.isBasicForCurrentGroup;
  }

  ngOnInit() {
  this.subscription =  this.roleService.roles$
      .pipe(
        map(roles => roles.filter(role => role.groupId === this.userSettingService.selectedLocation.groupId)),
        map(roles => roles.some(role => role.level >= RoleLevels.Enhanced)))
      .subscribe(isAllowed => {
        this.elem.nativeElement.style.cursor =  isAllowed ? 'pointer' : 'default';
        this.isAllowed = isAllowed;
        }
      );
  }

  @HostListener('click', ['$event']) onClick() {
    if (!this.isAllowed) return;
    this.userSettingService.updateAppianBulkBoardSettings(this.userSettingService.selectedLocation.groupId)
      .pipe(take(1))
      .subscribe( _ =>  window.open(this.appianBulkBoardPageLink, 'appianPageOpen'));
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }
}
