export class TimeFormatter {

    public static msToTimeString(ms: any): string {

        if (!ms) {
            return '00:00'; 
        }

        const sec = parseInt(ms, 10) / 1000;
        const hours = Math.floor(sec / 3600);
        const minutes = Math.floor(sec / 60) % 60;
        
        return [hours, minutes].map(v => v < 10 ? '0' + v : v).join(':');
    }
}