import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { IconRegistry } from './icon-registry.service';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'svg-icon',
  exportAs: 'svgIcon',
  styles: [`
    ::ng-deep svg {
        width: 24px;
        height: 24px;
      }

  `],
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {
  private _icon: string;
  private _currentIconFetch = Subscription.EMPTY;

  @Input()
  get icon() { return this._icon; }
  set icon(value: string) {
    if (value !== this._icon) {
      if (value) {
        this._updateSvgIcon(value);
      } else {
        this._clearSvgElement();
      }
      this._icon = value;
    }
  }

  constructor(private elementRef: ElementRef,
              private _iconRegistry: IconRegistry
              ) {
  }


  private _setSvgElement(svg: SVGElement) {
    this._clearSvgElement();

    // Workaround for IE11 and Edge ignoring `style` tags inside dynamically-created SVGs.
    // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/10898469/
    // Do this before inserting the element into the DOM, in order to avoid a style recalculation.
    const styleTags = svg.querySelectorAll('style') as NodeListOf<HTMLStyleElement>;

    for (let i = 0; i < styleTags.length; i++) {
      styleTags[i].textContent += ' ';
    }

    this.elementRef.nativeElement.appendChild(svg);
  }

  private _clearSvgElement() {
    const layoutElement: HTMLElement = this.elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        layoutElement.removeChild(child);
      }
    }
  }

  private _updateSvgIcon(iconName: string|undefined) {
    this._currentIconFetch.unsubscribe();
    if (iconName) {

      this._currentIconFetch = this._iconRegistry.getNamedSvgIcon(iconName)
        .pipe(take(1))
        .subscribe(svg => this._setSvgElement(svg));
    }
  }
}
