import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class ContractService {

  constructor(private http: HttpService) {
  }

  getContract(groupId: string, status: number ): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/contract/${groupId}/${status}`);
  }

}