
export const CDIQuestionNames = {
  UseOfBottomPlug: 'Use of Bottom Plug',
  UseOfTopPlug: 'Use of Top Plug',
  SpacerContactTime: "Spacer Contact Time",
  SpacerEfficiency: "Spacer Efficiency",
  MudRemoval: "Mud Removal",
  HydrostaticVsPore: "Minimum Hydrostatic Pressure vs Pore Pressure",
  CasingStretch: "Casing Stretch",
  EcdVsFracture: "Maximum ECD vs Fracture Gradient",
  FlowPotentialFactor: "Flow Potential Factor",
  CementQuality: "Cement Quality",
  ShoeTrackLength: "Shoe Track Length Evaluation",
  RheologicalHierarchy: "Rheological Hierarchy",
  DensityHierarchy: "Density Hierarchy",
  TemperatureProfile: "Temperature Profile",
  StandoffProfile: "Standoff Profile",
  UseOfRotation: "Use of Rotation or Reciprocation",
  Eccentricity: "Eccentricity",
  DisplacementEfficiency: "Displacement Efficiency",
}

export const CDIQuestionTooltipMapping = {
  [CDIQuestionNames.UseOfBottomPlug]: 'Changes to the number of plugs should be made in iCem instead of VIDA to maximize impact on the Barrier Advisor Score.',
  [CDIQuestionNames.UseOfTopPlug]: 'The top plug must be set in iCem to be considered for this question.',
}

export const CDIEvaluationResultClasses = {
  NotEvaluated: 'scenario-not-evaluated',
  NotMet: 'scenario-not-met',
  Met: 'scenario-met',
}

export const CDIFieldType = {
  DecimalInput: 'DecimalInput',
  StringInput: 'StringInput',
  DropdownInput: 'StringDropdown',
}

export const CDIFieldTypeMapping = {
  [CDIFieldType.DecimalInput]: 'decimalValue',
  [CDIFieldType.StringInput]: 'stringValue',
  [CDIFieldType.DropdownInput]: 'stringValue'
}

export const CDIFieldNames = {
  RatholeCurrent: 'RatholeCurrent',
  RatholeRecommended: 'RatholeRecommended',
  CasingStretchCalculated: 'CasingStretchCalculated',
  RatholeScenario: 'RatholeScenario',
  NumberOfBottomPlugsCurrent: 'NumberOfBottomPlugsCurrent',
  NumberOfBottomPlugsRecommended: 'NumberOfBottomPlugsRecommended',
  NumberOfBottomPlugsScenario: 'NumberOfBottomPlugsScenario',
  NumberOfTopPlugsCurrent: 'NumberOfTopPlugsCurrent',
  NumberOfTopPlugsRecommended: 'NumberOfTopPlugsRecommended',
  NumberOfTopPlugsScenario: 'NumberOfTopPlugsScenario',
  SpacerContactTimeCurrent: 'SpacerContactTimeCurrent',
  SpacerContactTimeRecommended: 'SpacerContactTimeRecommended',
  SpacerContactTimeScenario: 'SpacerContactTimeScenario',
  CurrentErrodibility: 'CurrentErodibility',
  ScenarioErrodibility: 'ScenarioErodibility',
  RecommendedErrodibility: 'RecommendedErodibility',
  CurrentGradientDifference: 'CurrentGradientDifference',
  RecommendedDifference: 'RecommendedDifference',
  ScenarioDifference: 'ScenarioDifference',
  SpacerDisplacementEfficiencyCurrent: 'SpacerDisplacementEfficiencyCurrent',
  SpacerDisplacementEfficiencyRecommended: 'SpacerDisplacementEfficiencyRecommended',
  SpacerDisplacementEfficiencyScenario: 'SpacerDisplacementEfficiencyScenario',
  CementCurrentEfficiency: 'CementCurrentEfficiency',
  CementRecommendedEfficiency: 'CementRecommendedEfficiency',
  CementScenarioEfficiency: 'CementScenarioEfficiency',
  CurrentDensityGradientDifference: 'CurrentDensityGradientDifference',
  RecommendedDensityGradientDifference: 'RecommendedDensityGradientDifference',
  ScenarioDensityGradientDifference: 'ScenarioDensityGradientDifference',
  CurrentFlowPotentialFactor: 'CurrentFlowPotentialFactor',
  RecommendedFlowPotentialFactor: 'RecommendedFlowPotentialFactor',
  ScenarioFlowPotentialFactor: 'ScenarioFlowPotentialFactor',
  CurrentStandoffValue: 'CurrentStandoffValue',
  RecommendedStandoffValue: 'RecommendedStandoffValue',
  ScenarioStandoffValue: 'ScenarioStandoffValue',
  CurrentEcdVsFracture: 'CurrentEcdVsFracture',
  RecommendedEcdVsFracture: 'RecommendedEcdVsFracture',
  ScenarioEcdVsFracture: 'ScenarioEcdVsFracture',
  EccentricityScenario: 'EccentricityScenario',
  RotationReciprocationScenario: 'RotationReciprocationScenario',
  CurrentRheologicalGradientDifference: 'CurrentRheologicalGradientDifference',
  RecommendedRheologicalGradientDifference: 'RecommendedRheologicalGradientDifference',
  ScenarioRheologicalGradientDifference: 'ScenarioRheologicalGradientDifference',
  CurrentShoeTrackLength: 'CurrentShoeTrackLength',
  RecommendedShoeTrackLength: 'RecommendedShoeTrackLength',
  CalculatedMudcakeLength: 'CalculatedMudcakeLength',
  ScenarioShoeTrackLength: 'ScenarioShoeTrackLength',
  CurrentTempProfile: 'CurrentTempProfile',
  RecommendedTempProfile: 'RecommendedTempProfile',
  ScenarioTempProfile: 'ScenarioTempProfile',
}

export const CDIFieldpKeyFilterMapping = {
  [CDIFieldNames.RatholeCurrent]: 'num',
  [CDIFieldNames.RatholeRecommended]: 'num',
  [CDIFieldNames.CasingStretchCalculated]: 'num',
  [CDIFieldNames.RatholeScenario]: 'pnum',
  [CDIFieldNames.NumberOfBottomPlugsCurrent]: 'pint',
  [CDIFieldNames.NumberOfBottomPlugsRecommended]: 'pint',
  [CDIFieldNames.NumberOfBottomPlugsScenario]: 'pint',
  [CDIFieldNames.NumberOfTopPlugsCurrent]: 'pint',
  [CDIFieldNames.NumberOfTopPlugsRecommended]: 'pint',
  [CDIFieldNames.NumberOfTopPlugsScenario]: 'pint',
  [CDIFieldNames.SpacerContactTimeCurrent]: 'pnum',
  [CDIFieldNames.SpacerContactTimeRecommended]: 'pnum',
  [CDIFieldNames.SpacerContactTimeScenario]: 'pnum',
  [CDIFieldNames.ScenarioErrodibility]: 'pint',
  [CDIFieldNames.RecommendedErrodibility]: 'pint',
  [CDIFieldNames.CurrentErrodibility]: 'pint',
  [CDIFieldNames.CurrentGradientDifference]: 'num',
  [CDIFieldNames.RecommendedDifference]: 'num',
  [CDIFieldNames.ScenarioDifference]: 'num',
  [CDIFieldNames.SpacerDisplacementEfficiencyCurrent]: 'pnum',
  [CDIFieldNames.SpacerDisplacementEfficiencyRecommended]: 'pnum',
  [CDIFieldNames.SpacerDisplacementEfficiencyScenario]: 'pnum',
  [CDIFieldNames.CementCurrentEfficiency]: 'pnum',
  [CDIFieldNames.CementRecommendedEfficiency]: 'pnum',
  [CDIFieldNames.CementScenarioEfficiency]: 'pnum',
  [CDIFieldNames.CurrentDensityGradientDifference]: 'num',
  [CDIFieldNames.RecommendedDensityGradientDifference]: 'num',
  [CDIFieldNames.ScenarioDensityGradientDifference]: 'num',
  [CDIFieldNames.CurrentFlowPotentialFactor]: 'num',
  [CDIFieldNames.ScenarioFlowPotentialFactor]: 'pnum',
  [CDIFieldNames.CurrentStandoffValue]: 'num',
  [CDIFieldNames.RecommendedStandoffValue]: 'num',
  [CDIFieldNames.ScenarioStandoffValue]: 'pnum',
  [CDIFieldNames.CurrentEcdVsFracture]: 'num',
  [CDIFieldNames.RecommendedEcdVsFracture]: 'num',
  [CDIFieldNames.ScenarioEcdVsFracture]: 'num',
  [CDIFieldNames.CurrentRheologicalGradientDifference]: 'num',
  [CDIFieldNames.RecommendedRheologicalGradientDifference]: 'num',
  [CDIFieldNames.ScenarioRheologicalGradientDifference]: 'num',
  [CDIFieldNames.CurrentShoeTrackLength]: 'num',
  [CDIFieldNames.RecommendedShoeTrackLength]: 'num',
  [CDIFieldNames.CalculatedMudcakeLength]: 'num',
  [CDIFieldNames.ScenarioShoeTrackLength]: 'num',
};
