import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'libs/environment';
import { Plant } from 'libs/models/ifact/plant.model';

@Injectable()
export class SAPMaterialMappingService {

  constructor(private httpClient: HttpClient) { }

  getWaterMaterialId(fluidId: string): Observable<number> {
    return this.httpClient.get<any>(`${environment.baseUrl}/api/sap-material-mapping/water-material-by-fluid/${fluidId}`);
  }

  getIFactMaterialBySAPNumber(groupId: string, sapNumber: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.baseUrl}/api/sap-material-mapping/ifact-material-by-sap-number?groupId=${groupId}&sapNumber=${sapNumber}`);
  }

  searchPlant(query: string): Observable<Plant[]> {
    if(!query || query.trim().length === 0) return of([]);

    query = encodeURIComponent(query);
    return this.httpClient.get<Plant[]>(`${environment.baseUrl}/api/sap-material-mapping/search-plant/${query}`);
  }

  searchPlantByCode(code: string): Observable<Plant> {
    if(!code || code.trim().length === 0) return of(null);
    return this.httpClient.get<Plant>(`${environment.baseUrl}/api/sap-material-mapping/search-plant-by-code/${encodeURIComponent(code)}`);
  }

}
