export function TimeZoneConverterHelper(dateStr: string): Date {
  if (dateStr) {
    const date = new Date(dateStr);
    const dateWithTimezone = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return dateWithTimezone;
  }
  
  return null;
}

export function timeConvert(data) {
  if (!data) return null;
  const minutes = data % 60;
  const hours = ((data - minutes) / 60).toString().padStart(2, '0');
  return `${hours}:${minutes.toFixed(0).toString().padStart(2, '0')}`;
}

export function convertStringToTime(timeStr: string) {
  if (!timeStr) return null;
  const timeParts = timeStr.split(':');
  if (timeParts.length !== 2) return null;
  const minutes = +timeParts[0] * 60 + +timeParts[1];
  return typeof(minutes) === 'number' && !Number.isNaN(minutes) ? minutes : null;
}

export function convertMinutesToHours(data) {
  if (!data) return null;

  const hours = ((data) / 60);
  return hours;
}

export function minuteConverter(time) {
  if (time === null) return 0;
  const [h, m] = time.split(':');
  const value = +h + +m / 60;
  return value;
}
