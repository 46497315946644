import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environment';
import { Equipment, EquipmentType, TotCode } from '../../models/entities/equipment';

@Injectable({
  providedIn: 'root'
})
export class EquipmentMasterListService {

  equipmentMasterListUpdated$ = new Subject<boolean>();

  constructor(private http: HttpService) { }

  public getEquipment(): Observable<Equipment[]> {
    return this.http.Get<Equipment[]>(`${environment.baseUrl}/api/admin/equipment`).pipe(shareReplay());
  }

  public getEquipmentTypes(): Observable<EquipmentType[]> {
    return this.http.Get<EquipmentType[]>(`${environment.baseUrl}/api/admin/equipment/equipment-types`).pipe(shareReplay());
  }

  public getTotCodeData(totCode: string): Observable<TotCode> {
    return this.http.Get<TotCode>(`${environment.baseUrl}/api/admin/equipment/tot-code/${totCode}`).pipe(shareReplay());
  }

  public getCanDelete(id: string): Observable<boolean> {
    return this.http.Get<boolean>(`${environment.baseUrl}/api/admin/equipment/can-delete/${id}`).pipe(shareReplay());
  }

  public updateEquipmentList(equipmentList: Equipment[]): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/admin/equipment/update`, JSON.stringify(equipmentList));
  }

  public addEquipment(equipment: Equipment): Observable<boolean> {
    return this.http.Put(`${environment.baseUrl}/api/admin/equipment-add`, JSON.stringify(equipment));
  }

}
