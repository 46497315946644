import { OnInit, OnChanges, Injector, SimpleChanges, Input, Output, EventEmitter, ChangeDetectorRef, Renderer2, NgZone, Directive } from '@angular/core';
import { FormValueAccessor } from '../form-value-accessor';
import { UnitConversionService } from './unit-conversion.service';
import { formatNumber, getDisplayValue, getSourceValue } from './unit-helper';
import { UnitType } from 'libs/constants';

@Directive()
export class UnitBaseComponent extends FormValueAccessor implements OnInit, OnChanges {
  _sourceValue: any;
  _displayValue: any;
  _formattedValue: any;

  protected cdRef: ChangeDetectorRef;
  protected ngZone: NgZone;
  protected renderer: Renderer2;
  protected unitConversionService: UnitConversionService;

  @Input() unit: UnitType;
  @Input() disabled: boolean;
  @Input() decimalPlaces: number = 2;
  @Input() minDecimalPlaces: number = 0;
  @Input() sackWeight: number;
  @Input() canUserChangeUnit: boolean = true;
  @Output() onChange = new EventEmitter<string>();
  @Output() onClick = new EventEmitter<any>();
  @Output() onFocus = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Output() onFocusOut = new EventEmitter<any>();

  constructor(protected inj: Injector) {
    super(inj);
    this.cdRef = this.inj.get(ChangeDetectorRef);
    this.ngZone = this.inj.get(NgZone);
    this.renderer = this.inj.get(Renderer2);
    this.unitConversionService = this.inj.get(UnitConversionService);
  }

  ngOnInit() {
    this.unitConversionService.onSaveUnit$.subscribe(x => {
      this.writeValue(this._sourceValue);
    });
    // this._formControl.valueChanges.subscribe(value => {
    //   this._updateSourceValue(value);
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { unit, decimalPlaces, disabled, sackWeight } = changes;

    // When units of measure is changed manually by a users (e.g. by selecting units in dropdown),
    // do not convert value from one unit measure to another.
    // User wants to select a unit and not change field value.

    const manualUnitChange = unit
      && (Number(unit.previousValue) !== Number(unit.currentValue)
        || unit.previousValue !== 0 && unit.currentValue === 0)
      && unit.previousValue !== undefined  
      && !unit.firstChange;

    if (unit && (!manualUnitChange || !this.canUserChangeUnit) || decimalPlaces != null || sackWeight) {

      this.writeValue(this._sourceValue);

    } else if (manualUnitChange) {

      // convert if unit of measure is set
      this._updateSourceValue(this._displayValue);
    }

    if (disabled) {
      if (this.disabled)
        this._formControl.disable();
      else
        this._formControl.enable();
    }
  }

  _onClick(e, el) {
    this.onClick.emit({ e: e, el: el });
  }

  _onFocus(e) {
    if (this.control)
      this.control.markAsTouched();
    this._formControl.setValue(this._displayValue, { emitEvent: false });
    this.onFocus.emit(e);
  }

  _onBlur(e) {
    if (this.control) {
      this.control.markAsTouched();

      if (this.control.updateOn === 'blur') {
        this.control.setValue(this._sourceValue);       
      }
    }
    
    this._formControl.setValue(this._formattedValue, { emitEvent: false });
    this.onBlur.emit(e);
  }

  _onKeyUp(value) {
    if (this.control)
      this.control.markAsDirty();
    this._updateSourceValue(value);
  }

  _onFocusout(e) {
    this.onFocusOut.emit(e);
  }

  writeValue(obj: any) {
    this._sourceValue = obj;
    this._updateDisplayValue(this._sourceValue);
    this.cdRef.markForCheck();
  }

  private _updateSourceValue(value) {
    this._displayValue = value;
    this._formattedValue = formatNumber(this._displayValue, this.decimalPlaces, this.minDecimalPlaces);

    const apiUnitMeasure = this.unitConversionService.getApiUnitMeasure(this.unit);
    const currentUnitMeasure = this.unitConversionService.getCurrentUnitMeasure(this.unit);

    let sourceConverted = value;

    if (!!currentUnitMeasure
      && apiUnitMeasure
      && currentUnitMeasure.name !== apiUnitMeasure.name
      && (!!this.sackWeight
      || this.sackWeight === 0)
      || Number(this.unit) !== UnitType.BaseMaterialWeight) { // conversion will be incorrect if no sack weight

      sourceConverted = getSourceValue(value, currentUnitMeasure, apiUnitMeasure, this.sackWeight);
    }

    if ((!sourceConverted && this._sourceValue) || (sourceConverted != null && sourceConverted.toString() !== this._sourceValue)) {
      this._sourceValue = isNaN(sourceConverted) ? value : sourceConverted;
      this._propagateChange(this._sourceValue);
      this.onChange.emit(this._sourceValue);
    }
  }

  private _updateDisplayValue(sourceValue) {
    this._sourceValue = sourceValue;

    const apiUnitMeasure = this.unitConversionService.getApiUnitMeasure(this.unit);
    const currentUnitMeasure = this.unitConversionService.getCurrentUnitMeasure(this.unit);

    if (!!currentUnitMeasure
      && apiUnitMeasure
      && currentUnitMeasure.name === apiUnitMeasure.name) {

      // no units conversion needed
      this._displayValue = sourceValue;

    } else if (!!this.sackWeight
      || this.sackWeight === 0
      || Number(this.unit) !== UnitType.BaseMaterialWeight) { // conversion will be incorrect if no sack weight

      this._displayValue = getDisplayValue(
        sourceValue,
        apiUnitMeasure,
        currentUnitMeasure,
        this.sackWeight
      );
    }

    this._formattedValue = formatNumber(this._displayValue, this.decimalPlaces, this.minDecimalPlaces);
    this._formControl.setValue(this._formattedValue, { onlySelf: true, emitEvent: false });
  }
}
