import { AbstractType } from "@angular/core";
import { SELECTION_NULL } from "apps/vida/src/modules/advance-search-results/components/adv-search-casing-equipment/adv-search-casing-equipment-criteria";

interface IRiskLevel {
  riskLevel: number,
  isSet: boolean
}

const RISK_LEVELS: IRiskLevel[] = [
  { riskLevel: 1, isSet: false },
  { riskLevel: 2, isSet: false },
  { riskLevel: 3, isSet: false },
  { riskLevel: 4, isSet: false },
  { riskLevel: 5, isSet: false },
]

export class AdvanceSearch {
  hseObjectiveSuccess: boolean;
  hseObjectiveFail: boolean;
  hseObjectiveIncomplete: boolean;
  jobObjectiveSuccess: boolean;
  jobObjectiveFail: boolean;
  jobObjectiveIncomplete: boolean;
  casingEquipmentSuccess: boolean;
  casingEquipmentFail: boolean;
  casingEquipmentIncomplete: boolean;
  isFoam: boolean;
  isNotFoam: boolean;
  isGeothermal: boolean;
  isRemedial: boolean;
  isStageJob: boolean;
  isLand: boolean;
  isOffshore: boolean;
  isActive: boolean;
  isComplete: boolean;
  isCanceled: boolean;
  isClosed: boolean;
  isDraft: boolean;
  isAllJobs: boolean;
  isMyJobs: boolean;
  isVertical: boolean;
  riskLevels: boolean[];
  cmtSvc: boolean;
  ceSvc: boolean;
  ceDirectSales: boolean;
  isDeviated: string;
  jobName: string;
  customer: any;
  parentCustomer: any;
  projectedDateFrom: string;
  protectedDateTo: string;
  actualJobDateFrom: string;
  actualJobDateTo: string;
  group: any;
  rig: any;
  rigName: string;
  wellName: string;
  wellId: any;
  wellNumber: string;
  jobOwnerBy: any;
  engineer: any;
  serviceCoordinator: any;
  jobType: string;
  bomType: any;
  saleOrderNumber: string;
  jobMdFrom: string;
  jobMdTo: string;
  jobTvdFrom: string;
  jobTvdTo: string;
  waterDepthFrom: string;
  waterDepthTo: string;
  casingSizeFrom: string;
  casingSizeTo: string;
  holeSizeFrom: string;
  holeSizeTo: string;
  bhstFrom: string;
  bhstTo: string;
  bhctFrom: string;
  bhctTo: string;
  country: string;
  proposalNumber: string;
  field: string;
  quoteNumber: string;
  ceProductGroup: string;
  ceProductFamily: string;
  ceProductCode: string;
  ceClass: string;
  ceSize: string[];
  ceWeight: string[];
  ceGrade: string[];
  ceThread: string[];
  ceSAPNumber: string[];
  sapNumberSelection: any[];
  plannedCarbonFootprintFrom: string;
  plannedCarbonFootprintTo: string;
  actualCarbonFootprintFrom: string;
  actualCarbonFootprintTo: string;
  /**
   *
   */
  constructor(
    hseObjectiveSuccess = false,
    hseObjectiveFail = false,
    hseObjectiveIncomplete = false,
    jobObjectiveSuccess = false,
    jobObjectiveFail = false,
    jobObjectiveIncomplete = false,
    casingEquipmentSuccess = false,
    casingEquipmentFail = false,
    casingEquipmentIncomplete = false,
    isFoam = false,
    isNotFoam = false,
    isLand = false,
    isOffshore = false,
    isActive = true,
    isComplete = false,
    isCanceled = false,
    isClosed = false,
    isDraft = true,
    isMyJobs = false,
    isDeviated = null,
    isVertical = null,
    cmtSvc = false,
    ceSvc = false,
    ceDirectSales = false,
    riskLevels = RISK_LEVELS,
    jobName = '',
    customer = [],
    parentCustomer = [],
    projectedDateFrom = '',
    protectedDateTo = '',
    actualJobDateFrom = '',
    actualJobDateTo = '',
    group = [],
    rig = [],
    rigName = '',
    wellId = [],
    wellName = '',
    wellNumber = '',
    jobOwnerBy = [],
    engineer = [],
    serviceCoordinator = [],
    jobType = null,
    bomType = [],
    saleOrderNumber = '',
    jobMdFrom = null,
    jobMdTo = null,
    jobTvdFrom = null,
    jobTvdTo = null,
    waterDepthFrom = null,
    waterDepthTo = null,
    casingSizeFrom = null,
    casingSizeTo = null,
    holeSizeFrom = null,
    holeSizeTo = null,
    bhstFrom = null,
    bhstTo = null,
    bhctFrom = null,
    bhctTo = null,
    country = null,
    proposalNumber = null,
    quoteNumber = null,
    field = null,
    plannedCarbonFootprintFrom = null,
    plannedCarbonFootprintTo = null,
    actualCarbonFootprintFrom = null,
    actualCarbonFootprintTo = null,
    isStageJob = false,
    isRemedial = false,
    isGeothermal = false,
  ) {
    this.hseObjectiveSuccess = hseObjectiveSuccess;
    this.hseObjectiveFail = hseObjectiveFail;
    this.hseObjectiveIncomplete = hseObjectiveIncomplete;
    this.jobObjectiveSuccess = jobObjectiveSuccess;
    this.jobObjectiveFail = jobObjectiveFail;
    this.jobObjectiveIncomplete = jobObjectiveIncomplete;
    this.casingEquipmentSuccess = casingEquipmentSuccess;
    this.casingEquipmentFail = casingEquipmentFail;
    this.casingEquipmentIncomplete = casingEquipmentIncomplete;
    this.isFoam = isFoam;
    this.isNotFoam = isNotFoam;
    this.isStageJob = isStageJob;
    this.isRemedial = isRemedial;
    this.isGeothermal = isGeothermal;
    this.isLand = isLand;
    this.isOffshore = isOffshore;
    this.isActive = isActive;
    this.isComplete = isComplete;
    this.isCanceled = isCanceled;
    this.isClosed = isClosed;
    this.isDraft = isDraft;
    this.isMyJobs = isMyJobs;
    this.isVertical = isVertical;
    this.cmtSvc = cmtSvc,
      this.ceSvc = ceSvc,
      this.ceDirectSales = ceDirectSales,
      this.isDeviated = isDeviated;
    this.riskLevels = this.assignRiskLevels(riskLevels);
    this.jobName = jobName;
    this.customer = customer;
    this.parentCustomer = parentCustomer;
    this.projectedDateFrom = projectedDateFrom;
    this.protectedDateTo = protectedDateTo;
    this.actualJobDateFrom = actualJobDateFrom;
    this.actualJobDateTo = actualJobDateTo;
    this.group = group;
    this.rig = rig;
    this.rigName = rigName;
    this.wellId = wellId;
    this.wellName = wellName;
    this.wellNumber = wellNumber;
    this.jobOwnerBy = jobOwnerBy;
    this.engineer = engineer;
    this.serviceCoordinator = serviceCoordinator;
    this.jobType = jobType;
    this.bomType = bomType;
    this.saleOrderNumber = saleOrderNumber;
    this.jobMdFrom = jobMdFrom;
    this.jobMdTo = jobMdTo;
    this.jobTvdFrom = jobTvdFrom;
    this.jobTvdTo = jobTvdTo;
    this.waterDepthFrom = waterDepthFrom;
    this.waterDepthTo = waterDepthTo;
    this.casingSizeFrom = casingSizeFrom;
    this.casingSizeTo = casingSizeTo;
    this.holeSizeFrom = holeSizeFrom;
    this.holeSizeTo = holeSizeTo;
    this.bhstFrom = bhstFrom;
    this.bhstTo = bhstTo;
    this.bhctFrom = bhctFrom;
    this.bhctTo = bhctTo;
    this.country = country;
    this.proposalNumber = proposalNumber;
    this.field = field;
    this.quoteNumber = quoteNumber;
    this.plannedCarbonFootprintFrom = plannedCarbonFootprintFrom;
    this.plannedCarbonFootprintTo = plannedCarbonFootprintTo;
    this.actualCarbonFootprintFrom = actualCarbonFootprintFrom;
    this.actualCarbonFootprintTo = actualCarbonFootprintTo;
  }
  static advanceSearchWithParam(params) {
    return Object.assign(new AdvanceSearch, params);
  }

  buildQueryParams(jobStatusId: any, holeSize, casingSize) {
    const jobStatusArr = [
      { key: 'Active', value: 'isActive' },
      { key: 'Completed', value: 'isComplete' },
      { key: 'Cancelled', value: 'isCanceled' },
      { key: 'Closed', value: 'isClosed' },
      { key: 'Draft', value: 'isDraft' }
    ];
    const that = this;
    const baScoreValues = [];
    const jobStatus = jobStatusArr.map(({ key, value }) => {
      const temp = jobStatusId.find(job => {
        baScoreValues.push(job.baScoreValue);
        return job.statusDescription === key;
      });
      if (temp && that[value]) {
        return temp.id;
      }
    }).filter(e => e);
    const mapping = {
      'isMyJob': this.isMyJobs,
      'jobName': this.jobName ? this.jobName.trim() : '',
      'projectedDate': {
        from: this.formatDate(this.projectedDateFrom, false),
        to: this.formatDate(this.protectedDateTo, true)
      },
      'actualDate': {
        from: this.formatDate(this.actualJobDateFrom, false),
        to: this.formatDate(this.actualJobDateTo, true)
      },
      'rigName': this.rigName ? this.rigName.trim() : '',
      'wellName': this.wellName ? this.wellName.trim() : '',
      'wellNumber': this.wellNumber,
      'jobMd': {
        from: this.jobMdFrom,
        to: this.jobMdTo
      },
      'jobTvd': {
        from: this.jobTvdFrom,
        to: this.jobTvdTo
      },
      'salesOrderNumber': this.saleOrderNumber ? this.saleOrderNumber.trim() : '',
      'field': this.field ? this.field.trim() : '',
      'proposalNumber': this.proposalNumber ? this.proposalNumber.trim() : '',
      'quoteNumber': this.quoteNumber ? this.quoteNumber.trim() : '',
      'waterDepth': {
        from: this.waterDepthFrom,
        to: this.waterDepthTo
      },
      'casingSize': {
        from: this.getCasingSizeValue(this.casingSizeFrom, casingSize),
        to: this.getCasingSizeValue(this.casingSizeTo, casingSize),
      },
      'holeSize': {
        from: this.getHoleSizeValue(this.holeSizeFrom, holeSize),
        to: this.getHoleSizeValue(this.holeSizeTo, holeSize),
      },
      'bhst': {
        from: this.bhstFrom,
        to: this.bhstTo,
      },
      'bhct': {
        from: this.bhctFrom,
        to: this.bhctTo
      },
      'riskLevels': this.setRiskLevelsSearch(),
      'jobClass': {
        cmtSvc: this.cmtSvc ? this.cmtSvc : null,
        ceSvc: this.ceSvc ? this.ceSvc : null,
        ceDirectSales: this.ceDirectSales ? this.ceDirectSales : null
      },
      'plannedCarbonFootprint': {
        from: this.plannedCarbonFootprintFrom,
        to: this.plannedCarbonFootprintTo,
      },
      'actualCarbonFootprint': {
        from: this.actualCarbonFootprintFrom,
        to: this.actualCarbonFootprintTo,
      },
    };

    const hseSuccess: boolean[] = [];
    if (this.hseObjectiveSuccess) {
      hseSuccess.push(true);
    }
    if (this.hseObjectiveFail) {
      hseSuccess.push(false);
    }
    if (this.hseObjectiveIncomplete) {
      hseSuccess.push(null);
    }

    const jobSuccess: boolean[] = [];
    if (this.jobObjectiveSuccess) {
      jobSuccess.push(true);
    }
    if (this.jobObjectiveFail) {
      jobSuccess.push(false);
    }
    if (this.jobObjectiveIncomplete) {
      jobSuccess.push(null);
    }

    const casingEquipmentSuccess: boolean[] = [];
    if (this.casingEquipmentSuccess) {
      casingEquipmentSuccess.push(true);
    }
    if (this.casingEquipmentFail) {
      casingEquipmentSuccess.push(false);
    }
    if (this.casingEquipmentIncomplete) {
      casingEquipmentSuccess.push(null);
    }

    if (this.isFoam) {
      mapping['isFoam'] = this.isFoam;
    }
    if (this.isStageJob) { mapping['isStageJob'] = this.isStageJob; }
    if (this.isRemedial) { mapping['isRemedial'] = this.isRemedial; }
    if (this.isGeothermal) { mapping['isGeothermal'] = this.isGeothermal; }

    if (this.isDeviated !== null) {
      if (this.isDeviated === 'isVertical') {
        mapping['isVertical'] = true;
      } else {
        mapping['isDeviated'] = true;
      }
    }
    if (this.isOffshore) {
      mapping['isOffshore'] = true;
    } else {
      mapping['isOffshore'] = false;
    }

    if (this.isOffshore && this.isLand || !this.isOffshore && !this.isLand) {
      delete mapping['isOffshore'];
    }

    if (!this.cmtSvc && !this.ceSvc && !this.ceDirectSales) {
      delete mapping['jobClass'];
    }

    if (this.ceProductGroup) mapping['ceProductGroup'] = this.ceProductGroup;
    if (this.ceProductFamily) mapping['ceProductFamily'] = this.ceProductFamily;
    if (this.ceProductCode) mapping['ceProductCode'] = this.ceProductCode;
    if (this.ceClass) mapping['ceClass'] = this.ceClass;
    if (this.ceSize) mapping['ceSize'] = this.replaceNullValues(this.ceSize);
    if (this.ceWeight) mapping['ceWeight'] = this.replaceNullValues(this.ceWeight);
    if (this.ceGrade) mapping['ceGrade'] = this.replaceNullValues(this.ceGrade);
    if (this.ceThread) mapping['ceThread'] = this.replaceNullValues(this.ceThread);
    if (this.ceSAPNumber) mapping['ceSAPNumber'] = this.ceSAPNumber;

    let owner = [];
    if (!!this.jobOwnerBy) {
      owner = this.jobOwnerBy.map(e => e.networkId);
    }
    let designEngineer = [];
    if (!!this.engineer) {
      designEngineer = this.engineer.map(e => e.networkId);
    }
    let serviceCoord = [];
    if (!!this.jobOwnerBy) {
      serviceCoord = this.serviceCoordinator.map(e => e.networkId);
    }
    const customersId = this.customer.map(cust => cust.customerId);
    const parentCustomerNames = this.parentCustomer.map(parent => parent.parentCustomerName);
    const mappingArr = {
      'customers': customersId && customersId.length ? customersId : null,
      'ParentCustomerName': parentCustomerNames,
      'jobStatuses': jobStatus.length ? jobStatus : null,
      'hseSuccess': hseSuccess.length ? hseSuccess : null,
      'casingEquipmentSuccess': casingEquipmentSuccess.length ? casingEquipmentSuccess : null,
      'baScore': baScoreValues.length ? baScoreValues : null,
      'jobSuccess': jobSuccess.length ? jobSuccess : null,
      'groups': this.group && this.group.length ? this.group : null,
      'wells': this.wellId && this.wellId.length ? this.wellId : null,
      'rigs': this.rig && this.rig.length ? this.rig : null,
      'bOMTypes': this.bomType && this.bomType.length ? this.bomType : null,
      'jobTypes': this.jobType && this.jobType.length ? this.jobType : null,
      'owners': owner && owner.length ? owner : null,
      'engineers': designEngineer && designEngineer.length ? designEngineer : null,
      'serviceCoordinators': serviceCoord && serviceCoord.length ? serviceCoord : null,
      'countries': this.country && this.country.length ? this.country : null
    };
    const body = Object.assign({}, mapping, mappingArr);
    return body;
  }

  formatDate(date, toDate) {
    let tail = '00:00:00';
    if (toDate) {
      tail = '23:59:59';
    }
    if (!date) {
      return date;
    }
    date = new Date(date);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return `${mm}-${dd}-${yyyy} ${tail}`;
  }
  getHoleSizeValue(id, holeSize) {
    if (id == null) {
      return null;
    }
    const hole = holeSize.find(holeT => holeT.id.toString() === id.toString());
    return hole ? hole.value : null;
  }
  getCasingSizeValue(id, casingSize) {
    if (id == null) {
      return null;
    }
    const caseH = casingSize.find(casingS => casingS.id.toString() === id.toString());
    return caseH ? caseH.value : null;
  }
  isEmpty(value) {
    return (typeof value === 'undefined' || value === null || value === '');
  }

  private setRiskLevelsSearch() {
    const riskLevels = [];
    let level = 1;

    for (let riskLevel of this.riskLevels) {
      if (riskLevel) riskLevels.push(level);
      level++;
    }

    return riskLevels.length > 0 ? riskLevels : null;
  }

  private assignRiskLevels(riskLevels: IRiskLevel[]) {
    const sortedLevels = riskLevels.sort((a, b) => {
      if (a.riskLevel > b.riskLevel) return 1;
      else if (a.riskLevel < b.riskLevel) return -1;
      else return 0;
    });

    const riskObj = [];

    for (let i = 0; i < sortedLevels.length; i++) {
      riskObj.push(sortedLevels[i].isSet);
    }

    return riskObj;
  }
  private replaceNullValues(values: string[]): string[] {
    return values.map((value: string) => value == SELECTION_NULL ? null : value);
  }
}











