import { MemberModel } from "./member.model";

export class GroupSettingsModel {
  groupId: string;
  groupName: string;
  nwa: string;
  plantCode: string;
  regionId: string;
  members: MemberModel[];

  constructor() {
    this.members = [];
  }
}
