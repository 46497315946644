export class UploadSettings {

    public constructor(

        public chunkSize = 2000000,

        public parallelism = 3

    ) {
    }
}
