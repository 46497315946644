import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})

export class AccessDeniedComponent implements OnInit {
  selfService = 'https://apps.powerapps.com/play/72bfcb10-2e60-44a6-b237-f5da83d9e577?tenantId=b7be7686-6f97-4db7-9081-a23cf09a96b5';

  ngOnInit() {
    // Clean up localStorage to get rid of any invalid tokens
    localStorage.clear();
  }
}
