import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { VidaFileType, CommonMessageText } from '../../../shared/constant';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DynamicSidebarContent, DynamicSidebarRef } from 'libs/ui';
import { CallSheet } from '../../../control-point/models';
import * as moment from 'moment-mini';
import { MessageService } from 'primeng/api';
import { HttpCommanderService } from 'libs/shared/services';
import { JobUploaderComponent } from 'apps/vida/src/modules/shared/components';
import { environment } from 'libs/environment';

@Component({
  selector: 'call-sheet-sidebar',
  templateUrl: './call-sheet-sidebar.component.html',
  styleUrls: ['./call-sheet-sidebar.component.scss']
})
export class CallSheetSideBarComponent implements DynamicSidebarContent, OnInit {

  wellId: string;
  data: any;
  callSheetFb: UntypedFormGroup;
  vidaFileType: VidaFileType = VidaFileType.CallSheet;
  errorMessage: string;
  isCallSheetVisible = false;
  resetUploerUI = false;
  disableSaveButton;
  loadingSubscription: Subscription;
  jobId: string;
  destinationFolderPath: string;
  statusNoCallSheet: boolean = true;
  callSheet: CallSheet = null;
  callSheetFiles = [];
 @ViewChild('uploader', { static: true }) jobUploader: JobUploaderComponent;

  constructor(
    private HCService: HttpCommanderService,
    private fb: UntypedFormBuilder,
    private dynamicSidebarRef: DynamicSidebarRef<CallSheetSideBarComponent>,
    private messageService: MessageService
  ) {
  }

  ngOnInit() {
    this.destinationFolderPath = this.data.path;
    this.jobId = this.data.jobId;
    this.getFiles();
  }

  // Upload in progress callback
  uploadInprogress(event) {
    if (event) {
      this.disableSaveButton = false;
    }
  }

  // Upload success callback
  uploadSuccess(event) {
    if (event.isSuccess) {
      this.getFiles();
      this.disableSaveButton = false;
    }
  }

  // Upload cancel callback
  uploadCancel(event) {
    if (!event.isSuccess) {
      this.getFiles();
    }
  }

  getFiles() {
    this.resetUI();
    this.loadingSubscription = this.HCService.getFilesByVirtualRelativeFolderPath(this.destinationFolderPath, VidaFileType.CallSheet)
      .subscribe((data: any) => {
        this.handleDataFromVIDA(data);
      });
  }

  resetUI() {
    this.errorMessage = '';
  }

  disableOtherRadioButton(event) {
    const listCallSh = this.callSheetFb.value.callSheet.map(callSh => {
      const jobTemp = Object.assign(new CallSheet, callSh);
      if (jobTemp.virtualRelativeFilePath !== event.virtualRelativeFilePath) {
        jobTemp.isLastUsed = false;
      } else {
        jobTemp.isLastUsed = true;
      }
      return jobTemp;
    });

    const callSheet = listCallSh.find((callsh: any) => callsh.isLastUsed);
    callSheet ? this.statusNoCallSheet = false : this.statusNoCallSheet = true;
    this.parseObjectToForm(listCallSh);
  }


  handleDataFromVIDA(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        this.callSheetFiles = result.map(callSh => {
          const temp = Object.assign(new CallSheet, callSh);
          return temp;
        });
        if (!this.callSheetFiles) {
          this.disableSaveButton = true;
        }
        this.callSheet = this.callSheetFiles.find((callsh: any) => callsh.isLastUsed);
        this.callSheet ? this.statusNoCallSheet = false : this.statusNoCallSheet = true;
        this.parseObjectToForm(this.callSheetFiles);
      }
    }
  }

  saveCallSheet() {
    this.callSheetFb.markAsTouched();
    const listCallSh = this.callSheetFb.value.callSheet.map(callSh => {
      let jobTemp = Object.assign(new CallSheet, callSh);
      if (jobTemp.approvalDate) {
        let givenTimezoneOffset = moment.parseZone(jobTemp.approvalDate).utcOffset();
        if (givenTimezoneOffset !== 0) {
          jobTemp.approvalDate = moment(jobTemp.approvalDate).format('L'); // date in local format without offset
        }
      }
      return jobTemp;
    });
    // save call sheet
    this.loadingSubscription = this.HCService.saveCallSheet(listCallSh, this.jobId).subscribe((data: any) => {
      this.handleDateFromHC(data);
      if (data.statusCode === 200) {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.CALL_SHEET.SUCCESS });
        this.dynamicSidebarRef.close(this.callSheetFiles);
      } else {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: CommonMessageText.CALL_SHEET.FAILED });
        this.hideFileSidebar();
      }
    });
  }

  cancelCallSheet() {
    this.jobUploader.cancelUpload();
    this.hideFileSidebar();
  }

  hideFileSidebar() {
    this.errorMessage = '';
    this.dynamicSidebarRef.close(null);
  }

  parseObjectToForm(callSheet: CallSheet[]) {
    const callSheetFg = callSheet.map(callSh => {
      return this.createFormGroup(callSh);
    });

    this.initForm(callSheetFg);
  }

  initForm(callShFgArr) {
    this.callSheetFb = this.fb.group({
      callSheet: callShFgArr && callShFgArr.length ? this.fb.array(callShFgArr) : this.fb.array([])
    });
  }

  createFormGroup(callSheet: CallSheet): UntypedFormGroup {
    return this.fb.group({
      virtualRelativeFilePath: [
        {
          value: callSheet.virtualRelativeFilePath,
          disabled: false
        }
      ],
      fileName: [
        {
          value: callSheet.getFileName(),
          disabled: false
        }
      ],
      approvalDateFormat: [
        {
          value: callSheet.getApprovalDate(),
          disabled: false
        }
      ],
      createDateFormat: [
        {
          value: callSheet.getCreatedDate(),
          disabled: false
        }
      ],
      filePathDownload: [
        {
          value: callSheet.filePathDownload,
          disabled: false
        }
      ],
      isLastUsed: [
        {
          value: callSheet.isLastUsed,
          disabled: false
        }
      ],
      uploader: [
        {
          value: callSheet.uploader,
          disabled: false
        }
      ],
      createdDate: [
        {
          value: callSheet.createdDate ? new Date(callSheet.createdDate) : null,
          disabled: false
        }
      ],
      description: [
        {
          value: callSheet.description,
          disabled: false
        }
      ],
      approver: [
        {
          value: callSheet.approver,
          disabled: false
        }
      ],
      approvalDate: [
        {
          value: callSheet.approvalDate ? new Date(callSheet.approvalDate) : null,
          disabled: false
        }
      ],
    });
  }

  handleDateFromHC(data) {
    const { statusCode, result } = data;
    if (statusCode === 200) {
      if (result) {
        this.callSheetFiles = result.map(callSh => {
          const temp = Object.assign(new CallSheet, callSh);
          return temp;
        });
        this.callSheet = this.callSheetFiles.find((job: any) => job.isLastUsed);
        this.parseObjectToForm(this.callSheetFiles);
      }
    }
  }
}
