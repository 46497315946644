
export enum ControlPointAction {
  Submitted = 0,
  Approved = 1,
  Rejected = 2,
  Unlock = 3,
  Complete = 4,
  Saved = 5,
  UnComplete = 6,
  Prepared = 7,
  Duplicated = 8,
  KPIComplete = 9,
  KPIIncomplete = 10
}

export enum ControlPointState {
  NonSubmitted,
  Submitted,
  Approved,
  Rejected,
  Unlock,
  Completed,
  UnCompleted,
  Prepared,
  KPIComplete
}

export const STATUS_NAMES = {
  [ ControlPointState.NonSubmitted ]: 'In Progress',
  [ ControlPointState.Submitted ]: 'Submitted',
  [ ControlPointState.Approved ]: 'Approved',
  [ ControlPointState.Rejected ]: 'In Progress',
  [ ControlPointState.Unlock ]: 'In Progress',
  [ ControlPointState.Completed ]: 'Completed',
  [ ControlPointState.UnCompleted ]: 'In Progress',
  [ ControlPointState.Prepared ]: 'Prepared',
  [ ControlPointState.KPIComplete ]: 'KPI Complete'
};


export const STATUS_ACTION_NAMES = {
  [ ControlPointState.NonSubmitted ]: 'Unlocked',
  [ ControlPointState.Submitted ]: 'Submitted',
  [ ControlPointState.Approved ]: 'Approved',
  [ ControlPointState.Rejected ]: 'Rejected',
  [ ControlPointState.Completed ]: 'Completed',
  [ ControlPointState.UnCompleted ]: 'UnCompleted',
  [ ControlPointState.Prepared ]: 'Prepared',
  [ ControlPointState.KPIComplete ]: 'KPI Complete'
};

export function GetControlPointStatusName(status: ControlPointState) {
  return STATUS_NAMES[status];
}

export function GetControlPointActionName(status: ControlPointState) {
  return STATUS_ACTION_NAMES[status];
}