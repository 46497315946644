import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeZoneConverterHelper } from '../../shared/helpers/index';

@Pipe({
  name: 'dateFormatWithLocalTimeZone'
})
export class DateFormatWithLocalTimeZonePipe extends DatePipe implements PipeTransform {
  transform(value: string, args?: string): any {
    const localTime = TimeZoneConverterHelper(value);
    return super.transform(localTime, args);
  }  
}
