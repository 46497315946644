import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'nonExistentOption',
  pure: false
})
export class NonExistentOptionPipe implements PipeTransform {

   /**
   * Returns the сontrol.value.name or No-Break Space symbol for a [placeholder],
   * uses when there is a value that should be displayed in the dropdown field,
   * but it is not in the list of dropdown options.
   */
  transform(optionControl: AbstractControl, optionList: any[]): string {

    const nbsp = '\u00A0';
    const option = optionControl?.value?.name ? optionControl.value : null;
    const isOptionExistInList = option && optionList.length ? optionList.filter(item => item.name === option.name).length !== 0 : false;
    const placeholder = !option || isOptionExistInList ? nbsp : option.name;

    return placeholder;
  }

}
