<div class="datetime-picker" [ngClass]="{'has-error': showAlert, 'minimal-datetime-picker': isMinimal}">
  <p-calendar [disabled]="disabled" 
    [(ngModel)]="value"
    (onSelect)="onSelect($event)"
    (onClickOutside)="_onBlur()"
    [hideOnDateTimeSelect]="!showTime"
    [readonlyInput] ="disabled"
    (onInput)="onChange($event)"
    [dateFormat]="datetimeFormat"
    styleClass="{{styleClass}}"
    showIcon="true"
    placeholder="{{placeholder}}"
    showButtonBar="true"
    (onClearClick)="onClearClick()" 
    [maxDate]="maxDate"
    [showTime]="showTime"
    [hourFormat]="hourFormat">
  </p-calendar>
</div>
<span *ngIf="canRemove" class="fa fa-times remove" (click)="_onRemove()"></span>
