import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';
import { KeyPerformanceIndicatorModel, KpiCentralizerSupplierModel, KpiEquipmentSupplierModel, KpiLossOptionModel, KpiSatisfiedOptionModel, KpiShutdownCategoryModel, KpiSurfaceSlurryOptionModel } from 'apps/vida/src/modules/control-point/models/KPIModels';
@Injectable()
export class KPIService {

  private jobObjectiveFromKPI = new Subject<boolean>();
  jobObjectiveFromKPI$ = this.jobObjectiveFromKPI.asObservable()

  constructor(private http: HttpService) {
  }

  getSatisfiedOptions(): Observable<KpiSatisfiedOptionModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/satisfied-options`);
  }

  getKpiShutdownCategory(): Observable<KpiShutdownCategoryModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/shutdown-categories`);
  }

  getKpiSatisfiedOptions(): Observable<KpiSatisfiedOptionModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/satisfied-options`);
  }

  getKpiCentralizerSuppliers(): Observable<KpiCentralizerSupplierModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/centralizer-suppliers`);
  }

  getKpiEquipmentSuppliers(): Observable<KpiEquipmentSupplierModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/equipment-suppliers`);
  }

  getKpiLossOptions(): Observable<KpiLossOptionModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/loss-options`);
  }

  getKpiSurfaceSlurryOptions(): Observable<KpiSurfaceSlurryOptionModel[]> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/surface-slurry-options`);
  }

  getKPIData(jobId: string): Observable<KeyPerformanceIndicatorModel> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/${jobId}`);
  }

  saveKPIData(model: KeyPerformanceIndicatorModel): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/kpi-metrics`, JSON.stringify(model));
  }

  checkKPIData(jobId: string): Observable<boolean> {
    return this.http.Get(`${environment.baseUrl}/api/kpi-metrics/${jobId}/check-data-completion`);
  }

  KPIDataReceived(value: boolean) {
    this.jobObjectiveFromKPI.next(value);
  }
}