<p-sidebar [(visible)]="isCFSidebar" (onHide)="onHideSidebar(requestIdInput)" position="right" blockScroll="true" appendTo="body"
  styleClass="vida-sidebar">
  <div class="sidebar-header">
    <h1>Carbon Footprint per {{this.modalTitle}} <span #modalTitleSpan [unitType]="UnitType.LargeVolume" [hidden]="!showSpan"></span>
      <div class="fa fa-question-circle icon-help"></div>
    </h1>
  </div>

  <div class="sidebar-content">
    <div class="row">
      <div class="col-md-12">
        <form (ngSubmit)="searchRequest(requestIdInput.value.trim())">
          <div class="form-group">
            <label>
              <span class="badge-number">1</span>Enter Request ID
            </label>
            <div class="indented-under-badge">
              <div class="input-group request-search">
                <input
                  class="form-control input-search"
                  placeholder="Request ID"
                  name="requestId"
                  #requestIdInput
                  [disabled]="modalManagementService.notifyIFactDown$ | async">
                <div class="input-group-addon btn-search" (click)="searchRequest(requestIdInput.value.trim())">
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="search-result error-result" *ngIf="errorMessage">{{errorMessage}}</div>
        <div class="search-request" *ngIf="requests.length">
          <label>
            <span class="badge-number">2</span>Results
          </label>
          <div class="indented-under-badge">
            <table class="table table-hover search-request">
              <thead>
                <tr>
                  <th width="50%">Request ID</th>
                  <th width="50%">Slurry Number</th>
                  <th width="25px" class="text-center">Select</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let slurry of requests; let i = index;">
                  <tr *ngFor="let singleSlurry of slurry.availableSlurries; let j = index;">
                    <td>{{slurry.id}}</td>
                    <td>
                      <a href="{{singleSlurry.id | iFactsLink:'slurry'}}" target="_blank">{{singleSlurry.number}}</a>
                    </td>
                    <td class="text-center">
                      <p-radioButton [inputId]="singleSlurry.id" name="singleSlurry" [value]="singleSlurry" [(ngModel)]="selectedSlurry" tabindex="j"></p-radioButton>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div *ngIf="scope3Array.length > 0" class="panel panel-custom no-padding">
          <div class="panel-heading">Product Carbon Footprint</div>
          <div class="panel-body">
            <div class="ui-table">
              <table>
                <thead>
                  <tr>
                    <th style="width: 190px; text-align: center;">Request ID / Slurry Number</th>
                    <th style="width: 95px; text-align: center;">{{tableTitle}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of scope3Array">
                    <td class="vertical-align-middle cell-style">
                      <a class="disable-except" href="{{item.requestId | iFactsLink:'request'}}" target="_blank">{{item.requestId}}</a>/<a class="disable-except"  href="{{item.slurryId | iFactsLink:'slurry'}}" target="_blank">{{item.slurryNumber}}</a>
                    </td>
                    <td class="vertical-align-middle">
                      {{item.lbsBbl | number: '1.0-0' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      

      </div>
    </div>
  </div>

  <div class="sidebar-footer">
    <a class="btn lnk-cancel" (click)="hideCF()">Cancel</a>
    <button type="button" class="btn primary-btn" (click)="compueCarbonFootprint()" [disabled]="!selectedSlurry">COMPUTE</button>
  </div>


</p-sidebar>
