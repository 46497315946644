
export class PumpScheduleEventModel {
  pumpScheduleEventId: string = null;
  pumpScheduleStageId: string = null;
  placementMethod: string = null;
  placementMethodName: string = null;
  rate: number = null;
  actualRate: number = null;
  volume: number = null;
  topOfFluid: number = null;
  length: number = null;
  duration: number = 0;
  actualDuration: number = null;
  bulkCement: number = null;
  order: number = 0;
}











