import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { FluidModel, FluidMaterialModel, PumpScheduleStageMaterialModel, PumpScheduleStageModel } from 'libs/models';
import { FluidFormFactory } from '../../../fluids/components/fluid-container/fluid-form.factory';
import { UnitType, DeadVolumeFluidType, FLUIDS_CONTANTS } from 'libs/constants';
import { calculateStageWater, calculateBulkCementByLoadOutVolume } from 'libs/shared/calculations';
import { CustomValidators } from '../../../shared/validators';
import { ValidatorSetting } from '../../../shared/constant';
import { MaterialService } from 'libs/shared/services';
import { IfactFluidSettingsSidebarComponent } from '../../../sidebar-dialogs/components';

const numericValidators = [
  Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT),
  Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT),
  CustomValidators.validateIsNumber,
  CustomValidators.validateNegativeNumber
];

export function createPumpFluidDetail(fb: UntypedFormBuilder, fluid: FluidModel, plannedVolume: number, stageModel: PumpScheduleStageModel, materialService: MaterialService): UntypedFormGroup {
  const fluidModel = fluid ? fluid : new FluidModel;
  const pumpScheduleFluidMaterials = stageModel && stageModel.fluidMaterials && stageModel.fluidMaterials.length ? stageModel.fluidMaterials : [];
  const loadoutVolume = stageModel && (stageModel.isChangeLoadOutVolume || (stageModel.loadoutVolume !== stageModel.plannedVolume && stageModel.loadoutVolume)) ? stageModel.loadoutVolume : plannedVolume;

  const deadVolume = stageModel ? stageModel.deadVolume : 0;


  const isManuallyDeadVolume = stageModel ? stageModel.isManuallyDeadVolume : false;
  const waterRequirements = calculateStageWater(loadoutVolume, plannedVolume, fluid.yield, fluidModel.mixWater);
  const isMixFluid = fluidModel.fluidMaterial && fluidModel.fluidMaterial.length > 0 ? fluidModel
    .fluidMaterial
    .filter(x => x.materialType === FLUIDS_CONTANTS.ADDITIVE)
    .every(x => x.mixingProcedureValue !== null) : false;

  const deadVolumeFluidType = stageModel && stageModel.deadVolumeFluidType !== null && stageModel.deadVolumeFluidType !== undefined && isMixFluid ? stageModel.deadVolumeFluidType : DeadVolumeFluidType.CementSlurry;
  const fg = fb.group({
    id: null,

    isCementBlend: null,
    isCementFluid: false,
    isFoam: null,

    labName: null,
    blendName: null,
    cementName: null,
    water: null,
    sackWeight: null,

    density: new UntypedFormControl({ value: fluidModel.density, disabled: true }),
    waterRequirements: new UntypedFormControl({ value: waterRequirements, disabled: true }),
    stageWaterTotal: new UntypedFormControl({ value: waterRequirements, disabled: true }),
    mixFluid: new UntypedFormControl({ value: fluidModel.mixFluid, disabled: true }),
    mixWater: new UntypedFormControl({ value: fluidModel.mixWater, disabled: true }),
    yield: new UntypedFormControl({ value: fluid.yield, disabled: true }),
    foamDensity: new UntypedFormControl({ value: fluid.foamDensity, disabled: true }),
    foamQuality: new UntypedFormControl({ value: fluid.foamQuality, disabled: true }),

    plannedVolume: new UntypedFormControl({ value: plannedVolume, disabled: true }),
    plannedVolumeUnit: new UntypedFormControl({ value: UnitType.LargeVolume, disabled: true }),
    loadoutVolume: loadoutVolume,
    deadVolume: deadVolume,
    isManuallyDeadVolume: isManuallyDeadVolume,
    deadVolumeFluidType: deadVolumeFluidType,
    totalBulkCement: null,
    bulkCementbBaseOnLoadOut: stageModel && stageModel.isChangeLoadOutVolume ? stageModel.bulkCement : null,
    bulkCement: null,
    isBulkCement: false,
    sapMaterialDisplayName: fluid.sapMaterialNumber,

    mixVolume: fluidModel.mixVolume,
    isMixFluid: isMixFluid,

    materials: fb.array(fluidModel.fluidMaterial.map(material => createPumpFluidDetailMaterial(fb, material, pumpScheduleFluidMaterials, fluidModel, 0, materialService.isWater(fluidModel.materialType)))),
    fluidMaterial: fb.array([]),
    fluidBlendMaterial: fb.array([]),
    supplementalMaterial: fb.array([]),
    order: fluidModel.order,
    hdfSlurryIds: new UntypedFormControl({ value: fluid.hdfSlurryIds, disabled: true }),
    slurryTypeId: new UntypedFormControl({ value: fluid.slurryTypeId, disabled: false }),
  });

  fg.controls.loadoutVolume.setValidators(numericValidators);
  fg.controls.deadVolume.setValidators(numericValidators);
  fg.controls.bulkCement.setValidators(numericValidators);

  return fg;
}

export function mapPumpFluidDetail(
  fb: UntypedFormBuilder,
  fg: UntypedFormGroup,
  fluidFormFactory: FluidFormFactory,
  fluid: FluidModel,
  plannedVolume: number,
  stageModel: PumpScheduleStageModel,
  stage: UntypedFormGroup,
  materialService: MaterialService): UntypedFormGroup {

  const fluidModel = fluid ? fluid : new FluidModel;
  const pumpScheduleFluidMaterials = stageModel && stageModel.fluidMaterials && stageModel.fluidMaterials.length ? stageModel.fluidMaterials : [];
  const cementMaterial = materialService.getCementMaterial(fluidModel.fluidMaterial);
  const deadVolume = stageModel ? stageModel.deadVolume : 0;

  const isManuallyDeadVolume = stageModel ? stageModel.isManuallyDeadVolume : false;
  const loadoutVolume = stageModel && (stageModel.isChangeLoadOutVolume || (stageModel.loadoutVolume !== stageModel.plannedVolume && stageModel.loadoutVolume)) ? stageModel.loadoutVolume : plannedVolume;
  const bulkCement = stage && stage.controls.isBulkCement.value ?
    stage.controls.bulkCement.value :
    calculateBulkCementByLoadOutVolume(loadoutVolume, plannedVolume, fluidModel.yield);
  const waterRequirements = calculateStageWater(loadoutVolume, plannedVolume, fluidModel.yield, fluidModel.mixWater);
  const isMixFluid = fluidModel.fluidMaterial && fluidModel.fluidMaterial.length > 0 ? fluidModel
    .fluidMaterial
    .filter(x => x.materialType === FLUIDS_CONTANTS.ADDITIVE)
    .every(x => x.mixingProcedureValue !== null) : false;
  // let deadVolumeFluidType = stageModel && stageModel.deadVolumeFluidType !== null && stageModel.deadVolumeFluidType !== undefined && isMixFluid ? stageModel.deadVolumeFluidType : DeadVolumeFluidType.CementSlurry;
  let deadVolumeFluidType = 0;
  const isCementFluid = fluid && fluid.slurryType && fluid.requestId ? fluid.slurryType.indexOf('Cement') >= 0 : false;

  if (stageModel && isCementFluid) {
    deadVolumeFluidType = stage.controls.deadVolumeFluidType.value !== null && stage.controls.deadVolumeFluidType.value !== undefined ?
      stage.controls.deadVolumeFluidType.value :
      stageModel.deadVolumeFluidType;
    if (deadVolumeFluidType === null || deadVolumeFluidType === undefined) {
      deadVolumeFluidType = DeadVolumeFluidType.CementSlurry;
    }
  }


  fg.patchValue({
    isCementFluid: isCementFluid,
    id: fluidModel.id,
    isCementBlend: fluidModel.isCementBlend,
    isFoam: fluidModel.isFoam,

    labName: fluidModel.labName,
    blendName: fluidModel.blendName,
    cementName: cementMaterial ? cementMaterial.materialName : null,
    water: fluidModel.water,
    sackWeight: fluidModel.sackWeight,

    density: fluidModel.density,
    waterRequirements: waterRequirements,
    stageWaterTotal: waterRequirements,
    mixWater: fluidModel.mixWater,
    mixFluid: fluidModel.mixFluid,
    yield: fluidModel.yield,
    foamDensity: fluidModel.foamDensity,
    foamQuality: fluidModel.foamQuality,
    plannedVolumeUnit: UnitType.LargeVolume,

    plannedVolume: plannedVolume,
    loadoutVolume: loadoutVolume,
    deadVolume: deadVolume,
    deadVolumeFluidType: deadVolumeFluidType,
    isManuallyDeadVolume: isManuallyDeadVolume,
    isBulkCement: stageModel ? stageModel.isBulkCement : null,
    bulkCement: bulkCement,
    sapMaterialName: null,
    sapMaterialDisplayName: fluidModel && fluidModel.sapMaterialName && fluidModel.sapMaterialNumber ? `${fluidModel.sapMaterialName} (${fluidModel.sapMaterialNumber})` : '',
    isMixFluid: isMixFluid,
    mixVolume: fluidModel.mixVolume,
    order: fluidModel.order,
    hdfSlurryIds: fluidModel.hdfSlurryIds,
    slurryTypeId: fluidModel.slurryTypeId,
  });
  const filteredCement = fluid && fluid.fluidMaterial ? fluid.fluidMaterial.filter(m => materialService.isCement(m)) : [];
  const countBlendComponet = filteredCement.length || 0;

  const fgMaterials = fg.controls.materials as UntypedFormArray;
  if (fgMaterials && fgMaterials.controls && fluidModel.fluidMaterial && fgMaterials.controls.length === fluidModel.fluidMaterial.length) {
    fgMaterials.controls.forEach((fgM: UntypedFormGroup, idx) => mapPumpFluidMaterialDetail(fgM, fluidModel.fluidMaterial[idx], pumpScheduleFluidMaterials, fluidModel, countBlendComponet, materialService.isWater(fluidModel.fluidMaterial[idx].materialType)));

  } else {
    fg.controls.materials = fluidModel.fluidMaterial && fluidModel.fluidMaterial.length ?
      fb.array(fluidModel.fluidMaterial.map(material => createPumpFluidDetailMaterial(fb, material, pumpScheduleFluidMaterials, fluidModel, countBlendComponet, materialService.isWater(material.materialType)))) :
      fb.array([]);
  }

  const materialGroupsSummary = fluidFormFactory.createMaterialGroupsSummary(
    (fg.controls.materials as UntypedFormArray).controls as UntypedFormGroup[]
  );

  if (materialGroupsSummary.baseCementMaterial) {
    fg.controls.baseCementMaterial = materialGroupsSummary.baseCementMaterial;
  }
  fg.controls.fluidMaterial = fb.array(materialGroupsSummary.notBlendMaterials);
  fg.controls.fluidBlendMaterial = fb.array(materialGroupsSummary.blendMaterials);
  fg.controls.supplementalMaterial = fb.array(materialGroupsSummary.supplementalMaterial);

  return fg;
}

export function createPumpFluidDetailMaterial(fb: UntypedFormBuilder, fluidMaterial: FluidMaterialModel, pumpScheduleFluidMaterials: PumpScheduleStageMaterialModel[],
  fluidModel: FluidModel, countBlendComponent: number, isWater: boolean): UntypedFormGroup {
  const pumpScheduleFluidMaterial = pumpScheduleFluidMaterials.find(p => p.fluidMaterialId === fluidMaterial.id);
  const fg = fb.group({
    id: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.id : null,
    fluidMaterialId: fluidMaterial.id,
    materialType: fluidMaterial.materialType,
    ifactMaterialName: new UntypedFormControl({ value: fluidMaterial.materialName, disabled: true }),
    ifactMaterialId: fluidMaterial.materialId,
    concentration: new UntypedFormControl({ value: fluidMaterial.concentration, disabled: false }),
    concentrationUnit: new UntypedFormControl({ value: fluidMaterial.concentrationUnit || fluidMaterial.fluidUnitMeasureName, disabled: false }),
    isBlendComponent: fluidMaterial.isBlendComponent,
    testAmount: fluidMaterial.testAmount,
    outputUnit: fluidMaterial.outputUnit,
    sg: fluidMaterial.sg,
    plannedVolume: new UntypedFormControl({ value: '', disabled: true }),
    plannedVolumeUnit: new UntypedFormControl({ value: '', disabled: true }),
    loadoutVolume: new UntypedFormControl({ value: '', disabled: true }),
    loadoutVolumeUnit: new UntypedFormControl({ value: '', disabled: true }),
    overrideVolume: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.overrideVolume : '',
    sapMaterialName: fluidMaterial.sapMaterialName,
    sapMaterialNumber: fluidMaterial.sapMaterialNumber,
    blendBulkDensity: fluidModel.cemmentBulkDensity,
    cemmentBulkDensity: fluidMaterial.bulkDensity,
    mixWater: fluidModel.mixWater,
    sackWeight: fluidModel.sackWeight,
    countBlendComponent: countBlendComponent,
    notExistedSAPMaterial: fluidMaterial.notExistedSAPMaterial,
    hdfMaterialId: fluidMaterial.hdfMaterialId,
    actualQty: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.actualQty : null,
    mixingProcedureValue: fluidMaterial.mixingProcedureValue,
    order: fluidMaterial.order,
    totalCOGS: isWater ? 0 : (pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.totalCOGS : null),
    uomIncompatible: false,
    cogsCalculated: false
  });

  fg.controls.overrideVolume.setValidators(numericValidators);
  fg.controls.actualQty.setValidators(numericValidators);

  return fg;
}

export function mapPumpFluidMaterialDetail(fg: UntypedFormGroup, fluidMaterial: FluidMaterialModel, pumpScheduleFluidMaterials: PumpScheduleStageMaterialModel[],
  fluidModel: FluidModel, countBlendComponent: number, isWater: boolean) {
  const pumpScheduleFluidMaterial = pumpScheduleFluidMaterials.find(p => p.fluidMaterialId === fluidMaterial.id);
  fg.patchValue({
    id: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.id : null,
    fluidMaterialId: fluidMaterial.id,
    materialType: fluidMaterial.materialType,
    ifactMaterialName: fluidMaterial.materialName,
    ifactMaterialId: fluidMaterial.materialId,
    concentration: fluidMaterial.concentration,
    concentrationUnit: fluidMaterial.concentrationUnit || fluidMaterial.fluidUnitMeasureName,
    isBlendComponent: fluidMaterial.isBlendComponent,
    testAmount: fluidMaterial.testAmount,
    outputUnit: fluidMaterial.outputUnit,
    sg: fluidMaterial.sg,
    plannedVolume: null,
    plannedVolumeUnit: UnitType.LargeVolume,
    loadoutVolume: null,
    overrideVolume: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.overrideVolume : '',
    sapMaterialName: fluidMaterial.sapMaterialName,
    sapMaterialNumber: fluidMaterial.sapMaterialNumber,
    blendBulkDensity: fluidModel.cemmentBulkDensity,
    cemmentBulkDensity: fluidMaterial.bulkDensity,
    mixWater: fluidModel.mixWater,
    sackWeight: fluidModel.sackWeight,
    countBlendComponent: countBlendComponent,
    notExistedSAPMaterial: fluidMaterial.notExistedSAPMaterial,
    actualQty: pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.actualQty : null,
    mixingProcedureValue: fluidMaterial.mixingProcedureValue,
    order: fluidMaterial.order,
    totalCOGS: isWater ? 0 : (pumpScheduleFluidMaterial ? pumpScheduleFluidMaterial.totalCOGS : null),
    uomIncompatible: false,
    cogsCalculated: false
  });
}
