import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'libs/environment';
import { AdUser } from 'libs/models';
import { HttpService } from './http.service';

@Injectable() export class MemberSearchService {

  constructor(private http: HttpService) { }

  search(text: string): Observable<AdUser[]> {
    if (!!text) {
      return this.http.Get<any>(`${environment.baseUrl}/api/ADUsers/ByKeyword/${text.trim()}`).pipe(
        map((list: any) =>
          list.map((item: any) => {
            const user = new AdUser();
            user.email = item.email;
            user.mobilePhoneNumber = item.mobilePhoneNumber;
            user.firstName = item.firstName;
            user.lastName = item.lastName;
            user.networkDomain = item.networkDomain;
            user.networkId = item.networkId;
            user.display = user.displayName;
            return user;
          })
        ),
        catchError((err: Error) => {
          console.log('[debug] error caught: ', err);
          return of([]);
        }),
      );
    }
    return of([]);
  }
}
