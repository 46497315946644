import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormArray } from '@angular/forms';


export function isFormValid(control: AbstractControl): boolean {
    const invalid = findInvalidControls(control);
    return invalid.length === 0;
}

export function isFormInvalid(control: AbstractControl): boolean {
    if (!control)
        return false;

    const invalid = findInvalidControls(control);
    return invalid.length !== 0;
}

export function findInvalidControls(control: AbstractControl) {
    const invalid = [];

    if (control instanceof UntypedFormControl && control.invalid)
        invalid.push(control);

    if (control instanceof UntypedFormGroup) {
        const controls = control.controls;
        for (const name in controls) {
            if (controls[name] instanceof UntypedFormGroup) {
                invalid.push(...findInvalidControls(controls[name] as UntypedFormGroup));
            } else if (controls[name] instanceof UntypedFormArray) {
                const frmArr = controls[name] as UntypedFormArray;
                frmArr.controls.forEach(frm => {
                    invalid.push(...findInvalidControls(frm));
                });
            } else if (controls[name].invalid) {
                invalid.push(controls[name]);
            }
        }
    }

    return invalid;
}

export function getControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
}

export function operateFormState(control: AbstractControl, state) {
  if (!control)
    return;

  control[state]({ onlySelf: true });
  if (control instanceof UntypedFormGroup) {
    Object.keys(control.controls).forEach(field => {
      operateFormState(control.get(field), state);
    });
  }
}

export function markAsTouched(control: AbstractControl) {
    if (!control)
        return;

    control.markAsTouched({ onlySelf: true });
    if (control instanceof UntypedFormGroup) {
        Object.keys(control.controls).forEach(field => {
            markAsTouched(control.get(field));
        });
    }
}
