export const MODAL_ID = {
  IFACTS_BASIC_SEARCH: 1,
  IFACTS_VISUALIZER: 2,
  NO_COGS: 3,
  JOB_CREATE: 4,
  JOB_EDIT: 5,
  SECURITY_ADMIN: 6,
  ICEM_FILE_IMPORT: 7,
  HDF_IMPORT: 8,
  COMMUNICATION_LOG: 9,
  FILE_EXPLORER: 10,
  ADVANCE_SEARCH: 11,
  JOB_HISTORY: 12,
  REMOVE_LINK_ORIGIN_JOB: 13,
  CREATE_WELL: 14,
  WELL_IMPORT: 15,
  WELL_NOT_IMPORT: 16,
  MMT_HISTORY: 17
};
