import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'libs/environment';

@Pipe({
  name: 'iFactsLink'
})
export class IFactsLinkPipe implements PipeTransform {
  transform(id: string, type: string): string {
    const url = `${environment.iFactsUrl}`;

    switch (type) {
      case 'request':
        return `${url}/Request/GetRequest?requestId=${id}`;
      case 'slurry':
        return `${url}/Request/GetRequestBySlurryID?slurryId=${id}`;
      default:
        return url;
    }
  }
}
