<p-sidebar position="right" [blockScroll]="true" styleClass="vida-sidebar" [(visible)]="isShowCollection">
  <div class="sidebar-header">
    <h1 class="title">Create New Collection
      <div class="fa fa-question-circle icon-help" (click)="openHelp()"></div>
    </h1>
  </div>
  <div class="sidebar-content">
    <form [formGroup]="collection">

      <div class="p-bottom-2">
        <label>Name</label>
        <input type="text" class="form-control" formControlName="collectionName" placeholder="New Collection">
        <error-display formControlName="collectionName" [errorMsg]="errorMessages.collectionName"></error-display>
      </div>

      <div>
        <label>Description</label>
        <textarea pInputTextarea class="full-width-input" rows="6" formControlName="collectionDescriptions"
                  placeholder="Give your collection a description"></textarea>
      </div>
    </form>
  </div>
  <div class="sidebar-footer">
    <a class="btn lnk-cancel" (click)="isShowCollection = false">Cancel</a>
    <button type="button" class="btn primary-btn" (click)="createCollection()" [disabled]="collection.pending || collection.invalid">SAVE</button>
  </div>
</p-sidebar>