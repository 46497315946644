import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AutocompleteExComponent, TypeaheadDatatableComponent, LoadingIndicatorDirective, PDropdownEx, PAccordionTabExDirective, ExDropdownDataComponent, SapMaterialComponent, ConfirmDialogContainer, LoadingIndicatorContainer, SapMaterialAutocompleteComponent } from './components';
import { HighlightPipe, TimePipe } from './pipes';
import { DebounceClickDirective, DynamicContentDirective, LockableElementDirective, NumericPrecisionDirective, NumericTextBoxDirective } from './directives';
import { ConfirmDialogService, ConfirmationDialogService, JobModalManagementService, DynamicComponentService } from './services';
import { DynamicSidebarRef, DynamicSidebarService } from './components/dynamic-sidebar';
import { ErrorDisplayComponent } from './validations';
import { UnitConversionModule } from './unit-conversions/unit-conversion.module';
import { FormValueAccessorDirective } from './form-value-accessor.directive';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { BlockUIModule } from 'primeng/blockui';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConfirmationDialogContainer } from './components/confirmation-dialog/confirmation-dialog.container';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { LoadingElementComponent } from './components/loading-element/loading-element.component';
import { NonExistentOptionPipe } from './pipes/non-existent-option.pipe';


const directives = [
    FormValueAccessorDirective,
    DebounceClickDirective,
    DynamicContentDirective,
    LoadingIndicatorDirective,
    NumericTextBoxDirective,
    PAccordionTabExDirective,
    LockableElementDirective,
    NumericPrecisionDirective
];

const components = [
    AutocompleteExComponent,
    ConfirmDialogContainer,
    ConfirmationDialogContainer,
    LoadingIndicatorContainer,
    DynamicSidebarRef,
    ErrorDisplayComponent,
    TypeaheadDatatableComponent,
    PDropdownEx,
    ExDropdownDataComponent,
    SapMaterialComponent,
    SapMaterialAutocompleteComponent
];

const pipes = [
    HighlightPipe,
    TimePipe,
    NonExistentOptionPipe
];

const services = [
    ConfirmationService,
    ConfirmDialogService,
    ConfirmationDialogService,
    DynamicSidebarService,
    DynamicComponentService,
    JobModalManagementService
];

const commonModules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    TypeaheadModule,
    SidebarModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    TooltipModule,
    BlockUIModule,
    KeyFilterModule,
    AutoCompleteModule,
    ContextMenuModule,
    TabViewModule,
    ToastModule
]

@NgModule({
    imports: [
        ...commonModules,
        UnitConversionModule.forRoot()
    ],
    declarations: [
        ...components,
        ...directives,
        ...pipes,
        LoadingElementComponent,
    ],
  exports: [
    ...components,
    ...directives,
    ...pipes,
    ...commonModules,
    UnitConversionModule,
    LoadingElementComponent,
  ],
    providers: []
})
export class UIModule {
    static forRoot(): ModuleWithProviders<UIModule> {
        return {
            ngModule: UIModule,
            providers: [...services]
        };
    }
}
