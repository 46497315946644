import { Component, Input, OnChanges, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UnitType, ERROR_TYPE, ERROR_MESSAGE, MODEL_CONTANTS, DeadVolumeFluidTypes, DeadVolumeFluidTypesForMixFluid, SpacerMixMethod, CustomUnit } from 'libs/constants';
import { ErrorMessageModel } from 'libs/ui';
import { ControlPointState, ControlPointType } from '../../../shared/constant';
import { ApplicationStateService, FluidService } from 'libs/shared/services';
import { ISAPMaterialModel, FluidModel, PumpScheduleStageModel, Job, UnitMeasureModel } from 'libs/models';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { calculateBulkCementByLoadOutVolume, calculateStageWater, calculateLoadOutVolumeByBulkCement, calculateLoadOutVolumeByDeadVolume } from 'libs/shared/calculations';
import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { Subscription } from 'rxjs';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { EditJobAdapter } from '../../../edit-job/adapters';

@AutoUnsubscribe()
@Component({
  selector: 'fluid-detail-info',
  templateUrl: './fluid-detail-info.component.html',
  styleUrls: ['./fluid-detail-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FluidDetailInfoComponent implements OnInit, OnChanges {

  @Input() fluidFormGroup: UntypedFormGroup;
  @Input() controlPointNumber: number;
  @Input() tabName: string;
  @Input() cpState: ControlPointState = 0;
  @Input() jobState: string = '';
  @Input() stageModel: PumpScheduleStageModel;
  @Input() stage: UntypedFormGroup; // Form Create Stage
  @Input() job: Job;
  @Input() isMix: boolean = false;
  @Input() isCementFluid: boolean = false;
  @Input() haveSupplemental: number = 0;
  @Input() OriginalJobId:string = null;
  cp4State: string;
  isManual: boolean;
  isOffshore: boolean;
  cp2State: string;
  deadVolumeFluidTypes: any[];
  mixWaterUnit: string;
  subscription = new Subscription();
  fluidFormGroupSubscription = new Subscription();

  UnitType = UnitType;
  errorMessages = {
    testTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Test Type'))
    ],
    slurryTypeId: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Type'))
    ],
    density: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Slurry Density'))
    ],
    sapMaterialName: [
      new ErrorMessageModel('notExisted', 'SAP Material does not exist. Please input another value.')
    ]
  };

  constructor(
    private applicationStateService: ApplicationStateService,
    private fluidService: FluidService,
    private cd: ChangeDetectorRef,
    private unitConversionService: UnitConversionService,
    private editJobAdapter: EditJobAdapter,
  ) { }

  ngOnInit() {
    if (this.fluidFormGroup.controls.loadoutVolume) {
      this.subscription.add(this.fluidFormGroup.controls.loadoutVolume.valueChanges.pipe(distinctUntilChanged()).subscribe(loadOutVolume => {
        if (this.fluidFormGroup.controls.loadoutVolume.dirty) {
          this.stage.controls.isChangeLoadOutVolume.setValue(true, { emitEvent: false });
          this.setValueBulkCementAndStageWater(loadOutVolume);
          this.fluidFormGroup.controls.isManuallyDeadVolume.setValue(true, { emitEvent: false });
        } else if (this.stage.controls && !this.stage.controls.isBulkCement) { // If Bulk Cement unset 
          this.setValueBulkCementAndStageWater(loadOutVolume);
        }

        this.cd.markForCheck();
      }));
    }

    if (this.fluidFormGroup.controls.deadVolume) {
      this.subscription.add(this.fluidFormGroup.controls.deadVolume.valueChanges.pipe(
        distinctUntilChanged(),
        filter(_ => !this.fluidFormGroup.controls.deadVolumeFluidType || this.fluidFormGroup.controls.deadVolumeFluidType.value !== 1)
      ).subscribe(deadVolume => {
        if (this.fluidFormGroup.controls.deadVolume.value === null) {
          this.fluidFormGroup.controls.deadVolume.setValue(0);
        }
        if (this.fluidFormGroup.controls.deadVolume.dirty && (!this.stageModel || !this.stageModel.isManuallyDeadVolume)) {
          this.setValueLoadOutAndBulkCement(deadVolume);
        }

        this.cd.markForCheck();
      }));
    }

    if (this.fluidFormGroup.controls.bulkCement) {
      this.subscription.add(this.fluidFormGroup.controls.bulkCement.valueChanges.pipe(distinctUntilChanged()).subscribe(bulkCement => {
        if (this.fluidFormGroup.controls.bulkCement.dirty) {
          this.setValueLoadOutAndStageWater(bulkCement);
          this.fluidFormGroup.controls.isManuallyDeadVolume.setValue(true, { emitEvent: false });
        }

        this.cd.markForCheck();
      }));
    }

    this.subscription.add(this.fluidService.changeSAPMaterial$.pipe(
      filter(fluidSAPNotification => fluidSAPNotification.source !== this)
    ).subscribe(fluidSAPNotification => {
      if (this.fluidFormGroup.controls.id.value === fluidSAPNotification.fluid.id) {
        this.fluidFormGroup.controls.sapMaterialName.setValue(fluidSAPNotification.fluid.sapMaterialName);
        this.fluidFormGroup.controls.sapMaterialNumber.setValue(fluidSAPNotification.fluid.sapMaterialNumber);
      }

      this.cd.markForCheck();
    }));

    this.subscription.add(this.fluidFormGroup.valueChanges.subscribe(() => {
      this.applicationStateService.validateCompletedPumpSchedule$.next();

      this.cd.markForCheck();
    }));

    this.subscription.add(this.applicationStateService.isChangeOffshore$.subscribe(value => {
      if (value) {
        this.isOffshore = true;
      } else {
        this.isOffshore = false;
      }

      this.cd.markForCheck();
    }));

    this.subscription.add(this.applicationStateService.spacerMixMethodChange$.subscribe(() => {
      this.setMixWaterUnit();
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    const {fluidFormGroup} = changes;
    if (fluidFormGroup && this.fluidFormGroup.controls.slurryTypeId) {
      if (this.fluidFormGroupSubscription && !this.fluidFormGroupSubscription.closed) {
        this.fluidFormGroupSubscription.unsubscribe();
      }
      this.fluidFormGroupSubscription = (this.fluidFormGroup.controls.slurryTypeId.valueChanges.subscribe(v => {
        this.editJobAdapter.slurryTypeChanges(this.fluidFormGroup.getRawValue() as FluidModel);
        this.setMixWaterUnit();
      }));
    }

    if (this.job && this.job.controlPoints && this.job.controlPoints.length) {
      this.cp4State = this.job.controlPoints[3].controlPointState.name;
      this.cp2State = this.job.controlPoints[1].controlPointState.name;
    }

    if (this.isMix) {
      this.deadVolumeFluidTypes = DeadVolumeFluidTypes;
      if (this.haveSupplemental > 0) {
        this.deadVolumeFluidTypes = DeadVolumeFluidTypesForMixFluid;
      }
    } else {
      this.deadVolumeFluidTypes = DeadVolumeFluidTypesForMixFluid;
    }
  }

  setValueLoadOutAndBulkCement(deadVolume: number) {
    const plannedVolume = this.fluidFormGroup.controls.plannedVolume.value;
    const loadOutVolume = calculateLoadOutVolumeByDeadVolume(deadVolume, plannedVolume);
    this.fluidFormGroup.controls.loadoutVolume.setValue(loadOutVolume, { emitEvent: false });
    this.setValueBulkCementAndStageWater(loadOutVolume);

    this.cd.markForCheck();
  }

  setValueBulkCementAndStageWater(loadOutVolume: number) {
    const yieldData = this.fluidFormGroup.controls.yield ? this.fluidFormGroup.controls.yield.value : 0;
    const mixWater = this.fluidFormGroup.controls.mixWater ? this.fluidFormGroup.controls.mixWater.value : 0;
    const plannedVolume = this.fluidFormGroup.controls.plannedVolume ? this.fluidFormGroup.controls.plannedVolume.value : 0;

    const bulkCement = calculateBulkCementByLoadOutVolume(loadOutVolume, plannedVolume, yieldData);
    const stageWater = calculateStageWater(loadOutVolume, plannedVolume, yieldData, mixWater);

    this.fluidFormGroup.controls.stageWaterTotal.setValue(stageWater);
    this.fluidFormGroup.controls.waterRequirements.setValue(stageWater);
    this.fluidFormGroup.controls.bulkCement.setValue(bulkCement, { emitEvent: false });
    this.stage.controls.bulkCement.setValue(bulkCement, { emitEvent: false });
    this.stage.controls.isBulkCement.setValue(true, { emitEvent: false });

    this.cd.markForCheck();
  }

  setValueLoadOutAndStageWater(bulkCement: number) {
    const yieldData = this.fluidFormGroup.controls.yield ? this.fluidFormGroup.controls.yield.value : 0;
    const mixWater = this.fluidFormGroup.controls.mixWater ? this.fluidFormGroup.controls.mixWater.value : 0;
    const plannedVolume = this.fluidFormGroup.controls.plannedVolume ? this.fluidFormGroup.controls.plannedVolume.value : 0;
    const loadOutVolume = calculateLoadOutVolumeByBulkCement(bulkCement, yieldData);
    const stageWater = calculateStageWater(loadOutVolume, plannedVolume, yieldData, mixWater);

    this.fluidFormGroup.controls.loadoutVolume.setValue(loadOutVolume, { emitEvent: false });
    this.fluidFormGroup.controls.stageWaterTotal.setValue(stageWater);
    this.fluidFormGroup.controls.waterRequirements.setValue(stageWater);
    this.stage.controls.isChangeLoadOutVolume.setValue(true, { emitEvent: false });
    this.stage.controls.loadoutVolume.setValue(loadOutVolume, { emitEvent: false });
    this.stage.controls.bulkCement.setValue(bulkCement, { emitEvent: false });
    this.stage.controls.isBulkCement.setValue(true, { emitEvent: false });

    this.cd.markForCheck();
  }

  get isFoam(): boolean {
    return this.fluidFormGroup.controls['isFoam'].value;
  }
 
  checkDisableItem() {
    const result = (this.jobState !== 'Completed' && 
    this.cp4State !== 'Completed' && 
    (this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) && 
    (this.cp2State && this.cp2State !== 'Completed')) ? 'exception-disable-item' : '';
    return result;
  }
 
  onChangeSAP(sapMaterial: ISAPMaterialModel) {
    if (sapMaterial)
      this._notifySAPChange(this.fluidFormGroup.controls.id.value, sapMaterial.materialName, sapMaterial.materialNumber);
  }

  _notifySAPChange(fluidId: string, sapMaterialName: string, sapMaterialNumber: string) {
    if (fluidId && fluidId !== MODEL_CONTANTS.EMPTY_GUID) {
      const fluidModel = new FluidModel();
      fluidModel.id = this.fluidFormGroup.controls.id.value;
      fluidModel.sapMaterialName = sapMaterialName;
      fluidModel.sapMaterialNumber = sapMaterialNumber;
      this.fluidService.changeSAPMaterial$.next({ source: this, fluid: fluidModel });
    }

    this.cd.markForCheck();
  }

  get isCP4(): boolean {
    return this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint4.toString();
  }

  getDeadVolumeFluidTypeName(index): string {
    return DeadVolumeFluidTypes[index] ? DeadVolumeFluidTypes[index].label : '';
  }

  setMixWaterUnit() {
    // User Story 386876
    const value = this.applicationStateService.spacerMixMethodChange$.value;
    const unit = this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);
    const slurryTypeId = this.fluidFormGroup.controls.slurryTypeId.value;
    const slurryType = this.editJobAdapter.slurryTypeData$.value.find(x => x.value === slurryTypeId);

    if (value === SpacerMixMethod.MixOnTheFly || !slurryType || slurryType.label !== 'Spacer') {
      this.mixWaterUnit = null;
    } else if (value === SpacerMixMethod.BatchMix) {
      if (unit.name === 'bbl') {
        this.mixWaterUnit = CustomUnit.galbbl;
      }

      if (unit.name === 'm3') {
        this.mixWaterUnit = CustomUnit.lm3;
      }
    }

    this.cd.markForCheck();
  }
}
