import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'libs/environment';
import { FluidModel, IJobModel } from 'libs/models';
import { FLUID_API_URL, JOB_CASING_EQUIPMENT, JOB_LOG } from 'libs/constants';
import { HttpService } from './http.service';
import { JobCasingEquipment, JobCasingEquipmentCP4, JobLog } from 'libs/models/entities';
import { ControlPointType } from 'apps/vida/src/modules/shared/constant';

@Injectable()
export class JobService {

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService
  ) { }

  getJobsInSameWell(jobId: string) {
    return this.httpService.Get<IJobModel[]>(`${environment.baseUrl}/api/jobs/${jobId}/jobs-in-same-well`);
  }

  getFluidTestDocument(keyword : Number) :Observable<{url: string}>{
    return this.httpService.Get(`${environment.baseUrl}/api/jobs/design-document-url/${keyword}`);
  }

  getFluidSummary(jobId: string, onlyGetiFact: boolean): Observable<FluidModel[]> {
    return this.httpService.Get(`${environment.baseUrl}/api/jobs/${jobId}/fluids/${onlyGetiFact}`);
  }

  linkFluids(jobId, requests: any[]): Observable<any> {
    return this.httpService.Put(FLUID_API_URL.LINK_FLUIDS_URL(jobId), JSON.stringify(requests));
  }

  linkRequest(jobId, fluids: FluidModel[], source: string = null): Observable<FluidModel[]> {
    return this.httpClient.post<FluidModel[]>(FLUID_API_URL.LINK_REQUEST_URL(jobId, source), fluids);
  }

  linkRequestFromDashboard(jobId, fluids: FluidModel[]): Observable<FluidModel[]> {
    return this.httpClient.post<FluidModel[]>(FLUID_API_URL.LINK_FLUIDS_DASHBOARD_URL(jobId), fluids);
  }

  deleteFluid(id: string): Observable<any> {
    return this.httpService.Delete(`${environment.baseUrl}/api/jobs/${id}/fluid`);
  }

  getJobLog(jobId: string): Observable<JobLog> {
    return this.httpService.Get<JobLog>(JOB_LOG.GET(jobId)).pipe(
      tap((x) => {
        if (x && x.events) {
          x.events.forEach(item => {
            item.eventTime = new Date(Date.parse(item.eventTime.toString()));
          });
        }
      })
    );
  }

  getJobCasingEquipment(jobId: string): Observable<JobCasingEquipment[]> {
    return this.httpService.Get<JobCasingEquipment[]>(`${environment.baseUrl}/api/job-casing-equipment/${jobId}`);
  }

  setJobCasingEquipment(jobId: string, casingEquipment: JobCasingEquipment[]): Observable<JobCasingEquipment[]> {
    return this.httpService.Put(`${environment.baseUrl}/api/job-casing-equipment/${jobId}`, JSON.stringify({ casingEquipments: casingEquipment }));
  }

  setJobCasingEquipmentCommon(jobId: string, casingEquipment: JobCasingEquipment[]): Observable<boolean> {
    return this.httpService.Put(`${environment.baseUrl}/api/job-casing-equipment/${jobId}/common`, JSON.stringify(casingEquipment));
  }

  getJobCasingEquipmentForCP4(jobId: string, controlPointType: ControlPointType = null): Observable<JobCasingEquipmentCP4[]> {
    return this.httpService.Get<JobCasingEquipmentCP4[]>(JOB_CASING_EQUIPMENT.GET(jobId));
  }

  setJobCasingEquipmentForCP1(jobId: string, casingEquipment: JobCasingEquipment[]): Observable<JobCasingEquipment[]> {
    return this.httpService.Put(JOB_CASING_EQUIPMENT.PUT(jobId), JSON.stringify(casingEquipment));
  }

  getScope3CarbonFootprint(request): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/pumpSchedule/carbonfooprint-per-barrel/${request.slurryId}/${request.groupId}`);
  }
}
