import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MudParameterModel } from 'libs/models';

export function createMudParameterFromGroup(fb: UntypedFormBuilder, data: MudParameterModel = null): UntypedFormGroup {
  const formData = data || {
    id: null,
    mudTypeId: null,
    typeName: null,
    mudName: null,
    mudDensity: null,
  };

  return fb.group(formData);
}
