<p-dialog [(visible)]="isVisible" [responsive]="true" [draggable]="false" styleClass="dialog-custom"
  [style]="{width: '600px', height: '300px'}" modal="false" dismissableMask="true" [closable]="false">
  <p-header style="font-size: 24px">
    Confirmation
    <span class="pi pi-times close-custom" (click)="closeDialog(false)"></span>
  </p-header>
  <form class="dialog-message" style="font-size: 14px">
    <span class="text-left">All materials have NOT been pushed to the bulk plant.<br/>Are you sure you wish to complete CP2?</span>
  </form>
  <div>
    <div class="dv-cancel-btn">
      <button class="btn cancel-btn" (click)="closeDialog(false)">Cancel</button>
    </div>
    <div class="dv-yes-btn">
      <button class="btn primary-btn yes-btn" (click)="closeDialog(true)">YES</button>
    </div>
  </div>
</p-dialog>
