import { DeadVolumeFluidType, UnitType } from 'libs/constants';
import { MaterialManagementMappingModel } from 'libs/models';
import { calculateMaterialCost, calculateMaterialQuantity } from 'libs/shared/calculations';
import { convertWithUnitMeasure, UnitConversionService } from 'libs/ui/unit-conversions';
import { combineLatest, concat, merge, Observable, of } from 'rxjs';
import { map, shareReplay, skip, switchMap, take } from 'rxjs/operators';
import { StageFluidCalculator } from './stage-calculator';

export interface CalculatedVolume {
    UnitType: UnitType;
    Value: number;
}

export interface CalculatedCogs {
    uomIncompatible: boolean;
    value: number;
}

export class FluidMaterialCalculator {

    private static GAL_TO_BBL_FACTOR: number = 1.0 / 42;

    private static LITER_TO_BBL_FACTOR = 0.0062898;

    private static GRAM_TO_LBS_FACTOR: number = 0.002204622622;

    private static GAL_TO_CC_FACTOR: number = 1.0 / 0.00026417205;

    private static BBL_TO_CC_FACTOR: number = 158987.2949;

    public readonly plannedVolume$: Observable<CalculatedVolume> = this._stageCalc.plannedVolume$
        .pipe(
            map(stageVolume => {

                return this._calcVolume(stageVolume);
            }),
            shareReplay()
        );

    public readonly loadoutVolume$: Observable<CalculatedVolume> = this._stageCalc.loadoutVolume$
        .pipe(
            map(stageVolume => {

                return this._calcVolume(stageVolume);
            }),
            shareReplay()
        );

    public readonly loadoutVolumeBbl$: Observable<number> = this.loadoutVolume$
        .pipe(
            map(volume => {

                return this._toBbl(volume);
            }),
            shareReplay()
        );

    private readonly _deadVolume$: Observable<number> =
        combineLatest([
            this.loadoutVolume$,
            this.loadoutVolumeBbl$,
            this._stageCalc.totalVolumeWithStageWater$,
            this._stageCalc.deadVolumeType$,
            this._stageCalc.deadVolume$,
        ])
            .pipe(
                map(([
                    loadoutVolume,
                    loadoutVolumeBbl,
                    totalStageVolume,
                    stageDeadVolumeType,
                    stageDeadVolume
                ]) => {

                    if (this._hasDeadVolume(loadoutVolume.UnitType, stageDeadVolumeType, stageDeadVolume)) {

                        return this._calcDeadVolume(loadoutVolumeBbl, totalStageVolume, stageDeadVolumeType, stageDeadVolume);
                    }

                    return 0;
                }),
                shareReplay()
            );

    public readonly deadVolumeType$ = this._stageCalc.deadVolumeType$

    public readonly isLiquid$: Observable<boolean> = this.loadoutVolume$
        .pipe(
            map(volume => {

                return volume.UnitType === UnitType.SmallVolume;
            }),
            shareReplay()
        );

    public readonly disableManualOverride$: Observable<boolean> =
        combineLatest([
            this._stageCalc.deadVolume$,
            this._stageCalc.deadVolumeType$,
            this._addToMixWater$,
            this.isLiquid$
        ])
            .pipe(
                map(([stageDeadVolume, stageDeadVolumeType, addToMixWater, isLiquid]) => {

                    // previous calculation:
                    /* return this._isLiquid
                        ? this._hasDeadVolume(loadoutVolume.UnitType, stageDeadVolumeType, stageDeadVolume)
                        : addToMixWater && !!stageDeadVolume; */

                    return isLiquid
                        ? stageDeadVolumeType === DeadVolumeFluidType.MixFluid && !!stageDeadVolume && addToMixWater
                        : addToMixWater && !!stageDeadVolume;
                }),
                shareReplay()
            );

    public readonly volumeCC$: Observable<number> =
        combineLatest([
            this.plannedVolume$,
            this._addToMixWater$,
            this.isLiquid$
        ])
            .pipe(
                map(([plannedVolume, addToMixWater, isLiquid]) => {

                    if (!addToMixWater) {
                        return 0;
                    }

                    if (!plannedVolume.Value) {
                        return 0;
                    }

                    return isLiquid
                        ? plannedVolume.Value * FluidMaterialCalculator.GAL_TO_CC_FACTOR
                        : plannedVolume.Value / FluidMaterialCalculator.GRAM_TO_LBS_FACTOR / this._sg
                }),
                shareReplay()
            );

    public readonly massGrm$: Observable<number> =
        combineLatest([
            this.plannedVolume$,
            this._addToMixWater$,
            this.isLiquid$,
        ])
            .pipe(
                map(([plannedVolume, addToMixWater, isLiquid]) => {

                    if (!addToMixWater) {
                        return 0;
                    }

                    if (!plannedVolume.Value) {
                        return 0;
                    }

                    return isLiquid
                        ? plannedVolume.Value * FluidMaterialCalculator.GAL_TO_CC_FACTOR * this._sg
                        : plannedVolume.Value / FluidMaterialCalculator.GRAM_TO_LBS_FACTOR;
                }),
                shareReplay()
            );

    public readonly partialOverrideQty$: Observable<number> =
        combineLatest([
            this.plannedVolume$,
            this._stageCalc.deadVolume$,
            this._stageCalc.stageWaterMassGrm$,
            this._stageCalc.totalVolumeCC$,
            this._stageCalc.totalMassGrm$,
            this.massGrm$,
            this.isLiquid$
        ]).pipe(
            map(([plannedVolume, stageDeadVolume, stageWaterMassGrm, totalVolumeCC, totalMassGrm, massGrm, isLiquid]:
                [CalculatedVolume, number, number, number, number, number, boolean]) => {

                // supplemental
                if (!this.isAdditive) {
                    return ((plannedVolume.Value) / (totalVolumeCC / FluidMaterialCalculator.BBL_TO_CC_FACTOR)) * stageDeadVolume;
                }

                // additives
                const p = (stageWaterMassGrm / totalMassGrm * ((stageDeadVolume * FluidMaterialCalculator.BBL_TO_CC_FACTOR) * totalMassGrm / totalVolumeCC));

                return isLiquid
                    ? p / stageWaterMassGrm * massGrm / this._sg / FluidMaterialCalculator.GAL_TO_CC_FACTOR
                    : p / stageWaterMassGrm * massGrm * FluidMaterialCalculator.GRAM_TO_LBS_FACTOR;
            }),
            shareReplay()
        );

    public readonly overrideVolume$: Observable<CalculatedVolume> = this.disableManualOverride$
        .pipe(
            switchMap(disable => {

                if (!disable) {

                    return combineLatest([
                        this.loadoutVolume$,
                        this._manualOverrideVolume$
                    ])
                        .pipe(
                            map(([loadoutVolume, manualOverrideVolume]) => {

                                return {
                                    UnitType: loadoutVolume.UnitType,
                                    Value: manualOverrideVolume
                                };
                            })
                        );
                }

                return combineLatest([
                    this.plannedVolume$,
                    this.partialOverrideQty$
                ])
                    .pipe(
                        map(([plannedVolume, partialOverrideQty]) => {

                            // previous calculation:
                            //  loadoutVolume: this.loadoutVolume$
                            //  deadVolume: this._deadVolume$
                            // return this._calcOverrideVolume(loadoutVolume, deadVolume);

                            const result = {
                                Value: (plannedVolume.Value ?? 0) + partialOverrideQty,
                                UnitType: plannedVolume.UnitType
                            };
                            return result;
                        })
                    );
            }),
            shareReplay()
        );

    public readonly dryWeight$: Observable<number> = this._hasDryWeight$
        .pipe(
            switchMap(itHas => {

                if (!itHas) {

                    return of(0);
                }

                return combineLatest([
                    this.loadoutVolume$,
                    this._manualOverrideVolume$
                ])
                    .pipe(
                        map(([loadout, override]) => {

                            // This is not dry material;
                            if (loadout.UnitType !== UnitType.Weight) {

                                return 0;
                            }

                            if (!!override || override === 0) {

                                return override;
                            }

                            return loadout.Value;
                        })
                    );
            }),
            shareReplay()
        );

    public readonly dryVolume$: Observable<CalculatedVolume> =
        this._disableDryVolumeCalculation
            ? of({
                UnitType: UnitType.DryVolume,
                Value: this._savedDryVolume
            })
            : combineLatest([
                this.dryWeight$,
                this._materialMapping$
            ])
                .pipe(
                    map(([dryWeight, materialMapping]) => {

                        return this._calcDryVolume(dryWeight, materialMapping);
                    }),
                    shareReplay()
                );

    public readonly plannedScope3Co2e$: Observable<number> = of(this._plannedScope3Co2e);

    public readonly actualScope3Co2e$: Observable<number> = of(this._actualScope3Co2e);

    public readonly actualVolume$: Observable<CalculatedVolume> =
        concat(
            // If no saved material actual volume, calculate it from stage actual volume
            combineLatest([
                this._manualActualVolume$,
                this.plannedVolume$,          // Planned volume here to know units of measure only
                this._stageCalc.actualVolume$
            ])
                .pipe(
                    map(([manualActualVolume, plannedVolume, stageActualVolume]) => {

                        if (!manualActualVolume && manualActualVolume !== 0) {

                            const volume = this._calcVolume(stageActualVolume);

                            // 0 actual volume is treated as set manually by user and not recalculated.
                            // Do not set calculated value to 0, to allow later reclaculation (e.g when data from iFacts arrives).
                            if (volume.Value === 0) {

                                volume.Value = null;
                            }

                            return volume;
                        }

                        return {
                            UnitType: plannedVolume.UnitType,
                            Value: manualActualVolume
                        };
                    }),
                    take(1),    // Do all above for actual volume just one time after data from server arrived
                ),
            // Later take either value - manually set or calculated from stage actual volume - which is latest
            merge(
                combineLatest([
                    this._manualActualVolume$,
                    this.plannedVolume$,          // Planned volume here to know units of measure only
                ])
                    .pipe(
                        skip(1),    // Skip first form patch after data from server arrived
                        map(([manualActualVolume, plannedVolume]) => {

                            return {
                                UnitType: plannedVolume.UnitType,
                                Value: manualActualVolume
                            };
                        })
                    ),
                this._stageCalc.actualVolume$
                    .pipe(
                        skip(1),    // Skip first form patch after data from server arrived
                        map(stageActualVolume => {

                            return this._calcVolume(stageActualVolume);
                        })
                    )
            )
        )
            .pipe(
                shareReplay()
            );

    public readonly cogs$: Observable<CalculatedCogs> = combineLatest([
        this.plannedVolume$,
        this._materialMapping$
    ])
        .pipe(
            map(([volume, materialMapping]) => {

                return this._calcCogs(volume, materialMapping);
            }),
            shareReplay()
        );

    public constructor(
        private readonly _materialMapping$: Observable<MaterialManagementMappingModel>,
        private readonly _stageCalc: StageFluidCalculator,
        private readonly _fluidMixVolume: number,
        private readonly _fluidYield: number,
        private readonly _fluidSackWeight: number,
        private readonly _fluidMixWater: number,
        private readonly _fluidWaterDensity: number,
        private readonly _fluidDensity: number,
        private readonly _outputUnit: string,
        private readonly _sg: number,
        private readonly _testAmount: number,
        private readonly _concentration: number,
        private readonly _concentrationUnit: string,
        private readonly _bulkDensity: number,
        private readonly _isLiquid: boolean,
        private readonly _manualOverrideVolume$: Observable<number>,
        private readonly _manualActualVolume$: Observable<number>,
        private readonly _savedDryVolume: number,
        private readonly _hasDryWeight$: Observable<boolean>,
        private readonly _disableDryVolumeCalculation: boolean,
        private readonly _unitConversionService: UnitConversionService,
        private readonly _plannedScope3Co2e: number,
        private readonly _actualScope3Co2e: number,
        private readonly _addToMixWater$: Observable<boolean>,
        public readonly isAdditive: boolean
    ) { }

    private _hasDeadVolume(unitType, stageDeadVolumeType: DeadVolumeFluidType, deadVolume: number): boolean {

        return this._isLiquid
            && unitType !== UnitType.Weight
            && stageDeadVolumeType === DeadVolumeFluidType.MixFluid
            && !!deadVolume;
    }

    private _calcVolume(stageVolume: number): CalculatedVolume {

        const materialVolume: CalculatedVolume = calculateMaterialQuantity(
            this._outputUnit,
            this._sg,
            this._testAmount,
            stageVolume,
            this._fluidMixVolume,
            this._fluidYield,
            this._concentration,
            this._concentrationUnit,
            this._fluidSackWeight,
            this._fluidMixWater,
            this._fluidWaterDensity,
            this._fluidDensity
        );

        if (materialVolume.Value === null || isNaN(materialVolume.Value) || !isFinite(materialVolume.Value)) {

            materialVolume.Value = !!this._concentrationUnit ? 0 : null;

        }

        return materialVolume;
    }

    private _toBbl(volume: CalculatedVolume): number {

        if (!this._isLiquid || !volume.Value) {

            return 0;
        }

        if (volume.UnitType === UnitType.SmallVolume) {

            const bbl = volume.Value * FluidMaterialCalculator.GAL_TO_BBL_FACTOR;

            return bbl;
        }

        if (volume.UnitType === UnitType.Weight) {

            const volumeLiters = convertWithUnitMeasure(
                volume.Value,
                this._unitConversionService.getCurrentUnitMeasure(volume.UnitType),
                this._unitConversionService.getSiUnitMeasure(UnitType.Weight)
            );

            const volumeBbl = volumeLiters / this._sg * FluidMaterialCalculator.LITER_TO_BBL_FACTOR;

            return volumeBbl;
        }

        return 0;
    }

    private _calcDeadVolume(
        loadoutVolume: number,
        totalStageVolume: number,
        deadVolumeType: DeadVolumeFluidType,
        stageDeadVolume: number
    ): number {

        if (deadVolumeType === DeadVolumeFluidType.CementSlurry
            || !totalStageVolume
            || !stageDeadVolume) {

            return null;
        }

        const dw = loadoutVolume / totalStageVolume * stageDeadVolume;
        return dw;
    }

    private _calcOverrideVolume(loadoutVolume: CalculatedVolume, deadVolume: number): CalculatedVolume {

        const result = {
            Value: null,
            UnitType: loadoutVolume.UnitType
        };

        if (!this._isLiquid || !loadoutVolume.Value || !deadVolume) {

            return result;
        }

        if (loadoutVolume.UnitType === UnitType.SmallVolume) {


            result.Value = loadoutVolume.Value + deadVolume / FluidMaterialCalculator.GAL_TO_BBL_FACTOR;
            return result;
        }

        if (loadoutVolume.UnitType === UnitType.Weight) {

            const loadoutLiters = convertWithUnitMeasure(
                loadoutVolume.Value,
                this._unitConversionService.getCurrentUnitMeasure(loadoutVolume.UnitType),
                this._unitConversionService.getSiUnitMeasure(UnitType.Weight)
            );

            const loadoutBbl = loadoutLiters / this._sg * FluidMaterialCalculator.LITER_TO_BBL_FACTOR;

            result.Value = (loadoutBbl + deadVolume) / FluidMaterialCalculator.LITER_TO_BBL_FACTOR * this._sg;
        }

        return result;
    }

    private _calcDryVolume(dryWeight: number, materialMapping: MaterialManagementMappingModel): CalculatedVolume {

        const emptyDryVolume: CalculatedVolume = {
            UnitType: UnitType.DryVolume,
            Value: null
        };

        if (!materialMapping || !materialMapping.bulkDensity) {

            return emptyDryVolume;
        }

        const bulkDensityUnitMeasure = this._unitConversionService
            .getUnitMeasureById(UnitType.Density, materialMapping.bulkDensityUnitMeasureId);

        if (!bulkDensityUnitMeasure) {

            return emptyDryVolume;
        }

        const zeroDryVolume: CalculatedVolume = {
            UnitType: UnitType.DryVolume,
            Value: 0
        };

        if (!dryWeight) {

            return zeroDryVolume;
        }

        const apiDensityUnitMeasure = this._unitConversionService
            .getApiUnitMeasure(UnitType.Density);

        const apiBulkDensity = convertWithUnitMeasure(
            materialMapping.bulkDensity,
            bulkDensityUnitMeasure,
            apiDensityUnitMeasure
        ); // ppg (pounds per gallon)

        if (apiBulkDensity === 0) {    // the same as no bulk density

            return emptyDryVolume;
        }

        const apiLiquidVolume = dryWeight / apiBulkDensity; // volume in gallons

        const apiLiquidVolumeUnitMeasure = this._unitConversionService
            .getApiUnitMeasure(UnitType.SmallVolume);

        const apiDryVolumeUnitMeasure = this._unitConversionService
            .getApiUnitMeasure(UnitType.DryVolume);

        const apiDryVolume = convertWithUnitMeasure(
            apiLiquidVolume,
            apiLiquidVolumeUnitMeasure,
            apiDryVolumeUnitMeasure
        ); // ft3

        return {
            UnitType: UnitType.DryVolume,
            Value: apiDryVolume
        };
    }

    private _calcCogs(
        volume: CalculatedVolume,
        materialMapping: MaterialManagementMappingModel
    ): CalculatedCogs {

        const noCost: CalculatedCogs = {
            uomIncompatible: false,
            value: null
        };

        if (!materialMapping
            || (!materialMapping.cogsPrice && materialMapping.cogsPrice !== 0)
            || !materialMapping.cogsUnitMeasureName
        ) {

            return noCost;
        }

        const zeroCost = {
            uomIncompatible: false,
            value: 0
        };

        if (materialMapping.cogsPrice === 0 || this._concentration === 0) {

            return zeroCost;
        }

        let calculatedCost: CalculatedCogs = {
            uomIncompatible: false,
            value: null
        };

        if (materialMapping.cogsPrice > 0
            && materialMapping.cogsUnitMeasureName
            // && (!!volume.Value && volume.Value !== 0)
            && volume.UnitType) {

            calculatedCost = calculateMaterialCost(
                volume.Value,
                volume.UnitType,
                this._sg,
                materialMapping.cogsPrice,
                materialMapping.cogsUnitMeasureName,
                materialMapping.sackWeight,
                this._bulkDensity,
                this._fluidYield
            );
        }

        return calculatedCost;
    }
}
