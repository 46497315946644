import { Injectable } from '@angular/core';

@Injectable()
export class MailService {

  constructor() { }

  sendReportSAPMismap() {
    const to = 'FHOUifacts-CMT@Halliburton.com';
    const subject = 'Report SAP Mismap';
    const lines: string[] = [
      'I\'d like to report an incorrect SAP mapping found in VIDA.',
      '%0D %0D',
      'Please see below:',
      '%0D %0D',
      'Location:',
      '%0D[Location]',
      '%0D %0D',
      'Current (Incorrect) Mapping:',
      '%0D(Enter SAP Name / Number ) --> (Enter iFacts Name)',
      '%0D %0D',
      'Recommended (Correct) iFacts Mapping:',
      '%0D(Enter iFacts Name)',
      '%0D %0D',
      'Thank you,',
      '%0D %0D',
      '[User Name]',
      '%0D '
    ];
    const body = lines.join('');

    window.location.href = 'mailto:' + to + '?Subject=' + subject + '&body=' + body;
  }
}
