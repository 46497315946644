import { UntypedFormGroup } from '@angular/forms';

import { ControlPointType } from 'apps/vida/src/modules/shared/constant/control-point';

export class CompatibilityConfirmation {
  constructor(
    public id: string = null,
    public jobId: string = null,
    public controlPointType: ControlPointType = ControlPointType.ControlPoint1,
    public isIDODCompatibilityConfirmed: boolean = null,
    public idodCompatibilityComment: string = null,
    public isThreadCompatibilityConfirmed: boolean = null,
    public threadCompatibilityComment: string = null,
    public isPressureCompatibilityConfirmed: boolean = null,
    public pressureCompatibilityComment: string = null,
    public isTemperatureCompatibilityConfirmed: boolean = null,
    public temperatureCompatibilityComment: string = null,
    public isCasingWeightCompatibilityConfirmed: boolean = null,
    public casingWeightCompatibilityComment: string = null
  ) { }

  public static toModel(fg: UntypedFormGroup): CompatibilityConfirmation {
    const res: CompatibilityConfirmation = fg.getRawValue();
    return res;
  }
}
