<div>
  <div>{{content}}</div>
  <div class="p-top-2">
    <div class="p-bottom-1">Comments/Justification*</div>
    <textarea style="height: 70px" type="text" class="form-control" [formControl]="jobStateControl"></textarea>
    <div *ngIf="displayTextError()" class="text-error-message">{{displayTextError()}}</div>
  </div>
  <div class="modal-footer text-right">
    <button class="btn cancel-btn" (click)="onClose()">Cancel</button>
    <button class="btn primary-btn confirm-btn" (click)="onSubmit()">Confirm</button>
  </div>
</div>