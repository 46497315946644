import { ControlPointType } from 'apps/vida/src/modules/shared/constant';
import { environment } from 'libs/environment';

export const API_URL = {
  TEST_TYPES_URL: `${environment.baseUrl}/api/test-types`,
  SLURRY_TYPES_URL: `${environment.baseUrl}/api/slurry-types`,
  PRIMARY_STATUSES_URL: `${environment.baseUrl}/api/primary-statuses`,
  BOM_TYPE_URL: `${environment.baseUrl}/api/bom-type`,
  MATERIAL_TYPES_URL: `${environment.baseUrl}/api/fluids/material-types`,
  CONCENTRATION_UNIT_URL: `${environment.baseUrl}/api/fluids/unit-measure`,
  ADMIN_REGIONS_URL: `${environment.baseUrl}/api/admin/regions`,
  CASING_SIZES_URL: `${environment.baseUrl}/api/casing-size`,
  HOLE_SIZES_URL: `${environment.baseUrl}/api/hole-size`,
  BUILD_NUMBER: `${environment.baseUrl}/api/system-settings/build-number`
};

export const FLUID_API_URL = {
  LINK_REQUEST_URL: (jobId, source) => `${environment.baseUrl}/api/jobs/${jobId}/link-request/${source}`,
  LINK_FLUIDS_URL: (jobId) => `${environment.baseUrl}/api/jobs/${jobId}/link-fluids`,
  LINK_FLUIDS_DASHBOARD_URL: (jobId) => `${environment.baseUrl}/api/jobs/${jobId}/link-fluids-dashboard`,
  MATERIAL_SEARCH_URL: `${environment.baseUrl}/api/fluids/material/search`
};

export const SAP_API_URL = {
  SAP_MATERIAL_BY_ID_URL: (id) => `${environment.baseUrl}/api/fluids/sap-material/${id}`,
  SAP_MATERIAL_BY_NUMBER_URL: (number) => `${environment.baseUrl}/api/fluids/sap-material-by-number/${number}`,
  SAP_MATERIAL_SEARCH_URL: `${environment.baseUrl}/api/fluids/sap-material/search`,
  SAP_MATERIAL_MISSING: `${environment.baseUrl}/api/fluids/sap-material/missing`,
};


export const PUMP_SCHEDULE_API_URL = {
  FLUID_TYPE_URL: `${environment.baseUrl}/api/pumpSchedule/fluid-type`,
  PLACEMENT_METHOD_URL: (stageSequence: number) => `${environment.baseUrl}/api/pumpSchedule/placement-method/${stageSequence}`
};

export const CACHABLE_URL = [
  // API_URL.TEST_TYPES_URL,
  // API_URL.SLURRY_TYPES_URL,
  // API_URL.PRIMARY_STATUSES_URL,
  // API_URL.MIX_WATERS_URL,
  // API_URL.BOM_TYPE_URL,
  // API_URL.MATERIAL_TYPES_URL,
  // API_URL.CONCENTRATION_UNIT_URL,

  // SAP_API_URL.SAP_MATERIAL_URL,

  // IFACT_API_URL.AREAS_URL,
  // IFACT_API_URL.LABS_URL,

  // FLUID_API_URL.MATERIAL_URL
];

export const JOB_LOG = {
  GET_ACTIVITY_TYPES: `${environment.baseUrl}/api/joblog/getactivitytypes`,
  GET: (jobid: string) => `${environment.baseUrl}/api/joblog/${jobid}`,
  SUMMARY: (jobid: string) => `${environment.baseUrl}/api/joblog/summary/${jobid}`
}
export const HDF_API_URL = {
  ATTACHMENTS: (jobId) => `${environment.baseUrl}/api/hdf/attachments/${jobId}`
  ,ATTACHMENT_TYPES: `${environment.baseUrl}/api/hdf/attachments/types`
  ,MAP_JOB_INFO_DATA: (jobId, groupId) => `${environment.baseUrl}/api/hdf/map-job-info-data/${jobId}?groupId=${groupId}`
  ,PROPOSAL_VERSIONS: (proposal) => `${environment.baseUrl}/api/hdf/proposal-versions/${proposal}`
  ,SALE_ORDER_JOB_INFO: (saleOrder) => `${environment.baseUrl}/api/hdf/sale-order-job-info/${saleOrder}`
};
export const JOB_CASING_EQUIPMENT = {
  GET: (jobId: string) => `${environment.baseUrl}/api/job-casing-equipment/${jobId}`,
  PUT: (jobId: string) => `${environment.baseUrl}/api/job-casing-equipment/${jobId}/cp1`,
};
