import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';
import { IRegionModel } from 'libs/models';

@Injectable()
export class ReferenceService {

  constructor(private http: HttpService) { }

  fetchAdmins(regionId: any): Observable<string[]> {
    return this.http.Get(`${environment.baseUrl}/api/Admin/Admins/ByRegionId/${regionId}`);
  }
}
