import { ISAPMaterialModel } from "libs/models";
import { IfactService, JobCasingEquipmentService, MasterDataService, MaterialService } from "libs/shared";
import { map } from 'rxjs/operators';
import { Observable, of } from "rxjs";

export interface ISAPMaterialProvider {
    searchMaterials(keyword: string): Observable<ISAPMaterialModel[]>;
    getSapMaterialDetails(generalMaterialId: number, groupId: string): Observable<ISAPMaterialModel[]>;
    getSAPMaterialByNumber(number: string): Observable<ISAPMaterialModel>;
}

export class iFactsMaterialProvider implements ISAPMaterialProvider {
    constructor(private _materialService: MaterialService,
        private _ifactService: IfactService,
        private _masterDataService: MasterDataService) { }
    public searchMaterials(keyword: string): Observable<ISAPMaterialModel[]>{
        return this._materialService.searchSAPMaterialbyId(keyword);
    }
    public getSapMaterialDetails(generalMaterialId: number, groupId: string): Observable<ISAPMaterialModel[]>{
        const request$ = groupId ? this._ifactService.updateSapMaterialDetails(generalMaterialId, groupId) :
                                this._ifactService.getSapMaterialDetails(generalMaterialId, groupId);

        return request$.pipe(
            map(mappings => {
                return mappings ? mappings.map(itm => {
                return {
                    materialName: itm.sapMaterialName,
                    materialNumber: itm.sapMaterialId
                };
            }) : [];
        }));
    }
    public getSAPMaterialByNumber(number: string): Observable<ISAPMaterialModel> {
        return this._masterDataService.getSAPMaterialByNumber(number);
    }
}

export class CEMaterialProvider implements ISAPMaterialProvider {
    constructor(private _ceService: JobCasingEquipmentService){}
    public searchMaterials(keyword: string): Observable<ISAPMaterialModel[]>{
        return this._ceService.getSapMaterialDescriptionByKeyword(keyword);
    }
    public getSapMaterialDetails(generalMaterialId: number, groupId: string): Observable<ISAPMaterialModel[]>{
        return of([]);
    }
    public getSAPMaterialByNumber(number: string): Observable<ISAPMaterialModel> {
        return this._ceService.getSapMaterialDescriptionByNumber(number);
    }
}