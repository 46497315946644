<form [formGroup]="_customerForm">
    <p-autoComplete #autocomplete formControlName="customer" [suggestions]="_suggestions" (completeMethod)="onComplete($event.query)"
    (keyup)="setHighlightedText($event.target.value)" [multiple]="true" placeholder="Add more customer(s)..." field="customerName" [forceSelection]="true">
        <ng-template pTemplate="header">
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="item" let-row>
            <tr> 
                <td *ngFor="let col of columns">
                    <span [innerHTML]="row[col.field] |  highlight: highlightedText"></span>
                </td>
            </tr>
        </ng-template>
    </p-autoComplete>
</form>