import { ReportTemplate } from 'libs/models';

export interface IReportEvent {
  reportTab: EReportTab;
  template: ReportTemplate;
}

export enum EReportTab {
  MyTemplate = 0,
  GlobalTemplate = 1,
  ReportList = 2
}