import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from 'libs/ui';
import { CPESharedModule } from '../shared/shared.module';
import { SidebarModule } from 'primeng/sidebar';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarbonFootprintSidebarComponent } from './components'

const modules = [
    SidebarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    CPESharedModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    ToastModule,
    TooltipModule,
    MultiSelectModule,
    AutoCompleteModule,
    RadioButtonModule,
    AccordionModule
  ];

  const components = [
    CarbonFootprintSidebarComponent
  ];

  @NgModule({
    imports: modules,
    declarations: components,
    exports: components,
    providers: [
      
    ]
  })
  export class CarbonFootprintModule {
    static forRoot(): ModuleWithProviders<CarbonFootprintModule> {
      return {
        ngModule: CarbonFootprintModule,
        providers: []
      };
    }
  }
  