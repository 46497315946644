import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit } from "@angular/core";
import { UntypedFormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UnitType } from "libs/constants";
import { AutoUnsubscribe } from "libs/helpers/subscription-helper";
import { UnitMeasureModel } from "libs/models";
import { convertWithUnitMeasure, UnitConversionService } from ".";
import {take} from 'rxjs/operators';

@AutoUnsubscribe()
@Directive({
  selector: '[dUnitDistance]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitDistanceDirective),
      multi: true
    }
  ],
})
export class UnitDistanceDirective implements OnInit {

    @Input('dUnitDistance') set unit(val:UnitMeasureModel) {
      this._unit = val;
      this.initialize(null);
    };
    get unit() {
      return this._unit;
    }
    @Input() control: UntypedFormControl;
    @Input()
    set distanceValue(val: string) {
      this._controlValue = val;
      this.initialize(val);
    }
    get distanceValue() {
      return this._controlValue;
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: any): void {
      this.handleInput(value);
    }

    private _value: string;
    private _unit: UnitMeasureModel;
    private _controlValue: string;
    private _isAPI: boolean;
    private _distanceAPI: UnitMeasureModel;
    private readonly _fractionalLength: number = 1;
    private readonly _unitType: UnitType = UnitType.LargeDistance;

    constructor(
        private elementRef: ElementRef,
        private unitConversionService: UnitConversionService
      ) { }

    ngOnInit(): void {
        this.initialize(null);
    }

    private initialize(val): void {

      this._distanceAPI = this.unitConversionService.getApiUnitMeasure(this._unitType);

      if (this.control && this.control.value !== null && this.control.value !== undefined) {

        let calculatedValue = this.control.value;

        if(val) calculatedValue = val;
        this._isAPI = this._distanceAPI.id === this.unit.id;

        if (!this._isAPI) {
          calculatedValue = convertWithUnitMeasure(calculatedValue, this._distanceAPI, this.unit);
        }
        calculatedValue = Number(calculatedValue);
        calculatedValue = calculatedValue % 1 !== 0
                    ? Number(calculatedValue.toFixed(this._fractionalLength))
                    : Number(calculatedValue);

        this._value = calculatedValue;
      }
      else {
        this._value = '';
      }

      this.elementRef.nativeElement.value = this._value;
    }

    private handleInput(input: string): void {
      const tempValue = this._value;

      if (this.isNumber(input) || input === '') {
        this._value = input;

        if (input.includes('.')) {
          const [whole, remainder] = input.split('.');

          if (remainder && remainder.length > this._fractionalLength) {
              this._value = `${+whole}.${remainder.slice(0, this._fractionalLength)}`;
          }
        }
      }

      this.elementRef.nativeElement.value = this._value;

      if (+this._value !== +tempValue) {
        this.updateControlValue();
      }
    }

    private updateControlValue(): void {
      if (this.control) {
        const controlValue = this._value ? +this._value : null;
        this.control.markAsDirty();
        if (this._isAPI || !controlValue) {
          this.control.setValue(controlValue);
        }
        else {
          this.control.setValue(convertWithUnitMeasure(controlValue, this.unit, this._distanceAPI));
        }
      }
    }

    private isNumber(value: any): boolean {
        return !isNaN(parseFloat(value)) && !isNaN(value - 0);
    }
}
