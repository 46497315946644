import { Injectable, NgZone } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { environment } from 'libs/environment';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class RequestQueueInterceptor implements HttpInterceptor {
  queue: any[];
  isPrimaryRequestSent: boolean;
  intervalHandler: NodeJS.Timer;
  timeoutHandler: NodeJS.Timer;

  constructor(
    zone: NgZone
  ) {
    this.queue = [];
    this.isPrimaryRequestSent = false;

    zone.runOutsideAngular(() => {
      // This is a recovering mode. 
      // In case the primary request wasn't sent within the expected seconds or the current page's primary request wasn't defined. 
      // We will resume all requests by cleaning up the queue.
      this.timeoutHandler = setTimeout(() => {
        if (!this.isPrimaryRequestSent) {
          this.cleanUp();
          this.isPrimaryRequestSent = true;
        }
      }, 5000);

      this.intervalHandler = setInterval(() => {
        if (this.isPrimaryRequestSent) {
          this.cleanUp();
        }
      }, 100);
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.baseUrl) || this.shouldTriggerImmediately(request.url) || this.isPrimaryRequestSent) {
      return next.handle(request);
    }

    // Using from(Promise) to make Observable pausable.
    // Approaches tried: takeUntil, delayWhen, pausable
    return from(new Promise((resolve) => {
      this.queue.push(resolve);
    })).pipe(
      mergeMap(() => {
        return next.handle(request);
      })
    );
  }

  private shouldTriggerImmediately(url: string): boolean {
    const isInitialRequest = url.includes('user')
      || url.includes('UserSettings/allsettings')
      || url.includes('UserSettings/FilterByLocation');

    // TODO: This list needs to be maintained with the latest request flow to ensure that we always prioritize primary requests.
    const isPrimaryRequest = url.includes('active-job')
      || url.includes('saved-job')
      || url.includes('my-job')
      || url.includes('includeControlPoints=true')
      || url.includes('control-point-job-info')
      || url.includes('personal-collections/collection')
      || url.includes('jobs/search/')
      || url.includes('report');

    if (isPrimaryRequest) {
      this.isPrimaryRequestSent = true;
    }

    return isInitialRequest || isPrimaryRequest;
  }

  private cleanUp() {
    // dequeue pending requests
    this.queue.map((resolve) => resolve());
    this.queue = [];
    clearInterval(this.intervalHandler);
    clearTimeout(this.timeoutHandler)
  }
}

