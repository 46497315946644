export class CasingEquipmentSearchCriteria {
    constructor(){
        this.productGroup = null;
        this.productFamily = null;
        this.product = null;
        this.className = null;
        this.casingSize = null;
        this.casingWeight = null;
        this.casingGrade = null;
        this.casingThread = null;
    }
    productGroup: string;
    productFamily: string;
    product: string;
    className: string;
    casingSize: string;
    casingWeight: string;
    casingGrade: string;
    casingThread: string;
}

export const SELECTION_NULL: string = '__NULL';