import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationStateService, IfactService, JobService, MasterDataService, UserSettingService } from 'libs/shared/services';
import { FluidModel, IFactsRequestInfo, ISlurryType, ITestType, Job } from 'libs/models';
import { UnitType } from 'libs/constants';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { ControlPointLessonsLearnedComponent } from '../../../control-point/components/control-point-lessons-learned/control-point-lessons-learned.component';
import { toInteger } from 'lodash';

export interface IfactFluidSettingsSidebarInput {
  job: Job;
  jobId: string;
  isLinkFluidRequest: boolean;
  testTypeId: string;
  testType: string;
  slurryTypeId: string;
  iFactsRequestIdHDF: string;
}

@Component({
  selector: 'cf-per-barrel',
  templateUrl: './carbon-footprint-sidebar.component.html',
  styleUrls: ['./carbon-footprint-sidebar.component.scss'],
})
export class CarbonFootprintSidebarComponent implements OnInit {

  data: IfactFluidSettingsSidebarInput = {
    job: null,
    jobId: null,
    isLinkFluidRequest: false,
    testTypeId: null,
    testType: null,
    slurryTypeId: null,
    iFactsRequestIdHDF: null
  };
  UnitType = UnitType;
  isCFSidebar: boolean = false;
  subscription = new Subscription();
  requests = [];
  errorMessage: string = '';
  selectedSlurry: any = null;
  modalTitle;
  showSpan = false;
  scope3Array = [];
  tableTitle;
  requestID;
  weight;
  volume;

  @ViewChild('modalTitleSpan') modalTitleSpan;


  constructor(
    public modalManagementService: ApplicationStateService,
    private ifactService: IfactService,
    private unitConversionService: UnitConversionService,
    private jobService: JobService,
    private userSettingService: UserSettingService
  ) { }


  ngOnInit(): void { 
    this.modalManagementService.openCarbonFootprintSidebar$.subscribe(() => {
      this.showCF();
    });    
  }

  showCF() {
    this.modalTitle = this.modalTitleSpan.nativeElement.innerHTML == 'bbl' ? 'Barrel' : 'm3';
    this.isCFSidebar = true;
    this.weight = this.unitConversionService.getCurrentUnitMeasure(UnitType.Weight);
    this.volume = this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);
    this.tableTitle = `${this.weight.name}/${this.volume.name}`;
  }

  hideCF() {
    this.isCFSidebar = false;
  }

  onHideSidebar(requestInput: HTMLInputElement) {
    requestInput.value = '';
    this.errorMessage = '';
    this.requests = [];
    this.scope3Array = [];
    this.selectedSlurry = null;
    // work around to fix memory leak
    this.subscription.unsubscribe();    
    this.subscription = new Subscription();
  }

  searchRequest(requestId) {
    this.requestID = requestId;
    if (this.modalManagementService.notifyIFactDown$.value) {
      this.errorMessage = 'IFact service unavailable';
      this.requests = [];
      return;
    }

    if (requestId === '') {
      this.errorMessage = 'Request ID is required.';
      this.requests = [];
    } else if (isNaN(requestId) === false) {
      this.subscription = this.ifactService.getRequestInfo(requestId, true).subscribe(
        (requestInfo: IFactsRequestInfo) => {
          if (requestInfo) {
            this.requests = [];
            // let slurry = JSON.parse(JSON.stringify(requestInfo));
            // slurry.index = this.requests.length;
            this.errorMessage = '';
            // TODO: for searching multi requests in the future
            //this.requests.unshift(slurry);
            this.requests.push(requestInfo)
            this.data.testType = requestInfo.type;
            this.selectedSlurry = null;
          } else {
            this.handleInvalidRequestId();
          }
        },
        err => {
          this.handleInvalidRequestId(err.error.message);
        });
    } else {
      this.handleInvalidRequestId();
    }
  }

  private handleInvalidRequestId(message = '') {
    this.errorMessage = message ? message : 'Invalid Request ID. Please try again.';
  }

  compueCarbonFootprint() {
    const request = {
      "slurryId": this.selectedSlurry.id.toString(),
      "groupId": this.userSettingService.selectedLocation.groupId 
    }

    this.jobService.getScope3CarbonFootprint(request).subscribe(res => {
      let weight = this.transformResult(res)
      let scope3 = {
        "lbsBbl": weight,
        "requestId": this.requestID,
        "slurryId": this.selectedSlurry.id,
        "slurryNumber": this.selectedSlurry.number
      }
      this.scope3Array.unshift(scope3);
    })
  }

  transformResult(value) {
    return this.unitConversionService.convertToUserUnit(value, this.UnitType.Weight); 
  }

}
