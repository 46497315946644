import { UOM } from './uom.model';

export class JobObjectiveUOM {
    requirement: UOM;
    target: UOM;
    targetOtherJobObjective: UOM;
    targetFitLot: UOM;
    finalPumpPressureRequirement: UOM;
    marginOfError: UOM;
    constructor() {
      this.requirement = { origin: null, display: null };
      this.target = { origin: null, display: null };
      this.targetFitLot = { origin: null, display: null };
      this.finalPumpPressureRequirement = { origin: null, display: null };
      this.marginOfError = { origin: null, display: null };
    }
  }