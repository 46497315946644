import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UnitConversionDirective, UnitNameDirective, UnitTypeDirective, InputUnitComponent, UnitFormatPipe, UnitFormat2Directive, UnitValueDirective, AutoCompleteUnitComponent, UnitDistanceDirective } from '.';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { KeyFilterModule } from 'primeng/keyfilter';

const thirdPartyModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  AutoCompleteModule,
  TooltipModule,
  KeyFilterModule,
];

const directives = [
  UnitConversionDirective,
  UnitFormat2Directive,
  UnitNameDirective,
  UnitTypeDirective,
  UnitValueDirective,
  UnitDistanceDirective
];

const components = [
  InputUnitComponent,
  AutoCompleteUnitComponent
];

const pipes = [
  UnitFormatPipe
];

const services = [
];

@NgModule({
  imports: [
    ...thirdPartyModules
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  exports: [
    ...thirdPartyModules,
    ...components,
    ...directives,
    ...pipes,
  ],
  providers: []
})
export class UnitConversionModule {
  static forRoot(): ModuleWithProviders<UnitConversionModule> {
    return {
      ngModule: UnitConversionModule,
      providers: [...services]
    };
  }
}
