
export interface ISaleOrderNumberModel {
    customerId: string;
    customerName: string;
    saleDoumentId: string;
    shipTo: string;
    shipToLocation: string;
    wellId: string;
    wellName: string;
    wellNumber: string;
    wellApiUwi: string;
    projectedDate: string;
    bomTypeId: string;
    bomTypePartNumber: string;
    bomTypeDescription: string;
    isCementingBomType: boolean;
    salesOrderNumber: string;
    controlpointType: number;
    lineItem: string;
    profitCenter: string;
    accountRepresentative: AccountRepresentative;
    isBOMTypeRequired: boolean;
}

export class AccountRepresentative {

  constructor(
    public fullName: string = '',
    public phoneNumber: string = ''
  ) { }

}

export class UpdateFromSalesOrderParameters {

  constructor(
    public model: ISaleOrderNumberModel = null,
    public isSaleOrderUpdateConfirmed: boolean = false
  ) { }
}

export class SaleOrderNumberModel implements ISaleOrderNumberModel {
  customerId: string;
  customerName: string;
  saleDoumentId: string;
  shipTo: string;
  shipToLocation: string;
  wellId: string;
  wellName: string;
  wellNumber: string;
  wellApiUwi: string;
  projectedDate: string;
  bomTypeId: string;
  bomTypePartNumber: string;
  bomTypeDescription: string;
  isCementingBomType: boolean;
  salesOrderNumber: string;
  controlpointType: number = 0;
  lineItem: string = null;
  profitCenter: string = null;
  accountRepresentative: AccountRepresentative = null;
  isBOMTypeRequired: boolean;

  constructor(customerId,
    customerName,
    shipTo,
    shipToLocation,
    wellId,
    wellName,
    wellNumber,
    wellApiUwi,
    isBOMTypeRequired,
    bomTypeId,
    bomTypePartNumber,
    bomTypeDescription,
    accountRepresentative
  ) {
    this.customerId = customerId;
    this.customerName = customerName;
    this.shipTo = shipTo;
    this.shipToLocation = shipToLocation;
    this.wellId = wellId;
    this.wellName = wellName;
    this.wellNumber = wellNumber;
    this.wellApiUwi = wellApiUwi;
    this.isBOMTypeRequired = isBOMTypeRequired;
    this.bomTypeId = bomTypeId;
    this.bomTypePartNumber = bomTypePartNumber;
    this.bomTypeDescription = bomTypeDescription;
    this.accountRepresentative = accountRepresentative;
  }
}

export function FillFullSOwithZeros(inputValue) {
  if (inputValue && inputValue.length < 10) {
    return inputValue.trim().padStart(10, 0);
  }
  return inputValue;
}
