import { AfterViewChecked, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UnitType } from 'libs/constants';
import { PumpScheduleEventModel } from 'libs/models';
import { ErrorMessageModel } from 'libs/ui';
import { MenuItem, SelectItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Observable, Subject } from 'rxjs';
import { ValidatorSetting } from '../../../shared/constant';
import { StageStateManager } from '../../state/stage-state-manager';
import { take } from 'rxjs/operators';

@Component({
    selector: 'stage-event',
    templateUrl: './stage-event.component.html',
    styleUrls: ['./stage-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageEventComponent implements AfterViewChecked {

    private static readonly _insertBeforeMenuItem: MenuItem = {
        label: 'Add Shutdown Event Before',
        icon: 'fa fa-plus-circle'
    };

    private static readonly _insertAfterMenuItem: MenuItem = {
        label: 'Add Shutdown Event After',
        icon: 'fa fa-plus-circle'
    };
    @Input() isRequiredShow;
    @Input() jobType: string;
    @Input()
    public stageState: StageStateManager;

    public ERROR_UNIT_DECIMAL = ValidatorSetting.ERROR_UNIT_DECIMAL;
    public ERROR_UNIT_DECIMAL_POS = ValidatorSetting.ERROR_UNIT_DECIMAL_POS;
    public ERROR_UNIT_DECIMAL_ONE_POS = ValidatorSetting.ERROR_UNIT_DECIMAL_ONE_POS;

    public UnitType = UnitType;
    public stageEventLoaded$ = new Subject();

    public errorMessages = {
        rate: [
            new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
            new ErrorMessageModel('max', ValidatorSetting.ERROR_UNIT_DECIMAL_MAX)
        ],
        actualRate: [
            new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
            new ErrorMessageModel('required', ValidatorSetting.ERROR_REQUIRED)
        ],
        actualDuration: [
            new ErrorMessageModel('negativeNumber', ValidatorSetting.ERROR_NEGATIVE),
            new ErrorMessageModel('required', ValidatorSetting.ERROR_REQUIRED)
        ]
    };

    public editEventListContextMenuItems: MenuItem[] = [
        StageEventComponent._insertBeforeMenuItem,
        StageEventComponent._insertAfterMenuItem
    ];

    public get isEditable(): boolean {

        return this.stageState.viewState.isEditable;
    }

    public get isJobEditable(): boolean {

        return this.stageState.viewState.isJobEditable;
    }

    public get isCP4View(): boolean {

        return this.stageState.viewState.isCP4View;
    }

    public get events$(): Observable<PumpScheduleEventModel[]> {

        return this.stageState.events$;
    }

    public get sackWeight$(): Observable<number> {

        return this.stageState.fluidState.sackWeight$;
    }

    public get isDrillingMud(): boolean {

        return this.stageState.isDrillingMud;
    }

    public get canAddEvent(): boolean {

        return this.isEditable && !this.isDrillingMud;
    }

    public get placementMethodItems$(): Observable<SelectItem<string>[]> {

        return this.stageState.dropdownPlacementMethodItems$;
    }

    ngAfterViewChecked(): void {
        this.stageEventLoaded$.next();
    }

    public onChangeEvent(e, eventForm) {
        this.placementMethodItems$.pipe(take(1)).subscribe(placementMethods => {
            const placementMethodName = placementMethods?.find(m => m.value === e.value)?.label;
            const placementMethodNameForm = eventForm.get('placementMethodName');

            if ((placementMethodName || e.value === null) && placementMethodNameForm) {
                placementMethodNameForm.patchValue(placementMethodName ?? null);
            }
        });
    }

    public isShutdown(event: PumpScheduleEventModel): boolean {

        return this.stageState.isShutdownEvent(event);
    }

    public isActualRateEditable(event: PumpScheduleEventModel): boolean {

        return !this.isShutdown(event) && !this.stageState.isDrillingMud;
    }

    public isCP4EventListEditable(): boolean {

        return this.isJobEditable && !this.stageState.isDrillingMud;
    }

    public isCP4EventDeletable(event: PumpScheduleEventModel): boolean {

        return this.isJobEditable && this.isShutdown(event) && !event.duration && !this.stageState.isDrillingMud;
    }

    public showEditListContextMenu(cm: ContextMenu, e: MouseEvent, event: PumpScheduleEventModel): void {

        this._updateContextMenuCommands(event);
        cm.show(e);
        e.stopPropagation();
    }

    public getForm(event: PumpScheduleEventModel): UntypedFormGroup {
        if(this.jobType == 'ceServices') {
            this.stageState.getEventForm(event).get('actualRate').setErrors(null);
            this.stageState.getEventForm(event).get('actualRate').setValidators(null);
            this.stageState.getEventForm(event).get('actualDuration').setErrors(null);
            this.stageState.getEventForm(event).get('actualDuration').setValidators(null);
        }
        return this.stageState.getEventForm(event);
    }

    public addEvent(): void {

        this.stageState.addEvent();
    }

    public deleteEvent(event: PumpScheduleEventModel): void {

        this.stageState.deleteEvent(event);
    }

    private _updateContextMenuCommands(event: PumpScheduleEventModel): void {
        StageEventComponent._insertBeforeMenuItem.command = _ => this._inserShutdownEvent(event.order);
        StageEventComponent._insertAfterMenuItem.command = _ => this._inserShutdownEvent(event.order + 1);
    }

    private _inserShutdownEvent(index: number): void {

        this.stageState.insertShutdownEvent(index);
    }
}
