import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IJobInformation } from '../../models/breadcrumb.model';


@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  constructor(private router: Router) { }

  @Input() jobInfor: IJobInformation;

  items: any[];
  urlPathName = window.location.pathname.replace('/', '');

  ngOnInit() {
    this.items = this.getListUrl(this.urlPathName);
  }

  ngOnChanges() {
    const urlPathName = window.location.pathname.replace('/', '');
    this.items = this.getListUrl(urlPathName);
  }

  getListUrl(urlPathName: string) {
    const result = [];
    const data = urlPathName.split('/');
    const urlQueryParam = data[data.length - 1];
    result.push({ label: 'VIDA', url: ['/active-jobs'] });

    if (urlPathName.indexOf('control-point/cp-detail/') !== -1) {
      result.push({ label: this.jobInfor.JobName, url: ['/job-simple', urlQueryParam] });
      result.push({ label: '', url: [''] });
      return result;
    }

    if (urlPathName.indexOf('saved-jobs') !== -1) {
      result.push({ label: 'Saved Job', url: ['/saved-jobs'] });
      return result;
    }

    if (urlPathName.indexOf('my-jobs') !== -1) {
      result.push({ label: 'My Job', url: ['/my-jobs'] });
      return result;
    }

    if (urlPathName.indexOf('job-simple') !== -1) {
      result.push({ label: this.jobInfor.JobName, url: [''] });
      return result;
    }

    return result;
  }

  isLastItemOfArray(array: any[], item: any) {
    return array.indexOf(item) === array.length - 1;
  }
}

