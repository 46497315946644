<span class="more-info-icon">
    <span class="more-info-icon-target"></span>
    <div class="icon-container" (click)="moreInfoOnClick(op, $event)">
      <ng-content select="[icon]"></ng-content>
    </div>
    <p-overlayPanel #op 
        [dismissable]="true" 
        [showCloseIcon]="true" 
        styleClass="cpoint-overlay-panel">
      <ng-container>
        <ng-content select="[message]"></ng-content>
      </ng-container>
    </p-overlayPanel>
</span>