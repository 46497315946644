import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UnitType } from 'libs/constants';
import { Observable, combineLatest, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { PumpScheduleStageStateManager } from '../../models/pump-schedule-stage-state-manager.model';
import { PumpScheduleStateManager } from '../../state/schedule-state-manager';
import { ViewState } from '../../view-state';

@Component({
  selector: 'job-totals',
  templateUrl: './job-totals.component.html',
  styleUrls: ['./job-totals.component.scss']
})
export class JobTotalsComponent implements OnInit {

  public planeedScop2Co2e = 0;
  public readonly UnitType = UnitType;

  @Input()
  public listPumpScheduleStageStateManager$: Observable<PumpScheduleStageStateManager[]>;

  @Input()
  public readonly scheduleState: PumpScheduleStateManager;

  @Input()
  public hasScope3access: boolean;

  public get pumpScheduleForm(): UntypedFormGroup {

    return this.scheduleState.form;
  }

  private get _viewState(): ViewState {
    return this.scheduleState.viewState;
  }

  public get _allStagesTotalBlendCo2e$(): Observable<number> {
    return this.scheduleState.totalBlendCo2e$;
  }
  constructor(private ref: ChangeDetectorRef) {

  }
  ngOnInit(): void {  
    
  }

  public get dryWeightVisible(): boolean {

    return this._viewState.isScheduleEditView || this._viewState.isCP2View || this._viewState.isCP1View;
  }

  public get dryVolumeVisible(): boolean {

    return this.dryWeightVisible;
  }

  public get anyMaterialMissingBulkDensity$(): Observable<boolean> {

    return this.scheduleState.anyMaterialMissingBulkDensity$;
  }
  public get isCo2eVisible(): boolean {
    return this.isPlannedScope3Co2eVisible || this.isActualScope3Co2eVisible;
  }
  public get totalCo2e$(): Observable<number> {
    return combineLatest(this.plannedScope3Co2e$, this.totalBlendC02eJobTotal$).pipe(map(([value1, value2]) => {
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2);
    }));  
  }
  public get totalBlendActualCo2e$(): Observable<number> { 
    return combineLatest(this.actualScope3Co2e$, this.totalBlendActualC02eJobTotal$).pipe(map(([value1, value2]) => { 
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2);
    }));
  }

  public get totalBlendActualC02eJobTotal$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.totalBlendActualCo2e$.subscribe(data => {
            value = data
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  }

  public get totalBlendC02eJobTotal$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.totalBlendCo2e$.subscribe(data => {
            value = data
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  }
  public get isPlannedScope3Co2eVisible(): boolean {
    return this._viewState.isCP1View && this.hasScope3access;
  }

  public get isActualScope3Co2eVisible(): boolean {
    return this._viewState.isCP4View && this.hasScope3access;
  }

  public get cogsVisible$(): Observable<boolean> {

    return this._viewState.cogsVisibleForReviewer$
      .pipe(
        map(visible => {

          return visible && (this._viewState.isScheduleEditView || this._viewState.isCP1View);
        })
      );
  }

  public get cogsAvailable$(): Observable<boolean> {

    return this.scheduleState.cogs$
      .pipe(
        map(scheduleCost => {
          return scheduleCost.totalCOGS !== null
            // ..and no empty children COGS
            && !scheduleCost.stages.some(x => x.fluidMaterials.some(x => x.totalCOGS == null));
        })
      );
  }

  public get cogs$(): Observable<number> {

    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.cogs$.subscribe(data => {
            value = data.totalCOGS
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  }

  public get dryWeight$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.dryWeight$.subscribe(data => {
            value = data
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  }

  public get dryVolume$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.dryVolume$.subscribe(data => {
            value = data
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  } 

  public get actualScope3Co2e$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.actualScope3Co2e$.subscribe(data => {
            value = data
          })
          return value;
        })),
        map(x => x.sum())
      )
    }
  }

  public get plannedScope3Co2e$(): Observable<number> {
    if (this.listPumpScheduleStageStateManager$) {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.map(y => {
          let value = 0;
          y.scheduleState?.plannedScope3Co2e$.subscribe(data => {
            value = y.isHidden ? 0 : data
          })
          return value;
        })),
        map(x => x.sum())
      )
    } 
  }

  public showCogsHelp(): void {

    this.scheduleState.showCogsHelp();
  }

  public showMissingBulkDensityHelp(): void {

    return this.scheduleState.showMissingBulkDensityHelp();
  }
}
