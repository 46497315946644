import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CPESharedModule } from '../shared/shared.module';
import { UIModule } from 'libs/ui';
import {
  PumpEventComponent,
  PumpEventComponentCP4,
  PumpFluidDetailComponent,
  PumpFluidMaterialDetailComponent,
  PumpScheduleComponent,
  PumpScheduleInfoComponent,
  PumpScheduleInfoComponentCP4,
  PumpScheduleLinerJobComponent,
  PumpScheduleShoeTrackComponent,
  PumpStageComponent,
  MudParametersComponent
} from './components';
import { PumpScheduleAdapter } from './adapters';
import { PumpScheduleService, PumpScheduleLogic, MudTypeService } from './services';
import { VIDASharedModule } from 'libs/shared/vida-shared.module';
import { FluidsModule } from '../fluids/fluids.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';

const thirdPartyModules = [
  CommonModule,
  TabViewModule,
  AutoCompleteModule,
  InputMaskModule,
  OverlayPanelModule,
  CheckboxModule,
  ClickOutsideModule,
  ContextMenuModule
];

const services = [
  PumpScheduleService,
  PumpScheduleLogic,
  MudTypeService
];

const adapters = [
  PumpScheduleAdapter
];

const components = [
  PumpEventComponent,
  PumpEventComponentCP4,
  PumpFluidDetailComponent,
  PumpFluidMaterialDetailComponent,
  PumpScheduleComponent,
  PumpScheduleInfoComponent,
  PumpScheduleInfoComponentCP4,
  PumpScheduleLinerJobComponent,
  PumpScheduleShoeTrackComponent,
  PumpStageComponent,
  MudParametersComponent
];

@NgModule({
  imports: [
    ...thirdPartyModules,
    UIModule,    
    CPESharedModule,
    VIDASharedModule,
    FluidsModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ],
  providers: [
  ]
})
export class PumpScheduleModule {
  static forRoot(): ModuleWithProviders<PumpScheduleModule> {
    return {
      ngModule: PumpScheduleModule,
      providers: [
        ...services,
        ...adapters,
      ]
    };
  }
}
