<div class="d-flex pt-4">
  <div class="form-subtitle-section">{{testTableName}}</div>
  <div class="pt-2 pl-2 text-nowrap" style="font-weight: bold;">Slurry #</div>
  <div>
    <p-dropdown
      [formControl]="selectedSlurry"
      (onChange)="changeSlurryNo($event)"
      [options]="availableTestSlurries"
      [disabled]="!canEdit"
      appendTo="body"
      [placeholder]="'\u00A0'"
      baseZIndex="9999">
    </p-dropdown>
  </div>
</div>

<div class="ui-table">
  <table style="font-weight: bold;">
    <thead>
      <tr>
        <th>Test Temp</th>
        <th>Test Time</th>
        <th style="width: 130px;">
          <div class="d-flex flex-row justify-content-center">{{test ? getTestDisplayName(test) : 'API / Calc Fluid Loss'}}</div>
          <div class="d-flex flex-row justify-content-center">(30 min)</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="test;else empty">
        <tr *ngIf="isTestFinishedOrValidated(test);else testStatusDescription">
          <td>
            <span [apiValue]="getFluidTemperature(test)" [unitType]="UnitType.Temperature"></span>
          </td>
          <td>
            <span> {{getTestTime(test)}}</span>
          </td>
          <td>
            <span>
              <span [apiValue]="getFluidLoss(test)"
                    [formatNum]="2"
                    [unitType]="">
              </span>ml
            </span>
          </td>
        </tr>
      </ng-container>

      <ng-template #testStatusDescription>
        <tr>
          <td class="text-center bc-white" colspan="3">{{test?.testStatusDescription}}</td>
        </tr>
      </ng-template>

      <ng-template #empty>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>