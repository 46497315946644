<form [formGroup]="localWellReview">
    <div class="row">
      <div class="col-xs-12 form-group">
          <div class="input-wrapper">
            <p-checkbox binary="true" formControlName="isWellReviewCompleted"></p-checkbox>
            <span style="font-weight: bold;">Well Review completed</span>
          </div>
      </div>
    </div>
    <div class="row">
        <div class="col-xs-6 form-group">
          <div class="input-wrapper">
           <span style="font-weight: bold;">Well Review Participants</span>
            <p-autoComplete class="add-members" [suggestions]="members" (completeMethod)="onComplete($event.query)"
                [multiple]="true" placeholder="Name 1, Name 2, Name 3" field="displayName" formControlName="participantNames">
            </p-autoComplete>
          </div>
        </div>
        <div class="col-xs-6 form-group">
          <div class="input-wrapper">
            <span style="font-weight: bold;">Well Review Date</span>
            <datetime-picker format="dd-MMM-yyyy" formControlName="reviewDate" styleClass="float-left-calendar"></datetime-picker>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-xs-12 form-group">
          <div class="input-wrapper">
            <span style="font-weight: bold;">Comments</span>
            <textarea pInputTextarea class="form-control" rows="5" formControlName="commentLocal" maxlength="257"></textarea>
          </div>
      </div>
    </div>
</form>
