import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { tap, debounceTime, switchMap } from 'rxjs/operators';

import { AdUser } from 'libs/models';
import { MemberSearchService } from 'libs/shared/services';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'local-well-review-enter-details',
  templateUrl: './local-well-review-enter-details.component.html',
  styleUrls: ['./local-well-review-enter-details.component.scss']
})

export class LocalWellReviewEnterDetails implements OnInit, OnDestroy {

  @Input() localWellReview: UntypedFormGroup;
  _subscriptions: Subscription[] = [];
  _isSearching: boolean = false;
  members: AdUser[];
  result: AdUser[] = [];

  result$ = new Subject<string>();

  @Output() onAdd = new EventEmitter<AdUser[]>();
  @Output() changeComment = new EventEmitter<number>();

  constructor(private membersSearchService: MemberSearchService) { }

  ngOnInit() {

    this._subscriptions.push(
      this.result$.pipe(
        tap(() => this._isSearching = true),
        debounceTime(500),
        switchMap((value: string) => this.membersSearchService.search(value))
      ).subscribe(
        (data: AdUser[]) => {
          this._isSearching = false;
          this.members = data;
        },
        error => {
          this._isSearching = false;
        }
      )
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._subscriptions = [];
  }

  onComplete(result: string) {
    this.result$.next(result);
  }

}
