export class CDIOverview {
    id?: string;
    jobId: string;
    type: number;
    tvd: number;
    density: number;
    pressure: number;
    mudDensity: number;
    mudPressure: number;
    justification: string;
    isManual: boolean;

    constructor() {
        this.id = '';
        this.jobId = '';
        this.type = null;
        this.tvd = null;
        this.density = null;
        this.pressure = null;
        this.mudDensity = null;
        this.mudPressure = null;
        this.justification = null;
        this.isManual = false;
    }
}