import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'libs/environment';
import { CollectionModel } from '../models';
import { HttpService } from 'libs/shared/services';

@Injectable()
export class PersonalCollectionService {

  constructor(private http: HttpService) {
  }

  createCollection(collection: CollectionModel): Observable<any> {
    return this.http.Post(`${environment.baseUrl}/api/personal-collections`, JSON.stringify(collection));
  }

  renameCollection(collection: CollectionModel): Observable<any> {
    return this.http.Put(`${environment.baseUrl}/api/personal-collections`, JSON.stringify(collection));
  }

  deleteCollection(collectionId: string): Observable<any> {
    return this.http.Delete(`${environment.baseUrl}/api/personal-collections/${collectionId}`);
  }

  checkCollectionName(collection: CollectionModel): Observable<any> {
    return this.http.Post(`${environment.baseUrl}/api/personal-collections/is-existed-name`, JSON.stringify(collection));
  }

  shareCollection(collectionShareModel: any): Observable<any> {
    return this.http.Post(`${environment.baseUrl}/api/personal-collections/is-existed-name-for-share`, JSON.stringify(collectionShareModel));
  }

  checkNameSharedCollection(collectionShareModel: any): Observable<any> {
    return this.http.Post(`${environment.baseUrl}/api/personal-collections/is-existed-name-for-shared`, JSON.stringify(collectionShareModel));
  }
}