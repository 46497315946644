import { BlockUI } from "primeng/blockui";

export function FixBlockUI() {
    if (BlockUI !== undefined) {
        var fnUnblock = BlockUI.prototype['unblock'];
        if (fnUnblock) {
            BlockUI.prototype['unblock'] = function() {
                if (this['mask']){ fnUnblock.call(this); }
            }
        }
    }
}