<div class="sidebar-header">
  <h1 class="title">Local Well Review</h1>
</div>
<div class="sidebar-content" [loadingIndicator]="loadingSubscription">
  <div [ngClass]="{'disabled': readOnly}">
    <div class="file-upload">
      <job-uploader #uploader
        [listFiles] = "localWellReviewFiles"
        [jobId]="jobId"
        (uploadCancelEmitter)="uploadCancel($event)"
        (uploadSuccessEmitter)="uploadSuccess($event)"
        (uploadInprogressEmitter)="uploadInprogress($event)"
        [destinationFolderPath]="destinationFolderPath"
        [header]="'Upload Files'"
        [title]="'Upload a Local Well Review file here.'"
        [drapDropDescription]="'or Drag and Drop Local Well Review File Here'"
        [isEnableDescriptionInput]="true"
        [vidaFileType]="vidaFileType"
        [jobProgramFileName]="null"
        [badgeNumber]=true
        [displayMode]="'Vertical'">
      </job-uploader>
    </div>
    <div class="files-list m-top-2">
      <div class="row">
        <div class="col-xs-1 form-group">
          <span class="badge-number">2</span>
        </div>
        <div class="col-xs-3 form-group">
          <label style="font-weight: bold;">File Name</label>
        </div>
        <div class="col-xs-4 form-group">
          <span style="font-weight: bold;">Uploaded By</span>
        </div>
        <div class="col-xs-4 form-group">
          <span style="font-weight: bold;">Upload Date</span>
        </div>
      </div>
      <div class="row" *ngFor="let item of localWellReviewFiles">
        <div class="col-xs-3 col-xs-offset-1 form-group">
          <span><a href="{{item.filePathDownload}}" class="file-name">{{item.getFileName()}}</a></span>
        </div>
        <div class="col-xs-4 form-group">
          <span>{{item.getUploader()}}</span>
        </div>
        <div class="col-xs-4 form-group">
          <span>{{item.getUploadDate()}}</span>
        </div>
      </div>
    </div>
    <div class="files-list m-top-2">
      <div class="row">
        <div class="col-xs-12">
          <span _ngcontent-c10="" class="badge-number">3</span><label>Enter Review Details</label>
          <p class="custom-margin">Enter the details regarding the review below.</p>
        </div>

      </div>
      <div>
        <local-well-review-enter-details [localWellReview]="localWellReviewFb"></local-well-review-enter-details>
      </div>
    </div>
  </div>
</div>
<div class="sidebar-footer">
  <a class="btn lnk-cancel" (click)="cancelLocalWellReview()">Cancel</a>
  <button type="button" class="btn primary-btn" (click)="saveLocalWellReview()"
    [disabled]="disableSaveButton">SUBMIT</button>
</div>