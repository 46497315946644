import { Component, Input, OnChanges, ViewChild, forwardRef, SimpleChanges, AfterViewInit, Injector, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from 'libs/ui/form-value-accessor';
import { PDropDownModel } from 'libs/ui/models/pdropdown.model';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'autocomplete-ex',
  templateUrl: './autocomplete-ex.component.html',
  styleUrls: ['./autocomplete-ex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteExComponent),
      multi: true
    }
  ]
})
export class AutocompleteExComponent extends FormValueAccessor implements OnChanges, AfterViewInit {

  @Input() items: PDropDownModel[];
  @ViewChild(AutoComplete, { static: true }) autoComplete: AutoComplete;

  _selected: string;
  _isDisabled: boolean = false;
  _suggestions: string[];

  constructor(
    protected inj: Injector,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { items } = changes;
    if (items) {
      this.injectNgControl();
      this._suggestions = this.items.map(item => item.label);
      if (this.control)
        this._updateSourceValue(this.control.value);
    }
  }

  writeValue(obj: any): void {
    this._updateSourceValue(obj);
  }

  setDisabledState(isDisabled: boolean): void { 
    this._isDisabled = isDisabled;
  }

  _updateSourceValue(value) {
    const selectedItem = this.items.find(item => item.value === value);
    if (selectedItem)
      this._selected = selectedItem.label;
    else
      this._selected = null;
    this.cd.markForCheck();
  }

  _onSelect(value) {
    const selectedItem = this.items.find(item => item.label === value);
    if (selectedItem)
      this._propagateChange(selectedItem.value);
  }

  _onComplete(search) {
    this._suggestions = this.items
      .filter(res => res.label && res.label.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      .map(item => item.label);
    if (search) {
      const selectedItem = this.items.find(item => item.label === this._selected);
      if (!selectedItem) {
        this.control.setErrors({ 'notExist': true });
      } else {
        this._propagateChange(selectedItem.value);
        const errors = this.control.errors;
        if (errors && errors['notExist']) {
          delete errors['notExist'];
          this.control.setErrors(errors);
        }
      }
    }
  }

  _onFocus(e) {
    this.control.markAsTouched();
    this._getDataOnFocus();
  }

  _onKeyUp(e) {
    if (this.control.pristine)
      this.control.markAsDirty();

    const selectedItem = this.items.find(item => item.label === this._selected);
    if (selectedItem)
      this._propagateChange(selectedItem.value);
  }

  _onClear(e) {
    this._getDataOnFocus();
    this._propagateChange(this._selected);
  }

  _getDataOnFocus() {
    this.control.markAsTouched();
    if (this.autoComplete) {
      this.autoComplete.handleDropdownClick(null);
    }
  }

}

