<div class="cp-states">
    <span class="cp-wrapper">
        <span class="cp-status-icon " [ngClass]="{
            'cp-status-box-submitted': cpStatuses?.cP1StatusName === 'Submitted',
            'cp-status-box-rejected': cpStatuses?.cP1StatusName === 'Rejected',
            'cp-status-box-approved': cpStatuses?.cP1StatusName === 'Approved',
            'cp-status-box-completed': cpStatuses?.cP1StatusName === 'Completed'}"></span>
        <img *ngIf="isOutOfCompliance(1)" 
            src="../../../assets/images/exclamationmark-circle-fill.svg" [pTooltip]="toolTipCompliance"
            tooltipPosition="left"/>
        <img *ngIf="isDueSoon(1)" 
            src="../../../assets/images/exclamationmark-triangle-fill.svg" [pTooltip]="toolTipDueSoon"/>
    </span>
    <span class="cp-wrapper">
        <span class="cp-status-icon" [ngClass]="{'cp-status-box-approved': cpStatuses?.cP2StatusName === 'Completed',
                                             'cp-status-box-prepared': cpStatuses?.cP2StatusName === 'Prepared'}"></span>
        <img *ngIf="isOutOfCompliance(2)" 
            src="../../../assets/images/exclamationmark-circle-fill.svg" [pTooltip]="toolTipCompliance"/>
        <img *ngIf="isDueSoon(2)" 
            src="../../../assets/images/exclamationmark-triangle-fill.svg" [pTooltip]="toolTipDueSoon"/>
    </span>
    <span class="cp-wrapper">
        <span class="cp-status-icon" [ngClass]="{'cp-status-box-approved': cpStatuses?.cP3StatusName === 'Completed',
                                                'cp-status-box-disabled': isDirectSales}"></span>
        <img *ngIf="isOutOfCompliance(3)" 
            src="../../../assets/images/exclamationmark-circle-fill.svg" [pTooltip]="toolTipCompliance"/>
        <img *ngIf="isDueSoon(3)" 
            src="../../../assets/images/exclamationmark-triangle-fill.svg" [pTooltip]="toolTipDueSoon"/>
    </span>
    <span class="cp-wrapper">
        <span class="cp-status-icon" [ngClass]="{'cp-status-box-approved': cpStatuses?.cP4StatusName === 'Completed',
                                                'cp-status-box-submitted': cpStatuses?.cP4StatusName === 'KPIComplete',
                                                'cp-status-box-disabled': isDirectSales}"></span>
        <img *ngIf="isOutOfCompliance(4)" 
            src="../../../assets/images/exclamationmark-circle-fill.svg" [pTooltip]="toolTipCompliance"/>
        <img *ngIf="isDueSoon(4)" 
            src="../../../assets/images/exclamationmark-triangle-fill.svg" [pTooltip]="toolTipDueSoon"/>
    </span>
</div>