import { isEmpty } from 'libs/helpers/lodash-helper';

type objectArray<T> = { [P in keyof T]: T[P]; }[];

export function PDropdownDataSanitizerHelper<T>(
    labelKey: string,
    valueKey: string,
    arr?: objectArray<T> 
) {

    const sanitizedData = items => {

        return items
            ? items.map(item => ({
                    label: item[labelKey],
                    value: item[valueKey]
                }))
            : [];
    };

    if (isEmpty(arr)) {
        return sanitizedData;
    }

    return sanitizedData(arr);
}