import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DashboardNavComponent } from './components';
import { CPESharedModule } from '../../modules/shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        FormsModule,
        CPESharedModule
    ],
    declarations: [
        DashboardNavComponent
    ],
    providers: [],
    exports: [
        DashboardNavComponent
    ]
})
export class NavigationMenuModule {
    static forRoot(): ModuleWithProviders<NavigationMenuModule> {
        return {
            ngModule: NavigationMenuModule,
            providers: []
        };
    }
}