import { UnitType } from 'libs/constants';

export class UOM {
  display: number;
  origin: number;
  unitType?: UnitType;
  constructor() {
    this.display = null;
    this.origin = null;
    this.unitType = null;
  }
}
