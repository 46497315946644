export function coordinate_to_dms(coord) {
  // The sign doesn't matter
  let n = Math.abs(coord);

  // Get the degrees
  const d = Math.floor(n);
  // Strip off the answer we already have
  n = n - d;
  // And then put the minutes before the '.'
  n *= 60;

  // Get the minutes
  const m = Math.floor(n);
  // Remove them from the answer
  n = n - m;
  // Put the seconds before the '.'
  n *= 60;

  // Get the seconds
  // Should this be round? Or rounded by special rules?
  const s = Math.round(n);

  // Return
  return ('' + d + ':' + m + ':' + s);
}