<div id="{{stageForm.get('number').value}}" class="flx-itm-grow flx-ctn-row" [formGroup]="stageForm">
  <div class="flx-itm flx-ctn-col pump-stage-number"
    [style.backgroundColor]="pumpScheduleAdapter.hashColors[stageForm.controls.number.value]" (click)="toggle()">
    <div class="flx-itm flx-itm-center expanded-stage">
      <div class="flx-itm-center collapse-expanded">
        <a *ngIf="!isTopBottom">
          <i class="fa fa-play" [ngClass]="{'expanded': isExpanded, 'pump-error': shouldDisplayError }"></i>
        </a>
      </div>
      <div class="number-expanded" [ngClass]="shouldDisplayError ? 'pump-error' : ''">
        <span>Stage</span>
        <span class="number-stage">
          {{ stageForm.get('number').value >= 0 ? stageForm.get('number').value + 1 : ''}}
        </span>
      </div>
    </div>
  </div>
  <div class="flx-itm-grow flx-ctn-col stage-information">
    <pump-schedule-info [stageForm]="stageForm" 
      [stageModel]="stageModel"
      [readonly]="readonly"
      [isTopBottom]="isTopBottom"
      [fluids]="fluids"
      [job]="job"
      (onSelectedFluid)="_onSelectedFluid($event)"
      (onFluidChange)="resetOrder($event)"
      (onFluidKeyUp)="setDefaultFluidType()"
      (insertStageBefore)="insertStageBefore()"
      (insertStageAfter)="insertStageAfter()"
      (deleteStage)="deleteStage()"
      (checkMudType)="checkMudType($event)"
      (onClearFluid)="onClearFluid()"
      (openStageCogsHelp)="openStageCogsHelp.emit()">
    </pump-schedule-info>

    <p-tabView [hidden]="isTopBottom || !isExpanded" class="pump-stage-tab p-20 p-r-40">
      <p-tabPanel header="EVENTS">
        <pump-event [form]="stageForm" (onChangePlacementMethod)="onChangePlacementMethod($event)" [readonly]="readonly"
          [outerIndex]="stageForm.get('order').value"></pump-event>
      </p-tabPanel>
      <p-tabPanel *ngIf="!isMudType" header="FLUID DETAILS">
        <pump-fluid-detail #pumpFluidDetail [stage]="stageForm" [stageModel]="stageModel" [fluid]="selectedFluid"
          [plannedVolume]="stageForm.get('volume').value" [cpState]="cpState" [jobState]="jobState" [job]="job"
          [canEdit]="!readonly" [sapMaterialMappings]="sapMaterialMappings">
        </pump-fluid-detail>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>