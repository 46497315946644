<div>
    <div class="dialog-custom">
        <div>
            <ul class="invalid-dialog-message">
                <li *ngFor="let message of completeValidate">
                    - {{message}}
                </li>
            </ul>
        </div>
    </div>
    <div class="fixed-bottom close-link">
        <a (click)="onClose()">Close</a>
    </div>
</div>