import { Component, ChangeDetectionStrategy, Injector, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core";
import { PumpEventComponent } from "./pump-event.component";
import { UntypedFormBuilder, UntypedFormGroup, FormArray, UntypedFormControl } from "@angular/forms";
import { PumpScheduleService } from "../../services";
import { ConfirmDialogService } from "libs/ui";
import { PumpScheduleAdapter } from "../../adapters";
import { LocalEventModel } from "./pump-event.logic";
import { ContextMenu } from "primeng/contextmenu";
import { MenuItem } from "primeng/api";

@Component({
    selector: 'pump-event-cp4',
    templateUrl: './pump-event-cp4.component.html',
    styleUrls: ['./pump-event.component.scss', './pump-event-cp4.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PumpEventComponentCP4 extends PumpEventComponent {
    editShutdownMenuItems: MenuItem[] = [{
        label: 'Add Shutdown Event Before',
        icon: 'fa fa-plus-circle'
    }, {
        label: 'Add Shutdown Event After',
        icon: 'fa fa-plus-circle'
    }];

    constructor(
        protected _inj: Injector,
        protected _fb: UntypedFormBuilder,
        protected _pumpScheduleService: PumpScheduleService,
        protected _confirmDialogService: ConfirmDialogService,
        protected _pumpScheduleAdapter: PumpScheduleAdapter,
        protected _cdr: ChangeDetectorRef
    ) {
        super(_inj, _fb, _pumpScheduleService, _confirmDialogService, _pumpScheduleAdapter, _cdr);
        this.readonly = true;
    }

    public onEventContextMenu(cm: ContextMenu, e: MouseEvent, fg: UntypedFormGroup) {
        if (fg.controls.order) {
            const event: LocalEventModel = this._logic.Events[fg.controls.order.value];
            this.refreshEventMenuItems(event);
            cm.show(e);
        }
        e.stopPropagation();
    }

    public get showContextMenu(): boolean {
        return !this.isFirstStage && !this.readonly;
    }

    public showRemoveButton(fg: UntypedFormGroup): boolean {
        if (this.isFirstStage) return false;
        const event: LocalEventModel = this._logic.Events[fg.controls.order.value];
        return this._logic.isShutdown(event) && !(event.duration > 0);
    }

    public isShutdown(fg: UntypedFormGroup): boolean {
        const event: LocalEventModel = this._logic.Events[fg.controls.order.value];
        return this._logic.isShutdown(event);
    }

    private refreshEventMenuItems(event: LocalEventModel): void {
        const insBeforeMenuItem: MenuItem = this.editShutdownMenuItems[0];
        const insAfterMenuItem: MenuItem = this.editShutdownMenuItems[1];
        insBeforeMenuItem.command = (e) => this.onInsertShutdownEvent(event, true);
        insAfterMenuItem.command = (e) => this.onInsertShutdownEvent(event, false);
    }

    private onInsertShutdownEvent(event: LocalEventModel, insertBefore: boolean): void {
        const addToEnd: boolean = !insertBefore && event.order === this._logic.EventsCount - 1;
        const postition: number = insertBefore ? event.order : event.order + 1;
        const newEvent: LocalEventModel = this._logic.addEmptyEvent(addToEnd ? null : postition);
        if (newEvent) {
            this._logic.updateEventProperty(newEvent.localId, 'placementMethodName', 'Shutdown');
            this.updateEventControlsValidation();
            this.cdr.markForCheck();
        }
    }

    protected subscribeFormChanges(fg: UntypedFormGroup, event: LocalEventModel){
        super.subscribeFormChanges(fg, event);
        //set actual Rate validator
        //TODO need to be refactored
        fg.controls['actualRate'].setValidators([(fc:UntypedFormControl) => {
            const newValue = fc.value === '' || fc.value === null ? null : Number(fc.value);
            return this._logic.validateEvent(event, 'actualRate', newValue, this.PumpScheduleFluidTypeName, this.isCompleteMode ? 'complete': null)['actualRate'];
        }]);
        fg.controls['actualDuration'].setValidators([(fc:UntypedFormControl) => {
            const newValue = fc.value === '' || fc.value === null ? null : Number(fc.value);
            return this._logic.validateEvent(event, 'actualDuration', newValue, this.PumpScheduleFluidTypeName, this.isCompleteMode ? 'complete': null)['actualDuration'];
        }]);
    }
}