export class AdUser {
  email: string;
  mobilePhoneNumber: string;
  firstName: string;
  lastName: string;
  networkDomain: string;
  networkId: string;
  display: string;
  constructor() { }

  get displayName(): string {
    return `${this.firstName} ${this.lastName} (${this.networkId})`;
  }
  get displayNameGroup(): string {
    return `${this.lastName} ${this.firstName} (${this.networkId})`;
  }
}
