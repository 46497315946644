import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IFactsLabReportAttachment } from 'libs/models';
import { IfactService } from 'libs/shared/services/ifact.service';
import { ExternalDocumentsService } from '../../services';
import { IFactsStageInfoModel } from '../../../shared/models';
import { DATE_TIME_CALL_STANDARD } from 'libs/constants/date-time-format.contants';
import { MessageService } from 'primeng/api';
import { DownloadStatusEnum, FileService, HttpCommanderService, TaskDownloadStatus } from 'libs/shared/services';
import { environment } from 'libs/environment';
import { sortBy } from 'lodash';

@Component({
  selector: 'ifacts-lab-reports',
  templateUrl: './ifacts-lab-reports.component.html',
  styleUrls: ['./ifacts-lab-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IfactsLabReportsComponent implements OnInit, OnDestroy {

  @Output() selectionChanged: EventEmitter<boolean> = new EventEmitter();

  private subscription = new Subscription();
  private _downloadToken: string = null;
  private jobId: string = null;
  private wellId: string = null;

  stages: IFactsStageInfoModel[] = [];
  requestsWithStages: any[] = [];
  files: IFactsLabReportAttachment[] = [];
  selectAllChecked: boolean;
  DATE_TIME_CALL_STANDARD = DATE_TIME_CALL_STANDARD;

  constructor(
    private ifactsService: IfactService,
    private externalDocumentsService: ExternalDocumentsService,
    private hcService: HttpCommanderService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private fileService: FileService
  ) { }

  get selectAllDisabled(): boolean {
    return this.files.every(f => f.isDownloaded);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.externalDocumentsService.jobDataChanged$.subscribe(data => {
        this.setJobData(data);
        const requestIds = this.requestsWithStages.map(x => x.requestId);
        this.loadLabReports(requestIds);
      })
    );

    this.subscription.add(
      this.externalDocumentsService.documentsDownload$.subscribe(_ => {
        const selectedFiles = this.files.filter(x => !x.isDownloaded && x.isSelected);
        this.downloadFiles(selectedFiles);
      })
    );

    this.subscription.add(
      this.externalDocumentsService.cancelationDownload$.subscribe(_ => {
        this.hcService.abortJobAttachmentsDownload(this._downloadToken);
      })
    );
  }

  async downloadFiles(files: IFactsLabReportAttachment[]) {
    let mayDownload = await this.fileService.mayDownloadFromExternalDocuments(files.map(e => e.fileName));
    if (files.length === 0 || !mayDownload) {
      return;
    }

    this.externalDocumentsService.startDownload();
    this.hcService.downloadIFactsReports(this.jobId, this.wellId, files)
      .subscribe((status: TaskDownloadStatus) => {
        if (status.status === DownloadStatusEnum.STARTED){
          this._downloadToken = status.taskId;
        }
        else if (status.status === DownloadStatusEnum.PENDING){
          //wait for execution
        }
        else if (status.status === DownloadStatusEnum.INPROGRESS){
          //calculate progressbar
        }
        else if (status.status === DownloadStatusEnum.DONE) {
          this.selectAllChecked = false;
          this.selectionChanged.emit(false);
          this.externalDocumentsService.finishDownload();
          if (status.isCancelled)
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: `The download progress of iFacts Lab Reports has been terminated: ${status.message}` });  
          else
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `Download successfully: ${status.message}`});
          
          this.cdRef.markForCheck();
        }
        else { //ERROR
          this.externalDocumentsService.finishDownload();
          const msg: string = status.message == null ? 
            'Connection to iFacts server was lost. Please click the ‘Download’ button to resume file transfer.'
            : status.message;
          this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'error', detail: msg });
        }
      });
    this.cdRef.markForCheck();
  }

  loadLabReports(requestIds: string[]) {
    if (!requestIds?.length) return;
    
    this.externalDocumentsService.showLoadingIndicator();
    const s =
      this.ifactsService.getLabReports(this.jobId, requestIds).subscribe((files: IFactsLabReportAttachment[]) => {
        this.files = files ?? [];

        this.files.forEach(f => f.isSelected = f.isDownloaded);
        this.checkSelection(null);

        this.requestsWithStages.forEach(r => {
          r.files = this.files.filter(f => f.requestId === r.requestId);
        });

        this.externalDocumentsService.hideLoadingIndicator();
        this.cdRef.markForCheck();
      });
    this.subscription.add(s);
  }

  toggleFile(file: IFactsLabReportAttachment) {
    if (!file.isDownloaded) {
      file.isSelected = !file.isSelected;
    }

    this.checkSelection(null);
  }

  checkSelection($event) {
    this.selectAllChecked = this.files.every(x => x.isSelected);
    const hasSelected = this.files.some(x => x.isSelected && !x.isDownloaded);
    this.selectionChanged.emit(hasSelected);
  }

  getTabStageHeader(pumpScheduleName: string, stages: IFactsStageInfoModel[]): string {
    stages = stages.filter(s => s.pumpSchedule == pumpScheduleName);
    let header = stages.length > 1 ? 'Stages' : 'Stage';
    header = (pumpScheduleName ?? '') +  ' ' + header;
    header += ' ';
    header += stages.map(x => x.stageNumber).join(', ');
    header += ': ';
    header += stages.map(x => x.stageType).distinct().join(', ');
    return header;
  }

  getSectionDescription() {
    return this.files.length > 0
      ? 'Choose the Lab Reports to download. *If already checked, the file has been downloaded.'
      : 'No documents available.';
  }

  checkAllFilesSelected() {
    this.files.forEach((f: { isDownloaded: any; isSelected: boolean; }) => {
      if (!f.isDownloaded) {
        f.isSelected = this.selectAllChecked;
      }
    });    
    this.selectionChanged.emit(this.selectAllChecked);
  }

  private setJobData(data) {
    if (data) {
      this.jobId = data.jobId;
      this.wellId = data.wellId;
      this.stages = data.stages;
      this.requestsWithStages = [];
      for (const stage of this.stages) {
        const requestWithStage = this.requestsWithStages.find(x => x.requestId === stage.requestId);
        if (!requestWithStage) {
          const stages = this.stages.filter(x => x.requestId === stage.requestId);
          this.requestsWithStages.push({
            pumpSchedule: stage.pumpSchedule,
            requestId: stage.requestId,
            stages: stages,
            files: []
          });
        }
      }
      this.requestsWithStages = sortBy(this.requestsWithStages, ['pumpSchedule', 'stages[0].stageNumber']);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
