<div class="typeahead-datatable-container" [ngClass]="styleClass">
  <p-overlayPanel #overlayPanel class="typeahead-datatable" [dismissable]="true" 
                  [style]="{'max-height': '200px', 'overflow-y': 'auto', 'overflow-x': 'hidden'}">

      <p-table [value]="options"
               [columns]="_visibleColumns"
               [loading]="_isLoading"
               selectionMode="single"
               [style]="{'width': '450px'}"
               [(selection)]="_selectedItem"
               (onRowSelect)="_onRowSelect($event.data)">

      <ng-template pTemplate="header" let-columns>
        <tr *ngIf="!_isLoading || options?.length > 0">
          <th *ngFor="let col of columns" [style.width]="col.width">
            {{col.header}}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-columns="columns">
        <tr [pSelectableRow]="row" class="clickable">
          <td *ngFor="let col of columns" [style.width]="col.width">
            <span [innerHTML]="row[col.field] | highlight: _formControl.value"></span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" >
        <div class="no-content">No records found</div>
      </ng-template>
    </p-table>
    
  </p-overlayPanel>
  <input #input *ngIf="!useTextArea"
    type="text"
    class="form-control"
    [formControl]="_formControl"
    (focus)="_onFocus($event)"
    (focusout)="_onFocusOut($event)"
    (input)="_onKeyup($event)"
    [placeholder]="placeholder" />
  <textarea #input *ngIf="useTextArea"
    rows="3"
    type="text"
    class="form-control"
    [formControl]="_formControl"
    (focus)="_onFocus($event)"
    (focusout)="_onFocusOut($event)"
    (input)="_onKeyup($event)"
    [placeholder]="placeholder"></textarea>
  <i *ngIf="_isLoading" class="typeahead-datatable-loader pi pi-spinner pi-spin"></i>
</div>
