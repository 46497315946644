import { Subject, Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment, ENV_NAME } from 'libs/environment';

interface CacheContent {
  expiry: number;
  value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  readonly DEFAULT_MAX_AGE: number = 300000;

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get<T>(key: string, fallback?: Observable<T>, maxAge: number = this.DEFAULT_MAX_AGE): Observable<T> | Subject<T> {

    if (this.hasValidCachedValue(key)) {
      environment.name === ENV_NAME.DEV && console.log(`%cGetting from cache ${key}`, 'color: green');
      return of(this.cache.get(key).value);
    }

    if (this.inFlightObservables.has(key)) {
        const observable = this.inFlightObservables.get(key);
        if (observable.observers.length > 0) {

          return observable;
        }

        this.inFlightObservables.delete(key);  // no observers yet
    }
    
    if (fallback && fallback instanceof Observable) {
      this.inFlightObservables.set(key, new Subject());
      environment.name === ENV_NAME.DEV && console.log(`%cCalling api for ${key}`, 'color: purple');
      return fallback.pipe(
        tap((value) => { this.set(key, value, maxAge); }),
        catchError((e: any) => { // `fallback` are crashed
          // crashed flight is terrible😰, it's better to clean it up...
          this.inFlightObservables.delete(key);
          // and when we have done our job, it's good idea to let the others know this event.
          // maybe they have their stuffs need to be done too.
          return throwError(e);
        })
      );
    } else {
      return throwError('Requested key is not available in Cache');
    }

  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   */
  set(key: string, value: any, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
    this.notifyInFlightObservers(key, value);
  }

  delete(key: string) {
    return this.cache.delete(key);
  }
  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all observers of the given
   * in progress observables if observers exist.
   */
  private notifyInFlightObservers(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      const observersCount = inFlight.observers.length;
      if (observersCount) {
        environment.name === ENV_NAME.DEV && console.log(`%cNotifying ${inFlight.observers.length} flight subscribers for ${key}`, 'color: blue');
        inFlight.next(value);
      }
      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
