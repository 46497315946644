export class PumpScheduleCOGSModel{
    totalCOGS: number;
    stages: PumpScheduleStageCOGSModel[];
}

export class PumpScheduleStageCOGSModel{
    id: string;
    totalCOGS: number;
    fluidMaterials: PumpScheduleStageMaterialCOGSModel[];
}

export class PumpScheduleStageMaterialCOGSModel{
    id: string;
    totalCOGS: number;
}