import { Injectable } from '@angular/core';
import { environment } from 'libs/environment';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ISAPMaterialProduct } from '../../models';


@Injectable()
export class ISAPMaterialProductService {

  constructor(
    private httpClient: HttpClient,
    private httpService: HttpService
  ) {
  }

  getCESAPMaterialProducts() {
    return this.httpService.Get<ISAPMaterialProduct[]>(`${environment.baseUrl}/api/sap-material-product/ce-products`);
  }
}
