export const REG_EXP_NUM: RegExp = /^-?\d+(\.{1}\d+)?$/;
export const REG_EXP_PNUM: RegExp = /^\d+(\.{1}\d+)?$/;
export const REG_EXP_INT: RegExp = /^-?\d+$/;
export const REG_EXP_PINT: RegExp = /^\d+$/;

export function getPattern(pKeyFilter: string = 'num') : RegExp {
  let pattern: RegExp;
  switch (pKeyFilter) {
    case 'num':
      pattern = REG_EXP_NUM;
      break;
    case 'pnum':
      pattern = REG_EXP_PNUM;
      break;
    case 'int':
      pattern = REG_EXP_INT;
      break;
    case 'pint':
      pattern = REG_EXP_PINT;
      break;
    default:
      pattern = REG_EXP_NUM;
      break;
  }
  return pattern;
}