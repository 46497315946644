import { PipeTransform, Pipe } from '@angular/core';
import { formatNumber } from './unit-helper';

@Pipe({ name: 'pUnitFormat' })
export class UnitFormatPipe implements PipeTransform {
  private readonly DEFAULT_DECIMAL_PLACES = 3;

  transform(value: string, args: any): any {
    return formatNumber(value, args || this.DEFAULT_DECIMAL_PLACES);
  }
}
