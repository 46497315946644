<div class="table-event">
  <p-table [value]="events$ | async" class="pump-event-table">
    <ng-template pTemplate="header">
      <tr *ngIf="isCP4View; else eventHeaderTemplate" class="pump-event-header">
        <th>Placement Method</th>
        <th>Volume</th>
        <th>Bulk Cement</th>
        <th>Planned Duration</th>
        <th>Actual Duration*</th>
        <th>Planned Rate</th>
        <th>Actual Rate*</th>
        <th class="context-menu-width"></th>
      </tr>
      <ng-template #eventHeaderTemplate>
        <tr class="pump-event-header">
          <th>Placement Method</th>
          <th>Rate</th>
          <th>Volume</th>
          <th>Top of Fluid</th>
          <th>Length</th>
          <th>Duration</th>
          <th>Bulk Cement</th>
        </tr>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr *ngIf="isCP4View; else eventBodyTemplate" class="pump-event-body" [formGroup]="getForm(event)">
        <td>
          <span>{{ event.placementMethodName }}</span>
        </td>
        <td>
          <span *ngIf="!isShutdown(event)" class="unit-value" [apiValue]="event.volume" [unitType]="UnitType.LargeVolume"></span>
        </td>
        <td>
          <span *ngIf="!isShutdown(event)" formControlName="bulkCement" dUnitValue={{UnitType.BaseMaterialWeight}} dUnitWithName="true" [sackWeight]="sackWeight$ | async"></span>
        </td>
        <td>
          <span class="unit-value">{{ event.duration | number:'1.2-2' }}</span>
          <span class="unit-measure">min</span>
        </td>
        <td>
          <div *ngIf="isShutdown(event)" class="display-measure-event" [lockableElement]="'PumpSchedule_actualDuration'">
            <input type="text" pInputText
              formControlName="actualDuration"
              class="form-control"
              pKeyFilter="pnum"
              pTooltip={{ERROR_UNIT_DECIMAL_POS}}
              tooltipStyleClass="tooltip-events"
              maxlength="13"
              dUnitFormat>
            <div class="flx-itm-center uom-ctrl">
              <span class="unit-of-measure">min</span>
            </div>
          </div>
        </td>
        <td>
            <span *ngIf="!isShutdown(event)" class="unit-value" [apiValue]="event.rate" [unitType]="UnitType.FlowRate"></span>
        </td>
        <td>
          <div>
            <div *ngIf="isActualRateEditable(event)" class="div-left">
              <div class="display-measure-event flx-ctn-row" [lockableElement]="'PumpSchedule_actualRate'">
                <div class="input-ctrl">
                  <input-unit
                    formControlName="actualRate"
                    unit={{UnitType.FlowRate}}
                    tooltip={{ERROR_UNIT_DECIMAL_ONE_POS}}
                    tooltipClass="tooltip-events"
                    decimalPlaces="1"
                    pKeyFilter="pnum"
                    maxlength="13">
                  </input-unit>
                </div>
                <div class="flx-itm-center uom-ctrl">
                  <span class="unit-of-measure" dUnitName={{UnitType.FlowRate}}></span>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="relative">
          <div *ngIf="isCP4EventListEditable()" class="context-menu-button_cp4">
            <span [lockableElement]="'job_completed'">
              <i class="fa fa-ellipsis-h" (click)="showEditListContextMenu(cm, $event, event);"></i>
            </span>
          </div>
          <button *ngIf="isCP4EventDeletable(event)" class="btn-remove-pump-event-readonly" (click)="deleteEvent(event)">
            <div>
              <i class="fa fa-times"></i>
            </div>
          </button>
        </td>
      </tr>
      <ng-template #eventBodyTemplate>
        <tr *ngIf="isEditable; else readonlyEventBodyTemplate" class="pump-event-body" [formGroup]="getForm(event)">
          <td [lockableElement]="'PumpSchedule_placementMethod'">
            <p-dropdown class="custom-dropdown" 
              [options]="placementMethodItems$ | async"
              formControlName="placementMethod" 
              (onChange)="onChangeEvent($event, getForm(event))"
              placeholder="&nbsp;"
              showClear="true"
              filter="true">
            </p-dropdown>
          </td>
          <td>
            <div class="display-measure-event" [lockableElement]="'PumpSchedule_rate'">
              <input-unit formControlName="rate" unit={{UnitType.FlowRate}} tooltip={{ERROR_UNIT_DECIMAL_POS}} tooltipClass="tooltip-events"></input-unit>
              <span class="unit-of-measure" dUnitName={{UnitType.FlowRate}}></span>
            </div>
            <error-display formControlName="rate" [errorMsg]="errorMessages.rate"></error-display>
          </td>
          <td>
            <div class="display-measure-event" [lockableElement]="'PumpSchedule_volume'">
              <input-unit formControlName="volume" unit={{UnitType.LargeVolume}} tooltip={{ERROR_UNIT_DECIMAL}} tooltipClass="tooltip-events" ></input-unit>
              <span class="unit-of-measure" dUnitName={{UnitType.LargeVolume}}></span>
            </div>
          </td>
          <td>
            <div class="display-measure-event" [lockableElement]="'PumpSchedule_topOfFluid'">
              <input-unit formControlName="topOfFluid" unit={{UnitType.Distance}}></input-unit>
              <span class="unit-of-measure" dUnitName={{UnitType.Distance}}></span>
            </div>
          </td>
          <td>
            <div class="display-measure-event" [lockableElement]="'PumpSchedule_length'">
              <input-unit formControlName="length" unit={{UnitType.Distance}}></input-unit>
              <span class="unit-of-measure" dUnitName={{UnitType.Distance}}></span>
            </div>
          </td>
          <td>
            <div class="display-measure-event" [lockableElement]="'PumpSchedule_duration'">
              <input type="text" pInputText
                formControlName="duration"
                class="form-control"
                pKeyFilter="num"
                pTooltip={{ERROR_UNIT_DECIMAL}}
                tooltipStyleClass="tooltip-events"
                dUnitFormat>
              <span class="unit-of-measure">min</span>
            </div>
          </td>
          <td class="relative" [lockableElement]="'pumpSchedule_addNewEvent'">
            <div class="display-measure-event">
                <input-unit
                  formControlName="bulkCement"
                  unit={{UnitType.BaseMaterialWeight}}
                  [sackWeight]="sackWeight$ | async"
                  tooltip={{ERROR_UNIT_DECIMAL_POS}}
                  tooltipClass="tooltip-events">
                </input-unit>
              <span class="unit-of-measure" dUnitName={{UnitType.BaseMaterialWeight}}></span>
            </div>
            <button class="btn-remove-pump-event" (click)="deleteEvent(event)">
              <div class="btn-remove-pump-event-icon">
                <i class="fa fa-times"></i>
              </div>
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template #readonlyEventBodyTemplate>
        <tr class="pump-event-body" [formGroup]="getForm(event)">
          <td>
            <span>{{event.placementMethodName}}</span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="event.rate" [unitType]="UnitType.FlowRate"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="event.volume" [unitType]="UnitType.LargeVolume"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="event.topOfFluid" [unitType]="UnitType.Distance"></span>
          </td>
          <td>
            <span class="unit-value" [apiValue]="event.length" [unitType]="UnitType.Distance"></span>
          </td>
          <td>
            <span class="unit-value">{{ event.duration | number:'1.2-2' }}</span>
            <span class="unit-measure">min</span>
          </td>
          <td class="relative">
            <div >
              <div class="div-left">
                <span formControlName="bulkCement" dUnitValue={{UnitType.BaseMaterialWeight}} dUnitWithName="true" [sackWeight]="sackWeight$ | async"></span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </ng-template>
  </p-table>
  <p-contextMenu #cm [model]="editEventListContextMenuItems" appendTo="body" styleClass="events-summary-contextmenu"></p-contextMenu>
</div>
<div [lockableElement]="'pumpSchedule_addNewEvent'">
  <button *ngIf="canAddEvent" class="action-add" (click)="addEvent()">
    <i class="fa fa-plus-circle"></i><span>Add New Event</span>
  </button>
</div>
