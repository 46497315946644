import { Component, OnInit } from '@angular/core';
import { HELP_FILES } from 'libs/constants';
import { environment } from 'libs/environment';
import { FluidModel, IFactsRequestInfo, ISlurryType, ITestType, Job } from 'libs/models';
import { ApplicationStateService, IfactService, MasterDataService } from 'libs/shared/services';
import { DynamicSidebarContent, DynamicSidebarRef } from 'libs/ui';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

export interface IfactFluidSettingsSidebarInput {
  job: Job;
  jobId: string;
  isLinkFluidRequest: boolean;
  testTypeId: string;
  testType: string;
  slurryTypeId: string;
  iFactsRequestIdHDF: string;
}

@Component({
  selector: 'ifact-fluid-settings-sidebar',
  templateUrl: './ifact-fluid-settings-sidebar.component.html',
  styleUrls: ['./ifact-fluid-settings-sidebar.component.scss']
})
export class IfactFluidSettingsSidebarComponent implements DynamicSidebarContent, OnInit {
  data: IfactFluidSettingsSidebarInput = {
    job: null,
    jobId: null,
    isLinkFluidRequest: false,
    testTypeId: null,
    testType: null,
    slurryTypeId: null,
    iFactsRequestIdHDF: null
  };
  labs: any[];
  requests: IFactsRequestInfo[] = [];
  errorMessage: string = '';
  testTypes: ITestType[];
  slurryTypes: ISlurryType[];
  loadingSubscription: Subscription;
  invisible: boolean = true;

  constructor(
    public appStateService: ApplicationStateService,
    private masterDataService: MasterDataService,
    private ifactService: IfactService,
    private dynamicSidebarRef: DynamicSidebarRef<IfactFluidSettingsSidebarComponent>,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.ifactService.getLabs().subscribe(response => {
      this.labs = response.map(lab => (
        {
          label: lab.LabName,
          value: lab.LabId,
          areaId: lab.AreaId
        }
      ));
    });
    this.masterDataService.listSlurryTypes().subscribe(slurryTypes => this.slurryTypes = slurryTypes);
    this.masterDataService.listTestTypes().subscribe(testTypes => this.testTypes = testTypes);
  }

  searchRequest(requestId) {
    if (this.appStateService.notifyIFactDown$.value) {
      this.errorMessage = 'IFact service unavailable';
      this.requests = [];
      return;
    }

    if (requestId === '') {
      this.errorMessage = 'Request ID is required.';
      this.requests = [];
    } else if (isNaN(requestId) === false) {
      this.loadingSubscription = this.ifactService.getRequestInfo(requestId, true).subscribe(
        (requestInfo: IFactsRequestInfo) => {
          if (requestInfo) {
            this.errorMessage = '';

            // TODO: for searching multi requests in the future
            this.requests = [];
            this.requests.push(requestInfo);
            this.data.testType = requestInfo.type;
            this.invisible = (this.requests.length <=0);
          } else {
            this.handleInvalidRequestId();
          }
        },
        err => {
          this.handleInvalidRequestId(err.error.message);
        });
    } else {
      this.handleInvalidRequestId();
    }
  }

  private handleInvalidRequestId(message = '') {
    this.errorMessage = message ? message : 'Invalid Request ID. Please try again.';
    this.requests = [];
    this.invisible = true;
  }

  close() {
    this.dynamicSidebarRef.close();
  }

  linkRequest() {
    const fluids = this.requests.filter(request => request.availableSlurries.some(slurry => slurry.selected)).reduce(
      (requests, currentRequest) => {
        currentRequest.availableSlurries.filter(slurry => slurry.selected).forEach(slurry => {
          const fluid = new FluidModel();
          fluid.requestId = currentRequest.id;
          fluid.slurryNo = slurry.number;
          fluid.slurryId = '' + slurry.id;
          fluid.testTypeId = this.data.testTypeId;
          fluid.testType = currentRequest.type;
          fluid.slurryTypeId = this.data.slurryTypeId;
          requests.push(fluid);
        });
        return requests;
      },
      []
    );

    if (fluids.length > 0) {
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: 'Request has been linked to job successfully.' });
      this.appStateService.cogsRecalculationNeeded$.next(true);
      this.dynamicSidebarRef.close(fluids);
    } else {
      this.errorMessage = 'Please select at least one Slurry Number.';
    }
  }

  openHelp() {
    window.open(HELP_FILES.Link_Fluid_Request);
  }

  onSlurrySelected(slurryId: number, checked: boolean) {
    this.requests.filter(request => {
      request.availableSlurries.find(x => x.id === slurryId).selected = checked;
    });
  }
}


