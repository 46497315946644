import { Job, FluidMaterialModel, FluidModel } from 'libs/models';
import { IFACT_CONSTANTS } from 'libs/constants';
import { MaterialService } from 'libs/shared/services';

const ADDTITIVES = 6;
const SPACER = 8;
const CEMENT = 4;
const MIX_WATER = 5;


export function standardConcentrationUnit(unit) {
    const uom = IFACT_CONSTANTS.UNITS_OF_MEASURE[unit.toLowerCase().replace(/\s+/g, '')];
    return uom ? uom.iFactsCode : '% BWOC';
}


export class IFactMapping {
    constructor(
        private _materialService: MaterialService
        ) {
    }

    mappingHeader = (job: Job, requestData) => {
        return this.toString({
            'EngineerUserId': requestData.engineerUserId,
            'RequestType': requestData.requestType || '6',
            'RequestStatus': 2,
            'LabId': requestData.labId,
            'RequestedDate': '',
            'RequiredByDate': '',
            'UsedFor': requestData.usedFor,
            'CustomerId': requestData.customerId,
            'RigId': job.rig,
            'LocationId': '',
            'WellId': job.wellId,
            'CasingSize': job.casingSizeValue,
            'HoleSize': job.holeSizeValue,
            'SideTrackId': '',
            'JobTypeId': job.jobType,
            'PlantCode': '',
            'TrademarkId': '',
            'ApplicationDevelopment': false,
            'CreatedByApp': 'VIDA',
            'HeaderComments': '',
            'SalesOrders': job.salesOrderNumber
        });
    }

    mappingTest = (job: Job) => {
        return this.toString({
            'Pressure': job.bhp,
            'MudDensity': '', // note
            'BatchMixing': '',
            'HeatingTime': '',
            'BHST': job.bhst,
            'BHCT': job.bhct,
            'MD': job.jobMd,
            'TVD': job.jobTvd,
            'MudSupplierId': '',
            'MudSupplierName': '',
            'MudTradeId': '',
            'MudTradeName': ''
        });
    }

    mappingSlurry = (fluidModel: FluidModel, requestData) => {
        const SlurryTypeId = (requestData.usedFor === 1 && requestData.slurryType) ? requestData.slurryType.SlurryTypeId : '1';
        return {
            'BlendID': '',
            'OldSlurryId': null,
            'RequestId': null,
            'SlurryTypeId': SlurryTypeId,
            'Mixability': '',
            'Density': requestData.density ? requestData.density.toString() : '',
            'MixVolume': '600',
            'UsedOnJob': 'N',
            'IsFoam': 'N',
            'FoamDensity': fluidModel.foamDensity ? fluidModel.foamDensity.toString() : '',
            'FoamBlendVol': '',
            'CreatedBy': requestData.engineerUserId,
            'Tests': [],
            'SlurryComments': ''
        };
    }

    buildCementBlend = (fluidModel: FluidModel) => {
        return fluidModel.fluidMaterial?.some(m => this._materialService.isCement(m)) ?
            ({
                'CementBlendSackWeight': fluidModel.sackWeight ? fluidModel.sackWeight.toString() : '',
                'CementBlendConcentration': '100',
                'CementBlendSG': '',
                'CementBlendUnit': '% BWOC',
                'CementBlendTestAmount': '',
            }) : null;
    }

    buildBlendComponents = (fluidMaterials: FluidMaterialModel[], sackWeight) => {
        if (!fluidMaterials)
            return null;
        const _fluids = [...fluidMaterials];

        return {
            CementBlendComponents: _fluids.filter(m => this._materialService.isCement(m))  // use the same condition to select blend components as are displayed on UI
                .map(_fluid => {
                    const concentrationUnit = _fluid.concentrationUnit || _fluid.fluidUnitMeasureName;

                    const bulkDensity = _fluid.bulkDensity || sackWeight;
                    let amount = _fluid.concentration || 0;

                    if (!/% BWOC|%BWOC/.test(concentrationUnit) && bulkDensity > 0) {
                        amount = amount * 100 / bulkDensity;
                    }
                    return this.toString({
                        'SampleID': '',
                        'MaterialID': _fluid.materialId,
                        'SG': '',
                        'SackWeight': bulkDensity,
                        'Amount': amount || '',
                        'ActualAmount': ''
                    });
                })
        };
    }

    buildAdditiveComponents = (fluidMaterials: any, water: any) => {
        if (!fluidMaterials)
            return null;
        const _fluids = [...fluidMaterials];
        let additives = _fluids.filter(this.isNotCementMat);
        additives = additives.map((material, index) => {
            // const orderStr = material.order || material.materialType === 'Mix Water' ?  100 : index + 1;
            const orderStr = material.materialType === 'Mix Water' ? 100 : index + 1;
            const materialTypeId = material.materialType === 'Spacer' && material.materialType !== 'Mix Water' ? SPACER : ADDTITIVES;
            return this.toString({
                'Order': orderStr.toString(),
                'MaterialId': material.materialId,
                'MaterialTypeId': materialTypeId,
                'Concentration': material.concentration.toString(),
                'ConcentrationUnit': standardConcentrationUnit(material.concentrationUnit || material.fluidUnitMeasureName || ''),
                'SG': material.sg,
                'TestAmount': material.testAmount,
                'SampleId': '',
                'BulkDensity': material.bulkDensity,
                'MixingProcedure': material.mixingProcedureValue || 'PH',
                'WeightMaterial': false
            });
        });
        if (!_fluids.some(m => this._materialService.isCement(m))) {
            additives.push({
                BulkDensity: '',
                Concentration: '100',
                ConcentrationUnit: '% BWOC',
                MaterialId: '',
                MaterialTypeId: CEMENT,
                MixingProcedure: '',
                Order: '0',
                SG: '',
                SampleId: '',
                TestAmount: '',
                WeightMaterial: false,
            });
        }

        const hasWater = water || additives.some(_fluid => _fluid.materialType === 'Mix Water');
        if (hasWater) {
            additives.push({
                Order: '100',
                MaterialId: `${water}`,
                MaterialTypeId: `${MIX_WATER}`,
                Concentration: '100',
                ConcentrationUnit: 'L/100kg',
                SG: '',
                TestAmount: '',
                SampleId: '',
                BulkDensity: '',
                MixingProcedure: '',
                WeightMaterial: false
            });
        } else {
            additives.push({
                Order: '100',
                MaterialId: '',
                MaterialTypeId: `${MIX_WATER}`,
                Concentration: '100',
                ConcentrationUnit: 'L/100kg',
                SG: '',
                TestAmount: '',
                SampleId: '',
                BulkDensity: '',
                MixingProcedure: '',
                WeightMaterial: false
            });
        }

        return additives;
    }

    mappingJobToIFact = (job, fluids, requestData) => {
        const Header = this.mappingHeader(job, requestData);
        const TestCondition = this.mappingTest(job);
        const { water } = job;
        // build slurry
        const baseSlurry = this.mappingSlurry(fluids, requestData);
        // build addtitives
        const Additives = this.buildAdditiveComponents(fluids.fluidMaterial, water);
        // build cement blend
        const CementBlend = {
            ...this.buildCementBlend(fluids),
            ...this.buildBlendComponents(fluids.fluidMaterial, fluids.sackWeight)
        };

        const Slurry = { ...baseSlurry, Additives, CementBlend };

        return { Header, TestCondition, Slurry };
    }

    isAdditivesMat = _fluid => !this._materialService.isCementIFacts(_fluid) || !this._materialService.isSupplemental(_fluid);
    isNotCementMat = _fluid => !this._materialService.isCementIFacts(_fluid);

    toString = (x) => {
        return Object.entries(x).reduce((init, [key, value]) => {
            if (typeof value === 'boolean' || value === null) {
                init[key] = value;
            } else {
                init[key] = value ? value.toString() : '';
            }
            return init;
        }, {});
    }
}
