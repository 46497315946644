import { Injectable } from '@angular/core';

import { ReplaySubject, Subject, BehaviorSubject, Observable } from 'rxjs';
import { Job, PumpSchedule } from 'libs/models';
import { ControlPointType, ControlPointState } from 'apps/vida/src/modules/shared/constant';
import { ISAPMaterialModel } from 'apps/vida/src/modules/edit-job/models/sap-material.model';
import { SpacerMixMethod } from 'libs/constants';
import { JobClassificationState, JobParamsState } from 'apps/vida/src/modules/edit-job/components';

@Injectable()
export class ApplicationStateService {
  jobCreated$ = new Subject();
  jobTypeChanged = new Subject<any>();
  isLandChanged = new Subject<boolean>();
  spacerMixMethodChange$ = new BehaviorSubject<SpacerMixMethod>(SpacerMixMethod.MixOnTheFly);
  iCemFileImported$ = new Subject();
  iCemRealtimeImported$ = new Subject();

  rigMasterDataChanged$ = new Subject();

  openJobEdit$ = new Subject<any>();
  openWellEdit$ = new Subject<any>();
  jobEditOpened$ = new BehaviorSubject<boolean>(false);
  createWellOpened$ = new BehaviorSubject<boolean>(false);
  openWellImport$ = new Subject<any>();
  wellImportOpened$ = new BehaviorSubject<boolean>(false);
  riskLevelChange$ = new Subject<number>();
  sapChanged$ = new Subject<ISAPMaterialModel>();
  bomTypeChanged$= new Subject<any>();
  openUserSetting$ = new Subject();
  openCreateOption$ = new Subject();
  openSecurityAdmin$ = new Subject();
  closeSecurityAdmin$ = new Subject();
  groupSelected$ = new Subject();
  openIcemFileSource$ = new Subject();
  openJobProgramSource$ = new Subject();
  openJobLogSource$ = new Subject();
  openIcemSource$ = new Subject();
  openLocalWellSource$ = new Subject<any>();
  openImportJobIcemFile$ = new Subject();
  openImportJobHDF$ = new Subject();
  openCollection$ = new Subject();
  resetDataOnUploader$ = new Subject();
  saveCDIComment$ = new Subject();
  openCallSheet$ = new Subject();
  openRiskAssessment$ = new Subject();
  openPostJobReport$ = new Subject();
  openCarbonFootprintSidebar$ = new Subject();
  jobProjectedDateChange$ = new Subject<any>();
  jobActualDateChange$ = new Subject<any>();

  pumpScheduleCreate$ = new Subject();
  createAction$ = new BehaviorSubject<string>(null);
  // controlPointRefresh$ = new Subject();
  controlPoint1Submission$ = new Subject<string>();
  controlPoint1Unlock$ = new Subject<string>();
  controlPoint1Opened$ = new Subject<boolean>();
  openUploadTemplateReport$ = new Subject();
  notifyCheckValidCp1$ = new Subject();
  validateCompletedPumpSchedule$ = new Subject<any>();
  lockableElement$ = new BehaviorSubject<string>(null);


  isModifiedByUser: boolean = false;
  isCreate: boolean = false;
  isCreateImportIcem: boolean = false;
  isCreateImportHDF: boolean = false;
  $checkCompleteCP3 = new Subject<boolean>();
  $checkCompleteJobProgram = new Subject<boolean>();
  $fileSelected = new Subject<any>();
  $checkJobLogFileDownloaded = new Subject<any>();

  jsqaFileChanged$ = new Subject<boolean>();
  equipmFileChanged$ = new Subject<boolean>();
  thickeningTimeChange = new Subject<any>();
  loadJobApprovalFileDropdownOptions$ = new Subject();

  soCP4Validate$ = new Subject<boolean>();
  actualDateState$ = new Subject<boolean>();
  actualDate$ = new Subject<any>();
  projectedDate$ = new Subject<any>();
  isJobLogExists$ = new Subject<boolean>();
  cp4iCemRealtimeState$ = new Subject<boolean>();
  validateCP4 = new Subject<any>();

  isChangeOffshore$ = new BehaviorSubject<boolean>(false);
  linkToHdf$ = new Subject<any>();

  updateMudData$ = new Subject();
  updateMudDensity$ = new Subject<number>();
  syncMudListCP = [];
  syncMudListJobEdit = [];
  initiatePassMudDensityFromCDI$ = new Subject();
  actualVolumeDetectChange$ = new Subject();
  actualDensityDetectChange$ = new Subject();
  materialCostCalculated$ = new Subject();
  cogsRecalculationNeeded$ = new BehaviorSubject<boolean>(false);
  isLoadingAccountRepresentative$ = new BehaviorSubject<boolean>(false);
  notifyIFactDown$ = new BehaviorSubject<boolean>(false);
  criticalWellReview$ = new Subject<boolean>();
  createIFactsRequestCompleted$ = new Subject<any>();
  openMaterialLoadSheet$ = new Subject();
  genarateMLSReport$ = new Subject();
  pumpScheduleReady$ = new BehaviorSubject<boolean>(false);

  dwxWellList$ = new BehaviorSubject<any>(null);

  cp2HandoverSubmitted$ = new Subject<any>();
  cp2HandoverFileChanged$ = new Subject<any>();

  pumpScheduleCache$ = new BehaviorSubject<PumpSchedule>(null);

  jobClassification$: Subject<JobClassificationState> = new Subject<JobClassificationState>();

  jobParams$: Subject<JobParamsState> = new Subject<JobParamsState>();

  isEnvanaDown$ = new BehaviorSubject(false);
  notifySapDown$ = new BehaviorSubject(false);
  jobBulkPlantPushNotes = new Map<string, string>();

  constructor() { }
}

export function getLockableClassByJob(job: Job, jobStatusComplete = null) {
  // The order is fixed. Please not change if you not understand it.
  let result = null;
  let cp1StateCompleted: boolean = false;
  if (job == null) return 'cpInit';
  // 1st
  const controlPoint1 = job.controlPoints.find(x => x.type === ControlPointType.ControlPoint1);
  if (controlPoint1 && controlPoint1.eControlPointState === ControlPointState.Submitted) {
    result = 'cp1Submitted';
    cp1StateCompleted = true;
  } else if (controlPoint1 && controlPoint1.eControlPointState === ControlPointState.Approved) {
    result = 'cp1Approved';
    cp1StateCompleted = true;
  }
  // 2nd
  const controlPoint2 = job.controlPoints.find(x => x.type === ControlPointType.ControlPoint2);

  if (controlPoint2 && controlPoint2.eControlPointState === ControlPointState.Prepared) {
    result = 'cp2Prepared';
    if (cp1StateCompleted) {
      result = 'cp1Submitted2Prepared';
    }
  }

  if (controlPoint2 && controlPoint2.eControlPointState === ControlPointState.Completed) {
    result = 'cp2Completed';
    if (cp1StateCompleted) {
      result = 'cp1Submit2Completed';
    }
  }
 // 3rd
  const controlPoint4 = job.controlPoints.find(x => x.type === ControlPointType.ControlPoint4);
  if (controlPoint4 && controlPoint4.eControlPointState === ControlPointState.Completed) {
    result = 'cp4Completed';
  }
  // 4th
  if (job.jobStatus === jobStatusComplete || job.jobStatusDescription === 'Completed') {
    result = 'jobComplete';
  }

  return result;
}
