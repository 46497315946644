import { AbstractControl } from '@angular/forms';

export function removeErrorFromControl(control: AbstractControl, key: string) {
    const errors = Object.assign({}, control.errors);
    if (errors && errors[key]) {
        delete errors[key];
        control.setErrors(errors);
    }
}

export function addErrorToControl(control: AbstractControl, key: string) {
    const errors = Object.assign({}, control.errors);
    errors[key] = true;
    control.setErrors(errors);
}