import { ContingenciesOption, ObjectState } from './../../../apps/vida/src/modules/shared/constant/';

export class Contingencies {
  id: string;
  option: ContingenciesOption;
  fromCustomer: boolean;
  contingency: string;
  mitigation: string;
  state: ObjectState;
  order: number;
  reasonOfOther: string;
  optionObjectiveMet: number;
  resultComment: string;

  constructor() {
    this.id = '';
    this.option = null;
    this.fromCustomer = null;
    this.contingency = null;
    this.mitigation = null;
    this.state = ObjectState.Created;
    this.order = null;
    this.reasonOfOther = null;
    this.resultComment = '';
    this.optionObjectiveMet = null;
  }
}
