import { PublicClientApplication } from '@azure/msal-browser';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdminService, FileService } from '.';
import { JobStateService } from './job-state.service';
import { RoleService } from './role.service';
import { SessionService } from './session.service';
import { UserSettingService } from './user-setting.service';

export function appLoadingFactory(
  sessionService: SessionService,
  userSettingsService: UserSettingService,
  unitConversionService: UnitConversionService,
  roleService: RoleService,
  jobStateService: JobStateService,
  azureAdApp: PublicClientApplication,
) {

  return () => new Promise((resolve) => {

    azureAdApp.handleRedirectPromise().then(result => {

      if (!result || !result.account) {
        azureAdApp.loginRedirect();
        return;
      }


      userSettingsService.getAllSettings()
        .pipe(
          catchError(() => of(false))
        )
        .subscribe(allSettings => {
          if (allSettings && allSettings.networkId) {
            sessionService.user.userName = result.account.name; // name = full name, username = email
            sessionService.user.prefUserName = allSettings.networkId;
            sessionService.user.hcToken = allSettings.hcToken;
            // Handle Locations
            userSettingsService.refreshLocationList(allSettings.locations);
            // Handle Unit Measurement
            userSettingsService.userSettings = { ...allSettings.settings };
            unitConversionService.userUnits = { ...allSettings.units };
            unitConversionService.unitMeasures$.next(allSettings.unitMeasures);
            // Get User Roles
            roleService.setRoles(allSettings.roles);
            // Get Job State
            jobStateService.jobStateData = allSettings.jobState;
          } else {
            roleService.setRoles([]);
          }

          resolve(true);
        });
    });
  });
}
