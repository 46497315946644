export enum CriteriaControls {
  FromCustomer = 0,
  TargetJustification = 1,
  ResultJustification = 2,
  TargetMinimumPressure = 3,
  ResultPressure = 4,
  MinVolume = 5,
  MaxVolume = 6,
  ResultVolume = 7,
  TargetMaximumPercentage = 8,
  ResultPercentage = 9,
  MinTime = 10,
  MaxTime = 11,
  ResultTime = 12,
  Rpm = 13,
  Temp = 14,
  MinTemp = 15,
  MaxTemp = 16,
  ResultTemp = 17,
  MinDensity = 18,
  MaxDensity = 19,
  ResultDensity = 20,
  MinOther = 21,
  MaxOther = 22,
  ResultOther = 23,
  UnitType = 24,
  ReasonOfOther = 25,
  MinGelStrength = 26,
  MaxGelStrength = 27,
  ResultGelStrength = 28
}