import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { GroupSettingsModel, IGroupList } from 'libs/models';
import { HttpService } from './http.service';
import { IAdditionalParams } from 'apps/vida/src/modules/shared/models';
import { add } from 'lodash';
import { Group } from 'libs/models/entities/group';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class GroupService {

  constructor(private http: HttpService) { }

  loadGroupDetail(groupId: string): Observable<GroupSettingsModel> {
    return this.http.Get(`${environment.baseUrl}/api/admin/groups/${groupId}`);
  }

  isExisted(regionId, groupName: string): Observable<boolean> {
    const params = new HttpParams()
      .set('regionId', regionId)
      .set('groupName', groupName);

    return this.http.GetWithParams(`${environment.baseUrl}/api/admin/groups/existence`, params);
  }

  saveGroup(groupSettings: GroupSettingsModel): Observable<boolean> {
    if (groupSettings.groupId) {
      return this.http.Put(`${environment.baseUrl}/api/admin/groups/${groupSettings.groupId}`, JSON.stringify(groupSettings));
    } else {
      return this.http.Post(`${environment.baseUrl}/api/admin/groups`, JSON.stringify(groupSettings));
    }
  }

  getGroupsByRegion(regionId: string, additionalParams: IAdditionalParams): Observable<IGroupList> {
    const { multiSort, pageSize, pageNum } = additionalParams;

    const params = `${regionId}?multiSort=${multiSort}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.http.Get(`${environment.baseUrl}/api/Admin/Groups/ByRegion/${params}`);
  }

  getGroup(groupId: string): Observable<Group> {
    return this.http.Get(`${environment.baseUrl}/api/admin/groups/group/${groupId}`);
  }

}