import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActualQuantityConfirmationService {

  private actualQuantityChange = new Subject<boolean>();
  private _actualQuantityChange$ = this.actualQuantityChange.asObservable();

  get actualQuantityChange$(): Observable<boolean> {
    return this._actualQuantityChange$;
  }

  constructor() { }

  confirmActualQuantityChange(value: boolean): void {
    this.actualQuantityChange.next(value);
  }
}
