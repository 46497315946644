
export const ValidatorSetting = {
  MAX_FT_LENGTH: 100000,
  MAX_M_LENGTH: 30480,
  MAX_LENGTH_TEXT_50: 50,
  MAX_LENGTH_TEXT_100: 100,
  MAX_LENGTH_TEXT_DEFAULT: 1500,
  MAX_DECIMAL_LENGHT: 999999.99,
  MIN_DECIMAL_LENGHT: -999999.99,
  NUM_15_WHOLE_DIGITS: 999999999999999.99,
  ERROR_MAX_LENGTH_50_TEXT: 'Must be under 50 characters.',
  ERROR_MAX_LENGHT_100_TEXT: 'Must be under 100 characters.',
  ERROR_MAX_LENGHT_DEFAULT_TEXT: `Must be under 1500 characters.`,
  ERROR_EMAIL: 'Not a valid email.',
  ERROR_REQUIRED: 'is required field.',
  ERROR_NUMBER_DEGREES: 'Enter number between 0-180.',
  ERROR_NUMBER_TIME: 'Enter number between 0-60.',
  ERROR_NUMBER_NUMBER: 'Invalid value. Please try again!.',
  ERROR_NUMBER_PHONE: 'Phone number is invalid.',
  ERROR_NEGATIVE: 'Value can\'t be negative',
  ERROR_UNIT_FT: 'Enter a number between 0-100,000 ft with no more than 1 decimal place',
  ERROR_UNIT_M: 'Enter a number between 0-30,480 m with no more than 1 decimal place',
  REGEX_PHONE_NUMBER: '^[0-9+() -]+',
  ERROR_UNIT_DECIMAL: 'Enter a number between -999,999.99-999,999.99 with no more than 2 decimal places',
  ERROR_UNIT_DECIMAL_POS: 'Enter a number between 0-999,999.99 with no more than 2 decimal places',
  ERROR_UNIT_DECIMAL_ONE_POS: 'Enter a number between 0-999,999.99 with no more than 1 decimal places',
  ERROR_UNIT_DECIMAL_MAX: 'Must be less then 999,999.99',
  ERROR_UNIQUE_COLLECTION_NAME: 'This collection name already exists. Please enter a new name.',
  MAX_LENGTH_JOB_NAME_50: 'The Job Name cannot exceed 50 characters.',
  MAX_DECIMAL_NUMBER: 922337203685477
};
