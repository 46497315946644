import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from '../../../shared/validators';
import { ValidatorSetting } from '../../../shared/constant';
import { createMudParameterFromGroup } from '../mud-parameters/mud-parameters.form.factory';
import { SpacerMixMethod } from 'libs/constants';

export function createPumpScheduleForm(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    jobId: null,
    pumpScheduleId: null,
    stages: fb.array([createPumpScheduleStage(fb)]),
    shoeTrackLength: [{
      value: '',
      disabled: false
    }, Validators.compose([Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT), Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT), CustomValidators.validateIsNumber])],
    shoeTrackVolume: [{
      value: '',
      disabled: false
    }, Validators.compose([Validators.min(ValidatorSetting.MIN_DECIMAL_LENGHT), Validators.max(ValidatorSetting.MAX_DECIMAL_LENGHT), CustomValidators.validateIsNumber])],
    scheduledShutdown: [{
      value: '',
      disabled: false
    }],
    targetSafetyFactor: [{
      value: '',
      disabled: false
    }],
    batchMixingTime: [{
      value: '',
      disabled: false
    }],
    spacerMixMethod: [{
      value: SpacerMixMethod.MixOnTheFly,
      disabled: false
    }],
    isManuallySpacerMixMethod: [{
      value: false,
      disabled: false
    }],
    totalCOGS:  [{
      value: null,
      disabled: false
    }],
    linerCirculationMethod: [{
      value: null,
      disabled:false
    }],
    linerCementLength: [{
      value: null,
      disabled: false
    }],
    isVersaFlexLiner: [{
      value: null,
      disabled: false
    }]
  });
}

export function createPumpScheduleStage(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    id: null,
    order: 0,
    syncObjectiveId: null,
    number: 0,
    pumpScheduleFluidTypeId: [
      {
        value: null,
        disabled: false
      }, CustomValidators.requiredWithTrimming
    ],
    pumpScheduleFluidTypeName: null,
    slurry: [{
      value: null
    }],
    avgRate: [{
      value: '',
      disabled: true
    }],
    loadoutVolume: null,
    deadVolume: 0,
    isManuallyDeadVolume: false,
    deadVolumeFluidType: null,
    stageWaterTotal: null,
    actualVolumePumped: null,
    actualDensity: null,
    plannedDensity: null,
    thickeningTime: null,
    minThickeningTime: null,
    actualSafety: null,
    volume: [{
      value: '',
      disabled: true
    }],
    topOfFluid: [{
      value: null,
      disabled: true
    }],
    totalBulkCement: null,
    bulkCement: null,
    isBulkCement: false,
    isChangeLoadOutVolume: false,
    isChangeActualVolumePumped: false,
    isManualyThickeningTime: false,
    mudParameter: createMudParameterFromGroup(fb),
    events: fb.array([]),
    fluids: fb.array([]),
    slurryIdHDF: null,
    totalCOGS: null
  });
}

export function createPumpScheduleStageFluid(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    id: null,
    overrideMaterial: null
  });
}
