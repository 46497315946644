import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class IfactSearchService {

  constructor(private httpService: HttpService) {
  }

  getIfactJobType(): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/ifactJobtype`);
  }
   getIfactTestField(): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/ifactTestField`);
  }
}
