import { Injectable } from '@angular/core';

import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { UnitType, APIUnitOfMeasure } from 'libs/constants';
import { UOM, UserUnitSystemSettingInfoModel, UnitSystemSettingsInfoModel, UnitClassMeasureInfoModel, UnitMeasureModel } from 'libs/models';
import { convertUnit, coordinate_to_dms } from 'libs/helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'libs/environment';
import { formatNumber, convertWithUnitMeasure } from './unit-helper';
import { publishReplay, refCount } from 'rxjs/operators';
import { preciseRound } from 'libs/helpers/math';

@Injectable()
export class UnitConversionService {

  readonly UNIT_TYPE_API: string = 'API';
  readonly UNIT_TYPE_SI: string = 'SI';
  readonly UNIT_TYPE_CUSTOM: string = 'Custom';

  userUnits: UserUnitSystemSettingInfoModel;
  unitMeasures$ = new BehaviorSubject<UnitClassMeasureInfoModel[]>([]);
  onSaveUnit$: Subject<any> = new Subject();

  _unitSystemTypesCache: Observable<any> = null;
  _unitClassesCache: Observable<any> = null;
  _systemSettingCache: Observable<any>[] = [];

  constructor(private httpClient: HttpClient) {
  }

  setUserUnits(info: UserUnitSystemSettingInfoModel): void {
    this.userUnits.unitSystemId = info.unitSystemId;
    this.userUnits.unitSystemSettings = info.unitSystemSettings;
  }

  getCurrentUserUnitSystemSettings(): Observable<UserUnitSystemSettingInfoModel> {
    return this.httpClient.get<UserUnitSystemSettingInfoModel>(`${environment.baseUrl}/api/UnitMeasure/CurrentUserUnitSystemSettings`);
  }

  getAllUnitSystemTypes(): Observable<any> {
    if (this._unitSystemTypesCache === null){
      this._unitSystemTypesCache = this.httpClient.get(`${environment.baseUrl}/api/UnitMeasure/UnitSystemTypes`).pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this._unitSystemTypesCache;
  }

  getAllUnitClasses(): Observable<any> {
    if (this._unitClassesCache === null){
      this._unitClassesCache = this.httpClient.get(`${environment.baseUrl}/api/UnitMeasure/UnitClasses`).pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this._unitClassesCache;
  }

  getUnitSystemSetting(unitSystemId): Observable<any> {
    if (!this._systemSettingCache[unitSystemId]){
      this._systemSettingCache[unitSystemId] = this.httpClient.get(`${environment.baseUrl}/api/UnitMeasure/UnitSystemSettings/${unitSystemId}`).pipe(
        publishReplay(1),
        refCount()
      );
    }
    return this._systemSettingCache[unitSystemId];
  }

  getUnitSystem(unitName: string): Observable<UnitSystemSettingsInfoModel[]> {
    return this.httpClient.get<UnitSystemSettingsInfoModel[]>(`${environment.baseUrl}/api/UnitMeasure/UnitSystem/${unitName}`);
  }

  getUnitSystems(): Observable<UnitSystemSettingsInfoModel[]> {
    const url = `${environment.baseUrl}/api/UnitMeasure/UnitSystems`;
    return this.httpClient.get<UnitSystemSettingsInfoModel[]>(url);
  }

  getAllUnitMeasuresOfUnitClass(): Observable<UnitClassMeasureInfoModel[]> {
    return this.httpClient.get<UnitClassMeasureInfoModel[]>(`${environment.baseUrl}/api/UnitMeasure/UnitClassMeasures`);
  }

  postSaveUserUnitSystemSettings(obj: any): Observable<any> {
    return this.httpClient.put(`${environment.baseUrl}/api/UnitMeasure/CurrentUserUnitSystemSettings`, obj);
  }

  // TODO: Remove references. Will be obsoleted
  convertToUserUnit(value: any, unitType: UnitType) {
    const numberToConvert = Number.parseFloat(value);
    if (Number.isNaN(numberToConvert)) return null;
    const destinationUnit = this.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === unitType).unitMeasure.name;
    const convertedValue = convertUnit(numberToConvert, unitType, APIUnitOfMeasure[unitType], destinationUnit);

    return isNaN(convertedValue) ? null : parseFloat(parseFloat(convertedValue).toFixed(8));
  }

  // TODO: Remove references. Will be obsoleted
  formatToUserUnit(value: any, unitType: UnitType, decimalPlaces = 2) {
    return formatNumber(this.convertToUserUnit(value, unitType), decimalPlaces);
  }

  // TODO: Remove references. Will be obsoleted
  roundSmallDistance(value: any, unitType: UnitType) {
    return preciseRound(this.convertToUserUnit(value, unitType), 3);
  }

  // TODO: Remove references. Will be obsoleted
  safelyConvertToUserUnit(value: any, unitType: UnitType) {
    const numberToConvert = Number.parseFloat(value);
    if (Number.isNaN(numberToConvert)) return { origin: null, display: null };
    if (unitType == null) return { origin: this._roundValueForConversion(+value), display: this._roundValueForConversion(+value) };
    const destinationUnit = this.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === unitType).unitMeasure.name;
    let convertedValue = null;

    convertedValue = this._roundValueForConversion(convertUnit(numberToConvert, unitType, APIUnitOfMeasure[unitType], destinationUnit));

    return {
      origin: value,
      display: convertedValue,
      unitType: unitType
    } as UOM;
  }

  // TODO: Remove references. Will be obsoleted
  convertToStandard(value: any, unitType: UnitType) {
    const numberToConvert = Number.parseFloat(value);
    if (Number.isNaN(numberToConvert) || unitType == null) return null;
    const originUnit = this.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum === unitType).unitMeasure.name;

    return convertUnit(numberToConvert, unitType, originUnit, APIUnitOfMeasure[unitType]);
  }

  // TODO: Remove references. Will be obsoleted
  convertToStandardCompareOrigin(uom: UOM, value: any, unitType: UnitType) {
    if (unitType == null) {
      return value;
    }
    if (uom == null || uom.display !== Number.parseFloat(value) || uom.unitType !== unitType) {
      return this.convertToStandard(value, unitType);
    } else {
      return uom.origin;
    }
  }

  convertToUnit(value: any, unitType: UnitType, originUnit: string, destinationUnit: string) {

    const numberToConvert = Number.parseFloat(value);
    if (Number.isNaN(numberToConvert) || unitType == null || !originUnit || !destinationUnit) return null;

    const convertedValue = this._roundValueForConversion(convertUnit(value, unitType, originUnit, destinationUnit));

    return convertedValue;
  }

  // TODO: Remove references. Will be obsoleted
  _roundValueForConversion(val) {
    return preciseRound(val, 3);
  }

  convertToDms(deg: number) {
    return coordinate_to_dms(deg);
  }

  getUnitName(unitType: UnitType, useAlias: boolean) {
    if (unitType == null) return '';

    const unit = this.userUnits.unitSystemSettings.find(m => m.unitClass.unitClassEnum.toString() === unitType.toString());
    if (unit) return useAlias && unit.unitMeasure.aliasName ? unit.unitMeasure.aliasName : unit.unitMeasure.name;
  }

  getApiUnitMeasure(unitType: UnitType): UnitMeasureModel {
    if (unitType === null || unitType === undefined) return null;
    const unitInfo = this.unitMeasures$.value.find(u => +u.unitClass.unitClassEnum === +unitType);
    return unitInfo ? unitInfo.apiUnitMeasure : null;
  }

  getSiUnitMeasure(unitType: UnitType): UnitMeasureModel {
    if (unitType === null || unitType === undefined) return null;
    const unitInfo = this.unitMeasures$.value.find(u => +u.unitClass.unitClassEnum === +unitType);
    return unitInfo ? unitInfo.siUnitMeasure : null;
  }

  getUnitMeasureById(unitType: UnitType, uomId: string) {
    if (unitType === null || unitType === undefined || uomId === null || uomId === undefined) return null;
    const unitInfo = this.unitMeasures$.value.find(u => +u.unitClass.unitClassEnum === +unitType);
    if (!unitInfo) return null;
    const unitMeasure = unitInfo.unitMeasures.find(um => um.id === uomId);
    if (!unitMeasure) return null;

    return unitMeasure;
  }

  getCurrentUnitMeasure(unitType: UnitType): UnitMeasureModel {
    if (unitType === null || unitType === undefined) return null;
    const unitInfo = this.userUnits?.unitSystemSettings.find(m => +m.unitClass.unitClassEnum === +unitType);
    return unitInfo ? unitInfo.unitMeasure : null;
  }

  convertFromApiToSi(value: number, unitType: UnitType, sackWeight: number = null) {
    return convertWithUnitMeasure(value, this.getApiUnitMeasure(unitType), this.getSiUnitMeasure(unitType), sackWeight);
  }

  convertFromSiToApi(value: number, unitType: UnitType, sackWeight: number = null) {
    return convertWithUnitMeasure(value, this.getSiUnitMeasure(unitType), this.getApiUnitMeasure(unitType), sackWeight);
  }

  convertFromApiToCurrentUnit(value: number, unitType: UnitType, sackWeight: number = null) {
    return convertWithUnitMeasure(value, this.getApiUnitMeasure(unitType), this.getCurrentUnitMeasure(unitType), sackWeight);
  }

  convertFromSiToCurrentUnit(value: number, unitType: UnitType, sackWeight: number = null) {
    return convertWithUnitMeasure(value, this.getSiUnitMeasure(unitType), this.getCurrentUnitMeasure(unitType), sackWeight);
  }

  ConvertEmissionsToUserUnit(value: any, weight: UnitMeasureModel, volume: UnitMeasureModel) { 
    if (weight.name == 'kg' && volume.name == 'm3') return value * 2.8530101742118;;
    if (weight.name == 'kg' && volume.name == 'bbl') return value * 0.4535923699999997;
    if (weight.name == 'metric ton' && volume.name == 'bbl') return value * 0.00045359237;
    if (weight.name == 'metric ton' && volume.name == 'm3') return value * 0.00285301017421182;
    if (weight.name == 'lbs' && volume.name == 'm3') return value * 6.2898107704321;
    if (weight.name == 'lbs' && volume.name == 'bbl') return value;
  }
}
