<div [loadingIndicator]="loadingSubscription" class="sidebar-content-wrapper">
  <div class="sidebar-header">
    <h1>Fluids Summary Settings
        <div class="fa fa-question-circle icon-help" (click)="openHelp()"></div>
    </h1>
  </div>

  <div class="sidebar-content fluid-setting">
    <div><span _ngcontent-c10="" class="badge-number" style="float: left;">1</span>
      <h4>Linked Fluids</h4>
    </div>
    <span style="margin-left: 35px;">Edit fluid settings below.</span>
    <div *ngIf="fluids.length > 0" class="fluids-list">
      <table class="table">
        <thead>
          <tr>
            <th width="70px">Request ID</th>
            <th width="150px">Slurry Type</th>
            <th width="140px">Test Type</th>
            <th width="110px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let fluid of fluids; let i = index">

            <td *ngIf="fluid.requestId">{{fluid.requestId}}/{{fluid.slurryNo}}</td>
            <td *ngIf="!fluid.requestId"></td>
            <td>
              <p-dropdown [options]="slurryTypes" [(ngModel)]="fluids[i].slurryTypeId"
                (onChange)="updateSlurryTypes($event, i)" ></p-dropdown>
            </td>
            <td style="max-width: 140px;">
              <p-dropdown [options]="testTypes" [(ngModel)]="fluids[i].testTypeId"
                (onChange)="updateTestTypes($event, i)" 
                [disabled]="fluids[i].slurrySource === SlurrySource.ManualFluid || fluids[i].slurrySource === SlurrySource.HDFFluid"></p-dropdown>
            </td>
            <td class="planned-used">
              <span *ngIf="fluids[i].iconStatus">
                <i class="primary-status">{{fluids[i].iconStatus}}</i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="sidebar-footer">
    <a class="btn lnk-cancel" (click)="close()">Cancel</a>
    <button type="button" class="btn primary-btn" (click)="updateSlurries()"
      [disabled]="fluids.length <= 0">SAVE</button>
  </div>
</div>