import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ERROR_TYPE } from 'libs/constants';
import { ErrorMessageModel } from 'libs/ui';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'job-state-modal',
    templateUrl: './job-state-modal.component.html', 
    styleUrls: ['./job-state-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobStateModalComponent implements OnInit{
    data: any;
    content:string = '';
    isValid: boolean = true;
    jobStateControl = new FormControl('', Validators.required);

    constructor(public ref: DynamicDialogRef,
                public dynamicDialogConfig: DynamicDialogConfig,
                private cd: ChangeDetectorRef,
                private fb: FormBuilder) { }

    ngOnInit(): void {
        this.data = this.dynamicDialogConfig.data;
        this.content = this.data.content;
    }

    errorMessages = [
        new ErrorMessageModel(ERROR_TYPE.REQUIRED, 'Comment field is required.')
      ];

    onClose() {
        this.ref.close(false);
    }

    onSubmit(): void {
        if(this.isValid && this.jobStateControl.touched){
            this.ref.close(this.jobStateControl);
        }else{
            this.jobStateControl.markAsTouched();
        }
    }

    displayTextError() {
        let errorType = this.errorMessages.find((x: ErrorMessageModel) => 
                        this.jobStateControl && this.jobStateControl.errors && this.jobStateControl.errors[x.errorType]);

        if(((this.jobStateControl.dirty && this.jobStateControl.errors != null && errorType != null) 
            || (this.jobStateControl.touched && !this.jobStateControl.value))){
            this.isValid = false;
            return errorType.errorMessage;
        }

        this.jobStateControl = this.jobStateControl;
        this.isValid = true;
        return '';
    }
}