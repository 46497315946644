import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { DynamicSidebarRef } from './dynamic-sidebar-ref.component';
import { DynamicSidebarContent } from './dynamic-sidebar-content.model';
import { DynamicSidebarConfig } from './dynamic-sidebar-config.model';

@Injectable()
export class DynamicSidebarService {

  rootViewContainer: ViewContainerRef;
  componentRefs: ComponentRef<DynamicSidebarRef<any>>[] = [];

  constructor(private _factoryResolver: ComponentFactoryResolver) { }

  setRootView(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  open<T extends DynamicSidebarContent>(content: any, config: DynamicSidebarConfig = new DynamicSidebarConfig()): DynamicSidebarRef<T> {
    const factory = this._factoryResolver.resolveComponentFactory(DynamicSidebarRef);
    if (this.componentRefs && this.componentRefs[content]) {
      this.componentRefs[content].destroy();
    }
    if(this.rootViewContainer?.parentInjector) {
      this.componentRefs[content] = factory.create(this.rootViewContainer?.parentInjector);
    }
    this.rootViewContainer?.insert(this.componentRefs[content].hostView);

    if (typeof config.data === 'object')
      config.data = { ...config.data };
    this.componentRefs[content]?.instance.init(content, config);

    return this.componentRefs[content]?.instance as DynamicSidebarRef<T>;
  }
}