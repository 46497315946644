export const IFactTestName = {
    ThickeningTime: 'Thickening Time',
    APIRheology: 'API Rheology',
    NonAPIRheology: 'Non-API Rheology',
    UCACompStrength: 'UCA Comp. Strength',
    FreeFluidAPI10B2: 'Free Fluid API 10B-2',
    StaticGelStrength: 'Static Gel Strength',
    StaticGelStrengthMACSII: 'Static Gel Strength (MACS II)',
    StaticGelStrengthSGSA: 'Static Gel Strength (SGSA)',
    APIFluidLoss: 'API Fluid Loss',
    FluidLoss: 'Fluid Loss',
    StirringFluidLoss: 'Stirring Fluid Loss',
    MixRheology: "Mix Rheology",
    Bob2Rheology: "Bob 2 Rheology",
    Bob5Rheology: "Bob 5 Rheology",
    FYSAViscosityProfileAndGelStrength: "FYSA Viscosity Profile & Gel Strength",
    ThickeningTimeOnOffOn: 'Thickening Time - ON-OFF-ON',
}

export const TestTableName = {
    Rheology: 'Rheology',
    FreeFluidAPI10B2: IFactTestName.FreeFluidAPI10B2,
    UCACompStrength: IFactTestName.UCACompStrength,
    APIFluidLoss: IFactTestName.FluidLoss,
    StaticGelStrength: IFactTestName.StaticGelStrength,
    StaticGelStrengthMACSII: IFactTestName.StaticGelStrengthMACSII,
    StaticGelStrengthSGSA: IFactTestName.StaticGelStrengthSGSA,
    ThickeningTime: IFactTestName.ThickeningTime,
}

export const TestTableNameTestNamesMapping = {
    [TestTableName.Rheology]: [
        IFactTestName.APIRheology,
        IFactTestName.NonAPIRheology,
        IFactTestName.MixRheology,
        IFactTestName.Bob2Rheology,
        IFactTestName.Bob5Rheology,
        IFactTestName.FYSAViscosityProfileAndGelStrength
    ],
    [TestTableName.FreeFluidAPI10B2]: [IFactTestName.FreeFluidAPI10B2],
    [TestTableName.UCACompStrength]: [IFactTestName.UCACompStrength],
    [TestTableName.APIFluidLoss]: [IFactTestName.APIFluidLoss, IFactTestName.FluidLoss, IFactTestName.StirringFluidLoss],
    [TestTableName.StaticGelStrength]: [IFactTestName.StaticGelStrength, IFactTestName.StaticGelStrengthMACSII, IFactTestName.StaticGelStrengthSGSA],
    [TestTableName.ThickeningTime]: [IFactTestName.ThickeningTime, IFactTestName.ThickeningTimeOnOffOn],
}

export const TestStatusDescription = {
    Finished: 'Finished',
    Validated: 'Validated',
    Test:'Tested'
}

export const TestTableNames = [
    TestTableName.Rheology,
    TestTableName.FreeFluidAPI10B2,
    TestTableName.UCACompStrength,
    TestTableName.APIFluidLoss,
    TestTableName.StaticGelStrength,
    TestTableName.ThickeningTime,
];
export const StaticGelStrengthField = {
    ZeroGel: 'ZeroGel',
    TransitionOrCGSP: 'TransitionOrCGSP',
}
export const FreeFluidField = { 
    ConditioningTemperature: 'Cond. Temp.',
    FreeFluid: '% Fluid',
    Inclination: 'Inclination (deg)',
}

export const UCAField = {
    EndTemperature: "End Temp.",
    Strength50: '50 psi (HH:MM)',
    Strength500: '500 psi (HH:MM)',
    Time24Hr: '24 hr CS',
}

export const APIFluidLossField = {
    TestTemperature: 'Test Temp.',
    TestTime: 'Test Time (min)',
    APIFluidLoss: 'API FL (mL/30min)',
    CalculatedAPIFluidLoss: 'Calculated API Fluid Loss (mL/30 min)',
}

export const FluidLossField = {
    TestTemperature: 'Test Temperature',
    TestTime: 'Test Time (min)',
    APIFluidLoss: 'API Fluid Loss (mL/30min)',
    CalculatedAPIFluidLoss: 'Calculated API Fluid Loss (mL/30min)',
}

export const StirringFluidLossField = {
    TestTemperature: 'Test Temp.',
    APIFluidLoss: 'API FL (mL/30 min)',
    CalculatedAPIFluidLoss: 'Calculated API Fluid Loss (mL/30min)',
    mlPerHalfMin: 'mL / 1/2 min',
    mlPer1Min: 'mL  / 1 min',
    mlPer2Min: 'mL / 2 min',
    mlPer5Min: 'mL  / 5 min',
    mlPer7AndHalfMin: 'mL  / 7.5 min',
    mlPer10Min: 'mL / 10 min',
    mlPer15Min: 'mL / 15 min',
    mlPer20Min: 'mL  / 20 min',
    mlPer25Min: 'mL / 25 min',
    mlPer30Min: 'mL  / 30 min',
}

export const StirringFluidLossTimeFields = [
    StirringFluidLossField.mlPer30Min,
    StirringFluidLossField.mlPer25Min,
    StirringFluidLossField.mlPer20Min,
    StirringFluidLossField.mlPer15Min,
    StirringFluidLossField.mlPer10Min,
    StirringFluidLossField.mlPer7AndHalfMin,
    StirringFluidLossField.mlPer5Min,
    StirringFluidLossField.mlPer2Min,
    StirringFluidLossField.mlPer1Min,
    StirringFluidLossField.mlPerHalfMin,
];

export const RheologyField = {
    Temperature: 'Temp.',
    TestTemperature: 'Test Temp.',
    F600: '600',
    F300: '300',
    F200: '200',
    F100: '100',
    F60: '60',
    F30: '30',
    F20: '20',
    F10: '10',
    F6: '6',
    F3: '3',
    BRConf: 'B-R Conf.',
    Pv: 'PVTraditional',
    Yp: 'YPTraditional',
}

export const ThickeningTimeFields = {
    Temperature: 'Temp.',
    TestTemperature: 'Test Temp.',
    BC40: "40 BC (HH:MM)",
    BC70: "70 BC (HH:MM)",
    BC100: "100 BC (HH:MM)",
}
