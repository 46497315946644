import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IJobDateFormGroup, JobForm } from '../../models/job-form';
import { JobsService } from 'libs/shared';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { environment } from 'libs/environment';
import { ConfirmDialogService } from 'libs/ui';
import { Observable } from 'rxjs/internal/Observable';
import { DATE_TIME_CALL_STANDARD } from 'libs/constants';

@Component({
  selector: 'job-date',
  templateUrl: './job-date.component.html',
  styleUrls: ['./job-date.component.scss']
})
export class JobDateComponent {
  @Input() jobForm: JobForm;
  @Output() onProjectDateChanged = new EventEmitter<any>();

  readonly DATE_TIME_CALL_STANDARD: string = DATE_TIME_CALL_STANDARD;

  constructor(
    private jobsService: JobsService,
    private messageService: MessageService,
    private confirmDialogService: ConfirmDialogService
  ) { }

  get jobId(): string {
    return this.jobForm.job.id;
  }

  get jobStatusDescription(): string {
    return this.jobForm.job.jobStatusDescription;
  }

  get cp4State(): string{
    return this.jobForm.job.cpStatuses.cP4StatusName;
  }

  get actualDate(): string{
    return this.jobForm.job.actualDate;
  }

  get isMemberOfGroup$(): Observable<boolean>{
    return this.jobForm.isMemberOfGroup$;
  }

  get jobDateFormGroup(): FormGroup<IJobDateFormGroup>{
    return this.jobForm.jobDateFormGroup;
  }

  changeProjectedDate(event: any) {
    if (event.type == 'select') {
      return;
    }

    this.messageService.clear();
    if (this.jobDateFormGroup?.controls?.actualDate?.value != null) {
      this.jobsService.updateActualDate(this.jobId, event).pipe(first()).subscribe(() => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Actual Date has been updated successfully.` });
      });
    } else {
      this.jobsService.updateProjectedDate(this.jobId, event).pipe(first()).subscribe(res => {
        this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Projected Date has been updated successfully.` });
      });
    }
    
    if (this.onProjectDateChanged){
      this.onProjectDateChanged.emit(event);
    }
  }

  deleteProjectedDate(event: any) {
    this.confirmDialogService.show({
      message: `Are you sure to delete Projected Date?`,
      header: 'Message',
      accept: () => {
        this.messageService.clear();
        if (this.actualDate != null) {
          this.jobsService.updateActualDate(this.jobId, null).pipe(first()).subscribe(res => {
            this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: `The Projected Date has been deleted successfully.` });
            this.jobDateFormGroup.controls.actualDate.setValue(null);
            this.jobDateFormGroup.controls.isManualActualDate.setValue(false);
          });
        }
        
        if (this.onProjectDateChanged){
          this.onProjectDateChanged.emit(null);
        }
      },
    });
  }
}
