export const FLUIDS_CONTANTS = {
  CEMENT: 'Cement',
  ADDITIVE: 'Additive',
  MIXWATER: 'Mix Water',
  SUPPLEMENTAL: 'Supplemental'
};

export const MIXING_PROCEDURE = {
  PH: 'PH',
  PB: 'PB'
};

export enum DeadVolumeFluidType {
  CementSlurry,
  MixFluid
}

export const DeadVolumeFluidTypes = [{
  value: 0,
  label: 'Cement Slurry',
  disabled: false,
}, {
  value: 1,
  label: 'Mix Fluid',
  disabled: false,
}];

export const DeadVolumeFluidTypesForMixFluid = [{
  value: 0,
  label: 'Cement Slurry',
  disabled: false,
}, {
  value: 1,
  label: 'Mix Fluid',
  disabled: true,
}];

export const MATERIAL_TYPE = {
  BLEND: 'Blend',
  ADDITIVE: 'Additives',
  SUPPLEMENTAL: 'Supplemental'
};
