export const FLUID_TYPE_SCHEDULE = {
  DRILLING_FLUID_MUD: 'Drilling Fluid (Mud)',
  DRILLING_FLUID_SPACER: 'Drilling Fluid (Spacer)',
  MUD: 'Mud',
  SPACER_FLUSH: 'Spacer/Flush',
  TUNED_SPACER_III: 'Tuned® Spacer III',
  CEMENT: 'Cement',
  BOTTOM_PLUG: 'Bottom Plug',
  PLUG_DART: 'Plug/Dart',
  TOP_PLUG_START_DISPLACEMENT: 'Top Plug/Start Displacement'
};

export const FLUID_SYSTEM_TYPE = {
  CEMENT: 'Cement System',
  SPACER: 'Spacer System'
};


export const Cement_Types = new Set([
  "Tail Cement", "Lead Cement", "Primary Cement", "Plug Cement", "Squeeze Cement"
]);
