import { Component, forwardRef, Input, EventEmitter, Output, OnChanges, SimpleChanges, Injector, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormValueAccessor } from 'libs/ui/form-value-accessor';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'p-dropdown-ex',
  template: `
    <p-dropdown [options]="options" [(ngModel)]="_selectedOption" [disabled]="_isDisabled" [optionLabel]="optionLabel" placeholder="&nbsp;"
       (onChange)="_onChange($event)"></p-dropdown>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PDropdownEx),
      multi: true
    }
  ]
})
export class PDropdownEx extends FormValueAccessor implements OnChanges, AfterViewInit {

  @Input() options: any[];
  @Input() optionLabel: string = 'label';
  @Input() optionValue: string = 'value';
  _selectedOption: any;
  _isDisabled: boolean = false;
  @Output() onChange = new EventEmitter<any>();

  constructor(
    protected inj: Injector,
    private cd: ChangeDetectorRef
  ) {
    super(inj);
  }

  ngOnChanges(changes: SimpleChanges) {
    const { options } = changes;
    if (options && this.control) {
      this._updateSelectedOption(this.control.value);
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this._isDisabled = this.control.disabled;
    this.control.statusChanges.pipe(
      filter(_ => this._isDisabled !== this.control.disabled)
    ).subscribe(_ => {
      this._isDisabled = this.control.disabled;
    });
    this.cd.markForCheck();
  }

  _onChange(e) {
    this._updateSelectedOption(e.value[this.optionValue]);
    this._propagateChange(this._selectedOption);
    this.onChange.emit(e);
  }

  _updateSelectedOption(value) {
    // tslint:disable-next-line:triple-equals
    this._selectedOption = this.options.find(option => option.value == value);
    this.cd.markForCheck();
  }

  writeValue(obj: any) {
    this._updateSelectedOption(obj);
  }
}

