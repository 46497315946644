import { Component, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ColumnDefinition } from '../../models';
import { isElementVisible } from '../../helpers';
import { OverlayPanel } from 'primeng/overlaypanel';
import { timer } from 'rxjs';

@Component({
  selector: 'ex-dropdown-data',
  templateUrl: './ex-dropdown-data.component.html',
  styleUrls: ['./ex-dropdown-data.component.scss']
})
export class ExDropdownDataComponent {

  @ViewChild(OverlayPanel, { static: true }) op: OverlayPanel;
  @ViewChild('target', { static: true }) target: ElementRef;


  @Input() disabled: boolean = false;
  @Input() formControl: UntypedFormControl = new UntypedFormControl();
  @Input() colDefs: ColumnDefinition[] = [];
  @Input() rowData: any[];
  @Input() selectedRow: any;
  @Input() rowLabel: string;
  @Input() showClear: boolean = false;
  @Input() isDisableRow: boolean = false;
  @Input() colCheckDisable: string = '';
  @Input() dataDisable: string[] = [''];
  @Input() isHighLight: boolean = false;

  @Output() selectedRowChange = new EventEmitter<any>();
  @Output() selectedInvalidItem = new EventEmitter<any>();

  dropdownShow = false;
  searchStr: string = '';
  keypressedTime: Date = new Date();
  lastMatchedIndex = -1;
  lastSearchStr: string = null;
  waitForLastSearch: any = null;
  isInvalid: boolean = false;

  constructor() { }

  onRowSelect(item: any) {
    if (!this.isDisableRow) {
      this.selectedRowChange.emit(item);
      // this.selectedInvalidItem.emit(false);     
    } else {
      if (this.dataDisable.length === 0 || !this.dataDisable.find(x => x[this.colCheckDisable] === item[this.colCheckDisable])) {
        this.selectedRowChange.emit(item);
        this.selectedInvalidItem.emit(false);
        this.isInvalid = false;
      }
      if (this.dataDisable.find(x => x[this.colCheckDisable] === item[this.colCheckDisable])) {
        this.selectedInvalidItem.emit(true);
        this.isInvalid = true;
      }

    }
    // else {
    //  this.selectedInvalidItem.emit(true);  
    // }
    this.hideTable();
  }

  toggleTable(ev) {
    this.op.toggle(ev, this.target.nativeElement);
  }

  showTable(ev) {
    this.op.show(ev, this.target.nativeElement);
  }

  hideTable() {
    this.op.hide();
  }

  onInputChange(ev) {
    this.showTable(ev);
  }

  toggleClass() {
    this.dropdownShow = !this.dropdownShow;
    this.lastMatchedIndex = -1;
    this.lastSearchStr = null;
    setTimeout(() => {
      this.scrollToSelectedRow();
    }, 100);
  }

  clearSelectedRow() {
    this.selectedRow = null;
    this.isInvalid = false;
    this.selectedInvalidItem.emit(false);
  }

  updown(event) {
    let nextSelected = -1;
    const keyCode = event.keyCode,
      selectedIndex = this.rowData.indexOf(this.selectedRow);
    if (keyCode === 38 || keyCode === 40) {
      event.preventDefault();
      if (keyCode === 40) {
        nextSelected = selectedIndex < this.rowData.length ? selectedIndex + 1 : 0;
      } else {
        nextSelected = selectedIndex > 0 ? selectedIndex - 1 : this.rowData.length - 1;
      }
      this.selectedRow = this.rowData[nextSelected];
      this.scrollToSelectedRow();
    } else if (keyCode === 13 || keyCode === 32) {
      event.preventDefault();
      this.toggleTable(event);
      this.toggleClass();
    } else if (keyCode === 9) {
      this.hideTable();
    }
  }

  navigate(event) {
    let timeEllapsed = 0;
    const keyCode = event.keyCode, now = new Date(),
      character = String.fromCharCode(keyCode),
      nonPrintableReg = /[\x00-\x1F]/,
      keyInterval = 300;

    if (!nonPrintableReg.test(character)) {
      timeEllapsed = now.getTime() - this.keypressedTime.getTime();
      this.keypressedTime = new Date();
      if (timeEllapsed < keyInterval) {
        this.searchStr += character;
      } else {
        this.searchStr = character;
      }
      if (this.waitForLastSearch) {
        clearTimeout(this.waitForLastSearch);
      }
      this.waitForLastSearch = timer(keyInterval).subscribe(_ => this.findMatched.bind(this));
    }
  }

  findMatched() {
    let matchedIndex = null;
    for (let i = 0; i < this.rowData.length; i++) {
      for (let j = 0; j < this.colDefs.length; j++) {
        const cellData = this.rowData[i][this.colDefs[j].field] as string;
        // Just search by "startWith" rule
        const matched = cellData && cellData.toString().toLowerCase().indexOf(this.searchStr.toLowerCase()) === 0;
        if (matched) {
          matchedIndex = i;
          break;
        }
      }
      if (matchedIndex != null) {
        if (matchedIndex <= this.lastMatchedIndex && this.lastSearchStr === this.searchStr) {
          continue; // continue searching the row data for the next matched
        } else if (matchedIndex != null) {
          break;
        }
      }
    }
    if (matchedIndex != null) {
      this.selectedRow = this.rowData[matchedIndex];
      this.lastMatchedIndex = matchedIndex;
      this.lastSearchStr = this.searchStr;
      this.scrollToSelectedRow();
    } else {
      this.lastMatchedIndex = null;
      this.lastSearchStr = null;
    }
  }

  scrollToSelectedRow() {
    const selectedIndex = this.rowData.indexOf(this.selectedRow);
    let selectedTableRow = this.op.el.nativeElement.querySelector(`table tbody tr:nth-child(${selectedIndex + 1})`) as HTMLElement;
    if (selectedTableRow && !isElementVisible(selectedTableRow)) {
      let height = 0;
      while (selectedTableRow != null) {
        height = height + selectedTableRow.clientHeight;
        selectedTableRow = selectedTableRow.previousElementSibling as HTMLElement;
      }
      const scrollable = this.op.el.nativeElement.querySelector('.p-datatable-scrollable-body') as HTMLElement;
      scrollable.scrollTop = selectedIndex === 0 ? 0 : height;
    }
  }

  disableRow(data) {
    if (this.dataDisable && this.dataDisable.length > 0) {
      return this.dataDisable.find(x => x[this.colCheckDisable] === data) ? 'disable-row' : 'highlight-row';
    }
    if (this.dataDisable.length === 0 && this.isHighLight) {
      return 'highlight-row';
    }
    return '';
  }
}

