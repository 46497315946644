<div [loadingIndicator]="loadingSubscription" class="sidebar-content-wrapper">
  <div class="sidebar-header">
    <h1>Fluids Summary Settings
        <div class="fa fa-question-circle icon-help" (click)="openHelp()"></div>
    </h1>
  </div>

  <div class="sidebar-content">
    <div class="row">
      <div class="col-md-12">
        <form (ngSubmit)="searchRequest(requestIdInput.value.trim())">
          <div class="form-group">
            <label>
              <span class="badge-number">1</span>Enter Request ID
            </label>
            <div class="indented-under-badge">
              <div class="input-group request-search">
                <input
                  class="form-control input-search"
                  placeholder="Request ID"
                  name="requestId"
                  #requestIdInput
                  [disabled]="appStateService.notifyIFactDown$ | async">
                <div class="input-group-addon btn-search" (click)="searchRequest(requestIdInput.value.trim())">
                  <i class="fa fa-search"></i>
                </div>
              </div>
              <div *ngIf="data.iFactsRequestIdHDF" class="request-id-hint">
                <span>iFacts Request ID from HDF: </span><span class="value-text">{{ data.iFactsRequestIdHDF }}</span>
              </div>
            </div>
          </div>
        </form>

        <div class="search-result error-result" *ngIf="errorMessage">{{errorMessage}}</div>
        <div class="search-request" *ngIf="requests.length">
          <label>
            <span class="badge-number">2</span>Results
          </label>
          <div class="indented-under-badge">
            <table class="table table-hover search-request">
              <thead>
                <tr>
                  <th width="50%">Request ID</th>
                  <th width="50%">Slurry Number</th>
                  <th width="25px" class="text-center">Select</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let slurry of requests[0].availableSlurries; let i = index;">
                  <td *ngIf="i==0">{{requests[0].id}}</td>
                  <td *ngIf="i!=0"></td>
                  <td>
                    <a href="{{slurry.id | iFactsLink:'slurry'}}" target="_blank">{{slurry.number}}</a>
                  </td>
                  <td class="text-center">
                    <p-checkbox name="groupname" binary="true" (onChange)="onSlurrySelected(slurry.id,$event.checked)"></p-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </div>
  </div>

  <div class="sidebar-footer">
    <a class="btn lnk-cancel m-r-20" (click)="close()">Cancel</a>
     <button *ngIf="!invisible" (click)="linkRequest()" type="button" class="btn primary-btn" [lockableElement]="'job_completed'">
      Add
    </button>
  </div>
</div>
