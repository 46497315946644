const somePrimitives = ['string', 'number', 'bigint'];

/**
 * Defines if the given object is of a certain type
 * @param {any} obj to be checked
 * @param {T} type has to have a constructor
 * @returns {boolean} returns true if the object is of a specified type and false if not
 */
export function isObjectOfCertainType<T extends {}>(obj: any, type: { new(): T} ): boolean {

    const typeInst = new type();

    if (isPrimitive(obj) || isPrimitive(typeInst))
        return typeof obj === typeof typeInst;
    
    return obj.constructor === typeInst.constructor;
}

/**
 * Checks if the value is of a primitive type
 * @param {any} value to be checked
 * @returns {boolean} returns true if the value is primitive and false if not
 */
export function isPrimitive(value: any): boolean {

    return !value || somePrimitives.some(e => typeof value === e);
}