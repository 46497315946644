export enum ContingenciesOption {
  Squeeze = 0,
  KickPNAPlug = 1,
  MinimumAcceptableCementVolume = 2,
  ContingencySlurries = 3,
  StaticTime = 4,
  LinerHanger = 5,
  ToeSleeves = 6,
  Volume = 7,
  EquipmentFailure = 8,
  StageCollar = 9,
  Other = 10
}
