import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "primaryStatus",
})
export class PrimaryStatusPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case "Planned":
        return "P";
      case "Planned1":
        return "P1";
      case "Planned2":
        return "P2";
      case "Planned3":
        return "P3";
      case "Planned4":
        return "P4";
      case "Planned5":
        return "P5";
      case "Planned6":
        return "P6";
      case "Planned7":
        return "P7";
      case "Planned8":
        return "P8";
      case "Planned9":
        return "P9";
      case "Planned10":
        return "P10";
      case "Planned11":
        return "P11";
      case "Planned12":
        return "P12";
      case "Used":
        return "U";
      default:
        return "";
    }
  }
}
