import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangeValueExport, PumpScheduleTestResultBase } from '../stage-test-results/pump-schedule-test-result-base.component';
import { StaticGelStrengthField, TestTableName } from '../stage-test-results/stage-test-results.constant';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from '../../../../../../../libs/models/ifact/ifacts-request-tests';
import { IFactsAvailableSlurry } from '../../../../../../../libs/models/ifact/ifacts-available-slurry';

@Component({
  selector: 'static-gel-strength-result',
  templateUrl: './static-gel-strength-result.component.html',
  styleUrls: ['./static-gel-strength-result.component.scss']
})
export class StaticGelStrengthResultComponent extends PumpScheduleTestResultBase implements OnInit {
  @Input() set testTables(value: PumpScheduleStageTestTable[]) {
    super.initTestTable(value);
  };
  @Input() set availableSlurries(value: IFactsAvailableSlurry[]) {
    super.initAvailableTestSlurries(value);
  }

  @Input() canEdit: boolean;
  @Output() onChangeSlurryNo = new EventEmitter<ChangeValueExport>();
  constructor() {
    super(TestTableName.StaticGelStrength);
  }

  StaticGelStrengthField = StaticGelStrengthField

  ngOnInit(): void {
  }

  changeSlurryNo(event: any) {
    super.changeSlurryNo(event.value);
    this.onChangeSlurryNo.emit(super.getChangeValueExport());
  }

  get test(): PumpScheduleStageTest {
    if (this.tests.length === 1 && this.tests[0]) {
      return this.tests[0];
    }

    const validatedOrFinishedTests = this.tests.filter(this.isTestFinishedOrValidated);

    if (validatedOrFinishedTests.length === 1 && validatedOrFinishedTests[0]) {
      return validatedOrFinishedTests[0];
    }

    return null;
  }

  get testDescription() {
    return !this.test ? '' : this.test?.testStatusDescription == 'Tested' ? 'Requested to Lab' : this.test?.testStatusDescription;
  }
}
