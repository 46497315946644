import { UnitType, MIXING_PROCEDURE, IFACT_CONSTANTS } from 'libs/constants';
import { FluidMaterialModel } from 'libs/models';
import { convertWithUnitMeasure, UnitConversionService } from 'libs/ui/unit-conversions';

export function calculateMaterialQuantity(
    outputUnit: string,
    sg: number,
    testAmount: number,
    volume: number,
    mixVolume: number,
    yieldData: number,
    concentration: number,
    concentrationUnit: string,
    sackWeight: number,
    mixWater: number = 0,
    waterDensity: number = 0,
    gravityOfSlurry: number = 0
): any {
    // iFact convert
    let F = 0;
    const A = 0.002204622622; // Conversion factor A: from gram to pound
    const C = 158987.2949; // Conversion factor C: from US Oil barrel to milliliter
    const H = 0.00026417205; // Conversion factor H: from milliliter to US gallon
    const bblToft3 = 5.61458333;  // Conversion factor: US Oil barrel to ft3
    const bblTom3 = 0.1589872949; // Conversion factor: US Oil barrel to cubic meter
    const bbltoL = 158.9872949; // Conversion factor: US Oil barrel to liter
    const bblTogal = 42.0; // Conversion factor: US Oil barrel to gallon
    const LTogal = 0.26417205234; // Conversion factor: Liter to gallon
    const density = 8.33;
    const POUND_TO_KG_FACTOR = 0.4535924;

    let cUnit = (concentrationUnit || "").replace(/\s+/g, '').toLowerCase();
    let outUnit = (outputUnit || "").replace(/\s+/g, '').toLowerCase();

    if (testAmount > 0 && yieldData > 0 && gravityOfSlurry > 0) {

        if (outUnit === 'g') {
            if (cUnit in IFACT_CONSTANTS.FLUID_CONCENTRATIONS) {
                F = volume * C / mixVolume * testAmount / sg * H;
                return {
                    UnitType: UnitType.SmallVolume,
                    Value: volume && F ? F : null
                };
            } else {
                F = volume * C / mixVolume * testAmount * A;  // return unit  lbs
                return {
                    UnitType: UnitType.Weight,
                    Value: volume && F ? F : null
                };
            }

        } else if (outUnit === 'ml') {
            if (cUnit in IFACT_CONSTANTS.FLUID_CONCENTRATIONS) {
                F = volume * C / mixVolume * testAmount * H;
                return {
                    UnitType: UnitType.SmallVolume,
                    Value: volume && F ? F : null
                };
            } else {
                F = volume * C / mixVolume * testAmount * sg * A;  // return unit  lbs
                return {
                    UnitType: UnitType.Weight,
                    Value: volume && F ? F : null
                };
            }
        }
    } else if (cUnit === 'lb/bbl') {
        F = volume * concentration; // Unit lbs
        return {
            UnitType: UnitType.Weight,
            Value: volume && F ? F : null
        };
    } else if (cUnit === '%bwoc') {
        const volumeInSack = ((volume * bblToft3) / yieldData); // Convert Volume (bbl) to sack
        F = volumeInSack * sackWeight * concentration / 100; //  return unit lbs
        return {
            UnitType: UnitType.Weight,
            Value: volume && F ? F : null
        };
    } else if (cUnit === '%bwow') {
        waterDensity = waterDensity ? waterDensity * density : density; // ifact value * 8.33 or default value
        const volumeInSack = (volume * bblToft3) / yieldData;  // Convert Volume in Sack
        F = volumeInSack * waterDensity * mixWater * concentration / 100;  // return unit lbs
        return {
            UnitType: UnitType.Weight,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'lb/sk') {
        F = ((volume * bblToft3) / yieldData) * concentration; // return unit lbs
        return {
            UnitType: UnitType.Weight,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'kg/m3') {
        F = volume * bblTom3 * concentration; // return unit kg
        F = F / POUND_TO_KG_FACTOR; // convert value to lbs unit
        return {
            UnitType: UnitType.Weight,
            Value: volume && F ? F : null
        };
    } else if (cUnit === '%bvow') {
        const volumeInSack = (volume * bblToft3) / yieldData;  // Convert volume (bbl) to sack
        F = volumeInSack * mixWater * concentration / 100;  // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'gps') {
        F = (volume * bblToft3 / yieldData) * concentration; // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'gal/sk') {
        F = (volume * bblToft3 / yieldData) * concentration; // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'l/100kg') {
        const volumeInSack = (volume * bblToft3) / yieldData;  // Convert volume (bbl) to sack
        F = volumeInSack * sackWeight * POUND_TO_KG_FACTOR * concentration / 100 * LTogal; // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'gal/bbl') {
        F = volume * concentration; // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'gal/mgal') {
        F = volume * bblTogal * concentration / 1000;  // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else if (cUnit === 'l/m3') {
        const volumeInM3 = volume * bblTom3;
        F = volumeInM3 * concentration * LTogal;  // return unit gal
        return {
            UnitType: UnitType.SmallVolume,
            Value: volume && F ? F : null
        };
    } else {
        return {
            UnitType: UnitType.SmallVolume,
            Value: 0
        };
    }
    // return outputUnit === 'ml' ? testAmount * volume / mixVolume : testAmount / sg * volume / mixVolume;
}

export function calculateMaterialCost(materialQuantity: number, materialQuantityUnitType: UnitType,
    sg: number, cogsPrice: number, cogsUnit: string, sackWeight: number, bulkDensity: number, yieldData: number = 1): any {
    const density = 8.345406;
    const lToGal = 0.26417205234;
    const kgToLbs = 2.20462262185;
    const bblToft3 = 5.61458333;
    const bblToGal = 42.0;
    const volumeFactor = sg ? sg * density : 1;

    let result = null;
    let uomIncompatible = false;

    if (!sg && (materialQuantityUnitType === UnitType.Weight && (cogsUnit === 'L' || cogsUnit === 'gal' || cogsUnit === 'bbl') ||
        materialQuantityUnitType === UnitType.SmallVolume && (cogsUnit === 'kg' || cogsUnit === 'lbs'
            || cogsUnit === 'sk' || cogsUnit === 'metric ton'))) {
        uomIncompatible = true;
    } else {
        if (materialQuantityUnitType === UnitType.Weight) {
            materialQuantity = materialQuantity / volumeFactor;
        }

        switch (cogsUnit) {
            case 'kg': result = (cogsPrice / kgToLbs) * volumeFactor * materialQuantity;
                break;
            case 'metric ton': result = ((cogsPrice / 1000) / kgToLbs) * volumeFactor * materialQuantity;
                // with bulk density
                // case 'metric ton': result = bulkDensity ? ((cogsPrice / 1000) / kgToLbs) * volumeFactor * bulkDensity * materialQuantity : null;
                break;
            case 'lbs': result = cogsPrice * volumeFactor * materialQuantity;
                break;
            case 'L': result = (cogsPrice / lToGal) * materialQuantity;
                break;
            case 'sk': result = sackWeight && sackWeight > 0 ? (cogsPrice / sackWeight) * volumeFactor * materialQuantity : null;
                break;
            case 'bbl': result = (cogsPrice / bblToGal) * materialQuantity;
                break;
            default:
                result = cogsPrice * materialQuantity;
                break;
        }
    }

    return {
        uomIncompatible: uomIncompatible,
        value: result
    };
}

export function calculateLoadOutVolumeByBulkCement(bulkCement: number, yieldData: number) {
    const ratiobblToft3 = 0.1781076;
    return bulkCement * yieldData * ratiobblToft3 ? bulkCement * yieldData * ratiobblToft3 : null;
}

export function calculateBulkCementByLoadOutVolume(loadOutVolume: number, plannedVolume: number, yieldData: number) {
    const ratiobblToft3 = 0.1781076;
    let bulkCementValue = null;
    if (loadOutVolume) {
        bulkCementValue = loadOutVolume / (yieldData * ratiobblToft3);
        return isFinite(bulkCementValue) ? bulkCementValue : null;
    } else {
        bulkCementValue = plannedVolume / (yieldData * ratiobblToft3);
        return isFinite(bulkCementValue) ? bulkCementValue : null;
    }
}

export function calculateLoadOutVolumeByDeadVolume(deadVolume: number, plannedVolume: number) {
    return plannedVolume + deadVolume;
}

export function calculateStageWater(loadOutVolume: number, plannedVolume: number, yieldData: number, mixWater: number) {
    const ratioft3Tobbl = 5.6145833;
    const ratiobblTogal = 0.0238095;
    let stageWaterValue = null;
    if (loadOutVolume) {
        stageWaterValue = (loadOutVolume * ratioft3Tobbl / yieldData) * mixWater * ratiobblTogal;
        return isFinite(stageWaterValue) ? stageWaterValue : null;
    } else {
        stageWaterValue = (plannedVolume * ratioft3Tobbl / yieldData) * mixWater * ratiobblTogal;
        return isFinite(stageWaterValue) ? stageWaterValue : null;
    }
}

export function calculateStageWaterAndOverrideQtyByDeadQty(unitConversionService: UnitConversionService, deadQty: number, stageWater: number, fluidMaterials: FluidMaterialModel[]) {
    const GAL_TO_BBL_FACTOR = 1.0 / 42;
    const LITER_TO_BBL_FACTOR = 0.0062898;

    const qtyInBblList = fluidMaterials.map(material => {
        if (material.mixingProcedureValue !== MIXING_PROCEDURE.PH) {
            return 0;
        } else if (material.loadoutVolume) {
            if (material.plannedVolumeUnit === UnitType.SmallVolume) {
                return material.loadoutVolume * GAL_TO_BBL_FACTOR;
            }
            if (material.plannedVolumeUnit === UnitType.Weight) {
                const loadoutVolumeKG = convertWithUnitMeasure(material.loadoutVolume,
                    unitConversionService.getCurrentUnitMeasure(material.plannedVolumeUnit),
                    unitConversionService.getSiUnitMeasure(UnitType.Weight));
                const loadoutVolumeLITER = loadoutVolumeKG / material.sg * LITER_TO_BBL_FACTOR;
                return loadoutVolumeLITER;
            }
        }
        return 0;
    });
    const totalQty = stageWater + qtyInBblList.sum();
    const percentages = qtyInBblList.map(qty => qty / totalQty);
    const deadQtyList = percentages.map(percentage => percentage * deadQty);
    const stageWaterByDeadQty = stageWater + stageWater / totalQty * deadQty;

    const overrideQtyByDeadQtyList = fluidMaterials.map((material, index) => {
        if (material.mixingProcedureValue !== MIXING_PROCEDURE.PH) {
            return null;
        } else if (material.loadoutVolume) {
            if (material.plannedVolumeUnit === UnitType.SmallVolume) {
                return material.loadoutVolume + deadQtyList[index] / GAL_TO_BBL_FACTOR;
            }
            if (material.plannedVolumeUnit === UnitType.Weight) {
                const loadoutVolumeKG = convertWithUnitMeasure(material.loadoutVolume,
                    unitConversionService.getCurrentUnitMeasure(material.plannedVolumeUnit),
                    unitConversionService.getSiUnitMeasure(UnitType.Weight));
                const loadoutVolumeLITER = loadoutVolumeKG / material.sg * LITER_TO_BBL_FACTOR;

                const loadoutVolumeKGResult = ((loadoutVolumeLITER + deadQtyList[index]) / LITER_TO_BBL_FACTOR * material.sg);

                return convertWithUnitMeasure(loadoutVolumeKGResult,
                    unitConversionService.getSiUnitMeasure(UnitType.Weight),
                    unitConversionService.getCurrentUnitMeasure(material.plannedVolumeUnit));
            }
        }
        return null;
    });

    return {
        stageWaterByDeadQty,
        overrideQtyByDeadQtyList
    };
}

