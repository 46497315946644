import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

@Injectable()
export class CustomerInformationService {
  customers = [
    { customerName: 'Customer 1', customerId: 'Description 1', location: 'New York' },
    { customerName: 'Customer 2', customerId: 'Description 2', location: 'New York' },
    { customerName: 'Customer 3', customerId: 'Description 3', location: 'New York' },
    { customerName: 'Customer 4', customerId: 'Description 4', location: 'New York' },
    { customerName: 'Customer 5', customerId: 'Description 5', location: 'New York' },
    { customerName: 'Customer 6', customerId: 'Description 6', location: 'New York' },
    { customerName: 'Customer 7', customerId: 'Description 7', location: 'New York' },
    { customerName: 'Customer 8', customerId: 'Description 8', location: 'New York' },
    { customerName: 'Customer 9', customerId: 'Description 9', location: 'New York' },
    { customerName: 'Customer 10', customerId: 'Description 10', location: 'New York' }
  ];

  constructor() {
  }

  searchCustomer(keyword: string): Observable<any[]> {
    return of(this.customers.filter(c => 
      c.customerName.toLowerCase().indexOf(keyword.toLowerCase()) > - 1 ||
      c.customerId.toLowerCase().indexOf(keyword.toLowerCase()) > - 1 ||
      c.location.toLowerCase().indexOf(keyword.toLowerCase()) > - 1
    ));
  }
}