<div style="width: 300px;">
  <div class="d-flex pt-4">
    <div class="form-subtitle-section">{{testTableName}}</div>
    <div class="p-2 pl-5" style="font-weight: bold;">Slurry #</div>
    <div>
      <p-dropdown [formControl]="selectedSlurry"
                  (onChange)="changeSlurryNo($event)"
                  [options]="availableTestSlurries"
                  [disabled]="!canEdit"
                  appendTo="body"
                  [placeholder]="'\u00A0'"
                  baseZIndex="9999">
      </p-dropdown>
    </div>
  </div>

  <div class="ui-table">
    <table style="font-weight: bold;">
      <thead>
        <tr>
          <th>Static Gel Strength</th>
          <th style="width: 130px;">
            <div class="d-flex flex-row justify-content-center">Time</div>
            <div class="d-flex flex-row justify-content-center">(hh:mm)</div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isTestFinishedOrValidated(test);else testStatusDescription">
        <tr >
          <td>
            <div class="d-flex flex-row justify-content-start">Zero Gel</div>
          </td>
          <td>
            <div class="d-flex flex-row justify-content-start">{{getField(test, StaticGelStrengthField.ZeroGel)}}</div>
          </td>
        </tr>
        <tr  >
          <td>
            <div class="d-flex flex-row justify-content-start">CGSP or</div>
            <div class="d-flex flex-row justify-content-start">Transition Time</div>
          </td>
          <td>
            <div class="d-flex flex-row justify-content-start">{{getField(test, StaticGelStrengthField.TransitionOrCGSP)}}</div>
          </td>
        </tr>

      </tbody>
      <ng-template #testStatusDescription>
        <tbody>
          <tr *ngIf="testDescription;else empty">
            <td class="text-center bc-white" colspan="2">{{ testDescription }}</td>
          </tr>
          <ng-template #empty>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </ng-template>
        </tbody>
      </ng-template>
    </table>
  </div>
</div>
