import { ICustomerModel } from '../../shared/models';

export interface ICustomerInformationModel {
  customerName: string;
  parentCustomerName: string;
  customerId: string;
  location: string;
}

export function createCustomerInformationFromCustomer(customer: ICustomerModel): ICustomerInformationModel {
  return {
    customerName: customer.customerName,
    parentCustomerName: customer.parentCustomerName,
    customerId: customer.policyId,
    location: `${customer.city ? customer.city.trim() : ''}, ${customer.country ? customer.country.trim() : ''}`
  };
}