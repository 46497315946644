import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Guid } from '../constant/string-comparision';
import { ERROR_MESSAGE } from 'libs/constants';
import { of } from 'rxjs';
import { ValidatorSetting } from 'apps/vida/src/modules/shared/constant';

export class CustomValidators {
  static requiredWithTrimming(control: AbstractControl): ValidationErrors | null {
    let valid = false;
    valid = !!control && !!control.value;
    valid = typeof control.value === 'string' ? typeof control.value === 'string' && !!control.value.trim() : valid;
    return valid ? null : { 'required': true };
  }

  static requiredWithTrimmingAllowZero(control: AbstractControl): ValidationErrors | null {
    if (control && control.value === 0) {
      return null;
    }
    return CustomValidators.requiredWithTrimming(control);
  }

  static validateEmail(control: AbstractControl): ValidationErrors | null {
    const emailRegex = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$');
    return !control.value || !control.value.trim() || emailRegex.test(control.value) ? null : { 'email': true };
  }

  static notPattern(reg: RegExp): ValidatorFn | null {
    return (control: AbstractControl) => {
      return !reg.test(control.value) ? null : { pattern: true };
    };
  }

  static validateIsNumber(control: AbstractControl): ValidationErrors | null {
    if (Number.isNaN(Number(control.value))) {
      return { 'isNumber': true };
    } else {
      return null;
    }
  }

  static validateNegativeNumber(control: AbstractControl): ValidationErrors | null {
    const parsed = Number(control.value);
    if (!Number.isNaN(parsed) && parsed < 0) {
      return { 'negativeNumber': true };
    } else {
      return null;
    }
  }

  static validateIsGuidEmpty(control: AbstractControl): ValidationErrors | null {
    if (control.value === Guid.Empty) {
      return { 'isGuidEmpty': true };
    } else {
      return null;
    }
  }

  static checkTimeValid(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const listNum = control.value && control.value.toString().split(':');
    const validHour = !!listNum[0] && Number.parseFloat(listNum[0]) > 23;
    const validMin = !!listNum[1] && Number.parseFloat(listNum[1]) > 59;
    return validHour || validMin ? { messageTime: ERROR_MESSAGE.MessageTimeInValid } : null;
  }

  static checkValidThickeningTimePump(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const listNum = control.value && control.value.toString().split(':');
    const validHourThickening = !!listNum[0] ? Number.parseFloat(listNum[0]) : 0;
    const validMinThickening = !!listNum[1] ? Number.parseFloat(listNum[1]) : 0;


    if (validHourThickening === validMinThickening && validMinThickening === null) return null;

    const listJpt = control.parent.value.jpt && control.parent.value.jpt.toString().split(':');
    const validHourJpt = listJpt && !!listJpt[0] ? Number.parseFloat(listJpt[0]) : 0;
    const validMinJpt = listJpt && !!listJpt[1] ? Number.parseFloat(listJpt[1]) : 0;

    if (validHourThickening < validHourJpt) {
      return { messageTime: ERROR_MESSAGE.MessageCompareTimeInValid };
    }

    if (validHourThickening === validHourJpt) {

      return validMinThickening <= validMinJpt ? { messageTime: ERROR_MESSAGE.MessageCompareTimeInValid } : null;
    }
    return null;
  }

  static checkMinMaxValue(field: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const group = control.parent;
      const fieldToCompare = group.get(field);
      const isLessThan = Number(fieldToCompare.value) > Number(control.value);
      return isLessThan ? { lessThan: 'Max value is great than Min value' } : null;
    };
  }

  static checkLessThanANumber(numberValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // const group = control.parent;
      // const fieldToCompare = group.get(field);
      const isLessThan = Number(numberValue) > Number(control.value);
      return isLessThan ? { lessThan: 'The value is great than Min value' } : null;
    };
  }

  static checkValidDecimalNumber() {
    return (control: AbstractControl) => {
      if (!!control.value) {
        if (Number(control.value) > ValidatorSetting.MAX_DECIMAL_NUMBER) {
          return { 'valid': true };
        }
      }
      return null;
    };
  }

  static checkValidDecimalWithDigit(control: AbstractControl) : ValidationErrors | null{
      if (!!control.value) {
        let checkString = "^\-?[0-9]+(?:\.[0-9]{1,2})?$";
        const decimalRegex = new RegExp(checkString);
        const check = decimalRegex.test(control.value);
        return !check ? { lessThan: 'The value is great than Min value' } : null;
      }
      
      return null;
  }

  static validateAtLeastOne = (validator: ValidatorFn, controls: string[] = null) => (
    group: FormGroup,
  ): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls)
    }

    const hasAtLeastOne = group && group.controls && controls
      .some(k => !validator(group.controls[k])); 

    return  hasAtLeastOne &&  group.valid ? null : {
      atLeastOne: true,
    };
  }
}
