<div class="d-flex pt-4">
  <div class="form-subtitle-section">{{testTableName}}</div>
  <div class="pt-2 pl-2 text-nowrap" style="font-weight: bold;">Slurry #</div>
  <div>
    <p-dropdown
      [formControl]="selectedSlurry"
      (onChange)="changeSlurryNo($event)"
      [options]="availableTestSlurries"
      [disabled]="!canEdit"
      appendTo="body"
      [placeholder]="'\u00A0'"
      baseZIndex="9999">
    </p-dropdown>
  </div>
</div>

<div class="ui-table">
  <table style="font-weight: bold;">
    <thead>
      <tr>
        <th>Temperature</th>
        <th>Time (hh:mm)</th>
        <th>Strength (<span [unitType]="UnitType.Pressure"></span>)</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="test;else empty">
        <ng-container *ngIf="isTestFinishedOrValidated(test);else testStatusDescription">
          <tr>
            <td>
              <span [apiValue]="getField(test, UCAField.EndTemperature)" [unitType]="UnitType.Temperature"></span>
            </td>
            <td>
              <span>{{getField(test, UCAField.Strength50)}}</span>
            </td>
            <td>
              <span [apiValue]="50" [unitType]="UnitType.Pressure" [shouldShowUoM]="false"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span [apiValue]="getField(test, UCAField.EndTemperature)" [unitType]="UnitType.Temperature"></span>
            </td>
            <td>
              <span>{{getField(test, UCAField.Strength500)}}</span>
            </td>
            <td>
              <span [apiValue]="500" [unitType]="UnitType.Pressure" [shouldShowUoM]="false"></span>
            </td>
          </tr>
          <tr>
            <td>
              <span [apiValue]="getField(test, UCAField.EndTemperature)" [unitType]="UnitType.Temperature"></span>
            </td>
            <td>
              <span>24:00</span>
            </td>
            <td>
              <span [apiValue]="getField(test, UCAField.Time24Hr)" [unitType]="UnitType.Pressure" [shouldShowUoM]="false"></span>
            </td>
          </tr>
        </ng-container>
        <ng-template #testStatusDescription>
          <tr>
            <td class="text-center bc-white" colspan="3">{{test?.testStatusDescription}}</td>
          </tr>
        </ng-template>
      </ng-container>

      <ng-template #empty>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>