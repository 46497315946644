import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';

export function isFormDirty(control: AbstractControl): boolean {
    if (!control)
        return false;
    const isDirty = findDirtyControls(control);
    return isDirty.length !== 0;
}

export function findDirtyControls(control: AbstractControl) {
    const dirty = [];

    if (control.dirty)
        dirty.push(control);

    if (control instanceof UntypedFormGroup) {
        const controls = control.controls;
        for (const name in controls) {
            if (controls[name] instanceof UntypedFormGroup) {
                dirty.push(...findDirtyControls(controls[name] as UntypedFormGroup));
            } else if (controls[name] instanceof UntypedFormArray) {

                const arr = controls[name] as UntypedFormArray;
                arr.controls.forEach(fg => {

                    dirty.push(...findDirtyControls(fg));
                });

            } else if (controls[name].dirty) {
                dirty.push(name);
            }
        }
    }

    return dirty;
}