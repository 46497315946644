import { Injectable } from '@angular/core';

import { environment } from 'libs/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SettingService {
  constructor(private httpClient: HttpClient) {
  }

  getUserSettings() {
    return this.httpClient.get(`${environment.baseUrl}/api/UserSettings/MetaData`);
  }

  updateUserSettings(payload) {
    return this.httpClient.put(`${environment.baseUrl}/api/UserSettings`, payload);
  }

  getFilterLocationSettings() {
    return this.httpClient.get(`${environment.baseUrl}/api/UserSettings/FilterByLocation`);
  }
  
  saveFilterByLocationSettings(payload: any): Observable<any> {
    return this.httpClient.post(`${environment.baseUrl}/api/UserSettings/FilterByLocation`, payload);
  }
}
