<div class="sidebar-header">
  <h1 class="title">Risk Assessment Source Settings</h1>
</div>
<div class="sidebar-content" [loadingIndicator]="loadingSubscription">

  <div class="file-upload">
    <job-uploader #uploader [listFiles]="riskAssessmentFiles" [jobId]="jobId" (uploadCancelEmitter)="uploadCancel($event)"
      (uploadSuccessEmitter)="uploadSuccess($event)" (uploadInprogressEmitter)="uploadInprogress($event)"
      [destinationFolderPath]="destinationFolderPath" [header]="'Upload Files'" [title]="'Select a Risk Assessment file to import.'"
      [drapDropDescription]="'or Drag and Drop a Risk Assessment File Here'" [isEnableDescriptionInput]="true" [vidaFileType]="vidaFileType"
      [badgeNumber]=true [displayMode]="'Vertical'">
    </job-uploader>
  </div>

  <div class="files-list m-top-2">
    <span _ngcontent-c10="" class="badge-number">2</span> <label>Select Source</label>
    <p>Select a Risk Assessment file as source.</p>
    <div class="row">
      <div class="col-sm-3 form-group" style="text-align: center">
        <strong>Source File</strong>
      </div>
      <div class="col-sm-9 form-group">
        <strong>File Name</strong>
      </div>
    </div>
    <div class="row" *ngFor="let riskAssessment of riskAssessmentFiles">
      <div class="col-sm-3 form-group" style="text-align: center">
          <p-radioButton name="riskAssessmentFile" [value]="riskAssessment" [(ngModel)]="lastUsedFile" (onClick)="riskAssessmentSelectedHandler()"></p-radioButton>
      </div>
      <div class="col-sm-9 form-group">
          <span [pTooltip]="riskAssessment.description" tooltipPosition="top" tooltipStyleClass="file-description-tooltip">{{ riskAssessment.getFileName() }}</span>
          <div class="full-width-input">{{ riskAssessment.description }}</div>
      </div>
    </div>

  </div>
</div>
<div class="sidebar-footer">
  <a class="btn lnk-cancel" (click)="cancelRiskAssessment()">Cancel</a>
  <button type="button" class="btn primary-btn" (click)="saveRiskAssessment()" [disabled]="disableSaveButton">SAVE</button>
</div>