import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';
import { Observable, of } from 'rxjs';

export class BaseApiService {

    readonly EXPIRY = 3600000;

    constructor(
        protected httpClient: HttpClient,
        protected cacheService: CacheService) {
    }

    protected _get<T>(url: string): Observable<T> {
        return this.cacheService.get(url, this.httpClient.get<T>(url), this.EXPIRY);
      }
}