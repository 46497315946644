import { UnitMeasureModel } from "libs/models";

export enum UnitType {
  Distance = 0,
  Inclination = 1,
  Temperature = 2,
  Density = 3,
  Pressure = 4,
  LargeVolume = 5,
  SmallVolume = 6,
  GasVolume = 7,
  FlowRate = 8,
  FlowRateGas = 9,
  SmallDistance = 10,
  WeightLength = 11,
  Yield = 12,
  LiquidsSolidsRatio = 13,
  Weight = 14,
  BaseMaterialWeight = 15,
  Time = 16,
  DryVolume = 17,
  LargeDistance = 18,
  Percent = 19,
}

export const CustomUnit = {
  galbbl: 'gal/bbl',
  lm3: 'L/m3'
}

export const CustomUnitMeasure = {
  galbbl: <UnitMeasureModel> {
    id: null,
    name: 'gal/bbl',
    aliasName: null,
    scale: 3948.00023767,
    offset: 0
  },
  lm3: <UnitMeasureModel> {
    id: null,
    name: 'L/m3',
    aliasName: null,
    scale: 93999.9945152,
    offset: 0
  }
}

export const APIUnitOfMeasure = ['ft', '°', '°F', 'ppg', 'psi', 'bbl', 'gal', 'MScf', 'bbl/min', 'scfm', 'in', 'lb/ft', 'ft3/sk', 'gal/sk', 'lbs','','','', 'miles', '%', ];
export const SIUnitOfMeasure = ['m', '°', '°C', 'kg/m3', 'Mpa', 'm3', 'L', 'mm3', 'm3/min', 'scfm', 'cm', 'kg/m', 'L/100kg', 'L/100kg', 'kg','','','', 'km', '%', ];
