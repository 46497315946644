import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {Injectable} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from '../../environment';

const instrumentationKey = environment.appInsightsInstrumentationKey;

@Injectable()
export class ApplicationInsightsService {
  private appInsights: ApplicationInsights;
  constructor(private router: Router) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey
      }
    });
    this.appInsights.loadAppInsights();
    this.loadCustomTelemetryProperties();
    this.createRouterSubscription();
  }

  logPageView(name?: string, url?: string): void {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message}, properties);
  }

  private loadCustomTelemetryProperties() {
    this.appInsights.addTelemetryInitializer(envelope => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties['ApplicationPlatform'] = 'Web';
      item.properties['ApplicationName'] = 'ApplicationName';
    });
  }
  private createRouterSubscription() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.logPageView(null, event.urlAfterRedirects);
    });
  }
}

