export enum ObjectiveMetOption {
  Met = 1,
  Unmet = 2,
  Unknown = 3
}

export enum ContingencyMetOption {
  Yes = 1,
  No = 2,
  Unknown = 3
}

export const ObjectiveMetOptionList =  [
  { id: 3, label: 'Unknown' },
  { id: 1, label: 'Met' },
  { id: 2, label: 'Unmet' },
];

export const ContingencyMetOptionList =  [
  { id: 3, label: 'Unknown' },
  { id: 1, label: 'Yes' },
  { id: 2, label: 'No' },
];
