<div>
  <div class="page-title">{{title}}</div>
  <div class="header-buttons">
    <button *ngIf="search" class="btn btn-sm btn-default">
      <i class="fa fa-search" aria-hidden="true"></i>
      Perform Global Search
    </button>
    <button *ngIf="delete" id="buttonhead" class="btn btn-sm btn-default ">
      <i class="fa fa-times" aria-hidden="true"></i>
      Delete
    </button>
    <button *ngIf="share" type="button" class="btn btn-sm btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fa fa-share" aria-hidden="true"></i>
      Share
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu">
      <li><a href="#">Copy Link</a></li>
      <li><a href="#">Invite Other Users</a></li>
    </ul>
    <button *ngIf="overflow" id="buttonhead" class="btn btn-sm btn-default "><i class="fa fa-ellipsis-h" aria-hidden="true"></i></button>
  </div>
</div>
