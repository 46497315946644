<div class="confirmation-message" tabindex="0" #focusElement>
    <p>You have entered an Actual Quantity that is > 125% of the Planned Quantity.</p>
    <div class="flex" >
        <div class="planned-qty-col">
            <p>Planned Quantity</p>
            <p>{{data?.plannedQty}}</p>
        </div>
        <div class="actual-qty-col">
            <p>Actual Quantity</p>
            <p>{{data?.actualQty}}</p>
        </div>
    </div>
    <p>Please confirm that this entered quantity is correct.</p>
</div>
<div class="confirmation-footer">
    <button (click)="onCancel()" class="btn aq-cancel">Cancel</button>
    <button (click)="onConfirm()" class="btn primary-btn aq-confirm">CONFIRM</button>
</div>
