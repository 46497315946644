export enum KPIOption {
    Yes = 1,
    No = 2,
    NotSet = 3
}

export const KPIOptionList = [
    { id: 1, label: 'Yes', value: true },
    { id: 2, label: 'No', value: false },
];

export const NPTCategoryOptionList = [
    { id: '7A2B9DB4-5284-4AD0-B129-A5BFAADEEC01', label: 'HAL equipment failure' },
    { id: 'BEA0201C-3136-48FE-B1E0-EC4FAF9EA853', label: 'Bulk Delivery or Fluid Supply issue' },
    { id: '32F16F0E-0008-454C-BA16-ADF40AF958E2', label: 'Fluid Mixability or Pumping Issue (not equipment related)' },
    { id: '607892C4-4E3A-4063-9F43-0A0EF6094551', label: 'Casing Equipment Failure' },
    { id: '9EAC575A-17D6-4E02-B626-9398C7DA7D4B', label: 'Equipment Late to Location' },
    { id: 'FEE66BB4-1C18-485A-B6A2-9A9B42A5C2C2', label: 'Materials Late to Location' },
    { id: 'C4ABC5D1-2F21-4A5A-8C4D-3D1A5FBC6A3A', label: 'Waiting on Lab Results' },
    { id: '537CE227-C105-44F8-8CF7-459BDEDC6B8A', label: 'Other' },
];