import * as moment from 'moment-mini';
import { DATE_TIME_CALL_MOMENT_JS } from 'libs/constants';
import { Injectable } from "@angular/core";
@Injectable()
export class FileModel {
    virtualRelativeFilePathLocal: string;
    virtualRelativeFilePath: string;
    description: string;
    createdDate: Date;
    uploader: string;
    filePathDownload: string;
    getFileName() {
        return this.virtualRelativeFilePath ? this.virtualRelativeFilePath.split('/').pop() : '';
    }
    getUploader() {
        if (this.uploader && this.createdDate) {
            return `${this.uploader}`;
        }
        return '';
    }

    getUploadDate() {
        if (this.uploader && this.createdDate) {
            return `${moment(this.createdDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }

    getUploadMessage() {
        if (this.virtualRelativeFilePath) {
            return `Uploaded by ${this.uploader} on ${moment(this.createdDate).format(DATE_TIME_CALL_MOMENT_JS)}`;
        }
        return '';
    }
}

export class SelectedFileModel extends FileModel {
    isLastUsed: boolean;

    constructor() {
        super();
        this.isLastUsed = false;
    }
}