import { Component, Output, Input, OnInit, EventEmitter, ViewChild, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PumpScheduleAdapter } from '../../adapters';
import { ApplicationStateService, MasterDataService, FluidService, JobService, UserSettingService, RoleService } from 'libs/shared/services';
import { UnitType, ERROR_TYPE, FLUID_TYPE_SCHEDULE } from 'libs/constants';
import { PDropdownDataPumpScheduleHelper } from '../../../shared/helpers';
import { PDropDownModel, ErrorMessageModel, DynamicSidebarRef, DynamicSidebarService } from 'libs/ui';
import { PumpScheduleService } from '../../services';
import { ControlPointState, ControlPointType, CommonMessageText } from '../../../shared/constant';
import { Job, MudParameterModel, FluidModel, ISlurryType, ITestType, IMaterialTypeModel, PumpSchedule, PumpScheduleStageModel } from 'libs/models';
import { Subscription, of } from 'rxjs';
import {filter, switchMap, retry, delay, tap} from 'rxjs/operators';
import { UnitConversionService, convertWithUnitMeasure, formatNumber } from 'libs/ui/unit-conversions';
import { IfactFluidSettingsSidebarComponent } from '../../../sidebar-dialogs/components';
import { FluidFormFactory } from '../../../fluids/components/fluid-container/fluid-form.factory';
import { ControlPointAdapter } from '../../../control-point/adapters';
import { EditJobAdapter } from '../../../edit-job/adapters';

import { AutoUnsubscribe } from 'libs/helpers/subscription-helper';
import { AutoComplete } from 'primeng/autocomplete';
import { MessageService } from 'primeng/api';
import { environment } from 'libs/environment';
import { JobModalManagementService } from 'libs/ui/services/job-modal-management.service';

@AutoUnsubscribe()
@Component({
  selector: 'pump-schedule-info',
  templateUrl: './pump-schedule-info.component.html',
  styleUrls: ['./pump-schedule-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PumpScheduleInfoComponent implements OnInit, OnChanges {
  @Input() stageForm: UntypedFormGroup;
  @Input() readonly: boolean;
  @Input() isTopBottom: boolean;
  @Input() fluids: any = [];
  @Input() controlPointNumber: string = null;
  @Input() cpState: ControlPointState;
  @Input() job: Job;
  @Input() stageModel: PumpScheduleStageModel;
  @Input() loadOutQuantity: number;

  slurryTypes: ISlurryType[];
  testTypes: ITestType[];
  materialTypes: IMaterialTypeModel[];

  searchKey: string;
  filteredFluid: any[] = [];
  fluidDropDownModels: PDropDownModel[] = [];

  @Output() onFluidChange = new EventEmitter<any>();
  // @Output() onActualVolumeChange = new EventEmitter<any>();
 
  @Output() onSelectedFluid = new EventEmitter<any>();
  @Output() onFluidKeyUp = new EventEmitter<any>();
  @Output() selectedFluidChange = new EventEmitter<any>();
  @Output() insertStageBefore = new EventEmitter<any>();
  @Output() insertStageAfter = new EventEmitter<any>();
  @Output() deleteStage = new EventEmitter<any>();
  @Output() checkMudType = new EventEmitter<boolean>();
  @Output() onClearFluid = new EventEmitter();
  @Output() openStageCogsHelp = new EventEmitter();

  @ViewChild('autoComplete') autoComplete: AutoComplete;

  UnitType = UnitType;
  ControlPointState = ControlPointState;
  fluidTypeDataStageOne: any = [];
  cp4State: string;
  cp2State: string;
  pumpScheduleFluidTypeId: string;
  isOffshore: boolean;

  isMudType: boolean = false;
  mudParameter: any;
  loadingSubscription: Subscription;
  ifactFluidSettingsSidebarRef: DynamicSidebarRef<IfactFluidSettingsSidebarComponent>;
  errorMessages = {
    pumpScheduleFluidType: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, 'Type is required field.')
    ]
  };

  subscription = new Subscription();

  get showCogs$() {
    return this.userSettingsService.showCogs$;
  }

  get isJobReviewer$() {
    return this.roleService.isJobReviewer$;
  }

  constructor(
    public applicationStateService: ApplicationStateService,
    private jobService: JobService,
    private pumpScheduleService: PumpScheduleService,
    private unitConversionService: UnitConversionService,
    private dynamicSidebarService: DynamicSidebarService,
    private messageService: MessageService,
    private pumpScheduleAdapter: PumpScheduleAdapter,
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private editJobAdapter: EditJobAdapter,
    protected changeDetector: ChangeDetectorRef,
    private masterDataService: MasterDataService,
    private controlPointAdapter: ControlPointAdapter,
    private fluidService: FluidService,
    private userSettingsService: UserSettingService,
    private jobModalManagementService: JobModalManagementService,
    private readonly _fluidFormFactory: FluidFormFactory
  ) {
    this.fluidTypeDataStageOne = pumpScheduleAdapter.fluidTypeDataStageOne;

    this.pumpScheduleService.updateType$.subscribe((res: boolean) => {
      if (res) {
        this.pumpScheduleAdapter.fluidTypes$.next(PDropdownDataPumpScheduleHelper('description', 'id', 'isEnableFirstStage', this.pumpScheduleAdapter.disableSelectedTopArray));
      } else {
        this.pumpScheduleAdapter.fluidTypes$.next(PDropdownDataPumpScheduleHelper('description', 'id', 'isEnableFirstStage', this.pumpScheduleAdapter.enableSelectedTopArray));
      }

      this.changeDetector.markForCheck();
    });
  }

  ngOnInit() {
    this.initMasterData();
    // Get current fluid object
    const fluidTypePumpSchedule = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === this.stageForm.controls.pumpScheduleFluidTypeId.value);
    this.pumpScheduleFluidTypeId = this.stageForm.controls.pumpScheduleFluidTypeId.value;
    // Check if current fluid is a Mud type

    let isFirstStageMud = false;
    if (fluidTypePumpSchedule) {
      isFirstStageMud = fluidTypePumpSchedule.name === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD;
      this.isMudType = isFirstStageMud || fluidTypePumpSchedule.name === FLUID_TYPE_SCHEDULE.MUD;
    }
    
    this.checkMudType.emit(this.isMudType);

    this.mudParameter = this.generateMudParameterText(this.stageForm.value.mudParameter, UnitType.Density);

    // Update data for Mud from dialog
    this.subscription.add(this.applicationStateService.updateMudData$
      .pipe(
        filter((x: any) => x.order === this.stageForm.value.order)
      )
      .subscribe((res: any) => this._setMudParameterDisplay(res.mudParameterData))
    );

    if (isFirstStageMud) {
      this.subscription.add(
        this.applicationStateService.updateMudDensity$.subscribe(
          (density: number) => {

            const mudParameter = this.stageForm.value.mudParameter;
            mudParameter.mudDensity = density;

            this.stageForm.patchValue({ mudParameter: mudParameter });
            this._setMudParameterDisplay(mudParameter);
          }
        )
      );
    }

    this.subscription.add(this.jobModalManagementService.$saveError.subscribe(() => {
      this.changeDetector.markForCheck();
    }));

    this.subscription.add(this.jobModalManagementService.$nextError.subscribe(() => {
      this.changeDetector.markForCheck();
    }));

    this.subscription.add(this.applicationStateService.isChangeOffshore$.subscribe(value => {
      this.isOffshore = value;
      this.changeDetector.markForCheck();
    }));

    this.subscription.add(this.applicationStateService.materialCostCalculated$
      
      .subscribe(() => {
        this.changeDetector.markForCheck();
      }));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { fluids } = changes;
    if (fluids && fluids.currentValue) {
      this.fluidDropDownModels = this.fluids.map(x => new PDropDownModel(x.displayName, x.id));
      this.filterFluids();
    }

    if (this.job && this.job.controlPoints && this.job.controlPoints.length) {
      this.cp4State = this.job.controlPoints[3].controlPointState.name;
      this.cp2State = this.job.controlPoints[1].controlPointState.name;
    }
  }

  initMasterData() {
    this.editJobAdapter.initMasterData();
    this.masterDataService.listTestTypes().subscribe((testTypes) => {
      this.testTypes = testTypes;
      this.changeDetector.markForCheck();
    });
    this.masterDataService.listSlurryTypes().subscribe((slurryTypes) => {
      this.slurryTypes = slurryTypes;
      this.changeDetector.markForCheck();
    });
    this.masterDataService.listMaterialTypes().subscribe((materialTypes) => {
      this.materialTypes = materialTypes;
      this.changeDetector.markForCheck();
    });
  }

  onFocusShowAllData() {
    if (!this.applicationStateService.isCreate) {
      if (this.autoComplete.inputEL.nativeElement.value.length <= 0) {
        this.autoComplete.handleDropdownClick(null);
      }
    }
  }

  onComplete(search) {
    this.searchKey = search;
    this.filterFluids();

    this.changeDetector.markForCheck();
  }

  filterFluids() {
    if (!this.searchKey) {
      this.filteredFluid = [...this.fluids];
    } else {
      this.filteredFluid = this.fluids.filter(fluid => {
        if (fluid.displayName)
          return fluid.displayName.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1;
      });
    }
  }

  openMudParameters() {
    // this.applicationStateService.isMudParameter$.next({ form: this.stageForm.controls.mudParameter, order: this.stageForm.value.order });
  }

  generateMudParameterText(mudData: MudParameterModel, unitType: number) {
    const { mudDensity = null, typeName = null, mudName = null } = mudData || {};

    // Get unit name
    const unitName = this.unitConversionService.getUnitName(unitType, false);

    // Create mudDensity string with unit name
    // Using == operator to check both null and undefined value
    let mudDensityString = '';
    if (mudDensity != null) {
      const convertedDensity = convertWithUnitMeasure(mudDensity, this.unitConversionService.getApiUnitMeasure(unitType), this.unitConversionService.getCurrentUnitMeasure(unitType));
      mudDensityString = `${formatNumber(convertedDensity, 2)} ${unitName}`;
    }

    // Create an array of string so that we can join by delimiter
    const stringArray = [mudDensityString, typeName, mudName];

    // Filter null value and join by delimiter
    return stringArray.filter(Boolean).join(', ');
  }

  _onFluidChange(event) {
    const selectedType = this.pumpScheduleAdapter.fluidTypes?.find(e => e.id === this.stageForm.controls.pumpScheduleFluidTypeId.value);
    if (selectedType) {
      this.stageForm.controls.pumpScheduleFluidTypeName.setValue(selectedType.name, { emitEvent: false });
      this.pumpScheduleFluidTypeId = selectedType.id;
    }
    this.onFluidChange.emit(event);
    // Select mud onchange mud parameters
    this.isMudType = selectedType && (selectedType.name === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD || selectedType.name === FLUID_TYPE_SCHEDULE.MUD);
    this.checkMudType.emit(this.isMudType);
    this.changeDetector.markForCheck();
    this.applicationStateService.cogsRecalculationNeeded$?.next(true);
  }

  _onSelectedFluid(event) {
    const fluid = this.fluids.find(x => x.id === event.value);
    this.stageForm.controls.slurry = this.fb.group(fluid);
    this.onSelectedFluid.emit(event.value);
  }

  onBlurFluid(e) {
    const slurry = this.stageForm.controls.slurry;
    if (slurry.value && !Boolean(slurry.value.slurryId) && slurry.value.displayName) {
      this.setFluidByName(slurry.value.displayName);
    }
  }

  getSanitizedData() {
    const data = this.stageForm.getRawValue();
    return data.slurry;
  }

  getactualVolumePumped() {
    const data = this.stageForm.getRawValue();
    return data.actualVolumePumped;
  }

  getActualDensity() {
    const data = this.stageForm.getRawValue();
    return data.actualDensity;
  }

  getActualLoadOutVolume() {
    const data = this.stageForm.getRawValue();
    return data.loadoutVolume;
  }

  getStageTotalCogs() {
    return this.stageForm.controls.totalCOGS.value !== null ? this.stageForm.controls.totalCOGS.value.toFixed(2) : null;    
  }

  checkDisableItem() {
    return (this.cpState === ControlPointState.Approved || this.cpState === ControlPointState.Submitted) ? 'exception-disable-item' : '';
  }

  get isCP4(): boolean {
    return (this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint4.toString());
  }

  get isCP1(): boolean {
    return (this.controlPointNumber && this.controlPointNumber.toString() === ControlPointType.ControlPoint1.toString());
  }

  get testType(): string {
    if (this.stageForm && this.stageForm.controls.testTypeId && this.editJobAdapter.testTypeData$.value && this.editJobAdapter.testTypeData$.value.length > 0) {
      const testType = this.editJobAdapter.testTypeData$.value.find(x => x.value === this.stageForm.controls.testTypeId.value);
      if (testType) {
        return testType.label;
      }
    }
    return '';
  }

  private _insertFluids(...fluids: FluidModel[]) {
    fluids.forEach(fluidModel => this.editJobAdapter.fluidFormArray.push(
          this._fluidFormFactory.createFluidForm(fluidModel)
        )
    );
    if (!this.job.canEdit) {
      this.editJobAdapter.fluidForm$.value.disable({ emitEvent: false, onlySelf: true });
    }

    this.changeDetector.markForCheck();
  }

  showFluidSettings() {
    this.ifactFluidSettingsSidebarRef = this.dynamicSidebarService.open(IfactFluidSettingsSidebarComponent, {
      data: {
        job: this.job,
        jobId: this.job.id,
        isLinkFluidRequest: true,
        testTypeId: this.stageForm.value.slurry && this.stageForm.value.slurry.testTypeId,
        testType: this.stageForm.value.slurry && this.stageForm.value.slurry.testType,
        slurryTypeId: this.stageForm.value.slurry && this.stageForm.value.slurry.slurryTypeId,
        iFactsRequestIdHDF: this.stageForm.controls.slurry.value && this.stageForm.controls.slurry.value.requestIdHDF
      }
    });
    const fluids$ = this.controlPointNumber ? this.controlPointAdapter.fluids$ : this.editJobAdapter.fluids$;
    this.ifactFluidSettingsSidebarRef.onClose.pipe(
      filter(data => !!data),
      tap(data => {
        const firstNewFluid = data[0];
        const selectedFluid = fluids$.value.find(f => +f.requestId === +firstNewFluid.requestId && +f.slurryNo === +firstNewFluid.slurryNo);
        if(selectedFluid ) {
          fluids$.next([...fluids$.value]);
          this.updateSelectedFluid([selectedFluid], selectedFluid);
        }
      }),
      switchMap(data => this.job && this.job.id ? this.jobService.linkRequest(this.job.id, data) : of(data))
    ).subscribe((linkedFluids: FluidModel[]) => {
      if (linkedFluids && linkedFluids.length) {
        let newFluids = linkedFluids.filter(s => !fluids$.value.find(f => +f.requestId === +s.requestId && +f.slurryNo === +s.slurryNo));
        const firstNewFluid = linkedFluids[0];
        if (newFluids && newFluids.length) {
          newFluids = this.oldSlurry(newFluids);
          this.loadingSubscription = this.fluidService.loadFromIFacts(this.job, ...newFluids).subscribe((newFluidModels: FluidModel[]) => {
            fluids$.next([...fluids$.value, ...newFluidModels]);
            const selectedFluid = fluids$.value.find(f => +f.requestId === +firstNewFluid.requestId && +f.slurryNo === +firstNewFluid.slurryNo);
            this.updateSelectedFluid(newFluidModels, selectedFluid);
          });
        } else {
          fluids$.next([...fluids$.value]);
          const selectedFluid = fluids$.value.find(f => +f.requestId === +firstNewFluid.requestId && +f.slurryNo === +firstNewFluid.slurryNo);
          this.updateSelectedFluid([selectedFluid], selectedFluid);
        }
        this.changeDetector.markForCheck();
      }
    });
  }

  setFluidByName(slurryName: string): boolean {
    const slurry = this.stageForm.controls.slurry;
    const slurryNameLowerCase: string = slurryName.toLowerCase();
    const existingFluid = this.fluids.find(fluid => {
      return fluid.displayName && fluid.displayName.toLowerCase() === slurryNameLowerCase;
    });
    if (existingFluid && existingFluid.slurryId) {
      slurry.setValue(existingFluid);
      return true;
    }
    return false;
  }

  updateSelectedFluid(newFluids: FluidModel[], selectedFluid: FluidModel) {
    if (this.controlPointNumber) {
      newFluids = this.oldSlurry(newFluids);
      this._loadDataAndInsertFluids(...newFluids);
      this.stageForm.controls.selectedFluidId.setValue(selectedFluid.id);
      this.onSelectedFluid.emit(selectedFluid.id);
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.FLUID_ADD.SUCCESS });
      this.stageForm.controls.slurry.value.requestId = selectedFluid.requestId;
      this.stageForm.controls.slurry.patchValue(selectedFluid);
    } else {
      newFluids = this.oldSlurry(newFluids);
      this._loadDataAndInsertFluids(...newFluids);
      this.stageForm.patchValue({ slurry: selectedFluid });
      // Update Fluid into Job
      if (this.stageModel) {
        this.stageModel.slurry = selectedFluid;
        this.stageForm.controls.pumpScheduleFluidTypeId.setValue(this.pumpScheduleFluidTypeId);
      }
      this.onSelectedFluid.emit({ ...selectedFluid });
      this.messageService.add({ life: environment.messagePopupLifetimeMs, severity: 'success', detail: CommonMessageText.FLUID_ADD.SUCCESS });
    }
    this.changeDetector.markForCheck();
  }

  oldSlurry(newFluids: FluidModel[]) {
    const { value: { slurry } } = this.stageForm;
    if (slurry && slurry.testTypeId && slurry.slurryTypeId) {
      //newFluids[0].testType = slurry.testType; //Fix Bug 400089:[VIDA_1.2_US#385974] Incorrect Test Type is assigned to a fluid after replacing oneiFacts Fluid with another from Pump Schedule
      newFluids[0].slurryType = slurry.slurryType;
      // newFluids[0].testTypeId = slurry.testTypeId;
      newFluids[0].slurryTypeId = slurry.slurryTypeId;
    }
    return newFluids;
  }

  addSelectedRequests(fluids: FluidModel[]) {
    this._loadDataAndInsertFluids(...fluids);
  }

  removeDuplicatedFluid(fluids: FluidModel[]): FluidModel[] {
    const arrResult: FluidModel[] = [];
    const allFluids = this.editJobAdapter.fluids$.value;
    fluids.forEach(item => {
      const fluid = allFluids.find(x => x.requestId && x.slurryId && x.requestId.toString() === item.requestId.toString() && x.slurryId.toString() === item.slurryId.toString());
      if (!fluid) {
        arrResult.push(item);
      }
    });
    return arrResult;
  }
  
  _loadDataAndInsertFluids(...fluids: FluidModel[]) {
    fluids = this.removeDuplicatedFormFluid(...fluids);
    this._insertFluids(...fluids);
  }

  removeDuplicatedFormFluid(...fluids: FluidModel[]): FluidModel[] {
    const newFluids = fluids.filter(f => {
      if (!f) {
        return false;
      }
      if (f.slurryId === null || f.requestId === null) return false;
      const foundF = this.editJobAdapter.fluidFormArray.controls.find(fc => {
        const frmFluid = fc as UntypedFormGroup;
        return frmFluid.controls.slurryId.value && frmFluid.controls.requestId.value &&
          f.slurryId.toString() === frmFluid.controls.slurryId.value.toString() &&
          f.requestId.toString() === frmFluid.controls.requestId.value.toString();
      });
      return !foundF;
    });
    return newFluids;
  }

  selectFirstFluid(requestId, slurryId, fluidModels: FluidModel[]): FluidModel {
    return fluidModels.find(x => x.requestId && x.slurryId && x.requestId.toString() === requestId.toString() && x.slurryId.toString() === slurryId.toString());
  }

  private _setMudParameterDisplay(mudParameter: MudParameterModel): void {
    this.mudParameter = this.generateMudParameterText(mudParameter, UnitType.Density);
    this.changeDetector.markForCheck();
  }

  isJobInProgess(): boolean {
    return this.job.jobStatusDescription !== 'Completed' && this.job.jobStatusDescription !== 'Cancelled';
  }

}
