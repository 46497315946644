import { ControlPointType, ControlPointState, ControlPointAction, JobComplienceStatus } from '../../shared/constant';

export class ControlPointModel {
    id: string;
    title: string;
    controlPointStateId: string;
    type: ControlPointType;
    submittedComment: string;
    approvedComment: string;
    rejectComment: string;
    unlockComment: string;
    otherNote: string;
    otherNoteControlPoint1: string;
    internalNotes: string;
    submitterNetworkId: string;
    submittedBy: string;
    submitterDomainId: string;
    submittedText: string;
    rejectNetworkId: string;
    rejectedBy: string;
    rejectDomainId: string;
    appoverNetworkId: string;
    approvedBy: string;
    appoverDomainId: string;
    approvedText: string;
    unlockNetworkId: string;
    unlockDomainId: string;
    unlockedBy: string;
    unlockDate?: Date;
    completedBy: string;
    completeNetworkId: string;
    completedText: string;
    submittedDate?: Date;
    approvedDate?: Date;
    rejectedDate?: Date;
    action: ControlPointAction;
    eControlPointState: ControlPointState;
    controlPointState: ControlPointStateModel;
    statusText: string;
    cpLink: any;
    disable: boolean;
    items: any[] = [];
    secondaryItems: any[] = [];
    visibleItems: ControlPointVisibleItem[] = [];
    controlPointActionComment: any[] = [];
    lastest: Object;
    unCompletedDate?: Date;
    prepareNetworkId: string;
    preparedBy: string;
    prepareDomainId: string;
    prepareText: string;
    preparedDate?: Date;
    isLessonsReviewed?: boolean;
    isCECompleted?: boolean;
    isSECompleted?: boolean;
}

export interface ControlPointVisibleItem {
    name: string;
    checked: boolean;
    visibled: boolean;
    isCMTService: boolean;
    isCEService: boolean;
    isDCEService: boolean;
}

export interface ControlPointStateModel {
    name: string;
    displayName: string;
}

export interface CpStatuses{
    JobId:string;
    cP1StatusName:string;
    cP2StatusName:string;
    cP3StatusName:string;
    cP4StatusName:string;
}
