import { ValidatorSetting } from "apps/vida/src/modules/shared/constant";

//import can fail for unit tests, so redefining here
class ErrorMessageModel {
  constructor(public errorType: string, public errorMessage: string) { }
}

export const ERROR_TYPE = {
  REQUIRED: 'required',
  PATTERN: 'pattern',
  MessageTime: 'messageTime',
  MaxLength: 'maxlength',
  UNIQUE: 'unique',
  ValidCasingSize: 'validCasingSize',
  NEGATIVE_NUMBER: 'negativeNumber',
  INVALID_VALUE: 'ivalidValue'
};

export const ERROR_MESSAGE = {
  MessageTimeInValid: 'Time must be 00:00 to 23:59',
  MessageCompareTimeInValid: '',
  MsgInvalidCasing: 'Casing Size cannot be greater than Hole Size.',
  REQUIRED: (field: string) => `${field} is required field.`,
};

export const JOB_ERROR_MESSAGES = {
  jobName: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Job Name')),
    new ErrorMessageModel(ERROR_TYPE.MaxLength, ValidatorSetting.MAX_LENGTH_JOB_NAME_50),
  ],
  wellNumber: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Well Number')),
    new ErrorMessageModel(ERROR_TYPE.MaxLength, ValidatorSetting.ERROR_MAX_LENGTH_50_TEXT),
  ],
  jobType: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Job Type')),
    new ErrorMessageModel('isGuidEmpty', ERROR_MESSAGE.REQUIRED('Job Type')),
  ],
  customer: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Customer')),
    new ErrorMessageModel('isGuidEmpty', ERROR_MESSAGE.REQUIRED('Customer')),
    new ErrorMessageModel('notExisted', 'Customer Name does not exist. Please input another value.')
  ],
  rig: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Rig Name')),
    new ErrorMessageModel('isGuidEmpty', ERROR_MESSAGE.REQUIRED('Rig Name')),
    new ErrorMessageModel('notExisted', 'Rig Name does not exist. Please input another value.')
  ],
  groupId: [
    new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Group')),
  ],
  // TODO: waiting error-display complete
   wellId: [
     new ErrorMessageModel(ERROR_TYPE.REQUIRED, ERROR_MESSAGE.REQUIRED('Well Name')),
     new ErrorMessageModel('isGuidEmpty', ERROR_MESSAGE.REQUIRED('Well Name')),
     new ErrorMessageModel('notExist', 'Well Name does not exist. Please input another value.'),
     new ErrorMessageModel('notValid', 'Well Name is longer than 50 symbols. Please input another value.')
   ],
  wellApiUwi: [
    new ErrorMessageModel(ERROR_TYPE.MaxLength, ValidatorSetting.ERROR_MAX_LENGTH_50_TEXT),
  ],
  latitudeDegrees: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('isNumber', ValidatorSetting.ERROR_NUMBER_NUMBER),
  ],
  latitudeMinutes: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_TIME),
  ],
  latitudeSeconds: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_TIME),
  ],
  longitudeDegrees: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_DEGREES),
    new ErrorMessageModel('isNumber', ValidatorSetting.ERROR_NUMBER_NUMBER),
  ],
  longitudeMinutes: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_TIME),
  ],
  longitudeSeconds: [
    new ErrorMessageModel('max', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('min', ValidatorSetting.ERROR_NUMBER_TIME),
    new ErrorMessageModel('pattern', ValidatorSetting.ERROR_NUMBER_TIME),
  ]
};
