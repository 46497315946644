import { Injectable } from '@angular/core';

import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class WellsService {
  data = [];
  status = 0;
  groupId = 0;
  statusText = 'All';

  constructor(private httpService: HttpService) { }

  getWellsByGroupId(groupId) {
    return this.httpService.Get(`${environment.baseUrl}/api/wells/groups/${groupId}`);
  }
}
