import { UOM } from './uom.model';

export class DesignCriteriaUOM {
  targetMinimumPressure: UOM;
  resultPressure: UOM;
  minVolume: UOM;
  maxVolume: UOM;
  resultVolume: UOM;
  temp: UOM;
  minTemp: UOM;
  maxTemp: UOM;
  resultTemp: UOM;
  minDensity: UOM;
  maxDensity: UOM;
  resultDensity: UOM;
  minOther: UOM;
  maxOther: UOM;
  resultOther: UOM;
  constructor() {
    this.targetMinimumPressure = new UOM();
    this.resultPressure = new UOM();
    this.minVolume = new UOM();
    this.maxVolume = new UOM();
    this.resultVolume = new UOM();
    this.temp = new UOM();
    this.minTemp = new UOM();
    this.maxTemp = new UOM();
    this.resultTemp = new UOM();
    this.minDensity = new UOM();
    this.maxDensity = new UOM();
    this.resultDensity = new UOM();
    this.minOther = new UOM();
    this.maxOther = new UOM();
    this.resultOther = new UOM();
  }
}