import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'libs/environment';
import { HttpService } from './http.service';

@Injectable()
export class CountryService {

  constructor(private http: HttpService) {
  }

  getCountry(): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/country`);
  }

  getCountryByName(countryName: string) {
    return this.http.Get(`${environment.baseUrl}/api/country/${countryName}/info`);
  }

  getStates(): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/country/states`);
  }

  getRigLocationTypes(): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/country/rig-location-types`);
  }
}
