import { Component } from '@angular/core';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'modal-remove-link-origin-job',
  templateUrl: './modal-remove-link-origin-job.component.html'
})
export class ModalRemoveLinkOriginJobComponent {

   confirm:boolean;

   callback: any;

  constructor(
    public modalService: BsModalService
  ) {
  }

  onClose() {
    this.modalService.hide(MODAL_ID.REMOVE_LINK_ORIGIN_JOB);
  }

  btnConfirm_click() {
    this.confirm = true;
    if (this.callback) this.callback();
    this.onClose();
  }
}
