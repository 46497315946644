import { Injectable } from '@angular/core';
import { switchMap, map, tap } from 'rxjs/operators';
import { JobService, FluidService, IfactService } from 'libs/shared/services';
import { PumpSchedule, Job, FluidModel, PumpScheduleEventModel, PumpScheduleStageModel, MaterialManagementMappingModel } from 'libs/models';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { PumpScheduleAdapter } from '../../pump-schedule/adapters';
import { FLUID_TYPE_SCHEDULE } from 'libs/constants';
import { timeConvert, convertStringToTime } from '../../shared/helpers';
import { PumpScheduleService } from '../../pump-schedule/services';
import { UpdateStageTests } from 'apps/vida/src/modules/pump/components/stage-test-results/test-results-mapper';

@Injectable({ providedIn: 'root' })
export class ControlPointAdapter {

    isReady$ = new BehaviorSubject<boolean>(false);
    fluids$ = new BehaviorSubject<FluidModel[]>([]);
    pumpSchedule$ = new BehaviorSubject<PumpSchedule>(null);
    pumpScheduleFormGroup$ = new BehaviorSubject<UntypedFormGroup>(this.fb.group({}));

    constructor(
        private fb: UntypedFormBuilder,
        private jobService: JobService,
        private fluidService: FluidService,
        private pumpScheduleService: PumpScheduleService,
        private pumpScheduleAdapter: PumpScheduleAdapter,
        private unitConversionService: UnitConversionService,
        private iFactsService: IfactService
    ) {
        this.unitConversionService.onSaveUnit$?.subscribe(_ => this.correctFluids());
    }

    correctFluids() {
        this.fluids$.next(
            this.fluids$.value.map(fluid => {
                fluid.displayName = this.fluidService.buildDisplayName(fluid);
                return fluid;
            })
        );
    }


    getJobMultiplePumpSchedule(jobId: string, job: Job, triggerIsReady: boolean = true): Observable<PumpSchedule[]> {
        this.isReady$.next(false);
        return this.pumpScheduleService.getPumpScheduleByJobId(jobId).pipe(
            switchMap(pumps => { 
                return this.getFluidForStage(jobId, job, pumps)
            }),
            map(pump => this.prepareDataForPumpSchedules(pump)),
            tap(_ => {
                if (triggerIsReady) { 
                    this.isReady$.next(true); 
                }})
        );
    }

    getFluidForStage(jobId: string, job: Job, pumps: PumpSchedule[]): Observable<PumpSchedule[]> {
        return this.getFluids(jobId, job).pipe(
            map(fluidModels => {
                return this.mapFluidDataToStage(pumps, fluidModels);
            })
        );
    }


    mapFluidDataToStage(pumps: PumpSchedule[], fluidModels: FluidModel[]): PumpSchedule[] {
        const fluidDic = fluidModels.toDictionary(x => x.id);

        pumps.map(pump => {
            pump.stages = pump.stages.map(stage => {
                if (stage != null && stage.slurry != null) {
                    stage.slurry = fluidDic[stage.slurry.id];
                    UpdateStageTests(stage, stage.slurry);
                }
                return stage;
            });
        });

        return pumps;
    }

    resetJobPumpSchedule() {
        this.pumpSchedule$.next(null);
    }

    public getFluids(jobId: string, job: Job): Observable<FluidModel[]> {
        return this.jobService.getFluidSummary(jobId, false).pipe(
            switchMap(fluidModels => this.fluidService.loadFromIFacts(job, null, ...fluidModels)),
            map(fluidModels => {
                const fluids = fluidModels.map((x) => {
                    x.status = x.testStatus;
                    x.displayName = this.fluidService.buildDisplayName(x);
                    return x;
                });
                return fluids;
            }),
            tap(fluidModels => this.fluids$.next(fluidModels))
        );
    }

    getMaterialMappings(groupId: any): Observable<MaterialManagementMappingModel[]> {
        return this.iFactsService.getMaterialMappings(groupId, null, null);
    }

    private prepareDataForPumpSchedules(pumpSchedules: PumpSchedule[]): PumpSchedule[] {
        pumpSchedules.map(pumpSchedule => {
            this.calculateStage(pumpSchedule);
            if (pumpSchedule.stages && pumpSchedule.stages.length > 0) {
                pumpSchedule.stages.forEach(stage => {
                    stage.isExpandedEvent = false;
                    stage.isExpandedFluid = false;
    
                    const selectedType = this.pumpScheduleAdapter.fluidTypes.find(e => e.id === stage.pumpScheduleFluidTypeId);
                    stage.isMudType = selectedType && (selectedType.name === FLUID_TYPE_SCHEDULE.DRILLING_FLUID_MUD || selectedType.name === FLUID_TYPE_SCHEDULE.MUD);
    
                    stage.selectedFluidId = stage.slurry && stage.slurry.id;
                    const slurry = stage.slurry as FluidModel;
                    if (slurry == null) return;
                    slurry.status = slurry.testStatus;
                    stage.slurry.displayName = this.fluidService.buildDisplayName(stage.slurry);
                });
            }
            let number = 0;
            pumpSchedule.stages.forEach((stage) => {
                if (this.pumpScheduleAdapter.isTopBottom(stage)) {
                    stage.number = -1;
                } else {
                    stage.number = number++;
                }
            });
        })

        return pumpSchedules;
    }

    calculateStage(pumpSchedule: PumpSchedule): PumpSchedule {
        const nonShutdown = (x: PumpScheduleEventModel) => x.placementMethodName !== 'Shutdown';

        pumpSchedule.stages?.forEach(stage => {
            const placementMethodShutdown = this.pumpScheduleAdapter.placementMethodFirstStage$.value.find(e => e.name === 'Shutdown');
            const placementMethodShutdownId = placementMethodShutdown === undefined ? null : placementMethodShutdown.id;
            stage.topOfFluid = this.pumpScheduleAdapter.calculationTopOfFluidStage(stage.events, placementMethodShutdownId);
        });

        pumpSchedule.stages?.filter(x => !this.pumpScheduleAdapter.isTopBottom(x))
            .map(stage => {
                const events = stage.events;
                events.map(event => {
                    if (!nonShutdown(event)) return event;
                    if (event.placementMethodName === 'Volume') {
                        event.duration = this.pumpScheduleAdapter.calculateDuration(event.volume, event.rate);
                    }
                    return event;
                });
                stage.placementTime = events.sum(x => Number(x.duration));
                stage.avgRate = events.filter(nonShutdown).sum(x => x.volume) / events.filter(nonShutdown).sum(x => Number(x.duration));
                // stage.topOfFluid = this.calculationTopOfFluidStage(events, null);
                stage.plannedVolume = events.sum(x => x.volume);

                if (isNaN(stage.avgRate) || !isFinite(stage.avgRate)) {
                    stage.avgRate = null;
                }

                return stage;
            })
            .reduceRight((result: PumpScheduleStageModel[], stage: PumpScheduleStageModel) => {
                if (result.length >= 1) {
                    stage.placementTime += result[result.length - 1].placementTime;
                }
                result.push(stage);
                return result;
            }, []).map(stage => {
                const events = stage.events && stage.events.length ? stage.events : [];
                if (events.length) {
                    const firstNonShutDownIndex = events.findIndex(nonShutdown);
                    stage.placementTime -= events.slice(0, firstNonShutDownIndex >= 0 ? firstNonShutDownIndex : events.length).sum(x => Number(x.duration));
                } else {
                    stage.placementTime = 0;
                }
                stage.minThickeningTime = timeConvert(stage.placementTime + convertStringToTime(pumpSchedule.scheduledShutdown) + convertStringToTime(pumpSchedule.targetSafetyFactor));
                if (stage.thickeningTime != null) {
                    stage.actualSafety = timeConvert(convertStringToTime(stage.thickeningTime) - stage.placementTime);
                }

                return stage;
            });

        return pumpSchedule;
    }
}
