export class RiskAssessment {
  virtualRelativeFilePath: string;
  filePathDownload: string;
  isLastUsed: boolean;
  uploader: string;
  createdDate: string;
  description: string;
  
  constructor() {
    this.virtualRelativeFilePath = null;
    this.filePathDownload = null;
    this.isLastUsed = false;
    this.uploader = null;
    this.createdDate = null;
    this.description = null;
  }

  getFileName() {
    return this.virtualRelativeFilePath ? this.virtualRelativeFilePath.split('/').pop() : '';
  }
}
