<div class="sidebar-block">
  <div class="sidebar-header">
    <h1 class="title">Call Sheet Upload</h1>
  </div>
  <div class="sidebar-content" [loadingIndicator]="loadingSubscription">
  
    <div class="file-upload">
      <job-uploader #uploader [listFiles]="callSheetFiles" [jobId]="jobId" (uploadCancelEmitter)="uploadCancel($event)"
        (uploadSuccessEmitter)="uploadSuccess($event)" (uploadInprogressEmitter)="uploadInprogress($event)"
        [destinationFolderPath]="destinationFolderPath" [header]="'File and Description'" [title]="'Upload a Call Sheet file with a Description.'"
        [drapDropDescription]="'or Drag and Drop Call Sheet File Here'" [isEnableDescriptionInput]="true" [vidaFileType]="vidaFileType"
        [badgeNumber]=true [displayMode]="'Vertical'">
      </job-uploader>
    </div>
  
    <div class="files-list m-top-2">
      <span _ngcontent-c10="" class="badge-number">2</span> <label>Call Sheet Selection</label>
      <p>Select a Call Sheet and enter Approval Information.</p>
      <div class="row">
        <div class="col-sm-2 form-group">
          <strong>Select</strong>
        </div>
        <div class="col-sm-3 form-group">
          <strong>File Name</strong>
        </div>
        <div class="col-sm-7 form-group ">
          <strong>Approval</strong>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col-sm-2 form-group radio-button" style="text-align: center;">
            <p-radioButton [value]="true" [(ngModel)]="statusNoCallSheet" (click)="disableOtherRadioButton($event)"></p-radioButton>
          </div>
          <div class="col-xs-3 form-group file-name">
            No Call Sheet
          </div>
        </div>
      </div>
      <div *ngFor="let item of (callSheetFb ? callSheetFb['controls'].callSheet['controls'] : [])">
        <call-sheet-selection-details [callSheet]="item" [jobId]="jobId" [wellId]="wellId" (valueChange)='disableOtherRadioButton($event)'></call-sheet-selection-details>
      </div>
    </div>
  
  </div>
  <div class="sidebar-footer">
    <a class="btn lnk-cancel" (click)="cancelCallSheet()">Cancel</a>
    <button type="button" class="btn primary-btn" (click)="saveCallSheet()" [disabled]="disableSaveButton">SAVE</button>
  </div>
</div>