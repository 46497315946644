import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SpacerMixMethods, UnitType } from 'libs/constants';
import { ApplicationStateService, ControlPointService2 } from 'libs/shared/services';
import { OverlayPanel } from 'primeng/overlaypanel';
import { concat, Observable, of, combineLatest, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, startWith } from 'rxjs/operators';
import { ControlPointState, ControlPointType, DesignCriteriaOption } from '../../../shared/constant';
import { ViewState } from '../../view-state';
import { PumpScheduleStateManager } from '../../state/schedule-state-manager';
import { PumpSchedule } from 'libs/models';
import { JobModalManagementService } from 'libs/ui/services/job-modal-management.service';
import { PumpScheduleStageStateManager } from '../../models/pump-schedule-stage-state-manager.model';
import { result } from 'lodash';

@Component({
    selector: 'shoetrack',
    templateUrl: './shoetrack.component.html',
    styleUrls: ['./shoetrack.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoeTrackComponent implements OnInit, OnDestroy {

    public readonly UnitType = UnitType;

    public readonly ControlPointState = ControlPointState;

    public readonly spacerMixMethods = SpacerMixMethods;

    public isJobTypeLinerUpdated = true; // updated from job edit screen

    @Input()
    public readonly scheduleState: PumpScheduleStateManager;

    @Input()
    public readonly listPumpSchedule$: Observable<PumpSchedule[]>;

    @Input()
    public namePumpSchedule: string;

    @Input()
    public isCmtSvc: boolean = true;

    @Input()
    public listPumpScheduleStageStateManager$: Observable<PumpScheduleStageStateManager[]>;

    @Input() 
    public hasScope3access: boolean;

    @Input()
    public controlPointType: ControlPointType;

    @Input() submitStatus: boolean;

    @Input() completeStatus:boolean;

    public linerParamsVisibleChanged$: Observable<boolean>;

    public isLinerParamsRequired$: Observable<boolean>;
    public isLinerParamsVisible$: Observable<boolean>;

    public capturedLinerParamsRestrictions = undefined;
    public isLinerParamsRequired: boolean;
    public isLinerParamsVisible: boolean;
    designCriteriaOption = DesignCriteriaOption;

    private subscription: Subscription = new Subscription();

    constructor(

        private readonly _applicationStateService: ApplicationStateService,
        private jobModalManagementService: JobModalManagementService,
        private controlPointService: ControlPointService2
    ) {
    }

  private get _viewState(): ViewState {
        return this.scheduleState.viewState;
    }

    public get pumpScheduleForm(): UntypedFormGroup {

        return this.scheduleState.form;
    }

    public get isScheduleEditView(): boolean {

        return this._viewState.isScheduleEditView;
    }

    public get isScheduleCp4View(): boolean {

        return this._viewState.isCP4View;
    }

    public get isJobTypeLiner(): boolean {

        if (this._viewState.isJobTypeLiner != undefined && this.isJobTypeLinerUpdated == undefined)
        {
            this.isJobTypeLinerUpdated = this._viewState.isJobTypeLiner;
        }
        return this._viewState.isJobTypeLiner;
    }

    public get isJobLiner(): Observable<boolean> {
        return this.linerParamsVisibleChanged$;
    }

    public get isScheduledShutdownRequired(): boolean {
      return this.isCmtSvc && this.isJobTypeLinerUpdated;
    }

    public get linerParamsVisible(): boolean {
        return this._viewState.isJobTypeLiner && (this._viewState.isScheduleEditView || this._viewState.isCP1View || this._viewState.isCP2View)
    }

    public get enablingCPStates$(): Observable<boolean> {

        return this._viewState.isCP1Submitted$
            .pipe(
                map(isCP1Submitted => {

                    return !this._viewState.isJobCompleted
                        && (isCP1Submitted || this._viewState.isCP2Approved)
                        && !this._viewState.isCP2Completed
                        && !this._viewState.isCP4Completed;
                }),
                shareReplay()
            );
    }

    public get isThickeningTimeDisable$(): Observable<boolean> {
        return combineLatest([
            this.controlPointService.controlPoint1State$,
            this.controlPointService.controlPoint2State$
        ]).pipe(
            map(([cp1State, cp2State]) => {
                const isCP1SubmittedOrApproved = cp1State === ControlPointState.Submitted 
                    || cp1State === ControlPointState.Approved;
                    
                const isCP2PreparedOrCompleted = cp2State === ControlPointState.Prepared 
                    || cp2State === ControlPointState.Completed;

                if (this._viewState.isCP1View) {
                    return isCP1SubmittedOrApproved;
                }

                if (this._viewState.isCP2View) {
                    return isCP2PreparedOrCompleted;
                }

                if (this.isScheduleEditView) {
                    return isCP1SubmittedOrApproved && isCP2PreparedOrCompleted;
                }

                return false;
            })
        );
    }

    public ngOnInit(): void {

        this.linerParamsVisibleChanged$ = concat(
            of(this.isJobTypeLiner),
            this._applicationStateService.jobTypeChanged
                .pipe(
                    map(jobType => {

                        this.isJobTypeLinerUpdated = jobType.context.isLiner; // update from job edit screen

                        return jobType.context.isLiner
                            && (this._viewState.isScheduleEditView || this._viewState.isCP1View || this._viewState.isCP2View);
                    })
                )
        );

        this.isLinerParamsRequired$ = combineLatest([
                this.listPumpScheduleStageStateManager$.pipe(map(list => list[0]?.scheduleState?.viewState?.isJobTypeLiner)),
                this._applicationStateService.jobEditOpened$.pipe(startWith(undefined)),
                this._applicationStateService.jobClassification$.pipe(startWith(undefined)),
                this._applicationStateService.jobTypeChanged.pipe(startWith(undefined))
            ]).pipe(
                switchMap((([isJobTypeLiner, _, jobClassificationState, jobType]) => {
                    
                    if (this._viewState.controlPointNumber == 2) {
                        return of(false);
                    }
                    
                    const isLiner = jobType?.context?.isLiner ?? isJobTypeLiner;
                    const isCmtSvc = jobClassificationState?.cmtSvc ?? this.isCmtSvc;
                    const isLinerParamsRequired = isLiner && isCmtSvc;
                    this.isLinerParamsRequired = this._applicationStateService.jobEditOpened$.value
                        ? isLinerParamsRequired
                        : this.capturedLinerParamsRestrictions?.isLinerParamsRequired ??(this.isCmtSvc && isJobTypeLiner);

                    return of(this.isLinerParamsRequired);
                }))
            );

        this.isLinerParamsVisible$ = combineLatest([
                this.listPumpScheduleStageStateManager$.pipe(map(list => list[0]?.scheduleState?.viewState?.isJobTypeLiner)),
                this._applicationStateService.jobEditOpened$.pipe(startWith(undefined)),
                this._applicationStateService.jobTypeChanged.pipe(startWith(undefined))
            ]).pipe(
                switchMap((([isJobTypeLiner, _, jobType]) => {

                    const isLiner = jobType?.context?.isLiner ?? isJobTypeLiner;
                    this.isLinerParamsVisible = this._applicationStateService.jobEditOpened$.value
                        ? isLiner
                        : this.capturedLinerParamsRestrictions?.isLinerParamsVisible ?? isJobTypeLiner;

                    return of(this.isLinerParamsVisible);
                }))
            );

        this.subscription.add(
            this.jobModalManagementService.jobEditSaveButtonWasPushed$.subscribe(_ => {
                this.capturedLinerParamsRestrictions = {
                    isLinerParamsVisible: this.isLinerParamsVisible,
                    isLinerParamsRequired: this.isLinerParamsRequired
                };
            })
        );

        this.subscription.add(
            this.jobModalManagementService.$saveError.subscribe(_ => {
                this.capturedLinerParamsRestrictions = undefined;
            })
        );

        // this.jobModalManagementService.$cancelJob.subscribe(_ => {
        //     console.error('$cancelJob');
        //     this.isJobTypeLinerUpdated = this.isJobTypeLiner; // restore original jobtype when it is changed on edit job but not saved
        // });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSelectedOverlayPanel(event, overlayPanelCurrent: OverlayPanel, overlayPanelDisplayed: OverlayPanel) {

        overlayPanelDisplayed.hide();
        overlayPanelCurrent.toggle(event);
    }

    public openTTDocument(event:Event){
        event.preventDefault();
        this.controlPointService.getTestResultDocumentUrl(this.designCriteriaOption.ThickeningTime).subscribe(result =>{
            window.open(result.url, '_blank');
        });
    }
}
