import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'libs/environment';
import { IRigModel, RigTypeModel } from 'libs/models';
import { HttpService } from './http.service';
import { IAdditionalParams, IPagingResultModel } from 'apps/vida/src/modules/shared/models';
import { JobCustomRig } from 'libs/models/entities/job-custom-rig';
import {shareReplay} from 'rxjs/operators';

@Injectable()
export class RigService {

  constructor(private http: HttpService) { }

  getJobRig(jobId): Observable<JobCustomRig> {
    return this.http.Get<JobCustomRig>(`${environment.baseUrl}/api/rigs/job/${jobId}`).pipe(shareReplay())
  }

  saveJobRig(model: JobCustomRig): Observable<boolean> {
    return this.http.Post(`${environment.baseUrl}/api/rigs/job-rig`, JSON.stringify(model))
  }

  removeJobRig(jobId) {
    return this.http.Delete(`${environment.baseUrl}/api/rigs/job/${jobId}`)
  }

  updateLockedRig(jobId: string, rigId: string) {
    return this.http.Post(`${environment.baseUrl}/api/rigs/update-locked/${jobId}/${rigId}`, {});
  }

  checkLockedValidity(jobId: string, rigId: string) {
    return this.http.Post<boolean>(`${environment.baseUrl}/api/rigs/check-locked-validity/${jobId}/${rigId}`, {});
  }

  updateRigList(model: JobCustomRig):Observable<boolean>  {
    return this.http.Put(`${environment.baseUrl}/api/rigs/update-rig-list`, JSON.stringify(model))
  }

  getRigsByGroupId(groupId: string, additionalParams: IAdditionalParams): Observable<IPagingResultModel<IRigModel>> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.http.Get(`${environment.baseUrl}/api/rigs/groups/${groupId}/rigs?${params}`);
  }

  getRigBySalesOrder(salesOrder: string, groupId: string): Observable<IRigModel> {
    return this.http.Get(`${environment.baseUrl}/api/rigs/sales-order/${salesOrder}?groupId=${groupId}`);
  }

  getDwlRigType() {
    return this.http.Get<RigTypeModel[]>(`${environment.baseUrl}/api/rigs/rig-types`);
  }

  saveRigs(groupId: string, model: IRigModel): Observable<boolean> {
    let request: Observable<boolean>;
    if (!model.rigId) {
      request = this.http.Post(`${environment.baseUrl}/api/rigs/groups/${groupId}/rigs`, JSON.stringify(model));
    } else {
      request = this.http.Put(`${environment.baseUrl}/api/rigs/groups/${groupId}/rigs`, JSON.stringify(model));
    }
    return request;
  }

  isRigInJob(model: IRigModel) {
    return this.http.Post(`${environment.baseUrl}/api/rigs/is-rig-in-job`, JSON.stringify(model));
  }

  deleteRig(rigId: string): Observable<IRigModel> {
    return this.http.Delete<IRigModel>(`${environment.baseUrl}/api/rigs/${rigId}`);
  }

  isExisted(model: IRigModel) {
    return this.http.Post(`${environment.baseUrl}/api/rigs/is-existed`, JSON.stringify(model));
  }

  getActiveRigByGroup(groupId: string, rigId: any): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/rigs?groupId=${groupId}&selectedRigId=${rigId}`);
  }

  getAllRigByGroup(groupId: string): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/rigs/${groupId}/all`);
  }

  getAllRigActiveByGroup(groupId: string): Observable<any> {
    return this.http.Get(`${environment.baseUrl}/api/rigs/${groupId}/active`);
  }
}
